import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import dbInvoice from '../../../firebasecomponents/invoice';
import Page from '../../page';
import Form from '../../../components/Form';
import InvoiceExtractDataForm from './InvoiceExtractDataForm';
import Log from '../../../newHelpers/Log';
const Logger = new Log('InvoiceAddedDetails');

class AddedInvoiceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialComponentState;
    this.handleChange = this.handleChange.bind(this);
  }

  get initialComponentState() {
    return {
      invoiceDetails: null,
      invoiceId: null,
      success: null,
      error: null,
      loading: true,
    };
  }

  handleChange(e) {
    if (e.success) {
      this.setState({ success: e.success });
      setTimeout(
        () =>
          this.props.history.replace(
            '/company/invoiceManagement/' +
              this.props.currentCompany.companyId +
              '/' +
              this.props.currentCompany.companyName,
          ),
        500,
      );
    }
    if (e.error) {
      Logger.log('error', e);
      this.setState({ error: e.error });
    }
    this.setState({ invoiceDetails: e.invoice });
  }

  async componentDidMount() {
    let invoiceId = this.props.match.params.invoiceId;
    await dbInvoice.getInvoiceData(invoiceId, (response) => {
      this.setState({
        invoiceDetails: { ...response },
        loading: false,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.invoiceDetails &&
      !_.isEqual(this.state.invoiceDetails, prevState.invoiceDetails) &&
      _.isEqual(this.state.error, prevState.error)
    ) {
      this.setState({
        success: 'Updated',
      });
    }
  }


  render() {
    const invoice = this.state.invoiceDetails;

    let imagePreviewUrl = invoice ? invoice.base64Value : null;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview =
        imagePreviewUrl.includes('.jpg') ||
        imagePreviewUrl.includes('.PNG') ||
        imagePreviewUrl.includes('.jpeg') ||
        imagePreviewUrl.includes('.png') ? (
          <img
            id="uploadedImage"
            src={imagePreviewUrl}
            border="1"
            align="middle"
            alt=""
            style={{ width: '341px', height: '550px' }}
            className="center"
          />
        ) : (
          <object
            data={imagePreviewUrl}
            type="application/pdf"
            width="100%"
            height="100%"
            style={{ minHeight: '89vh' }}
          />
        );
    } else {
      imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }
    let invoiceFormHeader = null;
    if (
      invoice &&
      invoice.parashift &&
      invoice.parashift.current_status === 'done'
    ) {
      invoiceFormHeader = 'Extract Data Results';
    }

    const invoiceForm = invoice ? (
      <Page
        role="Company"
        header="Invoice Details"
        currentPage="invoiceManagement"
      >

          <Grid container spacing={3} style={{ height: '100%' }}>
            <Grid item xs={5}>
              {imagePreview}
            </Grid>
            <Grid item xs={7}>

              <Form
                success={this.state.success}
                error={this.state.error}
                style={{ height: '100%' }}
                maxwidth={'100%'}
                close={() => this.setState({ error: undefined, success: undefined })}
               />
              <InvoiceExtractDataForm
                invoice={invoice}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
        {/*</Form>*/}
      </Page>
    ) : null;

    return this.state.loading ? <CircularProgress /> : invoiceForm;
  }
}

const mapStateToProps = (state) => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
  };
};

export default withRouter(connect(mapStateToProps, {})(AddedInvoiceDetails));
