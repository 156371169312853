import React, { Component } from 'react'
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

//import TextField from '@material-ui/core/TextField';
import TextField from '../../newComponents/TextField'
import Dropzone from '../../newComponents/Dropzone';

import { SuccessButton, FailButton } from '../../newComponents/Button';
import { updateCompany, companyClearDone } from '../../store/actions'


import './settings.scss'

class GeneralInformation extends Component {

  constructor(props) {
    super(props);

    this.state = this.initialState;

    this.handleChange = this.handleChange.bind(this);
    this.accept = this.accept.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  get initialState() {
    return {
      details: this.props.currentCompany,
      done: this.props.done || false,
      changed: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.done !== this.props.done) {
      if (this.props.done) {
        this.props.companyClearDone()
      }
      this.setState({ changed: false, done:false })
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.setState({ details: this.props.currentCompany });
    }
  }

  cancel() {
    this.setState({ details: this.props.currentCompany, changed: false });
  }

  accept() {
    const details = this.state.details;
    const currentDetails = this.props.currentCompany;
    details.companyName = details.companyName === '' ? currentDetails.companyName : details.companyName;
    details.firstName = details.firstName === '' ? currentDetails.firstName : details.firstName;
    details.lastName = details.lastName === '' ? currentDetails.lastName : details.lastName;

    this.props.updateCompany(this.props.currentCompany.companyId, details)
  }

  handleChange = (field) => (newValue) => {
    this.setState({
      details: { ...this.state.details, [field]: newValue },
      changed: true
    }
    );
  };

  render() {
    const data = this.state.details;

    if (data === undefined) {
      return <CircularProgress></CircularProgress>
    }

    let logoPreviewUrl = data.logo ? data.logo.data : null;

    return <div >
      <div className="form">
        <div className="logo">
          {logoPreviewUrl ?
            <img className="logoImg" src={logoPreviewUrl} alt={data.companyName} /> : null}
          <Dropzone
            label={logoPreviewUrl ? "Change logo" : "Click or drag file with square logo to this area to upload"}
            acceptedTypes={['image']}
            square={{ size: 100 }}
            onChange={this.handleChange('logo')} />
        </div>
        <Grid container spacing={4}>
          <Grid item xs={6} container spacing={4}>
            <Grid item xs={6}>
              <TextField
                required
                label="Company Name"
                disabled
                value={data.companyName}
                onChange={this.handleChange('companyName')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="VatNr"
                value={data.companyVatNr}
                onChange={this.handleChange('companyVatNr')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Street and Number"
                value={data.streetAndNumber}
                onChange={this.handleChange('streetAndNumber')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="City"
                value={data.city}
                onChange={this.handleChange('city')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Post Code"
                onChange={this.handleChange('postCode')}
                value={data.postCode}
              />
            </Grid>
          </Grid>
          <div className='divider'></div>
          <Grid item xs={6} container spacing={4}>
            <Grid item xs={6}>
              <TextField
                required
                label="First Name"
                value={data.firstName}
                onChange={this.handleChange('firstName')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Last Name"
                value={data.lastName}
                onChange={this.handleChange('lastName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                label="E-mail Address"
                value={data.emailId}
                onChange={this.handleChange('emailId')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Phone"
                value={(data.telephoneNumber)}
                onChange={this.handleChange('telephoneNumber')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Topal: Forderungen"
                value={(data.forderungsAccount)}
                onChange={this.handleChange('forderungsAccount')}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      {this.state.changed || this.state.done ?
        <div style={{ marginTop: '10px' }}>
          <SuccessButton
            loading={this.state.done}
            full
            onClick={this.accept}
          >
            Save changes
                  </SuccessButton>
          <FailButton
            full
            style={{ marginLeft: '10px' }}
            onClick={this.cancel}
          >
            Cancel Changes
                  </FailButton>
        </div> : null}
    </div >
  }
}


const mapStateToProps = state => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
    done: state.CompanyReducer.done,
  };
}

export default connect(mapStateToProps, { updateCompany, companyClearDone })(GeneralInformation);
