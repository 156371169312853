
function checkSteuerkonferenz(taxAmount, amount, policy) {
  const type = taxAmount.type;
  const task = taxAmount.policyFields;
  switch (type) {
    case 'travel expense':
      if(task.train){
        const trainOK = task.train === 'Second' || policy.trainClass === '1st';
        return { compliant: trainOK, field: 'train', max: policy.trainClass };
      }
      if(task.plane){
        const planeOK = task.plane === 'Economy' || (task.plane === 'Business' && policy.planeClass !== 'First') || policy.planeClass === 'First'
        return { compliant: planeOK, field: 'plane', max: policy.planeClass };
      }
      return undefined
    case 'food expense':
      switch (task.meal) {
        case 'Breakfast':
          return { compliant: amount <= policy.maxBreakfast, field: 'amount', max: policy.maxBreakfast };
        case 'Lunch':
          return { compliant: amount <= policy.maxLunch, field: 'amount', max: policy.maxLunch };
        case 'Dinner':
          return { compliant: amount <= policy.maxDinner, field: 'amount', max: policy.maxDinner };
        default:
          return undefined;
      }
    case 'lodging costs':
      switch (task.level) {
        case 'Low tier':
          return { compliant: true, field: 'level', max: policy.hotelClass };
        case 'Mid tier':
          return { compliant: policy.hotelClass !== 'low', field: 'level', max: policy.hotelClass };
        case 'High tier':
          return { compliant: policy.hotelClass === 'high', field: 'level', max: policy.hotelClass };
        case 'Guest Gift':
          return { compliant: amount <= policy.giftAmount, field: 'amount', max: policy.giftAmount };
        default:
          return undefined;
      }
    default:
      return undefined;
  }
}

function checkRz52(task, amount, policy) {
  if (task.meal)
    return { compliant: amount <= policy.maxAmount, field: 'amount', max: policy.maxAmount };
  else
    return undefined
}


export const checkPolicy = (task, tableRate, policy) => {
  let rate = { value: 1, multiple: 1 };
  if (tableRate !== 1) {
    rate = tableRate;
  }

  const subCompliant  = task.amounts.map(taxAmount =>{
    const amount = taxAmount.amount * rate.value / rate.multiple;

    switch (policy.type) {
      case 'Steuerkonferenz':
        return checkSteuerkonferenz(taxAmount, amount, policy.policyDetails)
      case 'Rz52':
        return checkRz52(taxAmount.policyFields, amount, policy.policyDetails)
      default:
        return undefined;
    }
  })

  return subCompliant;
}
