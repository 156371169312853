import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import task from '../../firebasecomponents/task';

import { checkPolicy } from '../Policy'

import { Averix, Pwebapps } from '../../externalAPI';

import { SaveButton, LinkButton } from '../Buttons';
import { CircularProgress } from '@material-ui/core';
import {NumberFormatCustom} from "../../newComponents/NumberFormatCustom";

class ComplianceCheckForm extends Component {

  constructor(props) {
    super(props);

    const taskDetails = props.task;

    //this.fetchTaskData = this.fetchTaskData.bind(this);
    this.state = this.state = {
      taskDetails: {
        ...taskDetails,
        reasonForDecline: '',
        //extractedAmount: taskDetails.amounts
      },
      acceptLoading: false,
      declineLoading: false,
      declinedClicked: false,
      automatedCheckLoading: true,// ['travel expense', 'food expense', 'lodging costs'].includes(taskDetails.type),
      compliant: undefined
    };
    this.updatetask = this.updatetask.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);

    this.decline = this.decline.bind(this);
    this.dialogCancel = this.dialogCancel.bind(this);
    this.dialogSubmit = this.dialogSubmit.bind(this);

    if (this.state.automatedCheckLoading) {
      let policy = null;
      task.getPolicy(taskDetails)
        .then(p => {
          policy = p;
          return Pwebapps.getRateDaily(taskDetails.date, taskDetails.currency)
        })
        .then(rate => {
          return checkPolicy(taskDetails, rate, policy)
        })
        .then(compliant => {
          this.setState({
            automatedCheckLoading: false,
            compliantDetails: compliant,
            compliant: compliant.reduce((prev, curr) => prev && curr, true)
          })
        })
        .catch(e => console.log(e))
    }
  }

  handleAmountChange = (idx) => (e) => {
    const task = this.state.taskDetails;
    const taxAmount = task.amounts[idx];
    if (taxAmount.extractedAmount === undefined) {
      taxAmount.extractedAmount = taxAmount.amount;
    }
    // taxAmount.amount = e.target.value;
    // console.log('am' ,taxAmount.amount)
    // this.setState({ taskDetails: task });

    const val = Number(e.target.value)//convert to number
    taxAmount.amount = val;
    // console.log('val', val, typeof val)
    const vat = taxAmount.vat_percentage.value
    // console.log('vat', vat)
    taxAmount.vat_amount = +(taxAmount.amount * vat / (100 + vat)).toFixed(2);
    // console.log('tax', taxAmount.vat_amount, typeof taxAmount.vat_amount)

    this.setState({ taskDetails: task });
  }

  handleChange = (e) => {
    const task = this.state.taskDetails;
    task[e.target.name] = e.target.value;
    this.setState({ taskDetails: task });
  }
  decline() {
    const task = this.state.taskDetails;
    task.reasonForDecline = '';
    this.setState({ taskDetails: task, declinedClicked: true });
  }
  dialogCancel() {
    this.setState({ declinedClicked: false });
  }

  dialogSubmit() {
    this.setState({ declinedClicked: false, declineLoading: true });

    const taskDetails = this.state.taskDetails;
    const { reasonForDecline, taskId } = taskDetails;

    task.updateTask({
      compliant: false,
      taskId,
      task_defination: 'manager-approval',
      reasonForDecline
    }).then(res => {
      taskDetails.task_defination = 'manager-approval';
      return Averix.complianceCheck({
        compliant: false,
        expenseId: taskId
      });
    }).then(res => {
      this.setState({ declineLoading: false });
      this.props.onChange({ task: taskDetails, success: 'You have declined policy compliance!' });
    }).catch(err => {
      this.setState({ declineLoading: false });
      this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
    });
  }

  updatetask(type) {
    return () => {
      this.setState({ acceptLoading: true });

      const taskDetails = this.state.taskDetails;
      const { taskId } = taskDetails;

      task.updateTask({
        compliant: type,
        taskId,
        task_defination: 'manager-approval',
        amounts: taskDetails.amounts
      }).then(res => {
        taskDetails.task_defination = 'manager-approval';
        return Averix.complianceCheck({
          compliant: true,
          expenseId: taskId
        });
      }).then(res => {
        this.setState({ acceptLoading: false });
        this.props.onChange({ task: taskDetails, success: 'You have accepted policy compliance!' });
      }).catch(err => {
        this.setState({ acceptLoading: false });
        this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
      });
    }
  }

  getAdditionalFields(policyFields) {
    if (policyFields.train !== undefined) return { label: "Train class", value: policyFields.train };
    if (policyFields.plane !== undefined) return { label: "Plane class", value: policyFields.plane };
    if (policyFields.meal !== undefined) return { label: "Meal type", value: policyFields.meal };
    if (policyFields.level !== undefined) return { label: "Lodging type", value: policyFields.level };
    return false;
  }

  render() {
    const task = this.state.taskDetails;
    const compliant = this.state.compliantDetails;

    return (
      <Grid container spacing={3}>
        {
          task.reimbursable !== undefined ?
            <Grid item xs={12}>
              <Typography variant="subtitle1">Expense is proposed to be <strong>{task.reimbursable ? '' : 'non-'}reimbursible</strong></Typography>
            </Grid>
            : null
        }{
          this.state.automatedCheckLoading ? <Grid item xs={12}><CircularProgress></CircularProgress></Grid> : null
        }
        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="merchant" label="Merchant" value={(task.merchant)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="date" label="Date" value={(task.date)}
          />
        </Grid>
        {
          task.amounts ? task.amounts.map((elem, idx) => {
            const add = this.getAdditionalFields(elem.policyFields);
            return (
              <Grid item xs={12} key={idx}>
                <strong style={{ textTransform: 'uppercase', marginRight: '10px' }}>{idx + 1}. {(elem.type)}</strong>
                {
                  compliant && compliant[idx] !== undefined ?
                    (compliant[idx].compliant ?
                      <Typography variant="subtitle1" style={{color:'green'}}><CheckIcon/> The expense is <strong>COMPLIANT</strong> with the policy</Typography> :
                      <Typography variant="subtitle1" style={{color:'red'}}><CloseIcon/>The expense is <strong>NONCOMPLIANT</strong> with the policy, because {compliant[idx].field} field extends max value of {compliant[idx].max}</Typography>
                    )
                    : null
                }
                {add ? add.label + ': ' + add.value : null}<br />
                <TextField
                  name="amount"
                  label="Amount"
                  disabled
                  value={elem.amount}
                  onChange={this.handleAmountChange(idx)}
                  // type="number"
                  InputProps={{
                    inputComponent:NumberFormatCustom
                  }}
                />
                <span style={{ marginTop: '24px', display: 'inline-block' }}>VAT amount: {elem.vat_amount} {task.currency} ({elem.vat_percentage.name})</span>
              </Grid>);
          }) : null
        }
        <Grid item xs={6}>
          <SaveButton fullWidth loading={this.state.acceptLoading} onClick={this.updatetask(true)}>
            <CheckIcon /> Approve
                    </SaveButton>
        </Grid>
        <Grid item xs={6}>
          <SaveButton fullWidth loading={this.state.declineLoading} onClick={this.decline}>
            <CloseIcon />Decline</SaveButton>
        </Grid>
        <Grid item xs={6}>
          <SaveButton fullWidth loading={this.state.declineLoading} onClick={this.updatetask('unknown')}>
            <CloseIcon />Context Info needed</SaveButton>
        </Grid>
        <Grid item xs={6}>
          <LinkButton fullWidth to='/' color="muted">Cancel</LinkButton>
        </Grid>
        <Dialog
          open={this.state.declinedClicked}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="reasonForDecline"
              label="Reason for decline"
              type="text"
              fullWidth
              value={(this.state.reasonForDecline)}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.dialogCancel} color="primary">
              Cancel
                    </Button>
            <Button onClick={this.dialogSubmit} color="primary">
              Submit decline
                    </Button>
          </DialogActions>
        </Dialog>
      </Grid>

    );
  }
}
export default ComplianceCheckForm;
