import React, {useState} from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { setCompany, getCompanies } from "../../store/actions";
import logo from '../../assets/clexx-logo.png';
import './companySelection.scss'
import Log from "../../newHelpers/Log";
const Logger = new Log('CompanySelection');

function CompanySelection(props) {

  const { currentCompany, onCollapse, currentUser, companies, isCollapsed} = props;
  let {companiesToSelect} = props;

  const [collapsed, setCollapsed] = useState(isCollapsed || false);
  const [companySelection, setCompanySelection] = useState(false);

  let { companyId, companyName } = useParams();
  let mainPage = false;

  if (!companyId) {
    mainPage = true;
    companyId = props.companyId
  }

  if (currentCompany && currentCompany.companyId !== companyId) {
    Logger.log('company selection setting comp', currentCompany, 'new ID: ', companyId)
    props.setCompany(companyId);
  }
  if (!companiesToSelect) {
    if(currentUser.currentRole!=='Approver'){
      props.getCompanies();
    } else{
      companiesToSelect=companies;
    }
  }

  const collapse = () => {
    onCollapse(!collapsed);
    setCollapsed(prev => !prev);
  }

  const cN = (companyName ? companyName : currentCompany ? currentCompany.companyName : '-');
  let fontSize = 400 / cN.length;

const clexxLogo = <img id='logo' src={logo} alt="Clexx logo" align="middle" />
const companyLogo = currentCompany && currentCompany.logo? <img id='logo' src={ currentCompany.logo.data} alt={companyName} align="middle" className="companyLogo"/> : clexxLogo;
  return (
    <div className="header">
      <Link to="/" className="logo">
        {companyLogo}
      </Link>
      {collapsed ? null :
        <div className="currentCompany">
          <h1 style={{ 'fontSize': fontSize > 28 ? 28 : fontSize + 'px' }}>{cN}</h1>
          {companyId && !mainPage ? <span className="selectCompany" onClick={() => setCompanySelection(!companySelection)}><ArrowDropDownIcon /></span> : null}
        </div>
      }
      <button className="expansion" onClick={collapse}>
        {collapsed ? <DehazeIcon /> : <ChevronLeftIcon />}
      </button>
      {collapsed && ! mainPage ? null :
        <div className={'companySelection' + (companySelection ? ' opened' : '')}>
          {
            companiesToSelect ? companiesToSelect.map(
              c => <Link key={c.companyId} to={'/company/managerApproval/' + c.companyId + '/' + c.companyName} onClick={() => setCompanySelection(false)}>{c.companyName}</Link>
            ) : null
          }
        </div>
      }
    </div>
  );
}

const mapStateToProps = state => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
    companiesToSelect: state.CompanyReducer.companiesToSelect
  };
}

export default connect(mapStateToProps, { setCompany, getCompanies })(CompanySelection);
