/**
 * Returns reconciliation accounts, given company's props
 * @param props
 * @returns {*|*[]}
 */
export const getReconciliationAccounts = (props) => {
  const mapping = props.currentCompany ? props.currentCompany.mapping : undefined;

  return mapping && mapping.reconciliation && mapping.reconciliation.length > 0
    ? mapping.reconciliation.map((e) => e.name)
    : [];
};
