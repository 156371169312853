import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


import Form from '../Form';

class SteuerkonferenzPolicy extends Component {
  constructor(props) {
    super(props);
    const { details } = props;

    this.state = {
      minAmount: 0,
      trainClass: '',
      planeClass: '',
      perKilometer: 0,
      maxBreakfast: 0,
      maxLunch: 0,
      maxDinner: 0,
      hotelClass: '',
      giftAmount: 0,
      ...details
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ ...state });
    this.props.onChange(state);
  }

  render() {
    const data = this.state;
    const inputProps = {readOnly: this.props.isReadOnly, style: {textAlign: 'right'}}
    return (
      <Grid container spacing={1} >
        <Grid item xs={6}>Minimum Amount per Expense</Grid>
        <Grid item xs={6}>
          <TextField fullWidth variant="outlined" required
            name="minAmount" type="number"
            onChange={this.handleChange}
            value={data.minAmount}
            inputProps={{ min: "0", ...inputProps }}
          />
        </Grid>
        <Grid item xs={6}>Train Class</Grid>
        <Grid item xs={6}>
          <Select fullWidth variant="outlined" required
            name="trainClass"
            onChange={this.handleChange}
            value={data.trainClass}
            inputProps={inputProps}
          >
            <MenuItem value="1st">1st</MenuItem>
            <MenuItem value="2nd">2nd</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>Plane Class</Grid>
        <Grid item xs={6}>
          <Select fullWidth variant="outlined" required
            name="planeClass"
            onChange={this.handleChange}
            value={data.planeClass}
            inputProps={inputProps}
          >
            <MenuItem value="Eco">Economy</MenuItem>
            <MenuItem value="Business">Business</MenuItem>
            <MenuItem value="First">First</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>Reimbursement per kilometer</Grid>
        <Grid item xs={6}>
          <TextField fullWidth variant="outlined" required
            name="perKilometer" type="number"
            onChange={this.handleChange}
            value={data.perKilometer}
            inputProps={{ min: "0",  ...inputProps }} />
        </Grid>
        <Grid item xs={6}>Max. Breakfast</Grid>
        <Grid item xs={6}>
          <TextField fullWidth variant="outlined" required
            name="maxBreakfast" type="number"
            onChange={this.handleChange}
            value={data.maxBreakfast}
            inputProps={{ min: "0",  ...inputProps }} />
        </Grid>
        <Grid item xs={6}>Max. Lunch</Grid>
        <Grid item xs={6}>
          <TextField fullWidth variant="outlined" required
            name="maxLunch" type="number"
            onChange={this.handleChange}
            value={data.maxLunch}
            inputProps={{ min: "0",  ...inputProps }} />
        </Grid>
        <Grid item xs={6}>Max. Dinner</Grid>
        <Grid item xs={6}>
          <TextField fullWidth variant="outlined" required
            name="maxDinner" type="number"
            onChange={this.handleChange}
            value={data.maxDinner}
            inputProps={{ min: "0",  ...inputProps }} />
        </Grid>
        <Grid item xs={6}>Regular Hotel Class</Grid>
        <Grid item xs={6}>
          <Select fullWidth variant="outlined" required
            name="hotelClass"
            onChange={this.handleChange}
            value={data.hotelClass}
            inputProps={inputProps}>
            <MenuItem value="low">Low tier</MenuItem>
            <MenuItem value="mid">Mid tier</MenuItem>
            <MenuItem value="high">High tier</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>Max. Amount for gift to hosts (overnight stay)</Grid>
        <Grid item xs={6}>
          <TextField fullWidth variant="outlined"
            name="giftAmount" type="number"
            onChange={this.handleChange}
            value={data.giftAmount}
            inputProps={{ min: "0",  ...inputProps }} />
        </Grid>
      </Grid>)
  }
}

class Rz52Policy extends Component {
  constructor(props) {
    super(props);
    const { details } = props;

    this.state = {
      perKilometer: 0,
      maxAmount: 0,
      ...details
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ ...state });
    this.props.onChange(state);
  }

  render() {
    const data = this.state;
    const inputProps = {readOnly: this.props.isReadOnly, style: {textAlign: 'right'}}
    return (
      <Grid container spacing={1} >
        <Grid item xs={6}>Max effective Amount for lunch and dinner <br />(&le; 35)</Grid>
        <Grid item xs={6}>
          <TextField fullWidth
            inputProps={{ min: "0", max: "35",...inputProps }}
            name="maxAmount"
            type="number"
            variant="outlined"
            onChange={this.handleChange}
            value={data.maxAmount}
          />
        </Grid>
        <Grid item xs={6}>Reimbursement per kilometer <br />(&le; 0.7)</Grid>
        <Grid item xs={6}>
          <TextField fullWidth
            inputProps={{ min: "0", max: "0.7", step: '0.1', ...inputProps }}
            name="perKilometer"
            type="number"
            variant="outlined"
            onChange={this.handleChange}
            value={data.perKilometer}
          />
        </Grid>
      </Grid>)
  }
}


class PolicyForm extends Component {

  constructor(props) {
    super(props);

    const { id, name, type, policyDetails } = props;

    this.state = {
      id: id || Math.random().toString(36).substring(2, 8),
      name: name || '',
      type: type || 'Steuerkonferenz',
      policyDetails: policyDetails || {}
    };

    this.onChangeDetails = this.onChangeDetails.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.policyDetails !== this.props.policyDetails) {
      const { policyDetails } = this.props;
      this.setState({
        policyDetails
      })
    }
  }

  onChangeDetails(newData) {
    this.setState({ policyDetails: newData });
    this.props.onChange(this.state);
  }

  onChange(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ ...state })
    this.props.onChange(state);
  }


  render() {
    return (<Form>
      <Grid container spacing={4} alignItems="flex-end">
        <Grid item xs={1}>{this.props.children}</Grid>
        <Grid item xs={5}>
          <TextField fullWidth
            label="Policy Instance Name"
            name="name"
            onChange={this.onChange}
            value={this.state.name}
          />
        </Grid>
        <Grid item xs={6}>
          {this.props.isReadOnly ? this.state.type :
            <FormControl component="fieldset">
              <RadioGroup
                className="policyRadio"
                aria-label="Policy type"
                name="type"
                onChange={this.onChange}
                value={this.state.type}
              >
                <FormControlLabel value="Steuerkonferenz" control={<Radio />} label="Steuerkonferenz" />
                <FormControlLabel value="Rz52" control={<Radio />} label="Rz. 52" />
              </RadioGroup>
            </FormControl>}
        </Grid>
        <Grid item xs={12} className="framed">
          {this.state.type === 'Steuerkonferenz' ?
            <SteuerkonferenzPolicy onChange={this.onChangeDetails} details={this.state.policyDetails} isReadOnly={this.props.isReadOnly} /> :
            <Rz52Policy onChange={this.onChangeDetails} details={this.state.policyDetails} isReadOnly={this.props.isReadOnly} />
          }
        </Grid>
      </Grid>
    </Form>)
  }

}

export default PolicyForm;
