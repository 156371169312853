import React, { useState } from 'react';
import { connect } from "react-redux";

import Papa from 'papaparse';

import CloseIcon from '@material-ui/icons/Close';

import { uploadStatements } from "../../../store/actions";
import Dropzone from '../../../newComponents/Dropzone';

import CSVFieldMap from './CSVFieldsMap'
import processCSV from './processCSV';
import processXML from './processXML';


const ProcessInputFile = (props) => {

  const { creditCards, employees } = props;

  const [errors, setErrors] = useState([])
  const [typesToMap, setTypesToMap] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const [bestFit, setBestFit] = useState(undefined);

  const fileUploaded = (file) => {
    if (file.type === 'text/csv' || file.name.split('.').pop() === 'csv') {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        error: (err) => {
          setErrors([err.message])
        },
        complete: results => {
          processCSV(results, employees, creditCards)
            .then(info => {
              props.uploadStatements(props.currentCompany.companyId, Object.values(info.entries));
            })
            .catch(data => {
              setBestFit(data.bestFit)
              setRawData(data.rawData);
              setTypesToMap(data.typesToMap)
            })
        }
      })
    // } else if (file.type === "text/xml") {
    //   processXML(file, employees, creditCards)
    //     .then(result => { console.log('xml result', result) })
    //     .catch(err => setErrors(err))
    } else {
      setErrors(['Not a supported file type. Use .csv or .xml (camt.053) file'])
    }
  }

  const applyMap = (data) => {
    if (data === undefined) {
      setTypesToMap(undefined)
      setBestFit(undefined);
    } else {
      processCSV(rawData, employees, creditCards, data)
        .then(info => {
          console.log(info)
          setTypesToMap(undefined)
          props.uploadStatements(props.currentCompany.companyId, info.data);
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  return <>
    {typesToMap && <CSVFieldMap externalTypes={typesToMap} bestFit={bestFit} onChange={applyMap} />}
    <Dropzone
      label={"Click or drag file with statements to this area to upload"}
      onFile={(data) => fileUploaded(data)}
      onChange={() => { }} />
    {errors.length > 0 &&
      <div className="errorBox">
        <CloseIcon style={{ float: 'right' }} onClick={() => this.setState({ errors: [] })} />
        {errors.map((e, idx) => <p key={idx}>{e}</p>)}
      </div>
    }
  </>
}



const mapStateToProps = state => {
  return {
    employees: state.EmployeeReducer.employees,
    currentCompany: state.CompanyReducer.currentCompany,
    creditCards: state.CreditCardReducer.creditCards
  };
}

export default connect(mapStateToProps, { uploadStatements })(ProcessInputFile);
