import firebase from "../components/firebase";

const db = firebase.db;

const Customer = {

  addCustomer: (companyId, customer) => {
    return new Promise((resolve, reject) => {
      db.collection('customer')
        .doc(customer.id)
        .set({
          ...customer,
          companyId
        })
        .then(() => resolve(customer))
        .catch(error => reject(error));
    })
  },
  updateCustomerDetails: (customer) => {
    return new Promise((resolve, reject) => {
      db.collection('customer')
      .doc(customer.id)
        .update(customer)
        .then(ref => resolve(ref))
        .catch(error => reject(error))
    })
  },
  deleteCustomer: (customer) => {
    return new Promise((resolve, reject) => {
      db.collection('customer')
        .doc(customer.id)
        .delete()
        .then(() => resolve(customer))
        .catch(error => reject(error));
    })
  },
  getCustomers: async (companyId, callback) => {
    try {
      db.collection("customer")
        .where("companyId", "==", companyId)
        .where('disabled', "==", false)
        .onSnapshot(doc => callback(doc.docs.map(d => d.data())));
    } catch (error) {
      callback(null);
    }
  }
}

export default Customer;
