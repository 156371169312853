import axios from 'axios';

import firebase from '../components/firebase';

import dbTask from '../firebasecomponents/task';
import employee from '../firebasecomponents/employee';
import accountant from '../firebasecomponents/accountant';
import {clexxAPI} from "./ClexxAPI";
import {teamsAPI} from "../config";

const db = firebase.db;

const headers = { "Access-Control-Allow-Origin": "*" }

const defaultExpenseTypes = [
  "travel expense",
  "food expense",
  "lodging costs",
  "representation expenses",
  "telecommunication",
  "shipping expenses",
  "legal expenses",
  "office supplies",
  "ad expenses",
  "fuel",
  "software",
  "mileage",
  "vehicle maintenance",
  "membership",
  "hosting",
  "purchased services",
  "other"
]

const MsTeams = {
  notify: (conversation, info) => {
    return new Promise(async (resolve, reject) => {

      axios.post(teamsAPI + 'notification', { conversation, info }, headers)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        })
    })
  },

  confirm: (token) => {
    return new Promise(async (resolve, reject) => {
      axios.post(clexxAPI + 'teamsConfirmRegister', { token }, headers)
        .then((response) => {
          if (typeof response.data == 'string') {
            reject(response.data)
          }
          const { conversation } = response.data;
          return axios.post(teamsAPI + 'notification', { conversation, info: 'You have successfully connected accounts.' })
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        })
    });
  },

  newTaskNotification(task) {
    return new Promise(async (resolve, reject) => {
      employee.fetchEmployeeDetails(
        { employeeId: task.empl_emailId },
        async response => {
          const conversation = response.userData.requestConnection ? response.userData.requestConnection.conversation : undefined;
          if (conversation) {
            const emplData = response.userData;
            console.log('empl', emplData)
            const msId = emplData.userMsId;
            const userData = await this.fetchEmployeeDetailsByMsId(msId)
            await axios.post(teamsAPI + 'newTask', {
              conversation,
              user:userData,
              task: {
                taskId: task.taskId,
                receiptNo: task.receiptNo,
                image: task.base64Value ,
                thumbnail: task.thumbnail,
                reimbursable:task.reimbursable
              },
              medium: 'Platform' }, headers)
              .then(response => resolve(task))
              .catch(err => reject(err))
          } else {
            resolve(task)
          }
        },
        err => reject('there was an error during employee conversation fetching', err)
      );
    })
  },

  approvalNotification(task) {
    return new Promise(async (resolve, reject) => {
      employee.fetchEmployeeDetails(
        { employeeId: task.empl_emailId },
        response => {
          const conversation = response.userData.requestConnection ? response.userData.requestConnection.conversation : undefined;
          if (conversation) {

            const hasCurrency = !!task.currency;
            const isCHF = hasCurrency && task.currency === 'CHF';
            const total = isCHF && task.amounts ? task.currency + ' ' + task.amounts.map(a => Math.round(parseFloat(a.amount)*100)/100).reduce((a, b) => Math.round(a*100)/100 + b, 0):
              !isCHF && hasCurrency && task.amounts ? 'CHF ' + task.amounts.map(a => Math.round(parseFloat(a.amountCHF)*100)/100).reduce((a, b) => Math.round(a*100)/100 + b, 0) :
                '-'
            const total_originalCurrency = task.amounts.map(a => Math.round(parseFloat(a.amount)*100)/100).reduce((a, b) => Math.round(a*100)/100 + b, 0)

            axios.post(teamsAPI + 'taskApproval',
              {
                conversation,
                task: {
                  taskId: task.taskId,
                  receiptNo: task.receiptNo,
                  merchant: task.merchant,
                  total:total,
                  approver: firebase.getCurrentUsername(),
                  ...(task.managerComment ? { comment: task.managerComment } : {}),
                  ...(task.approvedBy ? {approvedBy:task.approvedBy} : {}),
                  approve: task.approved,
                  ...(task.currency && task.currency!=='CHF') && {originalAmount:task.currency + ' ' + total_originalCurrency + ' - exchange rate: ' + task.rate}

                }
              },
              headers
            )
              .then(response => resolve(task))
              .catch(err => reject(err))
          } else {
            resolve(task)
          }
        },
        err => reject('there was an error during employee conversation fetching', err)
      );

    })
  },

  async clessEmployeeConnectionNotification(email, confirm) {
    const data = await accountant.getMsTeamsConnection(email)

    const conversation = data.conversation;
    if (conversation) {
      await axios.post(teamsAPI + 'managerConnectionApproval',
        {
          conversation,
          message: confirm ? "You have connected Your Clexx account to Teams" : "You have rejected connection."
        },
        headers
      )
      await accountant.activateMsTeamsConnection(email, confirm);
    }
  },

  async askQuestion(task, questionText) {
    return new Promise(async (resolve, reject) => {
      employee.fetchEmployeeDetails({ employeeId: task.empl_emailId }, async (user) => {
        const empl = user.userData;
        if (!empl || !empl.requestConnection || !empl.requestConnection.conversation) {
          reject();
        }

        const question = await dbTask.createQuestion(task, questionText);

        const conversation = empl.requestConnection.conversation;

        await axios.post(teamsAPI + 'question',
          {
            conversation,
            task: task,
            question
          },
          headers
        )
        resolve();
      });
    });
  },

  async fetchEmployeeDetailsByMsId(id) {
    console.log('id: ', id)
    return new Promise(async (resolve, reject) => {
      db.collection("employee")
        .where("userMsId", "==", id)
        .get()
        .then(async snapshot => {
          if (snapshot.docs.length === 0) {
            return resolve([])
          }
          const id = snapshot.docs[0].id;
          const employee = snapshot.docs[0].data();

          const customerDocs = await db.collection('customer')
            .where("companyId", "==", employee.companyId)
            .where("disabled", "==", false)
            .get()

          const projects = customerDocs.docs.map(d => d.data().projects.filter(p => !p.disabled)).reduce((a, b) => a.concat(b), [])

          const creditCardDocs = await db.collection('creditcard')
            .where("companyId", "==", employee.companyId)
            .where("disabled", "==", false)
            .get()

          const companyCards = creditCardDocs.docs
            .filter(c => c.data().holder === id || c.data().users.includes(id))
            .map(c => c.data())

          const companyDoc = await db.collection('company').doc(employee.companyId).get()

          const expenseTypes = companyDoc.data().mapping ? companyDoc.data().mapping.expense.map(e => e.expenseType) : defaultExpenseTypes

          let CostCenters_array = []
          Object.keys(companyDoc.data().costCenters).forEach(key => CostCenters_array.push(companyDoc.data().costCenters[key]))


          return resolve({
            accountId: id,
            companyId: employee.companyId,
            companyName: employee.companyName,
            emailid: employee.emailid,
            firstName: employee.firstName,
            lastName: employee.lastName,
            fixedCostCenter: employee.fixedCostCenter,
            fixedCustomerProject: employee.fixedCustomerProject,
            projects,
            companyCards,
            CostCenters : CostCenters_array,
            expenseTypes
          })
        }).catch(error => {
        return reject(error);
      })
    });
  }


}

export default MsTeams;
