import invoice from '../../firebasecomponents/invoice';
import {
  invoiceManagementDefaultFilters,
} from "../../newPages/InvoiceManagement/invoiceHelper";
import _ from 'lodash';

const INVOICES_LOADED = 'INVOICES_LOADED';
const INVOICES_UPDATED = 'INVOICES_UPDATED';
const CLEAR_DONE = 'INVOICES_CLEAR_DONE';
const INVOICE_FILTERS_UPDATE = "INVOICE_FILTERS_UPDATE";

const initialState = {
  invoiceFilters: _.cloneDeep(invoiceManagementDefaultFilters)
};

//reducer
export default function reducer(state = initialState, action) {
  if (action.type === INVOICES_LOADED) {
    return { ...state, invoices: action.payload };
  }
  if (action.type === INVOICES_UPDATED) {
    return { ...state, done: true };
  }
  if (action.type === CLEAR_DONE) {
    return { ...state, done: false };
  }
  if (action.type === INVOICE_FILTERS_UPDATE) {
    return { ...state, invoiceFilters: action.payload };
  }
  return state;
}

//actions

export function getInvoices(companyId, filter) {
  console.log(
    'getInvoicesBasedOnCompanyId: company ID: ',
    companyId
  );
  return function (dispatch) {
    invoice.getInvoicesBasedOnCompanyId({ companyId, filter }, (response) =>
      dispatch({ type: INVOICES_LOADED, payload: response }),
    );
  };
}

export function updateInvoice(invoiceDetails) {
  return function (dispatch) {
    invoice.updateInvoice(invoiceDetails).then((res) => {
      dispatch({ type: INVOICES_UPDATED, payload: res });
    });
  };
}

export function clearDoneInv() {
  return function (dispatch) {
    dispatch({ type: CLEAR_DONE, payload: {} });
  };
}

export function updateInvoiceFilters(invoiceFilters) {
  return function (dispatch) {
    dispatch({ type: INVOICE_FILTERS_UPDATE, payload: invoiceFilters });
  };
}
