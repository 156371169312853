import React from 'react'
import { Button } from '@material-ui/core'

import { withRouter } from 'react-router-dom';

import firebase from '../../components/firebase'

function Logout(props) {

  return (
    // <Button onClick={logout} style={{color:'#fff'}}>
    <Button onClick={logout} style={{color:'#9B9B9B'}}>
      Logout
    </Button>
  )

  async function logout() {
    await firebase.logout();
    props.history.push('/')
    window.location.reload()
  }
}

export default withRouter(Logout);
