import React, { Component } from 'react'
import { connect } from "react-redux";
import ScrollArea from 'react-scrollbars-custom';

import { CircularProgress, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import {
  getEmployees,
  updateEmployee,
  employeeClearDone,
  getCreditCards,
  getCustomers,
  modifyCardUser,
  modifyCostCenterUser,
  modifyCustomerUser
} from "../../store/actions";

import { SimpleButton } from '../../newComponents/Button';
import EmployeeDetails from './EmployeeDetails';
import Drawer from '../../newComponents/Drawer';
import Table from '../../newComponents/Table';


import Search from '../../newComponents/Search';
import QuickFilters from '../../newComponents/QuickFilters';
import Message from '../../newComponents/Message';

import validateEmail from '../../newHelpers/validateEmail';

import Page from '../page';

import './employees.scss'
import {getCollapsed, getShowExpenseItems} from "../../newHelpers/pageHelper";

class Employees extends Component {

  constructor(props) {
    super(props)
    this.state = this.initialState;

    this.opening = this.opening.bind(this);
    this.accept = this.accept.bind(this);
    this.cancel = this.cancel.bind(this);
    this.addEmployee = this.addEmployee.bind(this);

    this.policyFilter = this.policyFilter.bind(this);
    this.creditCardFilter = this.creditCardFilter.bind(this);
    this.searchFilter = this.searchFilter.bind(this);

    if (props.currentCompany) {
      props.getEmployees();
      props.getCreditCards({ company: props.currentCompany.companyId });
      props.getCustomers(props.currentCompany.companyId);
    }
  }

  get initialState() {
    return {
      openedEmployee: undefined,
      openedEmployeeCards: undefined,
      acceptLoading: false,
      dataLoading: false,
      editMode: false,
      message: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.props.getEmployees();
      this.props.getCreditCards({ company: this.props.currentCompany.companyId });
      this.props.getCustomers(this.props.currentCompany.companyId);
    }
    if (prevProps.employees !== this.props.employees) {
      this.setState({ employees: this.props.employees })
    }
    if (prevProps.error !== this.props.error) {
      this.setState({ message: this.props.error })
    }
    if (prevProps.done !== this.props.done) {

      if (this.props.done) {
        this.setState({
          acceptLoading: false,
          openedEmployee: undefined,
          openedEmployeeCards: undefined,
          editMode: false
        });
        this.props.employeeClearDone()
      }
    }
  }


  accept() {
    this.setState({ acceptLoading: true })

    const requiredFields = [
      'firstName', 'lastName',
      'streetAndNumber', 'postcode', 'city',
      'emailid', 'personalClexxEmailid',
      //'position',
      'bankName', 'bankplace', 'ibanNr',//'postAccNr',
    ];
    this.setState({ message: '' });

    const employeeData = this.state.openedEmployee;
    const required = requiredFields.filter(k => employeeData[k] === undefined || employeeData[k] === '')
    if (required.length > 0) {
      this.setState({ message: 'All fields are required: ' + required.join(', ') });
      return;
    }

    if (!validateEmail(employeeData.emailid)) {
      this.setState({ message: 'Email is invalid' });
      return;
    }

    if (this.state.openedEmployeeCards) {
      this.state.openedEmployeeCards.forEach(card => {
        this.props.modifyCardUser(card, employeeData.employeeId);
      });
    }

    this.props.updateEmployee(this.state.openedEmployee)

    const prevData = this.props.employees.find(e => e.employeeId === employeeData.employeeId);
    const prevCenter = prevData.fixedCostCenter;
    const currCenter = employeeData.fixedCostCenter;
    const prevCustomer = prevData.fixedCustomerProject;
    const currCustomer = employeeData.fixedCustomerProject;

    if (prevCenter !== currCenter) {
      if(prevCenter !== undefined && prevCenter !== '') this.props.modifyCostCenterUser(prevCenter, employeeData.employeeId);
      if(currCenter !== undefined && currCenter !== '') this.props.modifyCostCenterUser(currCenter, employeeData.employeeId);

    }
    if (prevCustomer !== currCustomer) {
      if(prevCustomer !== undefined && prevCustomer !== '') this.props.modifyCustomerUser(prevCustomer, employeeData.employeeId);
      if(currCustomer !== undefined && currCustomer !== '') this.props.modifyCustomerUser(currCustomer, employeeData.employeeId);
    }
  }
  cancel() {
    this.setState({ openedEmployee: undefined, editMode: false, message: '' })
  }

  policyFilter(item) {
    const filter = this.state.policyFilter
    if (filter === undefined || filter.length === 0) return true;

    const policy = this.props.currentCompany.policies.find(p => p.id === item.policyId).name;
    return filter.includes(policy);
  }
  creditCardFilter(item) {
    const filter = this.state.creditCardFilter;
    if (filter === undefined || filter.length === 0) return true

    return (item.hasCompanyCard && filter.includes('HasCard'));
  }
  searchFilter(item) {
    const filter = this.state.searchFilter
    if (filter === undefined || filter === '') return true;
    return JSON.stringify(item).includes(filter)
  }

  opening(task) {
    return () => this.setState({ openedEmployee: task })
  }
  addEmployee() {
    this.setState({
      openedEmployee: {
        companyId: this.props.currentCompany.companyId,
        companyName: this.props.currentCompany.companyName,
        personalClexxEmailid: Math.random().toString(36).substring(2, 16) + '@clexx.io',
        hasCompanyCard: false
      },
      editMode: true
    })
  }

  render() {
    const tableData = this.props.employees ? this.props.employees
      .filter(this.policyFilter)
      .filter(this.creditCardFilter)
      .filter(this.searchFilter) : []

    const tableBar = this.props.currentCompany ? <div className="tableBar">
      <QuickFilters className="quickFilters" name="Quick Filters" values={['HasCard']} onChange={e => this.setState({ creditCardFilter: e })} />
      {this.props.currentCompany.policies ?
        <QuickFilters
          className="quickFilters"
          name="Policy Filter:"
          values={this.props.currentCompany.policies.map(p => p.name)}
          onChange={e => this.setState({ policyFilter: e })}
        /> : null}
      <Search className="search" onChange={e => this.setState({ searchFilter: e })} />
    </div> : null;


    return <Page role='Company'
                 currentPage="employees"
                 header="Employees list"
                 collapsedNavBar={getCollapsed(this.props)}
                 collapsedExpItems={getShowExpenseItems(this.props)}
                 actions={
      <SimpleButton className="actionButton" full icon={AddIcon} onClick={this.addEmployee}>Add Employee</SimpleButton>
    }>
      {tableBar}
      {this.props.employees && !this.state.dataLoading ?
        <ScrollArea style={{ height: '85vh' }} native>
        <Table
          data={{
            head: {
              name: <strong>Name</strong>,
              department: <span>Department</span>,
              email: <span>Email</span>,
              phone: <span>Phone</span>,
              action: '',
            },
            content: tableData.map(e => {
              return {
                row: {
                  name: <strong>{e.firstName} {e.lastName}</strong>,
                  department: <span>{e.department}</span>,
                  email: <span>{e.emailid}</span>,
                  phone: <span>{e.phone}</span>,
                  action: <ChevronRightIcon onClick={this.opening(e)} />
                },
                sortData: e
              }
            }
            )
          }}
          sortable={true}
        />
        </ScrollArea>
        : <CircularProgress />}
      <Drawer direction="left" opened={this.state.openedEmployee !== undefined}>
        {this.state.openedEmployee ?
          <div className="drawerContainer">
            <div className="drawerHeader">
              <Typography>Employee Details</Typography>
              <span>
                {!this.state.editMode ? <EditIcon onClick={() => this.setState({ editMode: true })} /> : null}
                <MoreVertIcon />
                <CloseIcon onClick={() => this.setState({ openedEmployee: undefined })} />
              </span>
            </div>
            <div className="drawerContent" style={{ width: '775px' }}>
              <ScrollArea
                noScrollX >
                <Message message={this.state.message} type="error" />
                <EmployeeDetails
                  edit={this.state.editMode}
                  employee={this.state.openedEmployee}
                  policies={this.props.currentCompany.policies}
                  onChange={newDetails => this.setState({ openedEmployee: newDetails })}
                  onCardsChange={newCardsList => this.setState({ openedEmployeeCards: newCardsList })}
                />
              </ScrollArea>
            </div>
            <div className="drawerFooter">
              <SimpleButton
                bordered
                style={{ marginRight: '10px' }}
                onClick={this.cancel}
              >
                Cancel
                </SimpleButton>
              <SimpleButton
                loading={this.state.acceptLoading}
                full
                onClick={this.accept}
              >
                Save Employee Details
                </SimpleButton>
            </div>
          </div>
          : null}
      </Drawer>
    </Page>
  }
}


const mapStateToProps = state => {
  return {
    employees: state.EmployeeReducer.employees,
    error: state.EmployeeReducer.error,
    done: state.EmployeeReducer.done,
    currentCompany: state.CompanyReducer.currentCompany,
  };
}

export default connect(mapStateToProps, {
  getEmployees,
  updateEmployee,
  employeeClearDone,
  getCreditCards,
  getCustomers,
  modifyCardUser,
  modifyCostCenterUser,
  modifyCustomerUser
})(Employees);
