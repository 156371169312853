import React, { Component } from 'react'
import { connect } from "react-redux";

import ScrollArea from 'react-scrollbars-custom';

import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import { SimpleButton } from '../../newComponents/Button';
import Drawer from '../../newComponents/Drawer'
import Table from '../../newComponents/Table'


import { updateCompany, companyClearDone } from '../../store/actions'

import PolicyForm from './components/PolicyForm';

import './customization.scss'

class Policies extends Component {

  constructor(props) {
    super(props)
    this.state = this.initialState;

    this.opening = this.opening.bind(this);
    this.accept = this.accept.bind(this);
    this.cancel = this.cancel.bind(this);
    this.updateOpenedPolicy = this.updateOpenedPolicy.bind(this);
    this.addPolicy = this.addPolicy.bind(this);
  }

  get initialState() {
    return {
      policies: this.props.currentCompany ? this.props.currentCompany.policies || [] : [],
      openedIdx: undefined,
      openedPolicy: undefined,
      dataLoading: false,
      acceptLoading: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.setState({ policies: this.props.currentCompany.policies || [] })
    }
    if (prevProps.done !== this.props.done) {
      if (this.props.done) {
        this.setState({
          acceptLoading: false,
          openedIdx: undefined,
          openedPolicy: undefined
        });
        this.props.companyClearDone()
      }
    }
  }

  opening(openedIdx) {
    return () => this.setState({ openedIdx })
  }

  accept(taskPolicy) {
    return () => {
      this.setState({ acceptLoading: true });
      const policies = this.state.policies;
      policies[this.state.openedIdx] = this.state.openedPolicy;
      this.props.updateCompany(
        this.props.currentCompany.companyId,
        { policies }
      )
    }
  }
  cancel() {
    this.setState({
      openedIdx: undefined,
      openedPolicy: undefined,
      policies: this.props.currentCompany.policies
    })
  }
  updateOpenedPolicy(newPolicy) {
    this.setState({ openedPolicy: newPolicy })
  }

  addPolicy() {
    this.setState({
      openedIdx: this.state.policies.length,
      openedPolicy: {},
    })
  }

  render() {
    const policies = this.state.policies;

    const policiesTable = <Table
        data={{
          head: {
            name: <strong>Name</strong>,
            type: <span>Type</span>,
            attachment: <span>Attachment</span>,
            action: ''
          },
          content: policies.map((e, idx) => ({
            row: {
              name: <strong>{e.name}</strong>,
              type: <span>{e.type}</span>,
              attachment: <span>{e.attachment ? e.attachment.fileName : null}</span>,
              action: <ChevronRightIcon onClick={this.opening(idx)} />
            },
            sortData: e
          }))
        }
        }
        sortable={true}
      />;

    const openedPolicy = policies[this.state.openedIdx] || this.state.openedPolicy;

    const drawer = <Drawer direction="left" opened={openedPolicy !== undefined}>
      {openedPolicy ?
        <div className="drawerContainer">
          <div className="drawerHeader">
            <Typography>{openedPolicy !== {} ? 'Policy Details' : 'New Policy'}</Typography>
            <CloseIcon onClick={() => this.setState({ openedPolicy: undefined, openedIdx: undefined })} />
          </div>
          <div className="drawerContent" style={{ width: 775 }}>
            <ScrollArea style={{ height: '100%' }} noScrollX >
              <PolicyForm
                policy={openedPolicy}
                onChange={this.updateOpenedPolicy}
              />
            </ScrollArea>
          </div>
          <div className="drawerFooter">
            <SimpleButton
              bordered
              style={{ marginRight: '10px' }}
              onClick={this.cancel}
            >
              Cancel
                  </SimpleButton>
            <SimpleButton
              loading={this.state.acceptLoading}
              full
              onClick={this.accept(openedPolicy)}
            >
              Save Changes
                  </SimpleButton>
          </div>
        </div>
        : null}
    </Drawer>

    return <div className="actionPanel">
      <SimpleButton className="actionButton" full icon={AddIcon} onClick={this.addPolicy}>Add policy</SimpleButton>
      {policiesTable}
      {drawer}
    </div>
  }
}


const mapStateToProps = state => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
    done: state.CompanyReducer.done
  };
}

export default connect(mapStateToProps, { updateCompany, companyClearDone })(Policies);