import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { calculateCreditorInvoicesTotal } from '../navigationHelper';

const CreditorView = ({ invoices, url }) => {
  const [totalCreditors, setTotalCreditors] = useState('0');
  const [totalCreditors10d, setTotalCreditors10d] = useState('0');
  const [totalCreditorsOverdue, setTotalCreditorsOverdue] = useState('0');
  const [totalCreditorsNotApproved, setTotalCreditorsNotApproved] = useState(
    '0',
  );
  const [notApprovedAmount, setNotApprovedAmount] = useState(0);
  const [
    totalCreditorsNotApproved10d,
    setTotalCreditorsNotApproved10d,
  ] = useState('0');
  const [
    totalCreditorsNotApprovedOverdue,
    setTotalCreditorsNotApprovedOverdue,
  ] = useState('0');

  useEffect(() => {
    if (invoices) {
      const calcVals = calculateCreditorInvoicesTotal(invoices);
      setTotalCreditors(calcVals.totalCreditorSum);
      setTotalCreditors10d(calcVals.totalCreditor10d);
      setTotalCreditorsOverdue(calcVals.totalCreditorOverdue);
      setTotalCreditorsNotApproved(calcVals.totalCreditorNotApproved);
      setTotalCreditorsNotApproved10d(calcVals.totalCreditorsNotApproved10d);
      setTotalCreditorsNotApprovedOverdue(
        calcVals.totalCreditorsNotApprovedOverdue,
      );
      setNotApprovedAmount(calcVals.notApprovedAmount);
    }
  }, [invoices]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="box-style creditorBox"
    >
      <div className="title">
        <Typography variant="h6" className="grey2">
          Open Supplier Invoices
        </Typography>
        <Link
          to={{
            pathname: url,
            state: { showAllCreditor: true },
          }}
          style={{ textDecorationLine: 'none', alignSelf: 'flex-end' }}
        >
          <Typography className="purple">Show All</Typography>
        </Link>
      </div>
      <div className="borderDiv" />
      <div style={{ marginTop: '18px' }} />
      <Grid container spacing={0} style={{}}>
        {/*Left Side*/}
        <Grid item xs={6} style={{ borderRight: '1px solid #E0E0E0' }}>
          <Typography>Total Open Invoices</Typography>
          <Typography variant="h3" className="black">
            {totalCreditors}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Due in the next 10 days:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Overdue:</Typography>
            </Grid>
            <Grid item xs={6} className="alignedGridItem">
              <Typography variant="h6" className="blackalt">
                {totalCreditors10d}
              </Typography>
            </Grid>
            <Grid item xs={6} className="alignedGridItem">
              <Typography variant="h6" className="blackalt">
                {totalCreditorsOverdue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*Right Side*/}
        {/*padding of 32 comes from 24/2px in the middle + 20px*/}
        <Grid item xs={6} style={{ paddingLeft: '32px' }}>
          <div style={{ position: 'relative' }}>
            <Typography>Not Yet Approved</Typography>
            <Link
              to={{
                pathname: url,
                state: { showOpenCreditor: true },
              }}
              style={{ textDecorationLine: 'none' }}
            >
              <div className="circle absolute">
                <p className="textInCircle">{notApprovedAmount}</p>
              </div>
            </Link>
          </div>
          <Typography variant="h3">{totalCreditorsNotApproved}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Due in the next 10 days:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Overdue:</Typography>
            </Grid>
            <Grid item xs={6} className="alignedGridItem">
              <Typography variant="h5">
                {totalCreditorsNotApproved10d}
              </Typography>
            </Grid>
            <Grid item xs={6} className="alignedGridItem">
              <Typography variant="h5">
                {totalCreditorsNotApprovedOverdue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CreditorView;
