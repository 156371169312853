import axios from 'axios';
import { clexxAPI } from './ClexxAPI';
import Log from '../newHelpers/Log';
const Logger = new Log('Pwebapps API');

const Pwebapps = {
  getRateDaily: (date, currency) => {
    return new Promise((resolve, reject) => {
      if (currency === 'CHF') resolve(1);

      const strDate = date.toString().replaceAll('-', '');
      Logger.log('getDailyRate', strDate);

      const headers = { 'Access-Control-Allow-Origin': '*' };

      axios
        .get(clexxAPI + 'getRateDaily?date=' + strDate + '&curr=' + currency, headers)
        .then((response) => {
          Logger.log('response', response);
          const kurse = response.data.data.wechselkurse.devise;
          Logger.log('currency', currency);
          const entry = kurse.find((cur) => cur['$']['code'] === currency.toString().toLowerCase());
          Logger.log('en', entry);
          const value = parseFloat(entry.kurs[0]);
          Logger.log('val', value);
          const multiple = parseFloat(entry.waehrung[0]);
          Logger.log('mul', multiple);
          resolve({ value, multiple });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default Pwebapps;
