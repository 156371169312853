import firebase from '../components/firebase';
import { currentDateStamp } from '../components/dateHelpers';
import Log from '../newHelpers/Log';
const Logger = new Log('Statements');

const db = firebase.db;

const Statements = {
  deleteStatement: async (statement) => {
    Logger.log('del statement', statement);
    return new Promise((resolve, reject) => {
      db.collection('statements')
        .doc(statement.id)
        .delete()
        .then((res) => {
          Logger.log('statement successfully deleted');
          resolve(res);
        })
        .catch((err) => {
          console.error('Error removing statement: ', err);
        });
    });
  },
  getStatement: async (request, callback) => {
    Logger.log('request', request);
    const id = request.id;
    Logger.log('fb comp get Statement', id);
    db.collection('statements')
      .doc(id)
      .get()
      .then((res) => {
        callback({
          ...res.data(),
          id: id,
        });
      })
      .catch((err) => {
        console.error('Error removing statement: ', err);
      });
  },
  updateStatement: async (request, callback) => {
    //used by reconciliation
    Logger.log('request', request);
    const { statement } = request;

    Logger.log('fb comp update Statement', statement.id);
    Logger.log('fb comp update Statement', statement);

    db.collection('statements')
      .doc(statement.id)
      .update({
        updatedAt: currentDateStamp(),
        ...statement,
      })
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        console.error('Error updating statement: ', err);
      });
  },
};

export default Statements;
