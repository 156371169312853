import React from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../Select';
import { getCustomers } from '../../store/actions';

function CustomerSelection(props) {
  const { customers, label, value, onChange, ...rest } = props;

  if (!customers) {
    props.getCustomers();
  }

  let projects = [];
  if (customers) {
    customers.forEach((e) => {
      projects.push(
        <MenuItem key={e.id} value={e.id} disabled>
          {e.name}
        </MenuItem>,
      );
      projects = [
        ...projects,
        ...e.projects.map((p) => (
          <MenuItem key={p.id} value={e.id + '@' + p.id}>
            - {p.name}
          </MenuItem>
        )),
      ];
    });
  }

  return projects.length > 0 ? (
    <Select
      label={label}
      onChange={(data) => props.onChange(data)}
      value={value || ''}
    >
      {projects}
      <MenuItem value="">NONE</MenuItem>
    </Select>
  ) : (
    <div>Add customers to select "Customer Project"</div>
  );
}

export const CustomerSelect = connect(
  (state) => ({ customers: state.CompanyReducer.customers }),
  { getCustomers },
)(CustomerSelection);

function CostCenterSelection(props) {
  const { costCenters, label, value, onChange, ...rest } = props;
  let costCentersList = [];
  if (costCenters) {
    Object.values(costCenters).forEach((e) => {
      costCentersList.push(
        <MenuItem key={e.id} value={e.id}>
          {e.name}
        </MenuItem>,
      );
      costCentersList = [
        ...costCentersList,
        ...e.projects.map((p) => (
          <MenuItem key={p.id} value={e.id + '@' + p.id}>
            - {p.name}
          </MenuItem>
        )),
      ];
    });
  }

  return costCentersList.length > 0 ? (
    <Select
      label={label}
      onChange={(data) => props.onChange(data)}
      value={value || ''}
    >
      {costCentersList}
      <MenuItem value="">NONE</MenuItem>
    </Select>
  ) : (
    <div>Add cost center to select " Cost Center Project"</div>
  );
}
export const CostCenterSelect = connect(
  (state) => ({ costCenters: state.CompanyReducer.currentCompany.costCenters }),
  {},
)(CostCenterSelection);

function DebitAccountSelection(props) {
  const { debitAccounts, label, value, onChange, ...rest } = props;
  if (debitAccounts && debitAccounts.length === 1) {
    return (
      <dl>
        <dt>{`${debitAccounts[0].name}`}</dt>
        <dd>{`${debitAccounts[0].iban}`}</dd>
      </dl>
    );
  } else if (debitAccounts && debitAccounts.length > 1) {
    let debitAccountsList = [];
    Object.values(debitAccounts).forEach((e) => {
      debitAccountsList.push(
        <MenuItem key={e.id} value={e.id}>
          {e.name}
        </MenuItem>,
      );
    });
    return (
      <Select
        label={label}
        onChange={(data) => props.onChange(data)}
        value={value || ''}
      >
        {debitAccountsList}
        <MenuItem value="none">NONE</MenuItem>
      </Select>
    );
  } else {
    return <div>Bank Accounts need to be added and synced from Topal first</div>;
  }
}
export const DebitAccountSelect = connect(
  (state) => ({
    debitAccounts: state.CompanyReducer.currentCompany.topalBankAccounts,
  }),
  {},
)(DebitAccountSelection);

function TopalCostCenterSelection(props) {
  const { costCenters, label, value, onChange, ...rest } = props;
  let costCentersList = [];
  if (costCenters) {
    costCenters.forEach((e) => {
      if (!e.isGroup) {
        const group = costCenters.find((x) => x.id === e.groupId);
        const groupName = group ? `${group.name} - ` : '';
        costCentersList.push(
          <MenuItem key={e.id} value={e.id}>
            {groupName}
            {e.code} - {e.name}
          </MenuItem>,
        );
        // costCentersList = [...costCentersList, ...e.projects.map(p => <MenuItem key={p.id} value={e.id + '@' + p.id}>- {p.name}</MenuItem>)]
      }
    });
  }

  return costCentersList.length > 0 ? (
    <Select
      label={label}
      onChange={(data) => props.onChange(data)}
      value={value || ''}
    >
      {costCentersList}
      <MenuItem value="">NONE</MenuItem>
    </Select>
  ) : (
    <div>Cost Centers need to be added and synced from Topal first</div>
  );
}
export const TopalCostCenterSelect = connect(
  (state) => ({
    costCenters: state.CompanyReducer.currentCompany.topalCostCenters,
  }),
  {},
)(TopalCostCenterSelection);
