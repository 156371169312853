
import firebase from '../../components/firebase';

const USER_LOGIN = "USER_LOGIN";
const USER_LOGOUT = "USER_LOGOUT";

/** reducer */
export default function reducer(state = {}, action) {
  if (action.type === USER_LOGIN) {
    return { ...state, currentUser: action.payload.user, currentRole: action.payload.role };
  }
  if (action.type === USER_LOGOUT) {
    return { ...state, currentUser: undefined, currentRole: '-' };
  }
  return state;
};

/** actions */

export function authStateChanged() {
  return function (dispatch) {
    if(firebase.auth.currentUser){
      firebase.getCurrentUserRole().then((r) => {
        dispatch({ type: USER_LOGIN, payload: { user: firebase.auth.currentUser, role: r } });
      })
    } else {
      dispatch({ type: USER_LOGOUT, payload: {} });
    }
  };
}

