import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import { SmallPage } from '../page'

function Page404(props) {
  return (
    <SmallPage>
404 - Page not found
      <Button
        type="submit"
        fullWidth
        variant="contained"
        component={Link}
        to="/"
        color="secondary">
        Home
      </Button>
    </SmallPage>
  )
}

export default Page404;
