export const getCollapsed = props => {
  if(props && props.location && props.location.state && props.location.state.collapsed) {
    return true
  } else{
    return false;
  }
}
export const getShowExpenseItems = props => {
  if(props && props.location && props.location.state && props.location.state.showExpenseItems) {
    return true
  } else{
    return false;
  }
}
