import React from 'react'
import PDFViewer from 'mgr-pdf-viewer-react'

import Grid from '@material-ui/core/Grid';

import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import Dropzone from '../../../newComponents/Dropzone';
import Select from '../../../newComponents/Select';
import TextField from '../../../newComponents/TextField';

import '../reports.scss';

const types = ['Booking Report', 'Customers Report', 'Employee Report', 'Project Report'];

const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


export function pertiodForm(period) {
  const [year, month] = period.split('#');
  return Months[month - 1] + ' ' + year;
}

function ReportDetails(props) {
  const { report, add, onChange, onFileChange } = props;
  console.log('props', props);
  const handleChange = (field) => (value) => {
    console.log('handleChange', field);
    onChange({ ...report, [field]: value })
  }

  console.log('report', report);
  let imagePreviewUrl = report ? report.src : null;
  console.log('imagePreviewUrl', imagePreviewUrl);
  let imagePreview = null;
  if (imagePreviewUrl && imagePreviewUrl.data) {
    imagePreview = imagePreviewUrl.data.includes('.pdf') || imagePreviewUrl.data.includes('/pdf') ?
    <PDFViewer document={{ url: imagePreviewUrl.data }} /> :
      <Typography>{imagePreviewUrl.fileName}</Typography>
    //<embed src={imagePreviewUrl.data} width="100%" height="100%" type="application/pdf" />;
  } else if (imagePreviewUrl) {
    imagePreview = imagePreviewUrl.includes('.pdf') || imagePreviewUrl.includes('/pdf') ?
      <PDFViewer document={{ url: imagePreviewUrl }} /> :
      <Typography>{imagePreviewUrl.fileName}</Typography>
  } else
   {
    imagePreview = (<Dropzone
      label="Click or drag Report file to this area to upload"
      onChange={onFileChange} />);
  }

  return report ? (
    <Grid container spacing={3} style={{ alignContent: 'baseline' }}>
      <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
        <Select onChange={handleChange('type')} value={report.type || ''} label="Type of the report" disabled={!add}>
          {types.map((e, idx) => {
            return (<MenuItem key={idx} value={e} >{e}</MenuItem>)
          })}
        </Select>
      </Grid>
      <Grid item xs={6}>
        {add ? <div className="datePicker">
          <label>Report period</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              views={["year", "month"]}
              value={report.period}
              InputAdornmentProps={{ position: "start" }}
              onChange={handleChange('period')}
            />
          </MuiPickersUtilsProvider>
        </div> :
          <TextField value={pertiodForm(report.period)} disabled label="Report Period" />
        }
      </Grid>
      <Grid item xs={12} style={{ height: '100%' }}>
        {imagePreviewUrl
        // && add
          ? <Dropzone style={{ marginBottom: '10px' }}
          label="Change Report file"
          onChange={handleChange('src')} /> : null}
        {imagePreview}
      </Grid>
    </Grid>
  ) : null;
}

export default ReportDetails;
