import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

function Message(props) {
  const [showMessage, setShowMessage] = useState(props.message && props.message !== '');

  useEffect(() => {
    setShowMessage(props.message && props.message !== '');
  }, [props.message])
  let bg = '#fff';
  switch (props.type) {
    case 'error': bg = '#ffa7a7'; break;
    case 'success': bg = '#e0efae'; break;
    case 'warning': bg = '#f1e775'; break;
    default: bg = '#fff';
  }


  return showMessage ? (<Card style={{ backgroundColor: bg, maxWidth:'900px', margin:'10px 0' }} raised={false}>
    <IconButton onClick={() => setShowMessage(false)} style={{ float: 'right' }}>
      <CloseIcon />
    </IconButton>
    <CardContent>{props.message}</CardContent>
  </Card>) : null
}

export default Message;