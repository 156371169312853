import React from 'react';
import { connect } from 'react-redux';
import Page from '../page';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {
  FailButton,
  SimpleButton,
  SuccessButton,
  SuccessButtonOrange,
} from '../../newComponents/Button';
import Search from '../../newComponents/Search';
import { getInvoices, updateInvoice, clearDoneInv } from '../../store/actions';
import CloseIcon from '@material-ui/icons/Close';
import ScrollArea from 'react-scrollbars-custom';
import _ from 'lodash';
import InvoiceDetails from './components/InvoiceDetails';
import Topal from '../../firebasecomponents/topal';
import Drawer from '../../newComponents/Drawer';
import QuickFilters from '../../newComponents/QuickFilters';
import {
  stateFilter,
  searchFilter,
  dateFilter,
  typeFilter,
  states,
  getInitState,
  checkNewOpenRejectedState,
  paymentComplete,
  invoiceManagementDefaultFilters,
} from './invoiceHelper';
import { getCollapsed, getShowExpenseItems } from '../../newHelpers/pageHelper';
import { Link } from 'react-router-dom';
import Log from '../../newHelpers/Log';
import InvoiceTable from './components/InvoiceTable';
import './InvoiceManagement.scss';
import { updateInvoiceFilters } from '../../store/ducks/invoice';

const Logger = new Log('InvoiceManagement');

class InvoiceManagement extends React.Component {
  constructor(props) {
    super(props);
    Logger.log('inv props', props);
    this.state = getInitState(props);
    this.keyListener = this.keyListener.bind(this);

    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
    this.transfer = this.transfer.bind(this);

    if (this.props.currentCompany) {
      //if props are present when mounting, get invoices, (task defination not needed)
      this.props.getInvoices(this.props.currentCompany.companyId, {});
    }
  }

  //-------------LIFECYCLE----------------
  async componentDidMount() {
    window.addEventListener('keydown', this.keyListener);

    const fetch = async () => {
      if (
        await Topal.checkTopalOnline({
          companyID: this.props.currentCompany.companyId,
        })
      ) {
        this.setState({ online: true });
      }
    };
    if (this.props.currentCompany && this.props.currentCompany.companyId) {
      await fetch();
    }
  }

  componentWillUnmount() {
    //event listener for esc key to close detail
    window.removeEventListener('keyup', this.keyListener);
  }
  keyListener(e) {
    if (e.key === 'Escape') {
      //event listener for esc key to close detail
      this.setState({ openedInvoice: undefined });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.updateDone !== this.props.updateDone) {
      //update done, reset
      if (this.props.updateDone) {
        this.props.clearDoneInv();
      }
      this.setState({
        // openedInvoice: undefined,
        dataLoading: false,
        errors: [],
        reset: false,
        acceptLoading: false,
        declineLoading: false,
        syncDone: true,
      });
      Logger.log('state', this.state);
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      // get invoices if changed company
      this.setState({ dataLoading: true });
      this.props.getInvoices(this.props.currentCompany.companyId, {});
      const fetch = async () => {
        if (
          await Topal.checkTopalOnline({
            companyID: this.props.currentCompany.companyId,
          })
        ) {
          this.setState({ online: true });
        } else {
          console.error('topal is not online');
        }
      };
      fetch();
    }
    if (prevProps.invoices !== this.props.invoices) {
      //invoices changed, setFilters and length -> used to show how many invoices were filtered out
      Logger.log('Invoices updated');
      this.setState({ dataLoading: false });
      this.setDateFilters();

      this.setState({ invoicesCounter: this.props.invoices.length });
      // check if there is an open invoice, and overwrite the opened invoice with the updated data.
      // this ensures that the open invoice does not have 'old' data that would overwrite the current data.
      if (this.state.openedInvoice) {
        Logger.log('has openInv');
        const openInv = this.props.invoices.find((x) => x.id === this.state.openedInvoice.id);
        Logger.log('has openInv: invoice:', openInv);
        this.setState({ openedInvoice: openInv });
      }
    }
    if (prevProps.invoiceFilters !== this.props.invoiceFilters) {
      Logger.log('invoice filter changed');
      this.setState({ invoiceFilters: this.props.invoiceFilters });
    }
  }

  //-----------------------------------------
  decline = (item) => {
    //declining an invoice
    Logger.log('decline item', item);
    if (!this.state.declineLoading) {
      Logger.log('declining');
      this.setState({ declineLoading: true });
      this.props.updateInvoice({ invoiceId: item.id, state: 'declined' });
    }
  };

  accept = (item) => {
    //to accept an invoice. after accepting can transfer
    Logger.log('accept item', item);
    if (item.debitAccounts && !this.state.acceptLoading) {
      Logger.log('accepting');
      this.setState({ acceptLoading: true });
      this.props.updateInvoice({
        invoiceId: item.id,
        state: 'open',
        fibuError: null,
      });
    } else {
      //must have selected a debit account
      Logger.log('error');
      this.setState({ errors: ['Please Select a Debit Account'] });
    }
  };

  transfer = async (item) => {
    //transfering an accepted invoice generates the accounting file for topal
    Logger.log('transfer item', item);
    if (item.debitAccounts && !this.state.acceptLoading) {
      Logger.log('transferring');
      this.setState({ acceptLoading: true });
      this.props.updateInvoice({ invoiceId: item.id, state: 'approved' });

      const transactiontext = '_';
      Topal.toFibu({
        invoiceId: item.id,
        fibu: 'topal',
        transactiontext,
      }).catch((err) => {
        this.setState({ errors: [err] });
      });
      setTimeout(() => {
        this.setState({ acceptLoading: false });
        this.setState({ openedInvoice: undefined });
      }, 500);
    } else {
      this.setState({ errors: ['Please Select a Payment Account'] });
      return;
    }
  };

  //----------------------------------------
  /*
  opens invoiceDetails drawer on the right,
  & checks & sets debitAccounts if not set already
  & syncs invoice if it has already been sent to topal
   */
  openInvoice(invoice) {
    //opening of invoice Details drawer on the right
    return () => {
      //check if debit accounts have been set already
      if (invoice.debitAccounts) {
        Logger.log('has debit account');
      } else {
        // set debit account
        Logger.log('setting default debit');
        const defaultDebitAccount =
          this.props.currentCompany &&
          this.props.currentCompany.topalBankAccounts &&
          this.props.currentCompany.topalBankAccounts.find((x) => x.default === true);
        //already write in DB the updated state
        this.props.updateInvoice({
          invoiceId: invoice.id,
          debitAccounts:
            defaultDebitAccount && defaultDebitAccount.id ? defaultDebitAccount.id : '',
        });
      }
      if (invoice.topalFiscalID) {
        Logger.log('has topalFiscalID account');
      } else {
        const defaultTopalFiscalID =
          this.props.currentCompany &&
          this.props.currentCompany.topal &&
          this.props.currentCompany.topalFiscalYears &&
          this.props.currentCompany.topalFiscalYears.find(
            (x) => x.name === this.props.currentCompany.topal.fiscalYear
          );
        Logger.log('topalFiscalID id', defaultTopalFiscalID.id);

        this.props.updateInvoice({
          invoiceId: invoice.id,
          topalFiscalID:
            defaultTopalFiscalID && defaultTopalFiscalID.id ? defaultTopalFiscalID.id : '',
        });
      }
      this.setState({
        openedInvoice: {
          ...invoice,
        },
      });
      if (invoice.topalID) {
        this.setState({ syncDone: false });
        Topal.checkInvoiceTopalState(invoice.invoiceId).then((res) => {
          this.setState({ syncDone: res.syncDone });
        });
      }
    };
  }

  handleUpdateInvoice = (newDetails) => {
    Logger.log('handleUpdateInvoice newdetails: ', newDetails);
    this.props.updateInvoice({
      invoiceId: this.state.openedInvoice.id,
      ...(newDetails.debitAccounts && {
        debitAccounts: newDetails.debitAccounts,
      }),
      ...((newDetails.toPay || newDetails.toPay === false) && {
        toPay: newDetails.toPay,
      }),
      ...((newDetails.costCenter || newDetails.costCenter === '') && {
        costCenter: newDetails.costCenter,
      }),
      ...((newDetails.topalFiscalID || newDetails.topalFiscalID === '') && {
        topalFiscalID: newDetails.topalFiscalID,
      }),
    });
  };

  onDueDateChange = (invoice) => {
    this.props.updateInvoice({
      invoiceId: invoice.id,
      dueDateScheduled: invoice.dueDateScheduled,
    });
    Logger.log('invoice onduedatechange:', invoice);
    if (invoice.state === 'approved') {
      Topal.invoiceDueDateChange({
        invoiceId: invoice.id,
        dueDateScheduled: invoice.dueDateScheduled,
      }).then((r) => {
        Logger.log('onDueDateChange res', r);
      });
    }
  };

  onChangeFilter = (type) => (e) => {
    this.props.updateInvoiceFilters({
      ...this.state.invoiceFilters,
      [type]: e,
    });
    this.setState({ reset: false });
  };

  //----------------------------------------

  resetFilters = () => {
    //resets all filters that were applied
    const stateFilters = _.cloneDeep(invoiceManagementDefaultFilters.stateFilter);
    const typeFilters = _.cloneDeep(invoiceManagementDefaultFilters.typeFilter);
    const { dateFilter, initialFromDate, initialUntilDate } = this.getDateFilters();

    const t = {
      stateFilter: stateFilters,
      typeFilter: typeFilters,
      searchFilter: '',
      dateFilter: {
        ...dateFilter,
        fromDate: initialFromDate,
        untilDate: initialUntilDate,
      },
    };
    this.props.updateInvoiceFilters({
      stateFilter: stateFilters,
      typeFilter: typeFilters,
      searchFilter: '',
      dateFilter: {
        ...dateFilter,
        fromDate: initialFromDate,
        untilDate: initialUntilDate,
      },
    });
    this.setState({ reset: true });
  };

  getDateFilters = () => {
    //setting of date filters
    //DateFormat: YYYY-MM-DD
    let invoices = this.props.invoices;
    let min = moment();
    let max = moment();

    if (invoices) {
      for (let i = 0; i < invoices.length; i++) {
        let invoice = invoices[i];
        let d = moment(invoice.dueDate);
        if (d.isBefore(min, 'days')) min = d;
        if (d.isAfter(max, 'days')) max = d;
      }
    }

    const fromDates = moment(min).format('YYYY-MM-DD');
    const untilDates = moment(max).format('YYYY-MM-DD');
    return {
      dateFilter: { fromDates, untilDates },
      initialFromDate: fromDates,
      initialUntilDate: untilDates,
    };
  };

  setDateFilters = (reset = false) => {
    const { dateFilter, initialFromDate, initialUntilDate } = this.getDateFilters();

    this.props.updateInvoiceFilters({
      ...this.state.invoiceFilters,
      ...((reset || !this.state.invoiceFilters.dateFilter) && {
        dateFilter,
      }),
      initialFromDate: initialFromDate,
      initialUntilDate: initialUntilDate,
    });
  };

  handleDate = (f) => (e) => {
    const t = moment(e).format('YYYY-MM-DD');
    this.props.updateInvoiceFilters({
      ...this.state.invoiceFilters,
      dateFilter: { ...this.state.invoiceFilters.dateFilter, [f]: t },
    });
  };

  getInvoiceULR() {
    if (this.props.currentCompany && this.state.openedInvoice) {
      return `/company/invoiceManagement/${this.props.currentCompany.companyId}/${this.props.currentCompany.companyName}/invoice/${this.state.openedInvoice.id}`;
    } else {
      return '';
    }
  }
  getCompanyURL() {
    if (this.props.currentCompany) {
      return `/company/invoiceManagement/${this.props.currentCompany.companyId}/${this.props.currentCompany.companyName}/addInvoice/`;
    } else {
      return '';
    }
  }

  //--------------------------------------
  render() {
    const tableData = this.props.invoices
      ? this.props.invoices
          .filter((e) => typeFilter(e, this.state.invoiceFilters.typeFilter))
          .filter((e) => dateFilter(e, this.state.invoiceFilters.dateFilter))
          .filter((e) => searchFilter(e, this.state.invoiceFilters.searchFilter))
          .filter((e) => stateFilter(e, this.state.invoiceFilters.stateFilter))
      : [];
    const invoiceURL = this.getInvoiceULR();
    const addInvoiceURL = this.getCompanyURL();

    return (
      <Page
        className="invoiceManagement"
        role="Company"
        header="Invoice Management"
        currentPage="invoiceManagement"
        collapsedNavBar={getCollapsed(this.props)}
        collapsedExpItems={getShowExpenseItems(this.props)}
        actions={
          <div
            ref={(element) => {
              if (element) element.style.setProperty('position', 'relative', 'important');
            }}
          >
            <Link to={{ pathname: addInvoiceURL }} style={{ textDecorationLine: 'none' }}>
              <SimpleButton className="actionButton invoiceAction" full icon={AddIcon}>
                Add Invoice
              </SimpleButton>
            </Link>
          </div>
        }
      >
        {this.props.invoices &&
        this.state.invoiceFilters.dateFilter &&
        this.state.invoiceFilters.dateFilter.fromDates !== '' ? (
          <div>
            {this.state.errors.length > 0 && (
              <div className="errorBox">
                <CloseIcon
                  style={{ float: 'right' }}
                  onClick={() => this.setState({ errors: [] })}
                />
                {this.state.errors.map((e, idx) => (
                  <p key={idx}>{e}</p>
                ))}
              </div>
            )}

            <div className="invoiceTableBar">
              <span className="quickFilterMargin">Quick Filters:</span>
              <QuickFilters
                style={{ minWidth: '15em', maxWidth: '20em' }}
                className="quickFilters"
                name="Type"
                values={['Kreditor', 'Debitor']}
                reset={this.state.reset}
                selected={this.state.invoiceFilters.typeFilter}
                onChange={this.onChangeFilter('typeFilter')}
              />
              <QuickFilters
                className="quickFilters"
                name="State"
                values={states}
                reset={this.state.reset}
                selected={this.state.invoiceFilters.stateFilter}
                onChange={this.onChangeFilter('stateFilter')}
                style={{ justifyContent: 'flex-end' }}
              />
            </div>

            <div className="invoiceTableBar">
              <span className="periodTitle">Period</span>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  label={'from'}
                  value={this.state.invoiceFilters.dateFilter.fromDates}
                  onChange={this.handleDate('fromDates')}
                  format="dd/MM/yyyy"
                  style={{ marginLeft: '10px' }}
                />
                <DatePicker
                  variant="inline"
                  label={'until'}
                  value={this.state.invoiceFilters.dateFilter.untilDates}
                  onChange={this.handleDate('untilDates')}
                  format="dd/MM/yyyy"
                  style={{ marginLeft: '10px' }}
                />
              </MuiPickersUtilsProvider>
              <Search
                className="searchBar"
                value={this.state.invoiceFilters.searchFilter || ''}
                onChange={this.onChangeFilter('searchFilter')}
              />
              <Button
                title="Reset Filters"
                onClick={this.resetFilters}
                style={{
                  marginLeft: 'auto',
                  backgroundColor: '#BD2B8210',
                  borderRadius: '20px',
                  padding: '10px',
                }}
                color={'secondary'}
              >
                Default Filters
              </Button>
            </div>
            <div>
              Filtered: {tableData.length}/{this.state.invoicesCounter}
            </div>
          </div>
        ) : (
          <CircularProgress />
        )}
        {this.props.invoices && !this.state.dataLoading ? (
          <ScrollArea style={{ height: '78vh' }} native>
            <InvoiceTable
              onOpen={(e) => this.openInvoice(e)}
              tableData={tableData}
              setSearchFilter={(e) => this.setState({ searchFilter: e })}
            />
          </ScrollArea>
        ) : (
          <CircularProgress />
        )}
        <Drawer direction="left" opened={this.state.openedInvoice !== undefined}>
          {this.state.openedInvoice ? (
            <div className="invoiceDrawerContainer">
              <div className="drawerHeader">
                <Typography variant="h6"> Invoice Details</Typography>
                <CloseIcon onClick={() => this.setState({ openedInvoice: undefined })} />
              </div>
              <div className="drawerContent">
                <ScrollArea noScrollX>
                  <InvoiceDetails
                    invoice={this.state.openedInvoice}
                    onChange={this.handleUpdateInvoice}
                    onDueDateChange={this.onDueDateChange}
                    syncDone={this.state.syncDone}
                  />
                </ScrollArea>
              </div>

              <div className="drawerFooter">
                {checkNewOpenRejectedState(this.state.openedInvoice) ? (
                  <Link to={{ pathname: invoiceURL }} style={{ textDecorationLine: 'none' }}>
                    <SimpleButton full>Expert Mode</SimpleButton>
                  </Link>
                ) : (
                  <div />
                )}
                {checkNewOpenRejectedState(this.state.openedInvoice) ? (
                  <FailButton
                    loading={this.state.declineLoading}
                    full
                    style={{ marginLeft: '10px' }}
                    onClick={() => this.decline(this.state.openedInvoice)}
                  >
                    Decline
                  </FailButton>
                ) : null}

                {this.state.openedInvoice.state === 'open' ||
                this.state.openedInvoice.state === 'rejected' ? (
                  <SuccessButtonOrange
                    loading={this.state.acceptLoading}
                    full
                    style={{ marginLeft: '10px' }}
                    disabled={!paymentComplete(this.state.openedInvoice) || !this.state.online}
                    onClick={() => this.transfer(this.state.openedInvoice)}
                  >
                    Approve
                  </SuccessButtonOrange>
                ) : null}
              </div>
            </div>
          ) : null}
        </Drawer>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  //from Redux, get redux state and pass it as props
  return {
    invoices: state.InvoiceReducer.invoices,
    updateDone: state.InvoiceReducer.done,
    currentCompany: state.CompanyReducer.currentCompany,
    invoiceFilters: state.InvoiceReducer.invoiceFilters,
  };
};

export default connect(mapStateToProps, {
  getInvoices,
  updateInvoice,
  clearDoneInv,
  updateInvoiceFilters,
})(InvoiceManagement);
