import firebase from "../components/firebase";
import { currentDateStamp } from '../components/dateHelpers'
//import { CLIENT_RENEG_LIMIT } from "tls";

const db = firebase.db;
const thumbnailURL = 'https://firebasestorage.googleapis.com/v0/b/clexx-eaebf.appspot.com/o/thumbnailIcons%2Fbackoffice.jpg?alt=media&token=8bad4490-a87d-41de-a592-98a13dac52e3';

const Task = {

  addTask: (taskDetails) => {
    return new Promise((resolve, reject) => {
      const now = currentDateStamp();

      db.collection('company')
        .doc(taskDetails.company_id)
        .get()
        .then(doc => {
          const companyNo = doc.data().companyNo;
          const no = doc.data().lastReceiptNo + 1;
          const receiptNo = companyNo + String(no).padStart(5, '0');
          const taskId = "C-" + receiptNo + "-" + Math.random().toString(36).substring(2, 16);
          const task = {
            taskId,
            ...taskDetails,
            createdAt: now,
            updatedAt: now,
            receiptNo: receiptNo,
            thumbnail: thumbnailURL,
          }
          db.collection("task")
            .doc(taskId)
            .set(task)
            .then(() => {
              db.collection('company').doc(taskDetails.company_id).update({
                lastReceiptNo: no
              })
              resolve(task);
            }).catch(err => {
              reject(err);
            })
        })
    });
  },

  getTasksByCompanyAndYear: (companyId, year, resolve) => {
    db.collection("task")
      .where("company_id", "==", companyId)
      .where("task_defination", "in", ["non-reimbursible task", "payment-done", "manager-declined", "non-readable"])
      .onSnapshot(doc => {
        resolve(doc.docs
          .filter(d => {
            const date = d.data().date === '' ? d.data().createdAt : d.data().date;
            const y = (new Date(Date.parse(date))).getFullYear() + '';
            return y === year;
          })
          .map(d => {
            const data = d.data();
            return {
              id: d.id,
              updated: data.updatedAt ? data.updatedAt : '-no-update-date-',
              detail: data.amount + ' ' + data.task_defination + ' - ' + data.empl_name,
              ...data
            }
          }))
      })
  },
  getTasksFromDateRange: (companyId, dateRange, options) => {
    const start = dateRange.startDate.getTime();
    const end = dateRange.endDate.getTime();

    return new Promise((resolve, reject) => {
      try {
        db.collection("task")
          .where("company_id", "==", companyId)
          .where("task_defination", "in", options.expenseTypes)
          .onSnapshot(doc => {
            resolve(doc.docs
              .filter(d => {
                const date = Date.parse(d.data()[options.dateType]);
                return date >= start && date <= end;
              })
              .map(d => {
                const data = d.data();
                return {
                  id: d.id,
                  ...data
                }
              }))
          })
      } catch (error) {
        reject(error);
      }
    })
  },

  getTaskData: async (request, callback) => {
    let taskId = request.taskId;

    try {
      //let taskDetails = await
      db
        .collection("task")
        .doc(taskId)
        .onSnapshot(taskDetails => {
          if (taskDetails.exists) {
            let taskData = taskDetails.data();
            db.collection("employee").where('emailid', "==",taskData.empl_emailId).get().then(resp =>{
              const user = resp.docs[0].data();
              taskData.teams = user.userMsId;
              callback(taskData);
            })
          }
          else {
            //callback(userData);
          }
        });

    } catch (error) {
    }
  },

  getTaskBasedOnComapnyId: async (request, callback) => {
    let companyId = request.companyId;
    let filter = request.filter;
    let query = db.collection("task").where("company_id", "==", companyId);
    if (filter) {
      Object.keys(filter).forEach(k => {
        if(Array.isArray(filter[k])){
          query = query.where(k, 'in', filter[k]);
        } else {
          query = query.where(k, '==', filter[k]);
         }
      })
    }
    try {
      query
        .onSnapshot(function (doc) {
          callback(doc.docs.map(d => {
            return {
              ...d.data(),
              id: d.id,
            }
          }))

        })
    } catch (error) {
      callback(null);
    }
  },

  updateTask: async (request) => {
    return new Promise((resolve, reject) => {
      const { taskId, ...rest } = request;
      console.log('updateTask: ', taskId);
      db.collection('task')
        .doc(taskId)
        .update({
          updatedAt: currentDateStamp(),
          ...rest,
          taskId,
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
    })
  },

  getTotalTask: async (request, callback) => {

    try {
      db.collection("task")
        .onSnapshot(doc => {
          callback(
            doc.docs
              .filter(d => {
                if (request.ongoing) {
                  return ['extract-data', 'compliance-check', 'manager-approval', 'reimbursability-check', 'arrange-payment', 'payment-confirmation']
                    .includes(d.data().task_defination);
                } else {
                  return true;
                }
              })
              .map(d => {
                const data = d.data();
                return {
                  id: d.id,
                  updated: data.updatedAt ? data.updatedAt : '[no-update-date]',
                  filterTxt: data.task_defination + ' ' + data.empl_name + ' ' + data.company_name + ' ' + data.updatedAt,
                  ...data,
                }
              }))
        })
    } catch (error) {
      callback(null);
    }
  },

  getTaskBasedOnEmailId: async (request, callback) => {

    let emailid = request.emailid;
    try {
      db.collection("task")
        .where("empl_emailId", "==", emailid)
        .onSnapshot(function (doc) {
          callback(doc.docs.map(d => {
            return {
              id: d.id,
              ...d.data()
            }
          }))
        })
    } catch (error) {
      callback(null);
    }
  },

  getPolicy: (task) => {
    return new Promise((resolve, reject) => {
      const companyId = task.company_id;
      let companyData = null;
      db.collection("company")
        .doc(companyId)
        .get()
        .then(doc => {
          companyData = doc.data();
          return db.collection("employee")
            .where("emailid", "==", task.empl_emailId)
            .get()
        })
        .then(doc => {
          const empl = doc.docs[0].data();
          resolve(companyData.policies ? companyData.policies.filter(p => p.id === empl.policyId)[0] : null)
        })
        .catch(err => reject(err))
    })
  },
  getQuestions: (comapnyId, callback) => {
    try {
      db.collection("questions")
        .where("companyId", "==", comapnyId)
        .onSnapshot(resp =>
          callback(resp.docs.map(d => ({ questionId: d.id, ...d.data() })))
        )
    } catch (error) {
      callback(null);
    }
  },
  getQuestionsOnTaskId: (taskId, callback) => {
    try {
      db.collection("questions")
        .where("taskId", "==", taskId)
        .onSnapshot(resp =>
          callback(resp.docs.map(d => ({ questionId: d.id, ...d.data() })))
        )
    } catch (error) {
      callback(null);
    }
  },
  deleteQuestion: (questionId) => {
    return new Promise((resolve, reject) => {
      db.collection("questions").doc(questionId).delete()
        .then(resp => {
          resolve('ok')
        })
        .catch(err => reject(err))
    })
  },
  createQuestion(task, questionTxt) {
    return new Promise((resolve, reject) => {
      const question = {
        companyId: task.company_id,
        createdAt: (new Date()).toISOString(),
        question: questionTxt,
        receiptNo: task.receiptNo,
        taskId: task.taskId,
        userId: task.empl_emailId,
        userName: task.empl_name
      }

      db.collection("questions")
        .add(question)
        .then(ref => {
          resolve({ questionId: ref.id, text: questionTxt })
        })
        .catch(err => reject(err))
    })
  }
}
export default Task;
