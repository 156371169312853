import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
// import AddIcon from '@material-ui/icons/Add';

import Loading from '../../components/Loading';
import { LinkButton } from '../../components/Buttons';

import Page from '../page.js';

import firebase from '../../components/firebase';
import company from '../../firebasecomponents/company';
import accountant from '../../firebasecomponents/accountant';


class AccountanttaskList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      companyDetails: null,
      accountantId: null,
      accountantName: null,
      mainPage: true
    };

    this.back = this.back.bind(this);

  }
  async componentDidMount() {

    let accountantId, accountantName;
    if (this.props.match === undefined) {
      accountantId = firebase.getCurrentUserId();
      accountantName = firebase.getCurrentUsername();
      this.setState({ accountantId, accountantName})
    } else {
      accountantId = this.props.match.params.accountantId;
      const acc = await accountant.getAccountantProfileData(accountantId);
      accountantName = acc.firstName + ' ' + acc.lastName;
      this.setState({ mainPage: false, accountantId, accountantName })
    }

    company.getCompanyDetails({ accountantId }, response => {
      this.setState({
        companyDetails: response });
    });
  };

  back() {
    this.props.history.goBack();
  }

  render() {

    const companyNames = this.state.companyDetails ? this.state.companyDetails.map(companyDetails =>
      (<LinkButton
        color='primary'
        style={{ minWidth: 800 }}
        to={`/companytasklist/${companyDetails.companyId}/${companyDetails.companyName}`}
        key={companyDetails.companyId}
        value={companyDetails.companyName}
      >{companyDetails.companyName}</LinkButton>)
    ) : null;

    return (
      <Page>

        <div className='left' style={{ textAlign: 'left', marginBottom: '20px' }}>
          {this.state.mainPage ? null :
            <IconButton onClick={this.back} style={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>}
          <h2 style={{ color: '#c0548e' }}>
            {this.state.accountantName}
            <LinkButton to={'/accountant/profile/' + this.state.accountantId} className="settingsButton">
              <SettingsIcon />View profile
              </LinkButton>

          </h2>
        </div>
        {/* <LinkButton color='secondary' to={'/company/add/' + this.state.accountantId} className="addButton">
          <AddIcon />Add Company
        </LinkButton> */}

        <Tabs>
          <TabList>
            <Tab>All Companies</Tab>
          </TabList>
          <TabPanel>
            <Loading loading={!companyNames} />
            {companyNames}
          </TabPanel>
        </Tabs>
      </Page>
    );
  }
}

export default AccountanttaskList;
