import React, { useState } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@material-ui/core"

import { SimpleButton } from '../../../newComponents/Button';
import Select from '../../../newComponents/Select';
import TextField from '../../../newComponents/TextField';

const currencies = require('../../../currency.json');

const csvMapping = [
  "cardNumber",
  "holder",
  "date",
  "description",
  "currency",
  "amount",
  "dateFormat"
]

function CSVFieldMap(props) {

  const { externalTypes, bestFit, onChange } = props;
  console.log('CSV props', props)
  const [fieldsToSelect, setFieldsToSelect] = useState(externalTypes)
  const [map, setMap] = useState(bestFit ? bestFit : {})

  const [holder, setHolder] = useState(undefined);
  const [blockHolder, setBlockHolder] = useState(false);

  const [card, setCard] = useState(undefined);
  const [blockCard, setBlockCard] = useState(false);

  const [currency, setCurrency] = useState(undefined);

  const handleSelect = (field) => (value) => {
    console.log('field', field, 'value', value);
    const currMap = { ...map }
    console.log('currMap', currMap);
    currMap[field] = value;
    console.log('currMap after', currMap);
    setMap(currMap);
    setFieldsToSelect(prev => prev.filter(f => f !== value).concat(map[field] ? map[field] : []))
  }

  const chooseHolder = (holderId) => {
    if (holderId === null) {
      handleSelect('cardNumber')(undefined);
      setBlockCard(false);
    } else {
      const holderDetails = props.employees.find(e => e.employeeId === holderId);
      setHolder(holderDetails);
      handleSelect('cardNumber')('Choose card');
      setBlockCard(true);
    }
  }
  const chooseCard = (cardNumber) => {
    if (cardNumber === null) {
      setCard(undefined);
      setHolder(undefined);
      handleSelect('holder')(undefined);
      setBlockHolder(false);
      setBlockCard(false);
    } else {
      const cardDetails = props.creditCards.find(c => c.number === cardNumber)
      setCard(cardDetails);
      const holderDetails = props.employees.find(e => e.employeeId === cardDetails.holder);
      setHolder(holderDetails);
      handleSelect('holder')('Choose holder');
      setBlockHolder(true);
    }
  }
  console.log(map)
  return (
    <Dialog open={true}>
      <DialogTitle>Match fields from CSV file to our database fields</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {csvMapping.map(e => <React.Fragment key={e}>
            <Grid item xs={3}>{e}</Grid>
            <Grid item xs={4}>
              {e !== 'dateFormat' ? <Select
                value={map[e] || ''}
                disabled={(e === "holder" && holder !== undefined) || (e === "cardNumber" && (card !== undefined || blockCard))}
                onChange={handleSelect(e)}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="" disabled>Select field...</MenuItem>
                {e === "holder" && <MenuItem value="Choose holder"><strong> - Choose holder</strong></MenuItem>}
                {e === "cardNumber" && <MenuItem value="Choose card"><strong>- Choose card</strong></MenuItem>}
                {e === "currency" && <MenuItem value="Choose currency"><strong>- Choose currency</strong></MenuItem>}
                {fieldsToSelect
                  .concat(map[e] || [])
                  .map(f => <MenuItem key={f} value={f}>{f}</MenuItem>)}
              </Select>:
              <TextField variant="outlined" value={
                map[e] ||
                ''} onChange={(f)=> handleSelect('dateFormat')(f)}/>
              }
            </Grid>
            <Grid item xs={5}>
              {e === "holder" && map[e] === 'Choose holder' && <>
                <Select
                  value={holder ? holder.employeeId : ''}
                  onChange={chooseHolder}
                  SelectProps={{ displayEmpty: true }}
                  disabled={blockHolder}
                >
                  <MenuItem value="" disabled>Select holder instead...</MenuItem>
                  {props.employees.map(u => <MenuItem key={u.employeeId} value={u.employeeId}>{u.firstName} {u.lastName}</MenuItem>)}
                  <MenuItem value={null} >Clear selection</MenuItem>
                </Select>
              </>}
              {e === "cardNumber" && map[e] === 'Choose card' && <>
                <Select
                  value={card ? card.number : ''}
                  onChange={chooseCard}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="" disabled>Select card instead...</MenuItem>
                  {props.creditCards
                    .filter(c => (holder !== undefined && c.holder === holder.employeeId) || holder === undefined)
                    .map(c => <MenuItem key={c.number} value={c.number}>{c.system} {c.number}</MenuItem>)}
                  <MenuItem value={null} >Clear selection</MenuItem>
                </Select>
              </>}
              {e === "currency" && map[e] === 'Choose currency' && <>
                <Select
                  value={currency ? currency : ''}
                  onChange={setCurrency}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="" disabled>Select currency instead...</MenuItem>
                  <MenuItem value={'CHF'}>CHF</MenuItem>
                  <MenuItem value={'EUR'}>EUR</MenuItem>
                  <MenuItem value={'USD'}>USD</MenuItem>
                  <MenuItem value={'JPY'}>JPY</MenuItem>
                  <MenuItem value={'GBP'}>GBP</MenuItem>
                  <span>_________</span>
                  {Object.keys(currencies).map(k => <MenuItem key={k} value={k}>{k} : {currencies[k].name}</MenuItem>)}
                </Select>
              </>}
              {e === 'dateFormat' && <p>Type date format used in CSV file using dd, MM/MMM/MMMM, YYYY</p>}
            </Grid>
          </React.Fragment>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SimpleButton
          bordered
          style={{ marginRight: '10px' }}
          onClick={() => onChange(undefined)}
        >
          Cancel
                </SimpleButton>
        <SimpleButton full
          disabled={Object.keys(map).length !== csvMapping.length}
          onClick={() => onChange({ map, holder, card, currency })}
        >
          Process statement and save
                </SimpleButton>
      </DialogActions>
    </Dialog>)
}

const mapStateToProps = state => {
  return {
    employees: state.EmployeeReducer.employees,
    creditCards: state.CreditCardReducer.creditCards
  };
}

export default connect(mapStateToProps, {})(CSVFieldMap);
//export default CSVFieldMap;
