import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteIcon from '@material-ui/icons/Delete';

import { SimpleButton } from '../../../newComponents/Button';
import TextField from '../../../newComponents/TextField';

import { PlusCircleIcon, MinusCircleIcon } from '../../../newComponents/Icons';

import '../customization.scss'


export const useStyles = makeStyles({
  container: {
    borderTop: '1px solid #DADADA',
    '&:first-of-type': {
      borderTop: 'none'
    }
  },
  row: {
    borderTop: '1px solid #DADADA',
    paddingLeft: '25px',
    paddingRight: '25px',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'baseline',
      '& > label': {
        whiteSpace: 'nowrap'
      },
      '& > input': {
        marginLeft: '10px',
        marginRight: '10px',
      }
    },
    '& > button': {
      marginRight: '10px'
    },
    '&:first-of-type': {
      borderTop: 'none'
    }
  },
  header: {
    marginLeft: '15px',
    fontSize: '16px',
    marginBottom: '-5px',
    flexGrow: 1
  },
  project: {
    fontSize: '14px',
    marginLeft: '40px'
  },
  addProject: {
    fontWeight: 'bold',
    color: 'rgb(189, 43, 130)',
    marginLeft: '30px'
  }
});



function Project(props) {
  const classes = useStyles(props);

  const [name, setName] = useState(props.name);
  const [edit, setEdit] = useState(false)

  useEffect(() => setName(props.name), [props.name])

  const ok = () => {
    setEdit(false);
    props.onChange(name);
  }

  const project = edit ?
    <div className={classes.row}>
      <TextField
        onChange={(name) => setName(name)}
        value={name}
      />
      <SimpleButton full onClick={ok}>OK</SimpleButton>
      <CloseIcon onClick={() => setEdit(false)} />
    </div>
    :
    <div className={classes.row}>
      <div className={classes.project}>{name}</div>
      <EditIcon onClick={() => setEdit(true)} style={{ marginRight: '10px' }} />
      <props.deleteIcon onClick={() => props.onDelete()} />
    </div>

    return props.disabled ? null : project;
}

function CostObject(props) {
  const classes = useStyles(props);

  const [costObject, setCostObject] = useState(props.costObject);
  useEffect(() => setCostObject(props.costObject), [props.costObject, setCostObject])

  const [newProject, setNewProject] = useState(false)
  const [newProjectName, setNewProjectName] = useState('')
  const [opened, setOpened] = useState(false);
  const [name, setName] = useState(props.costObject.name);
  const [editName, setEditName] = useState(false);

  const remove = (idx) => {
    const newCostObject = { ...costObject }
    if (newCostObject.projects[idx].uses.length === 0) {
      newCostObject.projects.splice(idx, 1);
    } else {
      newCostObject.projects[idx].disabled = true;
    }
    props.onChange(newCostObject);
  }

  const ok = () => {
    const newCostObject = { ...costObject };
    newCostObject.projects.push({
      name: newProjectName,
      id: Math.random().toString(36).substring(2, 8),
      uses: [],
      disabled: false
    })
    setNewProject(false);
    props.onChange(newCostObject);
  }
  const okName = () => {
    const newCostObject = { ...costObject };
    newCostObject.name = name;
    setEditName(false);
    props.onChange(newCostObject);
  }
  const edit = (idx, name) => {
    const newCostObject = { ...costObject };
    newCostObject.projects[idx].name = name;
    props.onChange(newCostObject);
  }

  const DeleteCustomIcon = costObject.uses.length === 0 && costObject.projects.map(p => p.uses.length===0).reduce((p, c) => p && c, true) ? DeleteForeverIcon:DeleteIcon;

  return <div className={classes.container}>
    {editName ?
      <div className={classes.row}>
        {opened ?
          <MinusCircleIcon onClick={() => setOpened(false)} /> :
          <PlusCircleIcon onClick={() => setOpened(true)} />}
        <TextField
          onChange={(name) => setName(name)}
          value={name}
        />
        <SimpleButton full onClick={okName}>OK</SimpleButton>
        <CloseIcon onClick={() => setEditName(false)} />
      </div> :
      <div className={classes.row}>
        {opened ?
          <MinusCircleIcon onClick={() => setOpened(false)} /> :
          <PlusCircleIcon onClick={() => setOpened(true)} />}
        <strong className={classes.header}>{costObject.name}</strong>
        <EditIcon onClick={() => setEditName(true)} style={{ marginRight: '10px' }} />
        <DeleteCustomIcon onClick={() => props.onDelete()} />
      </div>}


    {opened ? costObject.projects.map((p, idx) => 
    <Project
     key={idx} 
     name={p.name}
     disabled={p.disabled} 
     onDelete={() => remove(idx)} 
     onChange={(newName) => edit(idx, newName)} 
     deleteIcon={p.uses.length > 0 ? DeleteIcon:DeleteForeverIcon}
     />) 
     : null}
    {opened ? newProject ?
      <div className={classes.row}>
        <TextField
          label="Name of the project"
          onChange={(name) => setNewProjectName(name)}
          value={newProjectName}
        />
        <SimpleButton full onClick={ok}>OK</SimpleButton>
        <CloseIcon onClick={() => setNewProject(false)} />
      </div> :
      <div className={classes.row}>
        <SimpleButton className={classes.addProject} onClick={() => setNewProject(true)}>+ Add project</SimpleButton>
      </div> :
      null}
  </div>
}

export default CostObject;