import InvoiceTableComponent from './InvoiceTableComponent';
import moment from 'moment';
import PDFViewer from 'mgr-pdf-viewer-react';
import { clexx_logo } from '../invoiceHelper';
import { Tooltip } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import InvoiceBox from './InvoiceBox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';

const InvoiceTable = ({ tableData, onOpen, setSearchFilter }) => {
  return (
    <InvoiceTableComponent
      data={{
        head: {
          receipt: <strong style={{ color: '#7F8FA4' }}> Receipt </strong>,
          merchant: <strong style={{ color: '#7F8FA4' }}> Merchant </strong>,
          type: <strong style={{ color: '#7F8FA4' }}> Type </strong>,
          amount: <strong style={{ color: '#7F8FA4' }}> Amount </strong>,
          dueDate: <strong style={{ color: '#7F8FA4' }}> Due Date </strong>,
          errors: <strong style={{ color: '#7F8FA4' }}> Errors </strong>,
          state: <strong style={{ color: '#7F8FA4' }}> State </strong>,
          action: '',
        },
        content: tableData.map((e) => {
          const url = e.thumbnail ? e.thumbnail : e.base64Value;
          const total = e.amounts
            ? e.amounts.reduce((prev, curr) => prev + curr.amount, 0).toFixed(2)
            : 0;
          const dueDate = e.dueDate
            ? moment(e.dueDate).format('DD. MMM YYYY')
            : '';
          const errors =
            e.extractionErrors && e.extractionErrors.length > 0
              ? e.extractionErrors.map((e, idx) => <p key={`e-${idx}`}>{e}</p>)
              : '';
          return {
            row: {
              receipt: !url.includes('.pdf') ? (
                <img
                  src={url}
                  alt={''}
                  onClick={onOpen(e)}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                    cursor: 'pointer',
                  }}
                />
              ) : e.base64Value.includes('.pdf') ? (
                <div
                  onClick={onOpen(e)}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                    cursor: 'pointer',
                  }}
                >
                  <PDFViewer
                    document={{ url }}
                    hideNavbar
                    style={{
                      maxWidth: '30px',
                      maxHeight: '30px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              ) : (
                <img
                  src={clexx_logo}
                  alt={''}
                  onClick={onOpen(e)}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                    cursor: 'pointer',
                  }}
                />
              ),
              merchant: (
                <span
                  onClick={() => setSearchFilter(e.merchant.name)}
                  style={{ cursor: 'pointer' }}
                >
                  <strong>{e.merchant.name}</strong>
                </span>
              ),
              type: (
                <span
                  style={{
                    border: '1px solid #92A6C3',
                    boxSizing: 'border-box',
                    borderRadius: '16px',
                    padding: '7px',
                    maxWidth: '20em',
                    minWidth: '20em',
                  }}
                >
                  {e.type}
                </span>
              ),
              amount: (
                <span>
                  {total ? total : ''} {e.currency}{' '}
                </span>
              ),
              dueDate: <span>{dueDate}</span>,
              errors: errors ? (
                <Tooltip title={errors}>
                  <PriorityHighIcon color="secondary" />
                </Tooltip>
              ) : (
                ''
              ),
              state: <InvoiceBox text={e.state} />,
              action: (
                <ChevronRightIcon
                  onClick={onOpen(e)}
                  style={{ cursor: 'pointer' }}
                />
              ),
            },
            sortData: {
              merchant: e.merchant.name,
              amount: parseFloat(total),
              dueDate: e.dueDate,
              state: e.state,
              type: e.type,
            },
          };
        }),
      }}
      sortable={true}
    />
  );
};

export default InvoiceTable;
