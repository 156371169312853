import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress'

import Storage from '../../firebasecomponents/storage';

import { ExportIcon } from '../Icons'

import Compressor from 'compressorjs';

const useStyles = makeStyles({
  root: {
    border: '2px dashed #35405280',
    backgroundColor: 'rgba(255,255,255,0.5)',
    color: '#BD2B82',
    fontWeight: 'bold',
    padding: '30px',
    borderRadius: '4px',
    '& p': {
      textAlign: 'center'
    },
    '& img': {
      width: '37px',
      display: 'block',
      margin: '0 auto 10px'
    }
  }
});

function Dropzone(props) {

  const { label, onChange, onFile, imageProps, bucket, square, acceptedTypes } = props;
  const classes = useStyles(props);

  const [error, setError] = useState('')
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if(onFile!==undefined){
      console.log('onFile');
      onFile(file);}
    else if(onChange!==undefined) {
      console.log('onchange')
      // onChange(file);
    }

    const reader = new FileReader()

    reader.onabort = () => setError('file reading was aborted')
    reader.onerror = () => setError('file reading has failed')
    reader.onload = () => {
      const binaryStr = reader.result
      if (bucket) {
        console.log('has bucket');
        Storage.upload(bucket, { name: file.name, data: binaryStr }, (newProgress) => setProgress(newProgress))
          .then(url => {
            console.log('url');
            onChange({ fileName: file.name, data: url });
          })
          .catch(err => setError(err.message))
      } else {
        onChange({ fileName: file.name, data: binaryStr });
      }
    }

    if (acceptedTypes) { //check if there are file type constraints
      const type = file['type'].split('/')[0];

      if (acceptedTypes.includes(type)) { // check file type match
        if (type === 'image') { //
          const squareProps = square ? {
            beforeDraw: (context, canvas) => {
              canvas.width = square.size; // in pixels
              canvas.height = square.size; // in pixels
            },
            maxWidth: square.size
          } : {}
          new Compressor(file, {
            quality: 0.6,
            maxWidth: 700,
            ...imageProps,
            ...squareProps,
            success(result) {
              if (result.size > 504800) {
                setError("File is too big");
              } else {
                setError('');
                const a = new FileReader();
                a.onloadend = () => {
                  onChange({ fileName: file.name, data: a.result });
                };
                a.readAsDataURL(result);
              }
            },
            error(err) {
              setError(err.message);
            },
          });
        } else {
          reader.readAsDataURL(file)
        }
      } else {
        setError('Unsupported file format');
      }
    } else { // upload all
      reader.readAsDataURL(file)
    }


  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} className={classes.root}>
      {error ? error : null}
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>
            {progress ? <CircularProgress /> : <ExportIcon />}
            {label ? label : `Drag and Drop, or click to select files`}
          </p>
      }
    </div>
  )
}

export default Dropzone;
