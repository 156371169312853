import { fbFunctions } from './fbFunctions';
import Log from '../newHelpers/Log';
const Logger = new Log('FirebaseComponent Topal');

const Topal = {
  syncPayTerms: async (companyID) => {
    Logger.log('id', companyID);
    try {
      const syncPayTerms = fbFunctions.httpsCallable('syncPayTerms');
      const res = await syncPayTerms({ companyID });
      Logger.log('res', res);
    } catch (e) {
      Logger.log('catched: ', e);
      return e;
    }
  },
  syncBankAccounts: async (companyID) => {
    return new Promise(async (resolve, reject) => {
      Logger.log('id', companyID);
      try {
        const syncBankAccounts = fbFunctions.httpsCallable('syncBankAccounts');
        const res = await syncBankAccounts({ companyID });
        Logger.log('res', res);
        return resolve(res);
      } catch (e) {
        return reject(e);
      }
    });
  },
  syncAccounts: async (companyID) => {
    return new Promise(async (resolve, reject) => {
      Logger.log('id', companyID);
      try {
        const syncAccounts = fbFunctions.httpsCallable('syncAccounts');
        const res = await syncAccounts({ companyID });
        Logger.log('res', res);
        return resolve(res);
      } catch (e) {
        return reject(e);
      }
    });
  },
  syncVats: async (companyID) => {
    return new Promise(async (resolve, reject) => {
      Logger.log('id', companyID);
      try {
        const syncVats = fbFunctions.httpsCallable('syncVats');
        const res = await syncVats({ companyID });
        Logger.log('res', res);
        return resolve(res);
      } catch (e) {
        return reject(e);
      }
    });
  },
  syncFiscalYears: async (companyID) => {
    return new Promise(async (resolve, reject) => {
      Logger.log('id', companyID);
      try {
        const syncFiscalYears = fbFunctions.httpsCallable('syncFiscalYears');
        const res = await syncFiscalYears({ companyID });
        Logger.log('res', res);
        return resolve(res);
      } catch (e) {
        return reject(e);
      }
    });
  },
  syncCostCenters: async (companyID) => {
    return new Promise(async (resolve, reject) => {
      Logger.log('id', companyID);
      try {
        const syncCostCenters = fbFunctions.httpsCallable('syncCostCenters');
        const res = await syncCostCenters({ companyID });
        Logger.log('res', res);
        return resolve(res);
      } catch (e) {
        return reject(e);
      }
    });
  },
  checkTopalOnline: async (params) => {
    const checkTopal = fbFunctions.httpsCallable('checkTopalOnline');
    try {
      Logger.log('checking');
      const res = await checkTopal(params);
      Logger.log('res', res);
      if (res && res.data && res.data.status === 200) {
        Logger.log('Topal Server Is Online');
        return true;
      } else {
        Logger.log('Topal not online');
        return false;
      }
    } catch (e) {
      Logger.log('error: ', e);
      return false;
    }
  },
  checkPaidInvoicesTopal: async (params) => {
    const checkPaidInvoicesTopal = fbFunctions.httpsCallable(
      'checkPaidInvoicesTopal',
    );
    try {
      Logger.log('checking');
      const res = await checkPaidInvoicesTopal({});
      Logger.log('res', res);
      if (res && res.data) {
        return res.data;
      } else {
        return false;
      }
    } catch (e) {
      Logger.log('error: ', e);
      return e;
    }
  },
  checkInvoiceTopalState: async (invoiceID) => {
    const syncInvoice = fbFunctions.httpsCallable(
      'syncInvoice',
    );
    try {
      Logger.log('checkInvoiceTopalState');
      const res = await syncInvoice({invoiceID});
      Logger.log('res', res);
      if (res && res.data) {
        return Promise.resolve(res.data);
      } else {
        return false;
      }
    } catch (e) {
      Logger.log('error: ', e);
      return e;
    }
  },
  checkPaidExpensesTopal: async (params) => {
    const checkPaidTopal = fbFunctions.httpsCallable('checkPaidTopal');
    try {
      Logger.log('checking');
      const res = await checkPaidTopal({});
      Logger.log('res', res);
      if (res && res.data) {
        return res.data;
      } else {
        return false;
      }
    } catch (e) {
      Logger.log('error: ', e);
      return e;
    }
  },
  getClientParties: async (params) => {
    const getParties = fbFunctions.httpsCallable('getClientParties');
    try {
      const res = await getParties(params);
      if (res && res.data) {
        return res.data;
      } else {
        return [];
      }
    } catch (e) {
      Logger.log('error: ', e);
      return [];
    }
  },
  // getAccountBalance: async (params) => {
  //   const getAccountBalance = fbFunctions.httpsCallable('getAccountBalance');
  //   try {
  //     const res = await getAccountBalance(params);
  //     if (res && res.data) {
  //       return res.data;
  //     } else {
  //       return 0;
  //     }
  //   } catch (e) {
  //     Logger.log('error: ', e);
  //     return 0;
  //   }
  // },
  invoiceDueDateChange: async (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const invoiceDueDateChange = fbFunctions.httpsCallable(
          'invoiceDueDateChange',
        );
        const res = await invoiceDueDateChange(params);
        Logger.log('invoiceDueDateChange Res:', res);
        resolve(res);
      } catch (error) {
        Logger.log('error', error);
        Logger.log(error.response);
        if (error.response && error.response.data) {
          Logger.log('has error data', error.response.data);
          reject(error.response.data);
        } else {
          Logger.log('rejecting');
          reject(JSON.stringify(error));
        }
      }
    });
  },
  toFibu: async (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const toFibu = fbFunctions.httpsCallable('toFibu');
        const res = await toFibu(params);
        Logger.log('toFibu Res:', res);
        resolve(res);
      } catch (error) {
        Logger.log('error', error);
        Logger.log(error.response);
        if (error.response && error.response.data) {
          Logger.log('has error data', error.response.data);
          reject(error.response.data);
        } else {
          Logger.log('else');
          reject(JSON.stringify(error));
        }
      }
    });
  },
};

export default Topal;
