import React, { Component } from 'react'
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Select from '../../../newComponents/Select';
import Switch from '../../../newComponents/Switch';
import CreditCard from '../../../newComponents/CreditCard';
import TextField from '../../../newComponents/TextField';
import { CostCenterSelect, CustomerSelect } from '../../../newComponents/SelectCostObject';
import { SuccessButton, FailButton } from '../../../newComponents/Button'

import { updateCreditCard, updateCustomer, updateCompany, updateEmployee } from "../../../store/actions";

import MsTeams from '../../../externalAPI/MsTeams'

import './employeeDetails.scss';

class EmployeeDetails extends Component {

  constructor(props) {
    super();

    this.state = {
      employee: props.employee,
      employeeCards: [],
      newCreditCards: [],
      remainingCards: [],
      customers: props.customers || []
    }
    this.handleChange = this.handleChange.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
    this.deleteTempCard = this.deleteTempCard.bind(this);
    this.addCard = this.addCard.bind(this);
    this.getModifiedCardList = this.getModifiedCardList.bind(this);
    this.manageConnectionRequest = this.manageConnectionRequest.bind(this);
  }

  componentDidMount(prevProps) {
    const employeeId = this.state.employee.employeeId;
    const cards = this.props.creditCards.filter(c => c.holder === employeeId || c.users.includes(employeeId));
    const remaining = this.props.creditCards.filter(c => c.holder !== employeeId && !c.users.includes(employeeId));
    this.setState({ employeeCards: cards, remainingCards: remaining, newCreditCards: [] });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.creditCards !== this.props.creditCards) {
      const employeeId = this.state.employee.employeeId;
      const cards = this.props.creditCards.filter(c => c.holder === employeeId || c.users.includes(employeeId));
      const remaining = this.props.creditCards.filter(c => c.holder !== employeeId && !c.users.includes(employeeId));
      this.setState({ employeeCards: cards, remainingCards: remaining, newCreditCards: [] });
    }
    if (prevProps.employee !== this.props.employee) {
      this.setState({ employee: this.props.employee, customers: this.props.customers })
    }
    if (prevProps.customers !== this.props.customers) {
      this.setState({ customers: this.props.customers })
    }
  }

  manageConnectionRequest(accepted) {
    return () => {
      const employee = { ...this.state.employee };
      if (accepted) {
        employee.userMsId = employee.requestConnection.userMsId
      }
      employee.requestConnection = {
        conversation: employee.requestConnection.conversation,
        ...(accepted ? {connected:true}:{})
      };

      MsTeams.notify(employee.requestConnection.conversation, accepted ? 'Your request has been accepted.' : 'Your request has been denied. Contact with Your manager.')
        .then(() => {
          this.props.updateEmployee(employee)
        })

    }
  }


  handleChange(field) {
    return (newValue) => {
      const state = { ...this.state.employee, [field]: newValue }
      this.setState({ employee: state });
      this.props.onChange(state);
    }
  };

  getModifiedCardList(options) {
    const employeeId = this.state.employee.employeeId;
    const initial = this.props.creditCards.filter(c => c.holder === employeeId || c.users.includes(employeeId));

    const { employeeCards, newCreditCards } = { ...this.state, ...options }
    const current = employeeCards.concat(newCreditCards);
    return [
      ...current.filter(x => !initial.map(c => c.id).includes(x.id)),
      ...initial.filter(x => !current.map(c => c.id).includes(x.id))
    ];
  }

  deleteCard(card) {
    const cards = this.state.employeeCards.filter(c => c.id !== card.id);
    const remaining = [...this.state.remainingCards];
    remaining.push(card);
    this.setState({ employeeCards: cards, remainingCards: remaining });
    this.props.onCardsChange(this.getModifiedCardList({ employeeCards: cards }));
  }
  deleteTempCard(card) {
    const cards = this.state.newCreditCards.filter(c => c.id !== card.id);
    const remaining = [...this.state.remainingCards];
    remaining.push(card);
    this.setState({ newCreditCards: cards, remainingCards: remaining });
    this.props.onCardsChange(this.getModifiedCardList({ newCreditCards: cards }));
  }

  addCard(newCard) {
    const cards = [...this.state.newCreditCards];
    cards.push(newCard);
    const remaining = this.state.remainingCards.filter(c => c.id !== newCard.id);
    this.setState({ newCreditCards: cards, remainingCards: remaining });
    this.props.onCardsChange(this.getModifiedCardList({ newCreditCards: cards }));
  }

  render() {
    const edit = this.props.edit;
    const handleChange = this.handleChange;

    const { employee, employeeCards, newCreditCards, remainingCards } = this.state;

    return employee ? (<Box display="flex"
                            justifyContent="center"
                            className="employeeDetails">
      <Grid container spacing={3}>
        <Grid item xs={12}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Personal Info
          </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <dl>
            <dt>Name:</dt>
            {edit ? <dd>
              <TextField required
                onChange={handleChange('firstName')}
                value={employee.firstName || ''}
                label="First Name" />
              <TextField required
                onChange={handleChange('lastName')}
                value={employee.lastName || ''}
                label="Last Name" />
            </dd> :
              <dd>{employee.firstName} {employee.lastName}</dd>
            }
            <dt>Department:</dt>
            {edit ? <dd>
              <TextField
                onChange={handleChange('department')}
                value={employee.department}
                label="Department" />
            </dd> :
              <dd>{employee.department}</dd>
            }

            <dt>Email:</dt>
            {edit && employee.employeeId === undefined ? <dd>
              <TextField
                onChange={handleChange('emailid')}
                value={employee.emailid || ''}
                label="Email" />
            </dd> :
              <dd><a href={`mailto:${employee.emailid}`}>{employee.emailid}</a></dd>
            }
            <dt>Phone:</dt>
            {edit ? <dd>
              <TextField
                onChange={handleChange('phone')}
                value={employee.phone}
                label="Phone" />
            </dd> :
              <dd>{employee.phone}</dd>
            }
            <dt>Address:</dt>

            {edit ? <dd>
              <TextField required
                onChange={handleChange('streetAndNumber')}
                value={employee.streetAndNumber || ''}
                label="Street And Number" />
              <TextField required
                onChange={handleChange('postcode')}
                value={employee.postcode || ''}
                label="Post code" />
              <TextField required
                onChange={handleChange('city')}
                value={employee.city || ''}
                label="City" />
            </dd> :
              <dd>{employee.streetAndNumber} {employee.postcode} {employee.city}</dd>
            }
            <dt>Clexx E-mail:</dt>
            <dd><a href={`mailto:${employee.personalClexxEmailid}`}>{employee.personalClexxEmailid}</a></dd>
            {employee.requestConnection &&
              <>
                <dt>MS Teams</dt>
                {employee.requestConnection.userName ?
                  <dd>
                    Request connection for name: <strong>{employee.requestConnection.userName}</strong><br />
                    <SuccessButton onClick={this.manageConnectionRequest(true)}>
                      accept
                </SuccessButton>
                    <FailButton onClick={this.manageConnectionRequest(false)}>
                      decline
                </FailButton>
                  </dd> : employee.userMsId ?
                    <dd>Connected</dd> : <dd>Request declined</dd>
                }
              </>}
          </dl>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <hr />
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1b-content"
          id="panel1b-header"
        >
          Payment Information
          </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <dl>
            <dt>Bank:</dt>
            {edit ? <dd>
              <TextField required
                onChange={handleChange('bankName')}
                value={employee.bankName || ''}
                label="Bank Name" />
              <TextField required
                onChange={handleChange('bankplace')}
                value={employee.bankplace || ''}
                label="Bank Place" />
            </dd> :
              <dd>{employee.bankName} {employee.bankplace}</dd>
            }

            <dt>IBAN:</dt>
            {edit ? <dd>
              <TextField required
                onChange={handleChange('ibanNr')}
                value={employee.ibanNr || ''}
                label="IBAN" />
            </dd> :
              <dd>{employee.ibanNr}</dd>
            }
          </dl>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <hr />
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          Employee Attributes
          </ExpansionPanelSummary>
        <ExpansionPanelDetails>

          <dl>
            <dt>Credit Cards:</dt>
            <dd>
              {employeeCards ? employeeCards.map((c, i) => {
                return <div key={i} className="cardBox">
                  <CreditCard key={i} card={c} />
                  {c.holder !== employee.employeeId ? <DeleteIcon onClick={() => this.deleteCard(c)} /> : <strong>HOLDER</strong>}
                </div>
              }) : null}
              <hr />
              {newCreditCards.map((c, i) => {
                return <div key={i} className="cardBox">
                  <CreditCard key={i} card={c} />
                  <CloseIcon onClick={() => this.deleteTempCard(c)} />
                </div>
              })}
              {remainingCards.length > 0 ? <Select
                label="+ Add Credit Card"
                onChange={(data) => this.addCard(data)}
                value={1}
              >
                {remainingCards.map(c => <MenuItem key={c.id} value={c}>{c.system} ****{c.number}</MenuItem>)}
              </Select> : null}
            </dd>
          </dl>
          <div className="box">
            {this.props.policies ? <Select
              label="Policy"
              onChange={handleChange('policyId')}
              value={employee.policyId}
            >
              {this.props.policies.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
            </Select> : null}
          </div>
          <div className="box">
            <CostCenterSelect label="Map to Cost Center" value={employee.fixedCostCenter} onChange={handleChange('fixedCostCenter')} />
          </div>
          <div className="box">
            <CustomerSelect label="Map to Customer" value={employee.fixedCustomerProject} onChange={handleChange('fixedCustomerProject')} />
          </div>
          <hr style={{ marginBottom: '10px' }} />
          <Switch checked={!!employee.corporatePhone}
            onChange={handleChange('corporatePhone')}
            label="Corporate Phone"/>
          <Switch checked={!!employee.GATravelCard}
            onChange={handleChange('GATravelCard')}
            label="GA Travel Card"/>
        </ExpansionPanelDetails>
      </ExpansionPanel>
        </Grid>
      </Grid>
    </Box >) : null;
  }
}


const mapStateToProps = state => {
  return {
    employees: state.EmployeeReducer.employees,
    currentCompany: state.CompanyReducer.currentCompany,
    creditCards: state.CreditCardReducer.creditCards,
    customers: state.CompanyReducer.customers
  };
}

export default connect(mapStateToProps, { updateCreditCard, updateCustomer, updateCompany, updateEmployee })(EmployeeDetails);


// export default EmployeeDetails;
