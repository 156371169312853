import React, { Component } from 'react'
import { connect } from "react-redux";

import moment from 'moment';

// import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';


import { getQuestions,deleteQuestion } from "../../store/actions";


import Page from '../page';


import './questions.scss'
import {getCollapsed, getShowExpenseItems} from "../../newHelpers/pageHelper";




function formatDate(date, format) {
  if (typeof date === 'string') {
    return moment(date, "DD-MM-YYYY").format('DD MMM YYYY');
  } else {
    return date.format('DD MMM YYYY');
  }
}

class Questions extends Component {

  constructor(props) {
    super(props)
    this.state = this.initialState;

    //this.handleChange = this.handleChange.bind(this);

    this.toggle = this.toggle.bind(this);
    this.delete = this.delete.bind(this);

    if (props.currentCompany) {
      props.getQuestions(props.currentCompany.companyId);
    }

  }

  get initialState() {
    return {
      errors: [],
      questions: [],
      opened: []
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questions !== this.props.questions) {
      this.setState({ questions: this.props.questions, opened: (new Array(this.props.questions).map(() => false)) })
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.props.getQuestions(this.props.currentCompany.companyId);
    }
  }

  toggle(idx) {
    const opened = this.state.opened;
    opened[idx] = !opened[idx];
    this.setState({ opened })
  }

  delete(id){
    this.props.deleteQuestion(id);
  }

  render() {
    const questions = this.props.questions;

    if (!questions || questions.length === 0) {
      return <Page role='Company' currentPage="questions" header="Expense questions" actions={''}>
        <div>No question so far</div>
      </Page>
    }

    return (
      <Page className="questions"
            role='Company'
            currentPage="questions"
            header="Expense questions"
            actions={''}
            collapsedNavBar={getCollapsed(this.props)}
            collapsedExpItems={getShowExpenseItems(this.props)}
      >
        <h2>Answered</h2>
        {questions.filter(q => q.answer !== undefined).map((q, idx) =>
          <ExpansionPanel expanded={this.state.opened[idx]} onChange={() => this.toggle(idx)} key={`qa-${idx}`}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${idx}-content`}
              id={`panel${idx}-header`}
            >
              <Typography><strong>{q.receiptNo}</strong></Typography>
              <Typography className="middle">{q.userName}</Typography>
              <Typography className="date">{moment(q.createdAt).format('DD MMM YYYY HH:mm')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>

              <p className="question-para">
                <strong>Q: </strong>{q.question}
                <DeleteIcon className="delete" onClick={()=>this.delete(q.questionId)}/>
              </p>
              <p className="answer-para">
                <strong>A: </strong>{q.answer}
              </p>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        <h2>Waiting for answer</h2>
        {questions.filter(q => q.answer === undefined).map((q, idx) =>
          <ExpansionPanel expanded={this.state.opened[idx]} onChange={() => this.toggle(idx)} key={`q-${idx}`}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${idx}-content`}
              id={`panel${idx}-header`}
            >
              <Typography><strong>{q.receiptNo}</strong></Typography>
              <Typography className="middle">{q.userName}</Typography>
              <Typography className="date">{moment(q.createdAt).format('DD MMM YYYY HH:mm')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <p className="question-para">
                {q.question}
              </p>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}


        {/* <Drawer direction="left" opened={openedStatement !== undefined}>
          {openedStatement ?
            <div className="drawerContainer">
              <div className="drawerHeader">
                <Typography>Credit Card Report Details</Typography>
                <CloseIcon onClick={() => this.setState({ openedStatement: undefined })} />
              </div>
              <div className="drawerContent" style={{ width: 575 }}>
                <dl className="statementDetails">
                  <dt>Card Number</dt>
                  <dd><CreditCard card={openedStatement.card} /></dd>
                  <dt>Card Holder</dt>
                  <dd>{openedStatement.holder.name}</dd>
                  <dt>Employee Email</dt>
                  <dd>{currentUser.emailid}</dd>
                  {currentUser.phone &&
                    <>
                      <dt>Employee Phone</dt>
                      <dd>{currentUser.phone}</dd>
                    </>
                  }
                  <dt>Period</dt>
                  <dd>{formatDate(openedStatement.minDate, 'DD MMM')} - {formatDate(openedStatement.maxDate, 'DD MMM YYYY')}</dd>
                  <dt>Total Amount</dt>
                  <dd>{openedStatement.amounts.reduce((prev, curr) => prev + parseFloat(curr.amount), 0).toFixed(2)} {openedStatement.currency}</dd>
                  <dt>No. Trransactions</dt>
                  <dd>{openedStatement.amounts.length}</dd>
                </dl>
              </div>
            </div>
            : null}
        </Drawer> */}
      </Page>
    )
  }
}


const mapStateToProps = state => {
  return {
    questions: state.TaskReducer.questions,
    currentCompany: state.CompanyReducer.currentCompany,
  };
}

export default connect(mapStateToProps, { getQuestions,deleteQuestion })(Questions);
