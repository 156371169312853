import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from '@material-ui/core/Slider';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { formatDate } from '../dateHelpers';
import { taskSteps } from '../taskRedirect';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { saveAs } from 'file-saver';

import task from '../../firebasecomponents/task';
// import { Pwebapps } from '../../externalAPI';
import { CircularProgress } from '@material-ui/core';


const JSZip = require("jszip");
const zip = new JSZip();

const marks = [
  { value: 0, label: 'Jan', },
  { value: 3, label: 'Apr', },
  { value: 6, label: 'Jul', },
  { value: 9, label: 'Oct', },
];
const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'];

function valueLabelFormat(value) {
  return monthLabels[value];
}

function valuetext(value) {
  return `${value}`;
}

function getMimeType(base64Str) {
  let ext = null;
  let value = null

  if (typeof base64Str !== 'string') {
    return [null, null];
  }

  const mime = base64Str.match(/data:[a-zA-Z0-9]+\/([a-zA-Z0-9-.+]+).*base64,(.*)/);
  if (mime && mime.length) {
    ext = mime[1];
    value = mime[2];
  }

  return [ext, value];
}

const downloadFromStorage = (fileUrl, fileName) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      resolve(zip.file(fileName, xhr.response));
    }
    xhr.onerror = err => reject(err);
    xhr.open('GET', fileUrl);
    xhr.send();
  })
}



function GenerateReport(props) {

  const [startDate, setStartDate] = useState(props.year ? new Date(props.year, 0, 1) : new Date());
  const [endDate, setEndDate] = useState(props.year ? new Date(props.year, 12, 0) : new Date());
  const [downloadReport, setDownloadReport] = useState(false);
  const [downloadFiles, setDownloadFiles] = useState(false);
  const [monthSelect, setMonthSelect] = useState([0, 12]);

  const [downloading, setDownloading] = useState(false);

  const [moreOptions, setMoreOptions] = useState(false);
  const [dateType, setDateType] = useState('updatedAt');
  const d = ["non-reimbursible task", "payment-done"];
  const init = Object
    .keys(taskSteps)
    .map(e => ({ [e]: d.includes(e) }))
    .reduce((obj, item) => ({ ...obj, ...item }))
  const [expenseTypes, setExpenseTypes] = useState(init);


  const handleChange = (event, newValue) => {
    const [start, end] = newValue;
    setMonthSelect(newValue);
    setStartDate(new Date(props.year, start, 1));
    setEndDate(new Date(props.year, end, 0));
  };

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setExpenseTypes({ ...expenseTypes, [type]: !expenseTypes[type] })
  };

  const download = async () => {
    // console.log('download')
    setDownloading(true);
    const downloadExpenseTypes = Object.keys(expenseTypes).filter(e => expenseTypes[e]);
    await task.getTasksFromDateRange(props.companyId, { startDate, endDate }, { dateType, expenseTypes: downloadExpenseTypes })
      .then(results => {
        const rows = [
          ["Date", "No.", "Type", "Employee", "Merchant", "Amount", "Currency", "Foreign Amount in CHF", "FX Rate", "CHF excl. VAT", "VAT Amount (CHF)", "VAT %", "VAT Name", "Approval status", "Reimbursability status", "Task status"]
        ];

        let receiptNo = '';

        const fileDownloadPromises = [];

        results.forEach(d => {
          let [ext, value] = getMimeType(d.base64Value);
          receiptNo = d.receiptNo;
          if (downloadFiles) {
            if (ext !== null && value !== null) {
              zip.file(d.receiptNo + '.' + ext, value, { base64: true });
            } else {
              ext = d.base64Value.match(/\.([a-zA-Z0-9]+)\?/)[1]
              fileDownloadPromises.push(downloadFromStorage(d.base64Value, d.receiptNo + '.' + ext));
            }

          }

          const rate = d.currency === 'CHF' ? '1' : d.rate;
          if (d.amounts) {
            d.amounts.forEach(am => {

              rows.push([
                d.date === undefined ? '-' : d.date,
                d.receiptNo,
                am.type,
                d.empl_name,
                d.merchant,
                am.amount === '' ? 0 : am.amount,
                d.currency,
                am.amountCHF === ''? 0 : am.amountCHF,
                rate,
                // d.currency === 'CHF' ? am.amount : am.amountCHF,
                d.currency === 'CHF' ? am.amount - (am.vat_amount=== '' ? 0 : am.vat_amount) : am.amountCHF - (am.vat_amountCHF===''? 0 : am.vat_amountCHF),
                // am.vat_amount === '' ? 0 : am.vat_amount,
                am.vat_amount === '' ? 0 : d.currency === 'CHF'? am.vat_amount : am.vat_amountCHF,
                am.vat_percentage.value,
                am.vat_percentage.name,
                d.approved,
                d.reimbursable,
                d.task_defination
              ])
            })
          } else { // data not extracted
            rows.push([
              d.date === undefined ? '-' : d.date,
              d.receiptNo,
              '-',
              d.empl_name,
              '-',
              0,
              '-',//currency
              '-',
              '-',//fx rate
              0,//chf excl vat
              0,//vatamount
              0,//vat%
              '-',//vatname
              d.approved,
              d.reimbursable,
              d.task_defination
            ])
          }
        })


        if (downloadReport) {

          let csvContent = "";
          rows.forEach(function (rowArray) {
            let row = rowArray.join(";");
            csvContent += row + "\r\n";
          });
          if (downloadFiles) {
            zip.file(receiptNo.slice(0, 3) + '.csv', csvContent)
          } else {
            const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8" })
            saveAs(blob, receiptNo.slice(0, 3) + '.csv')
            setDownloading(false);
          }
        }
        if (downloadFiles) {
          Promise.all(fileDownloadPromises)
          .then(() => zip.generateAsync({ type: "blob" }))
          .then(blob => {
            saveAs(blob, receiptNo.slice(0, 3) + ".zip");
            setDownloading(false);
          })
          .catch(err => this.setState({ error: err }))
        }
      })
  }



  const customDate = (<MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Grid container justify="space-around">
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="yyyy-MM-dd"
        margin="normal"
        id="date-picker-inline"
        label="Start"
        autoOk
        maxDate={endDate}
        value={startDate}
        onChange={(date) => setStartDate(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="yyyy-MM-dd"
        margin="normal"
        id="date-picker-inline"
        label="End"
        autoOk
        minDate={startDate}
        value={endDate}
        onChange={(date) => setEndDate(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </Grid>
  </MuiPickersUtilsProvider>);


  const yearSelect = (<Grid container >
    <Slider
      value={monthSelect}
      onChange={handleChange}
      valueLabelDisplay="auto"
      aria-labelledby="range-slider"
      getAriaValueText={valuetext}
      valueLabelFormat={valueLabelFormat}
      min={0}
      max={12}
      step={1}
      marks={marks}
    />
  </Grid>)

  const dateTypeSelect =
    <FormControl component="fieldset">
      <FormLabel component="legend">Expense date type</FormLabel>
      <RadioGroup aria-label="dateType" name="dateType" value={dateType} onChange={(e) => setDateType(e.target.value)}>
        <FormControlLabel value="createdAt" control={<Radio />} label="Upload date" />
        <FormControlLabel value="date" control={<Radio />} label="Date extracted from receipt" />
        <FormControlLabel value="updatedAt" control={<Radio />} label="Last modification date" />
      </RadioGroup>
    </FormControl>

  const expenseTypeSelect = expenseTypes ?
    <FormControl component="fieldset">
      <FormLabel component="legend">Expense type</FormLabel>
      {Object.keys(taskSteps).map(k => {
        return <FormControlLabel key={k}
          control={<Checkbox onChange={handleTypeChange} checked={expenseTypes[k]} value={k} />}
          label={taskSteps[k].txt}
        />
      })}
    </FormControl> : null

  return (<Dialog open >
    <DialogContent>
      <h2>Download time frame <br /> {formatDate(startDate)} : {formatDate(endDate)}</h2>
      <p>This will get all finished expenses uploaded in selected time frame despoite the receipt date.</p>
      {props.year ? yearSelect : customDate}
      <h2 style={{ margin: "25px 0 10px 0" }}>What would You like to download?</h2>
      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel style={{ marginLeft: '10px!important' }}
            control={
              <Checkbox checked={downloadReport}
                onChange={() => setDownloadReport(!downloadReport)}
              />
            }
            label="Excel or CSV"
          />
          <FormControlLabel style={{ marginLeft: '10px!important' }}
            control={
              <Checkbox checked={downloadFiles}
                onChange={() => setDownloadFiles(!downloadFiles)}
              />
            }
            label="Zip with all images/pdfs"
          />
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: '#3f51b5', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setMoreOptions(!moreOptions)}>{moreOptions ? 'hide' : ''} more options...</p>
        </Grid>
        {moreOptions ? <Grid item xs={6}>{dateTypeSelect}</Grid> : null}
        {moreOptions ? <Grid item xs={6}>{expenseTypeSelect}</Grid> : null}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closed} color="primary">
        Cancel
            </Button>
      <Button onClick={download} color="primary">
        Download Report {downloading ? <CircularProgress /> : null}
      </Button>
    </DialogActions>
  </Dialog >)
}

export default GenerateReport;
