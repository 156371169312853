import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getData,
  updateTask,
  updateTasks,
  clearDone,
} from '../../store/actions';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../../newComponents/TabPanel';

import Page from '../page';

import Policies from './Policies.js';
import CreditCards from './CreditCards.js';
import CostCenter from './CostCenter.js';
import Customers from './Customers';
import ApproverInformation from "./ApproverInformation";
import {getCollapsed, getShowExpenseItems} from "../../newHelpers/pageHelper";

const tabIndices = {
  policy: 0,
  creditcard: 1,
  costcentre: 2,
  customers: 3,
  approvers: 4,

};

class Customization extends Component {

  constructor(props) {
    super(props);

    const hash = this.props.location.hash.replace('#', '');
    this.state = { value: hash === '' ? 0 : tabIndices[hash] };

    this.handleChange = this.handleChange.bind(this);

    //this.onActive = this.onActive.bind(this);
    //this.getDefaultActiveTab = this.getDefaultActiveTab.bind(this);
  }

  handleChange(event, newValue) {
    const tabName = Object.keys(tabIndices)[newValue];
    this.props.history.replace(`#${tabName}`);
    this.setState({ value: newValue });
  }

  render() {
    return (
      <Page
        role="Company"
        currentPage="customization"
        header="Customization"
        actions={''}
        collapsedNavBar={getCollapsed(this.props)}
        collapsedExpItems={getShowExpenseItems(this.props)}
      >
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          style={{ overflow: 'visible' }}
        >
          <Tab label="Policy" />
          <Tab label="Credit Cards" />
          <Tab label="Cost Centers" />
          <Tab label="Customers" />
          <Tab label="Approvers" />
        </Tabs>

        <TabPanel value={this.state.value} index={0} invisible>
          <Policies />
        </TabPanel>
        <TabPanel value={this.state.value} index={1} invisible>
          <CreditCards />
        </TabPanel>
        <TabPanel value={this.state.value} index={2} invisible>
          <CostCenter />
        </TabPanel>
        <TabPanel value={this.state.value} index={3} invisible>
          <Customers />
        </TabPanel>
        <TabPanel value={this.state.value} index={4} invisible>
          <ApproverInformation />
        </TabPanel>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tasks: state.TaskReducer.tasks,
    updateDone: state.TaskReducer.done,
    currentCompany: state.CompanyReducer.currentCompany,
  };
};

export default connect(mapStateToProps, {
  getData,
  updateTask,
  updateTasks,
  clearDone,
})(Customization);
