
import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import MenuItem from '@material-ui/core/MenuItem';

import TextField from '../../../newComponents/TextField';
import Select from '../../../newComponents/Select';


class CreditCardForm extends Component {

  constructor(props) {
    super(props);
    const card = props.card;

    this.state = {
      holder: '',
      number: '',
      system: '',
      accounts: '',
      ...card
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.card !== this.props.card) {
      this.setState({ ...this.props.card })
    }
  }

  handleChange = (field) => (newValue) => {
    const state = { ...this.state, [field]: newValue }
    this.setState(state);
    this.props.onChange(state);
  };


  render() {

    return (
      <Grid container spacing={3} style={{ marginTop: '10px' }}>
        <Grid item xs={12}>
          <Select
            label="Card Holder"
            onChange={this.handleChange('holder')}
            value={this.state.holder}
          >
            {this.props.employees.map(e => <MenuItem key={e.employeeId} value={e.employeeId}>{e.firstName} {e.lastName}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Credit Card number (last 4 digits)"
            onChange={this.handleChange('number')}
            value={this.state.number}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Payment System"
            onChange={this.handleChange('system')}
            value={this.state.system}
          >
            <MenuItem value="Visa" >Visa</MenuItem>
            <MenuItem value="Master Card" >Master Card</MenuItem>
            <MenuItem value="Maestro" >Maestro</MenuItem>
            <MenuItem value="AmEx" >AmEx</MenuItem>
            <MenuItem value="Discrover" >Discrover</MenuItem>
            <MenuItem value="Other" >Other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Credit Card Account"
            onChange={this.handleChange('accounts')}
            value={this.state.accounts || ''}
          >
            {this.props.creditAccounts? this.props.creditAccounts.map((c, idx) => <MenuItem key={idx} value={c.id}>{c.name}</MenuItem>) : <MenuItem value="-">-</MenuItem>}
          </Select>
        </Grid>
        {this.state.users && this.state.users.length > 0 ?
          <Grid item xs={12}>
            <strong>Card users</strong>
            <ul>
              {this.state.users.map(u => {
                const user = this.props.employees.find(e => e.employeeId === u);
                return <li key={u}>{user.firstName} {user.lastName}</li>
              })}
            </ul>
          </Grid> : null}
      </Grid>
    )
  }

}


export default CreditCardForm;
