import React,{useState} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

function SearchBar(props) {

  const [value, setValue] = useState('')

  const handleChange = (e)=>{
    const val = e.target.value;
    setValue(val);
    props.onChange(val)
  }

  return <FormControl variant="outlined">
    <OutlinedInput
      id="outlined-adornment-password"
      type='search'
      value={value}
      onChange={handleChange}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  </FormControl>
}

export default SearchBar;