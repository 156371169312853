import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import React from 'react';
import cs from '../../../assets/bankicons/CS.svg';
import ubs from '../../../assets/bankicons/UBS.svg';
import akb from '../../../assets/bankicons/AKB.svg';
import jb from '../../../assets/bankicons/JB.svg';
import mb from '../../../assets/bankicons/MB.svg';
import rf from '../../../assets/bankicons/Raiffeisen.svg';
import zkb from '../../../assets/bankicons/ZKB.svg';
import { formatCurrencyToLocale } from '../navigationHelper';

const bankMap = {
  'Credit Suisse': cs,
  UBS: ubs,
  AKB: akb,
  'Julius Bär': jb,
  'Migros Bank': mb,
  Raiffeisen: rf,
  ZKB: zkb,
};

const BanksView = ({ company }) => {
  return (
    <Grid container spacing={3}>
      {company && company.topalBankAccounts
        ? company.topalBankAccounts.map((e) => {
            const total = e.balance ? e.balance : 0;
            const totalLocale = formatCurrencyToLocale(total);
            return (
              <Grid item xs={6} key={e.id}>
                <Box
                  display="flex"
                  flexDirection="column"
                  className="box-style bAccounts"
                >
                  <Typography variant="h6">Balance</Typography>
                  <Typography variant="h3" className="black">
                    {totalLocale}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginTop: '16px',
                      height: '20px',
                    }}
                  >
                    <img
                      id="bankIcon"
                      src={bankMap[e.bank]}
                      alt=""
                      style={{
                        marginRight: '10px',
                        objectFit: 'contain',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        width: 'auto',
                        height: 'auto',
                      }}
                    />
                    <div>{e.iban}</div>
                  </div>
                </Box>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};
export default BanksView;
