// export default category;
import firebase from '../components/firebase';
import Log from '../newHelpers/Log';
const Logger = new Log('Employee');

const db = firebase.db;

const Employee = {
  addEmployeeDetails: async (employeeDetails) => {
    return new Promise((resolve, reject) => {
      db.collection('employee')
        .where('emailid', '==', employeeDetails.emailid)
        .get()
        .then((doc) => {
          if (doc.docs.length > 0) {
            reject('User with that email already exists');
          } else {
            return db.collection('employee').add(employeeDetails);
          }
        })
        .then((ref) => resolve(ref))
        .catch((err) => reject(err));
    });
  },
  updateEmployeeDetails: async (emplId, employeeData) => {
    return new Promise((resolve, reject) => {
      db.collection('employee')
        .doc(emplId)
        .update(employeeData)
        .then((ref) => resolve(ref))
        .catch((err) => reject(err));
    });
  },

  deleteEmployee: (id) => {
    return new Promise((resolve, reject) => {
      try {
        const emp = db.collection('employee').doc(id).delete();
        if (emp) {
          resolve(emp);
        } else {
          reject('no employee to delete');
        }
      } catch (error) {
        reject(error);
      }
    });
  },

  getEmployeeNames: async (request, callback) => {
    let companyId = request.companyId;
    try {
      db.collection('employee')
        .where('companyId', '==', companyId)
        .onSnapshot(function (doc) {
          if (doc.docs.length > 0) {
            var associatedEmployeeDetails = [];
            for (let index = 0; index < doc.docs.length; index++) {
              const doc1 = doc.docs[index];
              var emplDetails = {};
              emplDetails.name = doc1.data().firstName + ' ' + doc1.data().lastName;
              emplDetails.id = doc1.id;
              emplDetails.emailid = doc1.data().emailid;

              // if(undefined === emplDetails.name){
              //   if(emplDetails.name.trim().length > 0){
              associatedEmployeeDetails.push(emplDetails);
              //   }
              // }
            }
            callback(associatedEmployeeDetails);
          } else {
            callback([]);
          }
        });
    } catch (error) {
      callback(null);
    }
  },
  getEmployees: async (request, callback) => {
    let companyId = request.companyId;
    try {
      db.collection('employee')
        .where('companyId', '==', companyId)
        .onSnapshot(function (doc) {
          callback(
            doc.docs.map((d) => ({
              employeeId: d.id,
              ...d.data(),
            }))
          );
        });
    } catch (error) {
      callback(null);
    }
  },
  fetchEmployeeDetails: async (request, callback, errCallback = () => {}) => {
    let employeeId = request.employeeId;
    Logger.log(request);
    try {
      let employeeDetails = await db
        .collection('employee')
        .where('emailid', '==', employeeId)
        .get();
      if (employeeDetails.docs.length > 0) {
        callback({
          userId: employeeDetails.docs[0].id,
          userData: employeeDetails.docs[0].data(),
        });
      } else {
        errCallback('no details for ' + employeeId);
      }
    } catch (error) {
      errCallback(error);
    }
  },

  fetchEmployeeDetailsById: async (request, callback, errCallback = () => {}) => {
    let employeeId = request.employeeId;
    Logger.log(employeeId);
    try {
      let employeeDetails = await db
        .collection('employee')
        .where('employeeId', '==', employeeId)
        .get();
      if (employeeDetails.docs.length > 0) {
        callback({
          userId: employeeDetails.docs[0].id,
          userData: employeeDetails.docs[0].data(),
        });
      } else {
        errCallback('no details for ' + employeeId);
      }
    } catch (error) {
      errCallback(error);
    }
  },

  fetchEmployeeDetailsByEmailId: async (request, callback) => {
    let emailId = request.empl_emailId;

    try {
      db.collection('employee')
        .where('emailid', '==', emailId)
        .onSnapshot(function (doc) {
          if (doc.docs.length > 0) {
            var employeeDetails = {};
            for (let index = 0; index < 1; index++) {
              const doc1 = doc.docs[index];
              employeeDetails.name = doc1.data().firstName + ' ' + doc1.data().lastName;
              employeeDetails.bankName = doc1.data().bankName;
              employeeDetails.bankplace = doc1.data().bankplace;
              employeeDetails.ibanNr = doc1.data().ibanNr;
            }
            callback(employeeDetails);
          } else {
            callback([]);
          }
        });
    } catch (error) {
      callback(null);
    }
  },

  getAllEmployee: async (dummyId, callback) => {
    let emplData = [];

    db.collection('employee').onSnapshot(function (employees) {
      if (employees.size > 0) {
        employees.forEach(function (employee) {
          let data_val = employee.data();
          data_val['id'] = employee.id;
          emplData = [...emplData, data_val];
        });
      }
      callback({ emplData });
    });
  },
  getEmployeeByNameAndCompanyName: async (request, callback) => {
    let companyName = request.companyName;
    let emplName = request.emplName;

    var firstName = emplName.substring(0, emplName.lastIndexOf(' '));
    let lastName = emplName.substring(emplName.lastIndexOf(' ') + 1);

    try {
      db.collection('employee')
        .where('companyName', '==', companyName.trim())
        .where('firstName', '==', firstName.trim())
        .where('lastName', '==', lastName.trim())
        .onSnapshot(function (doc) {
          if (doc.docs.length > 0) {
            var associatedEmployeeDetails = [];
            for (let index = 0; index < doc.docs.length; index++) {
              const doc1 = doc.docs[index];
              if (
                doc1.data().firstName === firstName.trim() &&
                doc1.data().lastName === lastName.trim()
              ) {
                associatedEmployeeDetails.push(doc1.data());
              }
            }
            callback(associatedEmployeeDetails);
          } else {
            callback([]);
          }
        });
    } catch (error) {
      callback(null);
    }
  },

  creditCards: {
    addCreditCard(companyId, card) {
      return new Promise((resolve, reject) => {
        const id =
          Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
        db.collection('creditcard')
          .doc(id)
          .set({ ...card, id, companyId, users: [], disabled: false })
          .then((ref) => resolve(ref))
          .catch((err) => reject(err));
      });
    },
    updateCreditCard(card) {
      return new Promise((resolve, reject) => {
        db.collection('creditcard')
          .doc(card.id)
          .update(card)
          .then((ref) => resolve(ref))
          .catch((err) => reject(err));
      });
    },

    getCreditCards(companyId, callback) {
      try {
        db.collection('creditcard')
          .where('companyId', '==', companyId)
          .where('disabled', '==', false)
          .onSnapshot((doc) => callback(doc.docs.map((d) => d.data())));
      } catch (error) {
        callback(null);
      }
    },
    getEmployeeCreditCards(employeeId, callback) {
      try {
        db.collection('creditcard')
          .where('users', 'array-contains', employeeId)
          .where('disabled', '==', false)
          .onSnapshot((doc) => callback(doc.docs.map((d) => d.data())));
      } catch (error) {
        callback(null);
      }
    },
    getCreditCardUsers(cardId, callback) {
      Logger.log('cardId', cardId);
      try {
        db.collection('creditcard')
          .doc(cardId)
          .get()
          .then((doc) => callback(doc));
      } catch (err) {
        callback(null);
      }
    },
  },
};
export default Employee;
