import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const cardLogos = {
  "Visa": require("../../assets/cards/visa.png"),
  "Master Card": require("../../assets/cards/master.png"),
  "Maestro": require("../../assets/cards/maestro.png"),
  "AmEx": require("../../assets/cards/amEx.png"),
  "Discrover": require("../../assets/cards/discover.png"),
}

const useStyles = makeStyles({
  root: {
    display:'block',
    marginBottom: '10px',
    '& img':{
      display: 'inline-block',
      verticalAlign: 'middle',
      border: '1px solid #E8E8E8',
      width: '63px',
      height: '40px',
      marginRight: '10px'
    }
  }
});

function CreditCard(props) {
  const { card } = props;
  const classes = useStyles(props)

  return <span className={classes.root}>
    {cardLogos[card.system] ? <img src={cardLogos[card.system]} alt={card.system} className="cardLogo" /> : null}
    **** {card.number}
  </span>
}

export default CreditCard;