
import moment from 'moment';


const csvMapping = [{
  "cardNumber": "Kartennummer",
  "holder": "Konto-/Karteninhaber",
  "date": "Einkaufsdatum",
  "description": "Buchungstext",
  "currency": "Währung",
  "amount": "Belastung",
  "dateFormat": "DD.MM.YYYY"
},
{
  "cardNumber": "Choose card",
  "holder": "Choose holder",
  "date": "Completed Date ",
  "description": " Description ",
  "currency": "Choose currency",
  "amount": " Paid Out (CHF) ",
  "dateFormat": "MMM dd, YYYY "
}]

const countFit = (map, columns) =>  Object.values(map).filter(m => columns.includes(m)).length


function mapData(results, options) {
  const { map } = options;

  return new Promise((resolve, reject) => {
    if (!results.data || results.data.lenght === 0) {
      resolve([]);
    }
    const columns = Object.keys(results.data[0]);
    let thisMap = map ? map :
      csvMapping.find(map =>
        countFit(map, columns) === 6 //6 obligatory columns
      )
    if (thisMap === undefined) {
      const bestFit = csvMapping.sort((a,b)=> countFit(a, columns) > countFit(b, columns)).pop()
      reject({bestFit})
    }
    const data = results.data.map(e => {
      const elem = {};
      Object.keys(thisMap).forEach(k => {
        elem[k] = e[thisMap[k]]
      })
      elem.date = moment(elem.date, thisMap.dateFormat)
      return elem;
    });
    resolve(data)
  })
}

function processCSV(data, employees, cards, options = {}) {
  const entries = {};
  const errors = [];
  const { holder, card, currency } = options

  const emp = employees.map(e => ({
    name: e.firstName + ' ' + e.lastName,
    id: e.employeeId,
  }));

  data.forEach((d, idx) => {

    const thisEmp = holder ?
      {
        name: holder.firstName + ' ' + holder.lastName,
        id: holder.employeeId,
      } :
      emp.find(e => d.holder.toLowerCase() === e.name.toLowerCase());
    if (!thisEmp) {
      errors.push('Row ' + (idx + 1) + '. Employee not found');
      return
    }

    const cardDetails = card ? card : cards.find(c => c.holder === thisEmp.id && d.cardNumber.includes(c.number))
    if (!cardDetails) {
      errors.push('Row ' + (idx + 1) + '. Employee does not have this card ' + d.cardNumber);
      return
    }

    if (d.amount === '') {
      errors.push('Row ' + (idx + 1) + '. Amount is empty ' + d.cardNumber);
      return
    }
    if (d.currency === '' && currency === undefined) {
      errors.push('Row ' + (idx + 1) + '. Currency is empty ' + d.cardNumber);
      return
    }

    const entryKey = thisEmp.name + ' ' + cardDetails.number
    const entryDate = d.date
    if (Object.keys(entries).includes(entryKey)) {
      const min = entries[entryKey].minDate;
      const max = entries[entryKey].maxDate
      entries[entryKey].minDate = entryDate.isBefore(min) ? entryDate : min;
      entries[entryKey].maxDate = entryDate.isAfter(max) ? entryDate : max;
      entries[entryKey].amounts.push({
        date: entryDate,
        description: d.description,
        amount: parseFloat(d.amount),
        id: Math.random().toString(36).substring(2, 8),
        //todo enter currency here if needed
      })
    } else {
      console.log(idx +' first')
      entries[entryKey] = {
        minDate: entryDate,
        maxDate: entryDate,
        card: cardDetails,
        holder: thisEmp,
        currency: currency ? currency : d.currency,
        amounts: [{
          date: entryDate,
          description: d.description,
          amount: parseFloat(d.amount),
          id: Math.random().toString(36).substring(2, 8),
        }]
      }
    }
  })
  return { entries, errors }
}

export default (results, employees, cards, options = {}) => {
  return new Promise((resolve, reject) => {

    mapData(results, options)
      .then(mappedData => {
        const info = processCSV(mappedData, employees, cards, options)
        console.log('input',mappedData, employees, cards, options)
        console.log('output',info)
        resolve({ data: Object.values(info.entries), errors: info.errors })
      })
      .catch(e => {
        console.log(e)
        reject({ rawData: results, typesToMap: Object.keys(results.data[0]), bestFit: e.bestFit })
      })

  })
}
