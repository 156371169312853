import firebase from 'firebase/app';
import 'firebase/functions';
import { production } from '../config';
const REGION = production ? 'us-central1' : 'europe-west3';

const app = firebase.app();
const fbFunctions = app.functions(REGION);

if (window.location.host.includes('localhost')) {
  fbFunctions.useFunctionsEmulator('http://localhost:5001');
}

export { fbFunctions };
