import axios from 'axios';
import * as moment from 'moment';
import { clexxAPI } from './ClexxAPI';
import { fbFunctions } from '../firebasecomponents/fbFunctions';
import Log from "../newHelpers/Log";
const _ = require('lodash');
const Logger = new Log('Parashift');

const Parashift = {
  sendDoc: (base64file, taskID, extraction) => {
    console.log(
      'base64file',
      base64file,
      'taskId',
      taskID,
      'extraction',
      extraction,
    );
    return new Promise((resolve, reject) => {
      const params = {
        base64_file: base64file
          .replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
          .replace(/^data:application\/pdf;base64,/, ''),
        taskID,
        extraction,
        document_type: 'receipt',
        extract_line_items: true,
      };
      console.log('params', params);
      const headers = { 'Access-Control-Allow-Origin': '*' };
      axios
        .post(clexxAPI + 'sendDoc', params, headers)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDoc: (id) => {
    return new Promise((resolve, reject) => {
      const headers = { 'Access-Control-Allow-Origin': '*' };
      axios
        .get(clexxAPI + 'getDoc?id=' + id, headers)
        .then((response) => {
          const data = response.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  mapParashiftToInvoice: (invoiceDetails) => {
    console.log('map parashift to invoice invoiceDetails', invoiceDetails);
    if (
      invoiceDetails.parashift === undefined ||
      invoiceDetails.parashift.current_status !== 'done'
    )
      return invoiceDetails;

    if (invoiceDetails.parashift.included === undefined) return invoiceDetails;

    const included = invoiceDetails.parashift.included;
    let accountingData, senderData;

    if (included[0].type === 'accounting_document') {
      accountingData = included[0].attributes;
      if (included.length > 1 && included[1].type === 'sender_address') {
        senderData = included[1].attributes;
      }
    } else if (included[0].type === 'sender_address') {
      senderData = included[0].attributes;
      if (included.length > 1 && included[1].type === 'accounting_document') {
        accountingData = included[1].attributes;
      }
    }

    console.log('accounting data', accountingData);
    console.log('sender data', senderData);

    const merchant = {};
    merchant.name =
      // !invoiceDetails.merchant &&
      senderData && senderData.company_name ? senderData.company_name : '';

    merchant.city =
      senderData && senderData.city_name ? senderData.city_name : '';
    merchant.zip =
      senderData && senderData.postal_code ? senderData.postal_code : '';
    const street = senderData && senderData.street ? senderData.street : '';
    const house_number =
      senderData && senderData.house_number ? senderData.house_number : '';

    merchant.address1 = street.toString() + ' ' + house_number.toString();
    merchant.country =
      senderData && senderData.country_code ? senderData.country_code : '';

    const currency =
      !invoiceDetails.currency && accountingData && accountingData.currency
        ? { currency: accountingData.currency }
        : {};

    //if vatIncluded is not set, set it per default to true
    let vatIncluded;
    if (accountingData && accountingData.including_tax) {
      vatIncluded = true;
    } else if (accountingData && accountingData.including_tax === false) {
      vatIncluded = false;
    } else {
      vatIncluded = true;
    }
    console.log('vatIncluded', vatIncluded);

    const amounts =
      !invoiceDetails.amounts &&
      accountingData &&
      accountingData.items &&
      accountingData.items.length > 0
        ? {
            amounts: vatIncluded
              ? accountingData.items.map(toVatAmount)
              : accountingData.items.map(toVatAmountVatNotIncluded),
          }
        : {};

    const amount_total =
      accountingData &&
      accountingData.amount_details &&
      accountingData.amount_details.gross_amount_total
        ? { amount_total: accountingData.amount_details.gross_amount_total }
        : {};

    // const account_number = !invoiceDetails.account_number && accountingData.bank_accounts!== null && accountingData.bank_accounts.length > 0 ? {account_number: accountingData.bank_accounts[0].account_number} : {}
    // const account_number =
    //   accountingData.bank_accounts !== null &&
    //   accountingData.bank_accounts.length > 0
    //     ? { account_number: accountingData.bank_accounts[0].account_number }
    //     : {};
    const account_number =
      accountingData && accountingData.esr && accountingData.esr.account_esr
        ? { account_number: accountingData.esr.account_esr }
        : {};

    // const esr = !invoiceDetails.esr && accountingData.esr_reference ? {esr:accountingData.esr_reference}:{}
    const esr =
      accountingData && accountingData.esr && accountingData.esr.reference_esr
        ? { esr: accountingData.esr.reference_esr }
        : {};

    const freeInvoiceNum =
      !invoiceDetails.freeInvoiceNum && accountingData.number
        ? { freeInvoiceNum: accountingData.number }
        : {};

    const bic =
      accountingData.bank_accounts !== null &&
      accountingData.bank_accounts.length > 0
        ? { bic: accountingData.bank_accounts[0].bic }
        : {};

    const iban =
      accountingData.bank_accounts !== null &&
      accountingData.bank_accounts.length > 0
        ? { iban: accountingData.bank_accounts[0].iban }
        : {};

    const vatNumber =
      !invoiceDetails.vatNumber && senderData.vat_id
        ? { vatNumber: senderData.vat_id }
        : { vatNumber: '' };

    // let account_number = {};
    // if(!invoiceDetails.account_number && accountingData.bank_accounts.length > 0

    //need iban+bic or account_number+esr+bic
    let payment = {};
    //set bic to '' if it was not given
    // (!isEmpty(bic) && bic.bic!=='') ? payment.bic = bic.bic : payment.bic=-1;
    // bic.bic===''?payment.bic=-1:;
    if (isEmpty(bic) || bic.bic === '' || bic.bic === null) {
      payment.bic = '';
    } else {
      payment.bic = bic.bic;
    }

    if (!isEmpty(esr)) {
      payment.esr = esr.esr;
      !isEmpty(account_number)
        ? (payment.account_number = account_number.account_number)
        : (payment.account_number = '');
    } else {
      if (!isEmpty(iban)) payment.iban = iban.iban;
    }
    console.log('payment', payment);

    let invoiceDate = {};
    if (
      !invoiceDetails.invoiceDate &&
      accountingData &&
      accountingData.invoice_date
    ) {
      invoiceDate = moment(accountingData.invoice_date).format('YYYY-MM-DD');
      invoiceDate = { invoiceDate: invoiceDate };
      console.log('invoice Date', invoiceDate);
    }

    // let dueDate = !invoiceDetails.dueDate && accountingData && accountingData.payment_conditions.length>0 && accountingData.payment_conditions[0].date!== null ? { dueDate: accountingData.payment_conditions[0].date } : {}
    let dueDate =
      accountingData &&
      accountingData.payment_conditions.length > 0 &&
      accountingData.payment_conditions[0].date !== null
        ? { dueDate: accountingData.payment_conditions[0].date }
        : {};
    console.log('due date', dueDate);
    // const number_of_days = !invoiceDetails.dueDate && accountingData && accountingData.payment_conditions[0].number_of_days ? number_of_days: accountingData.payment_conditions[0].number_of_days : ;
    if (
      !invoiceDetails.dueDate &&
      accountingData &&
      accountingData.payment_conditions.length > 0 &&
      accountingData.payment_conditions[0].number_of_days
    ) {
      let number_of_days = accountingData.payment_conditions[0].number_of_days;
      console.log('num of days', number_of_days);
      if (
        !accountingData.payment_conditions[0].date &&
        isFinite(accountingData.payment_conditions[0].number_of_days)
      ) {
        dueDate = moment(invoiceDate.invoiceDate)
          .add(number_of_days, 'days')
          .format('YYYY-MM-DD');
        console.log('due date', dueDate);
        dueDate = { dueDate: dueDate };
      }
    }

    const extracted = { extracted: true };
    return {
      ...invoiceDetails,
      merchant,
      ...amount_total,
      ...currency,
      ...amounts,
      ...invoiceDate,
      ...dueDate,
      // ...account_number,
      // ...esr,
      payment,
      ...extracted,
      ...freeInvoiceNum,
      ...vatNumber,
    };
  },

  mapParashiftToTask: (taskDetails) => {
    if (
      taskDetails.parashift === undefined ||
      taskDetails.parashift.current_status !== 'done'
    )
      return taskDetails;

    if (taskDetails.parashift.included === undefined) return taskDetails;

    const included = taskDetails.parashift.included;
    let accountingData, senderData;

    if (included[0].type === 'accounting_document') {
      accountingData = included[0].attributes;
      if (included.length > 1 && included[1].type === 'sender_address') {
        senderData = included[1].attributes;
      }
    } else if (included[0].type === 'sender_address') {
      senderData = included[0].attributes;
      if (included.length > 1 && included[1].type === 'accounting_document') {
        accountingData = included[1].attributes;
      }
    }

    const merchant =
      senderData && senderData.company_name
        ? { merchant: senderData.company_name }
        : {};
    const date =
      accountingData && accountingData.invoice_date
        ? { date: accountingData.invoice_date }
        : {};
    const currency =
      accountingData && accountingData.currency
        ? { currency: accountingData.currency }
        : {};

    const amounts =
      !taskDetails.amounts &&
      accountingData &&
      accountingData.items &&
      accountingData.items.length > 0
        ? { amounts: accountingData.items.map(toVatAmount) }
        : {};

    const amount_total =
      accountingData &&
      accountingData.amount_details &&
      accountingData.amount_details.gross_amount_total
        ? { amount_total: accountingData.amount_details.gross_amount_total }
        : {};

    const returnData = {
      ...taskDetails,
      ...merchant,
      ...date,
      ...currency,
      ...amounts,
      ...amount_total,
    };

    if (
      taskDetails.expenseTypePrediction &&
      taskDetails.expenseTypePrediction.length > 0 && !_.isEmpty(amounts)
    ) {
      const f = amounts.amounts;
      for (const amount of f) {
        const match = taskDetails.expenseTypePrediction.find(
          (x) => x.title === amount.item_name,
        );
        console.log('match: ', match);
        const expType = match.predictions.reduce((a, b) =>
          a.score > b.score ? a : b,
        );
        console.log('expType', expType);
        amount.type = expType.type;
      }
      returnData.amounts = f;
    }

    return returnData;
  },

  extractInvoice: (invoiceID) => {
    return new Promise(async (resolve, reject) => {
      try {
        const extractInvoice = fbFunctions.httpsCallable('extractInvoice');
        const res = await extractInvoice(invoiceID);
        console.log('res', res);
        resolve(res);
      } catch (error) {
        console.log('error---', error);
        console.log(error.response);
        if (error.response && error.response.data) {
          console.log('error data', error.response.data);
          reject(error.response.data);
        } else {
          console.log('else');
          reject(JSON.stringify(error));
        }
      }
    });
  },
  sendDocv2: (id, base64file, isInvoice ) => {
    /**
     * For parashift's api v2
     * @param id: String : taskId/invoiceId
     * @param isInvoice: boolean
     */
    return new Promise(async (resolve, reject) => {
      try {
        const sendDocv2 = fbFunctions.httpsCallable('sendDocToParashiftv2');
        const res = await sendDocv2({id, base64file, isInvoice});
        Logger.log('res', res);
        resolve(res);
      } catch (error) {
        Logger.log('error---', error);
        Logger.log(error.response);
        if (error.response && error.response.data) {
          Logger.log('error data', error.response.data);
          reject(error.response.data);
        } else {
          Logger.log('else');
          reject(JSON.stringify(error));
        }
      }
    });
  },
  getDocv2: (id) => {
    /**
     * For parashift's api v2
     * @param id: String : taskId/invoiceId
     * @param isInvoice: boolean
     */
    return new Promise(async (resolve, reject) => {
      try {
        const getDoc = fbFunctions.httpsCallable('getDocFromParashiftv2');
        const res = await getDoc({id});
        Logger.log('res', res);
        resolve(res);
      } catch (error) {
        Logger.log('error---', error);
        Logger.log(error.response);
        if (error.response && error.response.data) {
          Logger.log('error data', error.response.data);
          reject(error.response.data);
        } else {
          Logger.log('else');
          reject(JSON.stringify(error));
        }
      }
    });
  }
};


function toVatAmountVatNotIncluded(item) {
  console.log('item', item);
  const amountNoVat = parseFloat(item.amount_total);
  const vat = item.tax_rate !== null ? parseFloat(item.tax_rate) : 0;
  console.log('vat', vat);
  let vat_amount = vat !== 0 ? amountNoVat * (vat / 100) : 0;
  console.log('vat amount ', vat_amount);
  vat_amount = vat !== 0 ? parseFloat(vat_amount.toFixed(2)) : 0;
  console.log('vat amount ', vat_amount);

  const amount = amountNoVat + parseFloat(vat_amount);

  return {
    type: '',
    item_name: item.title,
    amount: amount,
    vat_amount: vat_amount,
    amountNoVat,
    vat_percentage: { name: item.tax_rate + '%', value: vat },
    policyFields: {},
  };
}

function toVatAmount(item) {
  // console.log('to Vat amount');
  const vat = parseFloat(item.tax_rate);
  const amount = parseFloat(item.amount_total);
  const vat_amount = +((amount * vat) / (100 + vat)).toFixed(2);

  return {
    type: '',
    item_name: item.title,
    amount: amount,
    vat_amount: vat_amount,
    vat_percentage: { name: item.tax_rate + '%', value: vat },
    policyFields: {},
  };
}
// function toVatAmount(item) {
//   const vat = parseFloat(item.tax_rate);
//   const amount = parseFloat(item.amount_total);
//   const vat_amount = +(amount * vat / (100 + vat)).toFixed(2)
//
//   return ({
//     type: '',
//     item_name: item.title,
//     amount: amount,
//     vat_amount: vat_amount,
//     vat_percentage: { name: item.tax_rate + '%', value: vat },
//     policyFields: {}
//   })
// }

function isEmpty(obj) {
  for (var x in obj) {
    return false;
  }
  return true;
}

export default Parashift;
