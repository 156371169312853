import React from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../Select';

const SelectFiscalYearComponent = (props) => {
  /*
  invoice has topalFiscalID = 3 = props.value

  fiscalYears = [
    {
      id:3,
      name: 2022,
      ...
    },
   ]
   */
  const { fiscalYears, value, label, onChange } = props;
  const fiscalYearValue = fiscalYears.find(x => x.id === value)
  const selectedFiscalYearId = fiscalYearValue ? fiscalYearValue.id : ''
  return (
    <Select label={label} onChange={(data) => onChange(data)} value={selectedFiscalYearId}>
      {fiscalYears.map((e, idx) => (
        <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
      ))}
    </Select>
  );
};

export const TopalFiscalYearSelect = connect(
  (state) => ({
    fiscalYears: state.CompanyReducer.currentCompany.topalFiscalYears,
  }),
  {},
)(SelectFiscalYearComponent);
