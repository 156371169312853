
import employee from '../../firebasecomponents/employee';

const EMPLOYEE_UPDATED = "EMPLOYEE_UPDATED";
const EMPLOYEES_LOADED = "EMPLOYEES_LOADED";
const ERROR = "EMPLOYEE_ERROR";
const CLEAR_DONE = "EMPLOYEE_CLEAR_DONE";

/** reducer */
export default function reducer(state = {}, action) {
  if (action.type === EMPLOYEE_UPDATED) {
    return { ...state, done: true, error: undefined };
  }
  if (action.type === EMPLOYEES_LOADED) {
    return { ...state, employees: action.payload, error: undefined };
  }
  if (action.type === ERROR) {
    return { ...state, error: action.payload };
  }
  if (action.type === CLEAR_DONE) {
    return { ...state, done: false };
  }
  return state;
};

/** actions */

export function updateEmployee(employeeData) {
  return function (dispatch) {
    if (employeeData.employeeId) {
      employee
        .updateEmployeeDetails(employeeData.employeeId, employeeData)
        .then(response => dispatch({ type: EMPLOYEE_UPDATED, payload: response }))
        .catch(err => dispatch({ type: ERROR, payload: err }))
    } else {
      employee
        .addEmployeeDetails(employeeData)
        .then(response => dispatch({ type: EMPLOYEE_UPDATED, payload: response }))
        .catch(err => dispatch({ type: ERROR, payload: err }))
    }
  };
};

export function getEmployees() {
  return function (dispatch, getState) {
    const { CompanyReducer } = getState();
    employee.getEmployees({ companyId: CompanyReducer.currentCompany.companyId }, (response) => dispatch({ type: EMPLOYEES_LOADED, payload: response }))

  };
};

export function clearDone() {
  return function (dispatch) {
    dispatch({ type: CLEAR_DONE, payload: {} });
  };
};
