import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function Message(props) {
  const [showMessage, setShowMessage] = useState(props.message && props.message !== '');

  useEffect(() => {
    setShowMessage(props.message && props.message !== '');
  }, [props.message])

  let bg = '#fff';
  switch (props.type) {
    case 'error': bg = '#ffa7a7'; break;
    case 'success': bg = '#e0efae'; break;
    case 'warning': bg = '#f1e775'; break;
    default: bg = '#fff';
  }

  const closeMessage = () => {
    setShowMessage(false)
    if (props.handleClose) {
      console.log('closing message');
      props.handleClose();
    }
  }

  return showMessage ? (<Card style={{ backgroundColor: bg, maxWidth: props.maxwidth, margin:'10px 0' }} raised={false}>
    <IconButton onClick={closeMessage} style={{ float: 'right' }}>
      <CloseIcon />
    </IconButton>
    <CardContent>{props.message}</CardContent>
  </Card>) : null
}


function Form(props) {
  const { success, error, children, close, ...rest } = props;
  const handleClose = close ? close : undefined;
  let type = 'info';
  let message;
  if (success) {
    type = 'success';
    message = success;
  } else if (error) {
    type = 'error';
    message = error;
  }
  let maxWidth = '900px';
  if (props.maxwidth) {
    maxWidth = props.maxwidth;
  }

  return (
    <div>
      <Message message={message} type={type} maxwidth={maxWidth} handleClose={handleClose}/>
      <div {...rest}>{children}</div>
    </div>
  );
}


export default Form;
