import firebase from "../components/firebase";
import { customAlphabet, nanoid } from 'nanoid';
import Log from "../newHelpers/Log";

const db = firebase.db;
const Logger = new Log('firebasecomponents/company.js');

const nextId = (str) => {
  let i3 = str.charCodeAt(2);
  let i2 = str.charCodeAt(1);
  let i1 = str.charCodeAt(0);

  i3++;
  if (i3 === 91) {
    i3 = 65
    i2++
    if (i2 === 91) {
      i2 = 65
      i1++;
      if (i1 === 91) {
        i1 = 97
      }
    }
  }
  return String.fromCharCode(i1, i2, i3)
}

const uid = () => {
  const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 28)
  return nanoid();
}


const Company = {
  addCompanyDetails: (companyDetails, id) => {
    Logger.log('comp details', companyDetails, 'id', id);
    return new Promise((resolve, reject) => {
      db.collection('company')
        .where('emailId', '==', companyDetails.emailId)
        .get()
        .then(response => {
            Logger.log('response', response)
          if (response.docs.length > 0) {
            throw new Error("company with that email already exists")
          }
          else {
            // get last companyNo
            return db.collection('company')
              .orderBy('companyNo', 'desc')
              .limit(1)
              .get()
          }
        }
        )

        .then(response => {
          Logger.log('response', response);
          let lastId;
          try {
            lastId = response.docs[0].data().companyNo
          }catch (e) {
            Logger.log('lastId Error, setting to AAA', e);
            lastId = 'AAA';
          }
          const guid = uid();
          Logger.log('guid:', guid)
          db.collection('company')
            .doc(guid)
            .set({
              ...companyDetails,
              companyId:guid,
              companyNo: nextId(lastId),
              lastReceiptNo: 0,
              lastInvoiceNo: 0,
              mapping:{
                credit:[],
                custom:[],
                debitAccounts:[],
                expense:[],
                reimbursement:{},
              },
              costCenters:{},
            })
          return guid
        })
        .then((guid) => {
          Logger.log('guid', guid, 'id', id)
          resolve([guid,id] )})
        .catch(error => {
          Logger.log('error', error)
          reject(error)
        });
    });

  },
  updateCompanyDetails: (request) => {
    Logger.log('update company');
    return new Promise((resolve, reject) => {

      let docId = request.docId;
      let companyDetails = request.companyData;

      db.collection('company').doc(docId)
        .update(companyDetails)
        .then(ref => resolve(ref))
        .catch(error => reject(error))
    })
  },


  getCompanyDetails: async (request, callback) => {

    let accountantId = request.accountantId;

    try {
      db.collection("company")
        .where("accountant_id", "==", accountantId)
        .onSnapshot(function (doc) {
          callback(doc.docs.map(d => {
            return {
              companyId: d.id,
              companyName: d.data().companyName
            }
          }))
        })
    } catch (error) {
      callback(null);
    }
  },

  getCompanyDetailsFromApproverId: async (request, callback) => {
    // Logger.log('getcomp from approver id');
    let approverId = request.approverId;
    // Logger.log('request', request);
    // Logger.log('approverId', approverId);
    try {
      const approverMailId = await db.collection('approver').doc(approverId).get().then(doc => (doc.data().emailId));
      // Logger.log('mailId', approverMailId);

      db.collection("company")
        .where("approverEmailId", 'array-contains-any', [approverMailId])
        .onSnapshot(function (doc) {
          callback(doc.docs.map(d => {
            return {
              companyId: d.id,
              companyName: d.data().companyName
            }
          }))
        })
    } catch (error) {
      // Logger.log('err', error);
      callback(error);
    }
  },

  fetchCompanyDetails: (companyId) => {
    return new Promise((resolve, reject) => {
      db.collection("company")
        .doc(companyId)
        .get()
        .then(doc => resolve(doc.data()))
        .catch(err => reject(err))
    })
  },

  getAllCompanyDetails: async (callback) => {

    try {
      db.collection("company")
        .onSnapshot(doc =>
          callback(doc.docs.map(d =>
            ({
              companyId: d.id,
              companyName: d.data().companyName
            })
          ))
        )
    } catch (error) {
      callback(null);
    }
  },

  getReports(companyId, callback) {
    try {
      db.collection("reports")
        .where('companyId', '==', companyId)
        .onSnapshot(
          doc => callback(doc.docs.map(d => ({
            id: d.id,
            ...d.data()
          })
          ))
        )
    } catch (error) {
      callback(null);
    }
  },
  getStatements(companyId, callback) {
    try {
      db.collection("statements")
        .where('companyId', '==', companyId)
        .onSnapshot(
          doc => callback(doc.docs.map(d => ({
            id: d.id,
            ...d.data()
          })
          ))
        )
    } catch (error) {
      callback(null);
    }
  },

  addStatement(data) {
    return new Promise((resolve, reject) => {
      db.collection("statements")
        .add(data)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
}
export default Company;
