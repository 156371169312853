import React from "react";
import Autosuggest from 'react-autosuggest';
import './EmployeeAutoSuggest.css';
import employee from '../../firebasecomponents/employee';

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

function renderSuggestion(suggestion) {
  return (<span>{suggestion}</span>);
}

class AutoSuggestion extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: []
    };
  }

  onChange = (event, { newValue, method }) => {
    if (method === "down" || method === "up") {
      return;
    }

    this.setState({
      value: newValue
    });

    const e = {};
    const target = {};
    target.value = newValue;
    target.name = "firstName";
    e.target = target;

    if (method === "enter") {
      this.setState({
        suggestions: []
      });
      return;
    }
    const escapedValue = escapeRegexCharacters(newValue.trim());

    if (escapedValue === '') {
      return [];
    }
    const regex = new RegExp(escapedValue, 'i');

    let suggestions = [];
    let newSuggestions = suggestions.filter(suggestion => regex.test(suggestion));
    let dummyId = '';

    const trim = (a => a!== undefined ? a.trim() : '')

    employee.getAllEmployee(dummyId, response => {
      response.emplData.map((empl, index) => {
        let ddValue = trim(empl.companyName) + ', ' + trim(empl.firstName) + ' ' + trim(empl.lastName);
        suggestions = [...suggestions, ddValue];
        let newSuggestions1 = suggestions.filter(suggestion => regex.test(suggestion));

        this.setState({
          suggestions: newSuggestions.concat(newSuggestions1)
        });
        return newSuggestions1;
      });
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    // this.setState({
    //   suggestions: getSuggestions(value)
    // });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      id: 'autoSuggestEnteredValue',
      placeholder: "Enter Company or Employee Name",
      value,
      autoFocus: true,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps} />
    );
  }
}

export default AutoSuggestion;