import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { SaveButton, CancelButton } from '../../components/Buttons';
import { Message, validateEmail } from '../../components/Form'


import Grid from '@material-ui/core/Grid';

import accountant from '../../firebasecomponents/accountant';

import AccountantForm from '../../components/AccountantForm'

import Page from '../page';

class AccountantProfile extends Component {
  constructor(props) {
    super(props);

    this.state = this.intialComponentState;

    this.handleChange = this.handleChange.bind(this);

    this.saveAccountant = this.saveAccountant.bind(this);
    this.back = this.back.bind(this);
  }

  get intialComponentState() {
    return {
      accountantDetails: {
        streetAndNumber: '',
        postCode: '',
        city: '',
        firstName: '',
        lastName: '',
        bankName: '',
        bankPlace: '',
        telephoneNumber: '',
        emailId: '',
        employeePosition: '',
        allowedToGiveApprovalForExpense: false,
        filesPrjList: [],
        filesClientist: [],
        filesApprovalList: [],
        accountantId: ''
      },
      isReadOnly: true,
      loading: false,
      message: '',
      newAccountant: true
    }
  }

  componentDidMount() {
    let accountantId = this.props.match.params.accountantId;
    if (accountantId) {
      accountant.getAccountantProfileData(accountantId).then(response => {
        const details = { ...this.state.accountantDetails, ...response };
        this.setState({
          accountantDetails: details,
          newAccountant: false,
          isReadOnly: true
        });
      }).catch(err => {
      });
    } else {
      this.setState({
        isReadOnly: false
      });
    }
  }


  saveAccountant() {
    this.setState({ loading: true });

    const docId = this.props.match.params.accountantId
    const accountantData = this.state.accountantDetails;

    if (!validateEmail(accountantData.emailId)) {
      this.setState({ message: 'Email is invalid', loading: false });
      return;
    }

    const call = docId === undefined ? accountant.addAccountantDetails(accountantData) : accountant.updateAccountantDetails({ docId, accountantData });

    call
      .then(response => {
        this.setState({ isReadOnly: true, loading: false });
        this.props.history.goBack();
      })
      .catch(error => {
        this.setState({ message: error.message, loading: false });
      });

  }

  back() {
    this.props.history.goBack();
  };

  handleChange = (e) => {
    this.setState({ companyDetails: e });
  }

  render() {

    return (
      <Page className={this.state.isReadOnly ? 'read-only' : 'edit'}>
        <div className='left' style={{ textAlign: 'left', marginBottom: '20px' }}>
          <IconButton onClick={this.back} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
          <h2 style={{ color: '#c0548e' }}>
            {this.state.isReadOnly ?
              this.state.accountantDetails.firstName + ' ' + this.state.accountantDetails.lastName :
              this.state.newAccountant ? 'New Accountant Details' : 'Edit profile'
            }
            {this.state.isReadOnly ?
              <Button onClick={() => this.setState({ isReadOnly: false })} className="settingsButton"><EditIcon />Edit profile</Button>
              : null}
          </h2>
        </div>
        <Message message={this.state.message} type="error" />
        <AccountantForm
          newAccountant={this.state.newAccountant}
          isReadOnly={this.state.isReadOnly}
          onChange={this.handleChange}
          data={this.state.accountantDetails} />


        {!this.state.isReadOnly ?
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SaveButton fullWidth loading={this.state.loading} onClick={this.saveAccountant}>
                {this.state.newAccountant ? 'Save' : 'Update'}
              </SaveButton>

            </Grid>
            <Grid item xs={6}>
              <CancelButton fullWidth onClick={this.back}>Cancel</CancelButton>

            </Grid>
          </Grid> : null}
      </Page>
    );
  }

}
export default AccountantProfile

