import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    border: '1px solid #dadada',
    borderRadius: '0 6px 6px 6px',
  },
  invisible :{
    border: 'none',
    padding: '0'
  }
});


function TabPanel(props) {
  const { children, value, index, invisible, ...other } = props;

  const classes = useStyles(props);

  return (
    <Typography
      className={classes.root + '' + (invisible ? classes.invisible : '')}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={3} className={(invisible ? classes.invisible : '')}>{children}</Box>}
    </Typography>
  );
}

export default TabPanel;