import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import task from '../../firebasecomponents/task';
import employee from '../../firebasecomponents/employee';
import invoice from '../../firebasecomponents/invoice';
import Topal from '../../firebasecomponents/topal';

import { SaveButton, LinkButton } from '../Buttons';
import { Averix } from '../../externalAPI';

class ArrangePaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taskDetails: {
        ...this.props.task,
        transactiontext: '',
      },
      ibanNr: '',
      bankName: '',
      loading: false,
      online: false,
    };

    this.updatetask = this.updatetask.bind(this);
    this.toFibu = this.toFibu.bind(this);
  }

  componentDidMount() {
    let empl_emailId = this.state.taskDetails.empl_emailId;
    employee.fetchEmployeeDetailsByEmailId({ empl_emailId }, (response) => {
      this.setState({
        bankName: response.bankName,
        bankplace: response.bankplace,
        ibanNr: response.ibanNr,
      });
    });
    //check if topal server is online
    const fetch = async () => {
      if (await Topal.checkTopalOnline({isExpense:true})) {
        this.setState({ online: true });
      }
    };
    fetch();
  }

  handleChange = (e) => {
    const task = this.state.taskDetails;
    task[e.target.name] = e.target.value;
    this.setState({ taskDetails: task });
  };

  async toFibu() {
    //todo only update task after return true
    this.setState({ loading: true });
    const taskDetails = this.state.taskDetails;
    const { transactiontext, taskId } = taskDetails;
    Topal.toFibu({ taskId, fibu: 'topal', transactiontext })
      .then((res) => {
        console.log('res', res);
        task.updateTask({
          taskId,
          task_defination: 'payment-confirmation',
          transactiontext,
        });
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.onChange({
          task: taskDetails,
          success: 'You have executed payment!',
        });
      })
      .catch((err) => {
        console.log('catched err', err);
        this.setState({ loading: false });
        this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
      });

    // task.updateTask({
    //   taskId,
    //   task_defination: 'payment-confirmation',
    //   transactiontext
    // })
    //   .then(res => {
    //     // taskDetails.task_defination = 'payment-confirmation';
    //     return Averix.toFibu({taskId, fibu:'topal', transactiontext})
    //   })
    //   .then(res => {
    //     this.setState({ loading: false });
    //     this.props.onChange({ task: taskDetails, success: 'You have executed payment!' });
    //   })
    //   .catch(err => {
    //     this.setState({ loading: false });
    //     this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
    //   });
  }

  updatetask() {
    this.setState({ loading: true });

    const taskDetails = this.state.taskDetails;
    const { transactiontext, taskId, date, merchant, amount } = taskDetails;

    task
      .updateTask({
        taskId,
        task_defination: 'payment-confirmation',
        transactiontext,
      })
      .then((res) => {
        taskDetails.task_defination = 'payment-confirmation';
        return Averix.paymentStatement({
          expenseId: taskId,
          iban: this.state.ibanNr,
          reimbursedAmount: amount,
          merchantName: merchant,
          receiptDate: date,
          repaymentExecutionDate: date, // TODO check the date
        });
      })
      .then((res) => {
        invoice.addInvoice(taskDetails, true);
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.onChange({
          task: taskDetails,
          success: 'You have executed payment!',
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
      });
  }

  render() {
    const task = this.state.taskDetails;
    console.log('task', task);
    const total = task.amounts
      ? task.amounts.reduce((prev, curr) => prev + curr.amount, 0)
      : task.amount;
    return (
      <Grid container spacing={1}>
        {task.reimbursable !== undefined ? (
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Expense is proposed to be{' '}
              <strong>{task.reimbursable ? '' : 'non-'}reimbursible</strong>
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              disabled
              id="ArrangePaymentIBAN-Nr"
              label="IBAN-Nr"
              value={this.state.ibanNr}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              disabled
              id="ArrangePaymentBankName"
              label="BankName"
              value={this.state.bankName}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              disabled
              id="ArrangePaymentAmount"
              label="Amount"
              value={total}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              disabled
              id="ArrangePaymentCurrency"
              label="Currency"
              value={task.currency}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="transactiontext"
              placeholder="Transaction Text"
              helperText="Transaction Text"
              variant="outlined"
              value={this.state.transactiontext}
              onChange={(e) => this.handleChange(e)}
            />
          </FormControl>
          {this.state.log ? (
            <TextField
              id="transactiontext"
              placeholder="Transaction Text"
              helperText="Transaction Text"
              variant="outlined"
              value={this.state.transactiontext}
              onChange={(e) => this.handleChange(e)}
            />
          ) : null}
        </Grid>
        <Grid item xs={6}>
          {this.state.online ? (
            <SaveButton
              fullWidth
              loading={this.state.loading}
              onClick={this.toFibu}
            >
              To Fibu (Topal)
            </SaveButton>
          ) : (
            <SaveButton fullWidth disabled>
              To Fibu (Topal)
            </SaveButton>
          )}
          <SaveButton
            fullWidth
            loading={this.state.loading}
            onClick={this.updatetask}
          >
            To Invoice Management
          </SaveButton>
        </Grid>
        <Grid item xs={6}>
          <LinkButton fullWidth to="/" color="muted">
            Cancel
          </LinkButton>
        </Grid>
      </Grid>
    );
  }
}
export default ArrangePaymentForm;
