import React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Loading from '../../components/Loading';
import { LinkButton } from '../../components/Buttons';

import Page from '../page.js';

import firebase from '../../components/firebase';
import company from '../../firebasecomponents/company';
import Log from "../../newHelpers/Log";

const Logger = new Log('ApproverTaskList');

class ApproverTaskList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      companyDetails: null,
      approverId: null,
      approverName:null,
      mainPage: true
    };
  }


  async componentDidMount() {

    let approverId, approverName;
    if (this.props.match === undefined) {
      approverId = firebase.getCurrentUserId();
      approverName = firebase.getCurrentUsername();
      this.setState({ approverId, approverName})
    }

    await company.getCompanyDetailsFromApproverId({ approverId }, response => {
      Logger.log('GetCompanyDetailsFromApproverId', response);
      this.setState({
        companyDetails: response });
    });
  };

  back() {
    this.props.history.goBack();
  }

  render(){

    const companyNames = this.state.companyDetails ? this.state.companyDetails.map(companyDetails =>
      (<LinkButton
        color='primary'
        style={{ minWidth: 800 }}
        to={`/company/managerApproval/${companyDetails.companyId}/${companyDetails.companyName}`}
        key={companyDetails.companyId}
        value={companyDetails.companyName}
      >{companyDetails.companyName}</LinkButton>)
    ) : null;

    return(
      <Page>
        <div className='left' style={{ textAlign: 'left', marginBottom: '20px' }}>
          {this.state.mainPage ? null :
            <IconButton onClick={this.back} style={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>}
          <h2 style={{ color: '#c0548e' }}>{'Hi '}
            {this.state.approverName}
            {/*<LinkButton to={'/approver/profile/' + this.state.approverId} className="settingsButton">*/}
            {/*  <SettingsIcon />View profile*/}
            {/*</LinkButton>*/}
          </h2>
        </div>

        <Tabs>
          <TabList>
            <Tab>Companies</Tab>
          </TabList>
          <TabPanel>
            <Loading loading={!companyNames} />
            {companyNames}
          </TabPanel>
        </Tabs>

      </Page>
    );
  }

}
export default ApproverTaskList;
