import React, { Component } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import employee from '../../firebasecomponents/employee';
import company from '../../firebasecomponents/company';

import Page from '../../pages/page.js';
import { LinkButton, SaveButton, CancelButton } from '../../components/Buttons';

import { Message, validateEmail } from '../../components/Form'

class EditEmployee extends Component {

  constructor(props) {
    super(props)

    this.state = this.intialComponentState;
    this.handleChange = this.handleChange.bind(this);
    this.editEmployee = this.editEmployee.bind(this);
    this.back = this.back.bind(this);
    this.deleteEmployee = this.deleteEmployee.bind(this);
  }

  get intialComponentState() {
    return {
      isReadOnly: true,
      employeeDetails: {
        firstName: '',
        lastName: '',
        streetAndNumber: '',
        postcode: '',
        city: '',
        emailid: '',
        //personalIdentificationNumber: '',
        personalClexxEmailid: '',
        //position: '',
        bankName: '',
        bankplace: '',
        ibanNr: '',
        //postAccNr: '',
        hasCompanyCard: false,
        cardNumber: '',
        card: '',
        companyId: '',
        companyName: '',
      },
      showCardDiv: 'none',
      loading: false,
      newEmployee: true,
      userId: null,
      message: '',
      deleteMessage: '',
      confirmDelete: false,
      companyDetails: null
    }
  }

  componentDidMount() {
    const employeeId = this.props.match.params.emplId;
    if (employeeId) {
      employee.fetchEmployeeDetails({ employeeId }, response => {
        this.setState({
          newEmployee: false,
          employeeDetails: response.userData,
          userId: response.userId
        });
        company.fetchCompanyDetails(response.userData.companyId).then(res => {
          this.setState({ companyDetails: res })
        })
      });
      return;
    }

    const companyId = this.props.match.params.companyId;
    const companyName = this.props.match.params.companyName;
    if (companyId && companyName) {
      const employee = this.state.employeeDetails;
      employee.companyId = companyId;
      employee.companyName = companyName;
      employee.personalClexxEmailid = Math.random().toString(36).substring(2, 16) + '@clexx.io';
      this.setState({
        employeeDetails: employee,
        isReadOnly: false
      })
      company.fetchCompanyDetails(companyId).then(res => {
        this.setState({ companyDetails: res })
      })
    }
  }

  deleteEmployee() {
    this.setState({ confirmDelete: false });
    employee.deleteEmployee(this.state.userId).then(response => {
      this.setState({ deleteMessage: 'You have succesfully deleted employee' });
      setTimeout(() => {
        this.props.history.push('/');
      }, 1500);
    });
  }



  editEmployee() {
    const requiredFields = [
      'firstName', 'lastName',
      'streetAndNumber', 'postcode', 'city',
      'emailid', 'personalClexxEmailid',
      //'position',
      'bankName', 'bankplace', 'ibanNr',//'postAccNr',
    ];

    const employeeData = this.state.employeeDetails;
    const required = requiredFields.filter(k => employeeData[k] === '')
    if (required.length > 0) {
      this.setState({ message: 'All fields are required:' + required });
      return;
    } else {
      this.setState({ message: '' });
    }

    if (!validateEmail(employeeData.emailid)) {
      this.setState({ message: 'Email is invalid' });
      return;
    }

    this.setState({ loading: true });
    try {
      if (this.state.newEmployee) {
        employee.addEmployeeDetails(employeeData).then(response => {
          this.props.history.goBack();
        });
      } else {
        let emplId = this.state.userId;
        employee.updateEmployeeDetails(emplId, employeeData).then(response => {
          this.setState({ isReadOnly: true, loading: false });
        });
      }

    } catch (error) {
      this.setState({ loading: false, message: JSON.stringify(error) });
    }
  }

  back() {
    this.props.history.goBack();
  };

  getPolicyById(id, policies) {
    return policies.find(p => p.id === id);
  }

  handleChange = (e) => {
    const employeeDetails = this.state.employeeDetails;
    employeeDetails[e.target.name] = e.target.value;
    this.setState({ employeeDetails });
  };

  render() {
    const employee = this.state.employeeDetails;
    const company = this.state.companyDetails;
    return (
      <Page className={this.state.isReadOnly ? 'read-only' : 'edit'}>

        <div className='left' style={{ textAlign: 'left', marginBottom: '20px' }}>
          <IconButton onClick={this.back} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
          <h2 style={{ color: '#c0548e' }}>
            {this.state.newEmployee ? 'Add Employee' : 'Employee Details'}
            {this.state.isReadOnly ?
              <Button onClick={() => this.setState({ isReadOnly: false })} className="settingsButton"><EditIcon />Edit profile</Button>
              : null}
            {!this.state.isReadOnly && !this.state.newEmployee ?
              <Button onClick={() => this.setState({ confirmDelete: true })} className="settingsButton">
                <DeleteForeverIcon /> delete employee
              </Button>
              : null}
          </h2>
        </div>
        <Message message={this.state.message} type="error" />
        <Message message={this.state.deleteMessage} type="success" />
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Typography variant="h5">Name</Typography>
          </Grid>
          <Grid item xs={9} container spacing={4}>
            <Grid item xs={6}>
              <TextField fullWidth required
                name="firstName" label="First Name" InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth required
                name="lastName" label="Last Name" InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.lastName}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Typography variant="h5">Address</Typography>
          </Grid>
          <Grid item xs={9} container spacing={4}>
            <Grid item xs={4}>
              <TextField fullWidth required
                name="streetAndNumber" label="Street and Number" InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.streetAndNumber}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth required
                InputProps={{ readOnly: this.state.isReadOnly }}
                name="postcode" label="PostCode" onChange={e => this.handleChange(e)}
                value={employee.postcode}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth required
                name="city" label="City" InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.city}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Typography variant="h5">Ids</Typography>
          </Grid>
          <Grid item xs={9} container spacing={4}>
            <Grid item xs={6}>
              <TextField
                fullWidth disabled={!this.state.newEmployee}
                name="emailid"
                label="Email Address" InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.emailid}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <TextField
                fullWidth InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.personalIdentificationNumber}
                name="personalIdentificationNumber"
                label="Personal identification Nr."
              />
            </Grid> */}
            <Grid item xs={6}>
              <TextField
                fullWidth disabled
                name="personalClexxEmailid"
                label="Peronal Clexx Email Address"
                value={employee.personalClexxEmailid}
              />
            </Grid>
          </Grid></Grid>
        {/* <Grid container spacing={4}>
          <Grid item xs={3}>
            <Typography variant="h5">Position</Typography>
          </Grid>
          <Grid container item xs={9} spacing={4}>
            <Grid item xs={5}>
              <TextField
                fullWidth InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.position}
                name="position"
                label="Employee Position"
              />
            </Grid>
          </Grid></Grid> */}
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Typography variant="h5">Personal Bank account</Typography>
          </Grid>
          <Grid container item xs={9} spacing={4}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="bankName"
                label="Bank Name" InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.bankName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.bankplace}
                name="bankplace"
                label="Bank place"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="ibanNr"
                label="IBAN Nr." InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.ibanNr}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                fullWidth InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={e => this.handleChange(e)}
                value={employee.postAccNr}
                name="postAccNr"
                label="Postaccount Nr."
              />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Typography variant="h5">Company Card</Typography>
          </Grid>
          <Grid container item xs={9} spacing={4}>
            <Grid item xs={12}>
              {this.state.isReadOnly ? 
              <Typography variant="subtitle1">Employee has {employee.hasCompanyCard ? 'a':'no'} company card</Typography>:
              <FormControlLabel style={{ marginLeft: '10px!important' }}
                control={
                  <Checkbox checked={!!employee.hasCompanyCard}
                    name="hasCompanyCard"
                    onChange={e => this.setState({employeeDetails:{...employee, hasCompanyCard: !employee.hasCompanyCard}})}
                  />
                }
                label="Corporate Card holder"
              />}
            </Grid>
            {employee.hasCompanyCard ? [
              <Grid item xs={6}>
                <TextField fullWidth
                  onChange={e => this.handleChange(e)}
                  value={employee.cardNumber}
                  name="cardNumber"
                  label="Last 4 digits of card number"
                  InputProps={{ readOnly: this.state.isReadOnly }}
                />
              </Grid>,
              <Grid item xs={6}>
                <FormControl fullWidth >
                  <InputLabel htmlFor="card">Select Card network</InputLabel>
                  <Select value={employee.card || ''} onChange={this.handleChange} name="card" labelId="card" autoWidth  InputProps={{ readOnly: this.state.isReadOnly }}>
                    <MenuItem value="Visa" >Visa</MenuItem>
                    <MenuItem value="Master Card" >Master Card</MenuItem>
                    <MenuItem value="Maestro" >Maestro</MenuItem>
                    <MenuItem value="AmEx" >AmEx</MenuItem>
                    <MenuItem value="Discrover" >Discrover</MenuItem>
                    <MenuItem value="Other" >Other</MenuItem>
                  </Select>
                </FormControl>

                {/* <TextField fullWidth
                  onChange={e => this.handleChange(e)}
                  value={employee.card}
                  name="card"
                  label="Card Expiry Date"
                /> */}
              </Grid>] : null}
          </Grid>
        </Grid>
        {company && company.policies ?
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Typography variant="h5">Policy</Typography>
            </Grid>
            <Grid item xs={9}>
              {this.state.isReadOnly ?
                <Typography variant="h6">
                  {employee.policyId === undefined || employee.policyId === '' ?
                    'Add a policy to take advantage of clexx`s Policy Check' :
                    this.getPolicyById(employee.policyId, company.policies).name
                  }
                </Typography> :
                <FormControl fullWidth >
                  <InputLabel htmlFor="policyId">Select policy for this employee</InputLabel>
                  <Select value={employee.policyId || ''} onChange={this.handleChange} name="policyId" labelId="card" autoWidth>
                    {company.policies.map(c =>
                      (<MenuItem value={c.id} >{c.name}</MenuItem>)
                    )}
                  </Select>
                </FormControl>
              }
            </Grid>
          </Grid> :
          <Typography variant="h6" style={{ textAlign: 'center', marginTop:'25px' }}>
            Add a policy to take advantage of clexx`s Policy Check 
            <LinkButton to={'/company/profile/' + employee.companyId} color="secondary" style={{marginLeft:'10px'}}>
              Go to company profile
            </LinkButton>
          </Typography>}
        {!this.state.isReadOnly ?
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SaveButton fullWidth loading={this.state.loading} onClick={this.editEmployee}>
                {this.state.newEmployee ? 'Save' : 'Update'}
              </SaveButton>

            </Grid>
            <Grid item xs={6}>
              <CancelButton fullWidth onClick={this.back}>Cancel</CancelButton>

            </Grid>
          </Grid> : null}
        <Dialog open={this.state.confirmDelete} >
          <DialogContent>
            Are You sure to delete the Employee <strong>{employee.firstName} {employee.lastName}</strong>? It cannot be undone.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ confirmDelete: false })} color="primary">
              Cancel
                    </Button>
            <Button onClick={this.deleteEmployee} color="primary">
              Delete employee
                    </Button>
          </DialogActions>
        </Dialog>

      </Page>
    );
  }
}
export default EditEmployee;
