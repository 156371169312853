import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import { SimpleButton } from '../../newComponents/Button';
import TextField from '../../newComponents/TextField';

import CostObject, {useStyles} from './components/CostObject';

import { updateCompany } from '../../store/actions';

import './customization.scss'



function CostCenter(props) {

  const [centers, setCenters] = useState({})
  const [newCenter, setNewCenter] = useState(false)
  const [newCenterName, setNewCenterName] = useState('')

  useEffect(() => {
    if (props.currentCompany !== undefined) {
      setCenters(props.currentCompany.costCenters || {})
    }
  }, [props.currentCompany, setCenters])

  const classes = useStyles(props);
  const addCenter = () => {
    const id = Math.random().toString(36).substring(2, 8);
    const costCenters = { ...centers, [id]: { id, name: newCenterName, projects: [], uses: [], disabled: false } }
    setCenters(costCenters)
    setNewCenter(false);
    props.updateCompany(props.currentCompany.companyId, { costCenters })
  }
  const centerUpdated = (costCenter) => {
    const costCenters = { ...centers, [costCenter.id]: costCenter }
    setCenters(costCenters);
    props.updateCompany(props.currentCompany.companyId, { costCenters })
  }
  const remove = (center) => {
    const costCenters = { ...centers };
    if (center.uses.length === 0 && center.projects.map(p => !p.disabled).reduce((p, c) => p && c, true)) {
      delete costCenters[center.id];
    } else {
      costCenters[center.id].disabled = true;
    }
    setCenters(costCenters);
    props.updateCompany(props.currentCompany.companyId, { costCenters })
  }

  return (<div className="costObjectPanel">
    <SimpleButton className="actionButton" full icon={AddIcon} onClick={() => setNewCenter(true)}>Add Cost Center</SimpleButton>
    {Object.values(centers).filter(c => !c.disabled).map(c => <CostObject key={c.name} costObject={c} onChange={centerUpdated} onDelete={() => remove(c)} />)}
    {newCenter ? <div className={classes.row}>
      <TextField
        label="Name of the new Cost Center"
        onChange={(name) => setNewCenterName(name)}
        value={newCenterName}
      />
      <SimpleButton full onClick={addCenter}>Add</SimpleButton>
      <CloseIcon onClick={() => setNewCenter(false)} />
    </div> : null}
  </div >)

}


const mapStateToProps = state => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
  };
}

export default connect(mapStateToProps, { updateCompany })(CostCenter);