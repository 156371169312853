var dateReg = /^\d{4}-\d{2}-\d{2}$/;

export const formatDate = (date) => {
  if (date === undefined) return undefined;
  let dateObject = date
  if (typeof date === 'string'){
    dateObject = new Date(Date.parse(date));
  }
  return dateObject.getFullYear() + '-' + ("0" + (dateObject.getMonth() + 1)).slice(-2) + '-' + ("0" + dateObject.getDate()).slice(-2)
}

export const matchFormat = (str) => {
  return str.match(dateReg);
}

export const formatStrDate = (str) => {
  const d = new Date(Date.parse(str));
  if (d instanceof Date && !isNaN(d)) {
    return formatDate(d);
  } else {
    return null;
  }
}

export const currentDateStamp = () => {
  return formatDate(new Date());
}
