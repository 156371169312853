import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

import './Buttons.css'

export function SaveButton(props) {
  const { loading, children, ...rest } = props;
  return (<Button {...rest} size='small' className='activeButton' >
    {children} {loading ? (<CircularProgress size={12} />) : null}
  </Button>);
}

export function CancelButton(props) {
  const { children, ...rest } = props;
  return (<Button {...rest} size='small' style={{ backgroundColor: '#eaeaea' }} > {children} </Button>);
}

export function ActionButton(props) {
  const { value, color, children, className, ...rest } = props;
  let type = '';
  if (color)
    switch (color) {
      case 'primary':
        type = 'activeButton';//'#b4ce5e';
        break;
      case 'secondary':
        type = 'secondaryButton';//backgroundColor = '#494949';
        break
      case 'muted':
        type = 'mutedButton';//backgroundColor = '#eaeaea';
        break;
      default:
        type = '';
    }

  return (<Button onClick={props.onClick}
    size='small' className={className + ' ' + type}
    style={{ ...props.style }}
    value={value}
    {...rest}
  >{children}</Button>);
}

export function LinkButton(props) {
  const { value, color, children, className, ...rest } = props;
  let type = '';
  if (color)
    switch (color) {
      case 'primary':
        type = 'activeButton';//'#b4ce5e';
        break;
      case 'secondary':
        type = 'secondaryButton';//backgroundColor = '#494949';
        break
      case 'muted':
        type = 'mutedButton';//backgroundColor = '#eaeaea';
        break;
      default:
        type = '';
    }

  return (<Button component={Link} to={props.to ? props.to : '/'}
    size='small' className={className + ' ' + type}
    style={{ ...props.style }}
    value={value}
    {...rest}
  >{children}</Button>);
}
