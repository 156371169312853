import firebase from '../components/firebase';
import { clexxAPI } from '../../src/externalAPI/ClexxAPI';
import axios from 'axios';
const db = firebase.db;

const Approver = {
  approverMailExists: async (mail) => {
    //going directly in db lead to error, get through firebase function. used when registering
    console.log('mail', mail);
    const data = {
      mail: mail,
    };
    let result;
    await axios.post(clexxAPI + 'approverMailExists', data).then((res) => {
      result = res.data;
    });
    console.log( 'result = ', result);
    return result;
  },

  addApproverDetails: (approverDetails, docId) => {
    console.log('addapproverdetails', approverDetails);
    return new Promise((resolve, reject) => {
        db.collection('approver')
                .doc(docId)
                .set(approverDetails)
                .then((response) => resolve(response))
                .catch((error) => reject(error));

    });
  },

  updateApproverDetails: (request) => {
    return new Promise((resolve, reject) => {
      let docId = request.docId;
      let approverDetails = request.approverData;

      db.collection('approver')
        .doc(docId)
        .update(approverDetails)
        .then((ref) => resolve(ref))
        .catch((error) => reject(error));
    });
  },
};
export default Approver;
