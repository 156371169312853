import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { connect } from "react-redux";

import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';

import company from '../../firebasecomponents/company';
import task from '../../firebasecomponents/task';
import accountant from '../../firebasecomponents/accountant';

import { LinkButton } from '../../components/Buttons';
import Loading from '../../components/Loading';
import SortTask from '../../components/SortTask';
import SearchBar from '../../components/SearchBar';

import Page from '../page.js';

import MsTeams from '../../externalAPI/MsTeams';

import './dashboard.css';

class ClexxEmployeeDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = this.intialComponentState;


    this.taskNo = this.taskNo.bind(this);
    this.confirmConnection = this.confirmConnection.bind(this);
  }

  taskNo() {
    task.prepareAllTaskNo();
  }

  get intialComponentState() {
    return {
      companyDetails: null,
      allTasks: null,
      allAccountants: null,
      allTaskskByTaskDefination: {
        'clarification': [],
        'extract-data': [],
        'compliance-check': [],
        'manager-approval': [],
        'reimbursability-check': [],
        'arrange-payment': [],
        'payment-confirmation': [],
        'manager-declined': [],
        'non-reimbursible task': [],
      },
      companyWiseTasks: {},
      companyFilter: '',
      accountantFilter: '',
      taskFilter: '',
      showConnectionButton: false
    }
  }

  componentDidMount() {
    accountant.getMsTeamsConnection(this.props.currentUser.email).then(e => {
      if (e && e.activated === false) {
        this.setState({ msConnection: e });
      }
    });

    company.getAllCompanyDetails(response => {
      this.setState({ companyDetails: response });
    });
    accountant.getAllAccountants(response => {
      this.setState({ allAccountants: response });
    });
    task.getTotalTask({/*ongoing:true*/ }, response => {
      this.setState({ allTasks: response });

      const allTaskskByTaskDefination = this.intialComponentState.allTaskskByTaskDefination;
      const companyWiseTasks = this.intialComponentState.companyWiseTasks;

      response.forEach(t => {
        const detailDef = t.company_name + ' - ' + t.empl_name + ' [' + t.id + ']';
        if (allTaskskByTaskDefination[t.task_defination]) {
          allTaskskByTaskDefination[t.task_defination].push({ ...t, detail: detailDef });
        }
        else {
          allTaskskByTaskDefination[t.task_defination] = [{ ...t, detail: detailDef }];
        }
        const detailCom = t.task_defination + ' - ' + t.empl_name + ' [' + t.id + ']';
        if (Object.keys(companyWiseTasks).includes(t.company_name)) {
          companyWiseTasks[t.company_name].push({ ...t, detail: detailCom });
        } else {
          companyWiseTasks[t.company_name] = [{ ...t, detail: detailCom }];
        }
      })
      this.setState({ allTaskskByTaskDefination });
      this.setState({ companyWiseTasks });

    });

  };

  filter(phrase, collection, field) {
    return collection.filter(e => e[field].toUpperCase().includes(phrase.toUpperCase()))
  }

  confirmConnection(confirm) {
    MsTeams.clessEmployeeConnectionNotification(this.props.currentUser.email, confirm).then().catch();
    this.setState({ popupClosed: true })
  }

  render() {

    const allTasks = this.state.allTasks ?
      this
        .filter(this.state.taskFilter, this.state.allTasks, 'filterTxt')
        .map(task =>
          (<LinkButton
            color='primary'
            style={{ minWidth: 800 }}
            to={'task/' + task.id}
            key={task.id}
            value={task.task_defination}
          >{task.updated + ' : ' + task.task_defination + ' - ' + task.company_name + ' - ' + task.empl_name}</LinkButton>
          )
        ) : null;

    const companyNames = this.state.companyDetails ?
      this
        .filter(this.state.companyFilter, this.state.companyDetails, 'companyName')
        .map(companyDetails =>
          (<LinkButton
            color='primary'
            style={{ minWidth: 800 }}
            to={`/companytasklist/${companyDetails.companyId}/${companyDetails.companyName}`}
            key={companyDetails.companyId}
            value={companyDetails.companyName}
          >{companyDetails.companyName}</LinkButton>
          )
        ) : null;

    const allAccountantsDef = this.state.allAccountants ?
      this
        .filter(this.state.accountantFilter, this.state.allAccountants, 'name')
        .map((acc) =>
          (<LinkButton
            color='primary'
            style={{ minWidth: 800 }}
            to={`/accountanttasklist/${acc.id}`}
            key={(acc.id)}
            value={acc.name}
          >{acc.name}</LinkButton>)
        ) : null;

    return (
      <>
        {this.state.msConnection && this.state.msConnection.activated === false && !this.state.popupClosed &&
          <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 10 }}>
            <p style={{ width: '50%', margin: '200px auto 0', padding: 100, backgroundColor: '#fff', borderRadius: 5 }}>
              <span style={{ display: 'block', marginBottom: 20, fontSize:'1.5em', fontWeight:'bold' }}>Connection request</span>
              There is pending request for connection with teams Account for <strong>{this.state.msConnection.user.name}</strong>.<br/><br/>
            Click button to connect account. You will be notified about success in Teams Clexx channel.<br /><br/>
              <Button variant="contained" color="primary" onClick={() => this.confirmConnection(true)}>Confirm connection to Teams account</Button>
              <Button variant="contained" style={{ marginLeft: 10 }} onClick={() => this.confirmConnection(false)}>Discard connection request</Button>
            </p>
          </div>
        }
        <Page>
          <LinkButton color='secondary' to="/addtask" className="addButton"><AddIcon />Add task</LinkButton>
          <LinkButton color ='muted' to ='/syncExpenses' className='syncButton'><SyncIcon />Sync</LinkButton>
          {/* <Button onClick={this.taskNo}>add</Button> */}
          <Tabs>
            <TabList>
              <Tab key="companies">All Companies</Tab>
              <Tab key="accountants">All Accountants</Tab>
              <Tab key="tasks">All Tasks</Tab>
              <Tab key="sortCompany">Sort by Company</Tab>
              <Tab key="sortSteps">Sort by Steps</Tab>
            </TabList>
            <TabPanel key="companyNames">
              <Loading loading={!companyNames} />
              <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                <Grid item>
                  <SearchBar onChange={(e) => this.setState({ companyFilter: e })} />
                </Grid>
                <Grid item>
                  {/*button to add company, if wanted (add route again) */}
                  {/* <LinkButton color='secondary' to="/company/add" className="addButton"><AddIcon />Add company</LinkButton> */}
                </Grid>
              </Grid>
              {companyNames}
            </TabPanel>
            <TabPanel key="allAccountantsDef">
              <Loading loading={!allAccountantsDef} />
              <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                <Grid item>
                  <SearchBar onChange={(e) => this.setState({ accountantFilter: e })} />
                </Grid>
                <Grid item>
                  <LinkButton color='secondary' to="/accountant/add" className="addButton"><AddIcon />Add accountant</LinkButton>
                </Grid>
              </Grid>
              {allAccountantsDef}
            </TabPanel>
            <TabPanel key="allTasks">
              <Loading loading={!allTasks} />
              <SearchBar onChange={(e) => this.setState({ taskFilter: e })} />
              {allTasks}
            </TabPanel>
            <TabPanel key="companyWiseTasks">
              <SortTask tasksMap={this.state.companyWiseTasks} />
            </TabPanel>
            <TabPanel key="allTaskskByTaskDefination">
              <SortTask tasksMap={this.state.allTaskskByTaskDefination} />
            </TabPanel>
          </Tabs>
        </Page>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    currentUser: state.UserReducer.currentUser
  };
}

export default connect(mapStateToProps, {})(ClexxEmployeeDashboard);

