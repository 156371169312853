import SpaceGroteskBold from '../assets/fonts/SpaceGrotesk-Bold.woff2';
import SpaceGroteskRegular from '../assets/fonts/SpaceGrotesk-Regular.woff2';

export const spaceGroteskBold = {
  fontFamily: 'Space Grotesk',
  fontWeight: 700,
  src: `
    url(${SpaceGroteskBold}) format('woff2')
  `,
};

export const spaceGroteskRegular = {
  fontFamily: 'Space Grotesk',
  src: `
    url(${SpaceGroteskRegular}) format('woff2')
  `,
};