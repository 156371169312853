import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import { getStatements, getEmployees, uploadStatements, getCreditCards } from '../../store/actions';

import { CircularProgress, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Modal from '../../newComponents/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Drawer from '../../newComponents/Drawer';
import Table from '../../newComponents/Table';
import CreditCard from '../../newComponents/CreditCard';
//import Select from '../../newComponents/Select';

import Search from '../../newComponents/Search';
import ProcessInputFile from './components/ProcessInputFile';

import Page from '../page';
import dbStatement from '../../firebasecomponents/statements';

import './statements.scss';
import { FailButton, SimpleButton, SuccessButton } from '../../newComponents/Button';
import TextField from '../../newComponents/TextField';
import { TrashIcon } from '../../newComponents/Icons';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { getCollapsed, getShowExpenseItems } from '../../newHelpers/pageHelper';

function formatDate(date, format) {
  if (typeof date === 'string') {
    return moment(date, 'DD-MM-YYYY').format('DD MMM YYYY');
  } else {
    return date.format('DD MMM YYYY');
  }
}

class Statements extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;

    this.handleChange = this.handleChange.bind(this);
    // this.fileUploaded = this.fileUploaded.bind(this);

    this.cardFilter = this.cardFilter.bind(this);
    this.userFilter = this.userFilter.bind(this);
    this.searchFilter = this.searchFilter.bind(this);

    this.delete = this.delete.bind(this);
    this.removeStatement = this.removeStatement.bind(this);
    this.reconciliation = this.reconciliation.bind(this);
    this.setMerge = this.setMerge.bind(this);
    this.merge = this.merge.bind(this);

    this.toggle = this.toggle.bind(this);

    if (props.currentCompany) {
      props.getStatements(props.currentCompany.companyId);
      props.getEmployees();
      props.getCreditCards({ company: this.props.currentCompany.companyId });
    }
  }

  get initialState() {
    return {
      openedStatement: undefined,
      cardFilter: '-',
      userFilter: '-',
      errors: [],
      statements: [],
      opened: [],
      csvTypesToMap: undefined,
      click1: false,
      click2: false,
      showDelete: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.statements !== this.props.statements) {
      this.setState({
        statements: this.props.statements,
        opened: new Array(this.props.statements).map(() => false),
      });
    }
    if (prevProps.creditCards !== this.props.creditCards) {
      const cards = this.props.creditCards;
      this.setState({ cards });
    }
    if (prevProps.employees !== this.props.employees) {
      const employees = this.props.employees.map((e) => ({
        name: e.firstName + ' ' + e.lastName,
        emailid: e.emailid,
        phone: e.phone,
        id: e.employeeId,
      }));
      this.setState({ employees });
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.props.getStatements(this.props.currentCompany.companyId);
      this.props.getEmployees();
      this.props.getCreditCards({
        company: this.props.currentCompany.companyId,
      });
    }
  }

  removeStatement() {
    //delete statement
    const statement = this.state.openedStatement;
    dbStatement
      .deleteStatement(statement)
      .then(() => {
        this.setState({ openedStatement: undefined });
      })
      .catch((err) => this.setState({ error: err }));
  }

  delete() {
    return () => {
      const e = this.state.statementToDeleteFrom;
      const a = this.state.transactionToDelete;
      console.log('statement', e);
      console.log('entry a', a);
      let removeIdx = e.amounts.map((item) => item.id).indexOf(a.id);
      console.log('removIdx', removeIdx);
      ~removeIdx && e.amounts.splice(removeIdx, 1);
      const statement = e;
      dbStatement.updateStatement({ statement }, (res) => {
        console.log('res', res);
      });
      this.setState({
        showDelete: false,
        statementToDeleteFrom: undefined,
        transactionToDelete: undefined,
      });
    };
  }

  showDelete(e, a) {
    return () => {
      console.log('showDelete', e, a);
    };
  }

  reconciliation() {
    //go to reconciliation site
    const statement = this.state.openedStatement;
    console.log('statement', statement);
    this.props.history.replace(
      '/company/statements/' +
        statement.id +
        '/reconciliation/' +
        this.props.currentCompany.companyId +
        '/' +
        this.props.currentCompany.companyName
    );
  }

  handleChange(event) {
    return (newValue) => {
      this.setState({ value: newValue });
    };
  }

  cardFilter(item) {
    const filter = this.state.cardFilter;
    if (filter === undefined || filter === '-') return true;
    return item.card.number === filter;
  }

  userFilter(item) {
    const filter = this.state.userFilter;
    if (filter === undefined || filter === '-') return true;
    return item.holder.name === filter;
  }

  searchFilter(item) {
    const filter = this.state.searchFilter;
    if (filter === undefined || filter === '') return true;
    return JSON.stringify(item).includes(filter);
  }

  opening(statement) {
    return () => this.setState({ openedStatement: statement });
  }
  toggle(idx, open) {
    const opened = this.state.opened;
    opened[idx] = open;
    this.setState({ opened });
  }

  setMerge(id, statement) {
    return () => {
      console.log('id : ', id);
      console.log('statement: ', statement);
      if (this.state.openedStatementForMerge !== statement) {
        console.log('different statemtns');
        //could be that two statements are open, and one tries to merge from different statements
        this.setState({
          openedStatementForMerge: statement,
          click1: id,
          click2: false,
        });
      } else if (!this.state.click1) {
        console.log('not click1');
        this.setState({ click1: id });
      } else if (this.state.click1 && !this.state.click2) {
        this.setState({ click2: id });
      } else {
        //click1 and click2
        this.setState({ click1: id, click2: false });
      }
    };
  }

  merge() {
    return () => {
      console.log(
        'merge',
        this.state.click1,
        this.state.click2,
        this.state.openedStatementForMerge
      );

      const statement = this.state.openedStatementForMerge;
      let transaction1 = this.state.click1;
      const transaction2 = this.state.click2;
      const amounts2 = transaction2.amounts ? transaction2.amounts : [];
      const amounts1 = transaction1.amounts ? transaction1.amounts : [];

      transaction1 = {
        ...transaction1,
        amount: parseFloat(transaction1.amount) + parseFloat(transaction2.amount),
        // amounts: [
        //   {
        //     amount: transaction2.amount,
        //     vat_amount: 0,
        //     item_name: transaction2.description,
        //     vat_percentage: {
        //       name: '0.0%',
        //       value: 0,
        //     },
        //   },
        // ],
      };
      //todo refactor
      let amounts =
        transaction1.amounts && transaction1.amounts.length > 0 ? transaction1.amounts : [];
      amounts.push({
        amount: parseFloat(transaction2.amount),
        vat_amount: 0,
        item_name: transaction2.description,
        policyFields: {},
        amountNoVat: parseFloat(transaction2.amount),
        vat_percentage: {
          name: '0.0%',
          value: 0,
        },
      });

      // if(transaction1.amounts && transaction1.amounts.length>0){
      //   //for merge multiple
      //   transaction1.amounts.push(
      //       {
      //         amount: parseFloat(transaction2.amount),
      //         vat_amount: 0,
      //         item_name: transaction2.description,
      //         policyFields:{},
      //         amountNoVat:parseFloat(transaction2.amount),
      //         vat_percentage: {
      //           name: '0.0%',
      //           value: 0,
      //         },
      //       },
      //   )
      // }else{
      //   transaction1.amounts=[];
      //   transaction1.amounts.push(
      //     {
      //       amount: parseFloat(transaction2.amount),
      //       vat_amount: 0,
      //       item_name: transaction2.description,
      //       policyFields:{},
      //       amountNoVat:parseFloat(transaction2.amount),
      //       vat_percentage: {
      //         name: '0.0%',
      //         value: 0,
      //       },
      //     },);
      // }
      transaction1.amounts = amounts;

      console.log('trans1', transaction1);
      console.log('trans2', transaction2);
      let updateIdx = statement.amounts.map((item) => item.id).indexOf(transaction1.id);
      statement.amounts[updateIdx] = transaction1;

      let removeIdx = statement.amounts.map((item) => item.id).indexOf(transaction2.id);
      console.log('removIdx', removeIdx);
      ~removeIdx && statement.amounts.splice(removeIdx, 1);
      // const statement = e;
      dbStatement.updateStatement({ statement }, (res) => {
        console.log('res', res);
      });
      this.setState({
        click1: false,
        click2: false,
        openedStatementForMerge: undefined,
      });
    };
  }

  render() {
    const users = this.state.employees || [];
    const cards = this.state.cards || [];

    const tableData = this.state.statements
      ? this.state.statements
          .filter(this.cardFilter)
          .filter(this.userFilter)
          .filter(this.searchFilter)
      : [];

    const openedStatement = this.state.openedStatement;
    const currentUser = openedStatement
      ? users.find((u) => u.id === openedStatement.holder.id)
      : null;

    return (
      <Page
        role="Company"
        currentPage="statements"
        header="Credit Card Statements"
        actions={''}
        collapsedNavBar={getCollapsed(this.props)}
        collapsedExpItems={getShowExpenseItems(this.props)}
      >
        {this.props.creditCards && this.props.employees && <ProcessInputFile />}
        <div className="tableBar">
          <div className="selectFilter">
            <Typography>Cards</Typography>
            <Select
              variant="outlined"
              value={this.state.cardFilter}
              onChange={(e) => this.setState({ cardFilter: e.target.value })}
            >
              <MenuItem key="all" value="-">
                All
              </MenuItem>
              {cards.map((k, idx) => {
                return (
                  <MenuItem key={idx} value={k.number}>
                    {k.system} {k.number}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="selectFilter">
            <Typography>Employees</Typography>
            <Select
              variant="outlined"
              value={this.state.userFilter}
              onChange={(e) => this.setState({ userFilter: e.target.value })}
            >
              <MenuItem key="all" value="-">
                All
              </MenuItem>
              {users.map((k) => {
                return (
                  <MenuItem key={k.id} value={k.name}>
                    {k.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <Search className="search" onChange={(e) => this.setState({ searchFilter: e })} />
        </div>
        {tableData ? (
          <Table
            data={{
              head: {
                period: <strong>Period</strong>,
                cardNumber: <span>Card Number</span>,
                employees: <span>Employees</span>,
                currency: <span>Currency</span>,
                action: '',
              },
              content: tableData.map((e, idx) => {
                const subtable = (
                  <Table
                    className="innerTable"
                    data={{
                      head: {
                        date: <strong>Date</strong>,
                        description: <strong>Description</strong>,
                        amount: <strong>Amount</strong>,
                        merge:
                          this.props.currentRole === 'Employee' ? <strong>Merge</strong> : null,
                        action:
                          this.props.currentRole === 'Employee' ? <strong>Delete</strong> : null,
                      },
                      content: e.amounts.map((a) => {
                        return {
                          row: {
                            date: <span>{formatDate(a.date, 'DD MMM YYYY')}</span>,
                            description: <span>{a.description}</span>,
                            amount: (
                              <span>
                                {a.amount}{' '}
                                {a.currency && a.currency !== e.currency ? a.currency : ''}
                              </span>
                            ),
                            merge:
                              this.props.currentRole === 'Employee' ? (
                                <Radio
                                  onClick={this.setMerge(a, e)}
                                  checked={
                                    (this.state.click1 && this.state.click1.id === a.id) ||
                                    (this.state.click2 && this.state.click2.id === a.id)
                                  }
                                />
                              ) : null,
                            action:
                              this.props.currentRole === 'Employee' ? (
                                <TrashIcon
                                  style={{ cursor: 'pointer' }}
                                  // onClick={this.delete(e, a)}
                                  onClick={() =>
                                    this.setState({
                                      showDelete: true,
                                      statementToDeleteFrom: e,
                                      transactionToDelete: a,
                                    })
                                  }
                                />
                              ) : null,
                          },
                          sortData: {
                            date: new Date(formatDate(a.date, 'DD MMM YYYY')),
                            description: a.description,
                            amount: parseFloat(a.amount),
                          },
                        };
                      }),
                    }}
                    sortable={true}
                  />
                );

                return {
                  row: {
                    period: (
                      <span>
                        {this.state.opened[idx] ? (
                          <RemoveCircleOutlineIcon
                            className="pink"
                            onClick={() => {
                              this.toggle(idx, false);
                              this.setState({
                                click1: false,
                                click2: false,
                                openedStatementForMerge: undefined,
                              });
                            }}
                          />
                        ) : (
                          <AddCircleOutlineIcon
                            className="pink"
                            onClick={() => {
                              this.toggle(idx, true);
                              this.setState({
                                click1: false,
                                click2: false,
                                openedStatementForMerge: undefined,
                              });
                            }}
                          />
                        )}
                        {formatDate(e.minDate, 'DD MMM')} - {formatDate(e.maxDate, 'DD MMM YYYY')}
                      </span>
                    ),
                    cardNumber: (
                      <strong>
                        <CreditCard card={e.card} />
                      </strong>
                    ),
                    employees: <strong>{e.holder.name}</strong>,
                    currency: <strong>{e.currency}</strong>,
                    action: <ChevronRightIcon onClick={this.opening(e)} />,
                    ...(this.state.opened[idx] ? { subRow: subtable } : {}),
                  },
                  sortData: e,
                };
              }),
            }}
            sortable={true}
          />
        ) : (
          <CircularProgress />
        )}
        <Modal
          open={this.state.showDelete}
          onClose={() =>
            this.setState({
              showDelete: false,
              statementToDeleteFrom: undefined,
              transactionToDelete: undefined,
            })
          }
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          body={
            <div
              style={{
                width: '400px',
                height: '300px',
                backgroundColor: 'white',
                position: 'absolute',
                left: '50%',
                top: '35%',
              }}
            >
              <div
                style={{
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                <h2>Are you sure you want to delete this entry?</h2>
                <br />
                {this.state.transactionToDelete ? (
                  <div>
                    <h4>{this.state.transactionToDelete.description}</h4>
                    <h4>{this.state.transactionToDelete.amount}</h4>
                    <h4>{this.state.transactionToDelete.date}</h4>
                  </div>
                ) : null}
                <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    position: 'absolute',
                    bottom: '5px',
                    right: '5px',
                  }}
                >
                  <FailButton
                    full
                    // style={{ margin }}
                    onClick={() =>
                      this.setState({
                        showDelete: false,
                        statementToDeleteFrom: undefined,
                        transactionToDelete: undefined,
                      })
                    }
                  >
                    Close
                  </FailButton>
                  <SuccessButton
                    style={{ marginLeft: '5px', marginRight: '5px' }}
                    full
                    onClick={this.delete()}
                  >
                    Delete
                  </SuccessButton>
                </div>
              </div>
            </div>
          }
        />
        <Drawer direction="left" opened={openedStatement !== undefined}>
          {openedStatement ? (
            <div className="drawerContainer">
              <div className="drawerHeader">
                <Typography>Credit Card Report Details</Typography>
                <CloseIcon onClick={() => this.setState({ openedStatement: undefined })} />
              </div>
              <div className="drawerContent" style={{ width: 575 }}>
                <dl className="statementDetails">
                  <dt>Card Number</dt>
                  <dd>
                    <CreditCard card={openedStatement.card} />
                  </dd>
                  <dt>Card Holder</dt>
                  <dd>{openedStatement.holder.name}</dd>
                  <dt>Employee Email</dt>
                  <dd>{currentUser.emailid}</dd>
                  {currentUser.phone && (
                    <>
                      <dt>Employee Phone</dt>
                      <dd>{currentUser.phone}</dd>
                    </>
                  )}
                  <dt>Period</dt>
                  <dd>
                    {formatDate(openedStatement.minDate, 'DD MMM')} -{' '}
                    {formatDate(openedStatement.maxDate, 'DD MMM YYYY')}
                  </dd>
                  <dt>Total Amount</dt>
                  <dd>
                    {openedStatement.amounts
                      .reduce((prev, curr) => prev + parseFloat(curr.amount), 0)
                      .toFixed(2)}{' '}
                    {openedStatement.currency}
                  </dd>
                  <dt>No. Transactions</dt>
                  <dd>{openedStatement.amounts.length}</dd>
                </dl>
              </div>
              <div className="drawerFooter">
                {this.props.currentRole === 'Employee' ? (
                  <>
                    <FailButton full onClick={this.removeStatement}>
                      Delete Statement
                    </FailButton>
                    <SuccessButton
                      full
                      style={{ marginLeft: '10px' }}
                      onClick={this.reconciliation}
                    >
                      Reconciliation
                    </SuccessButton>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </Drawer>
        <Drawer
          direction="left"
          opened={!!this.state.click1 && !!this.state.click2}
          style={{ position: 'fixed', top: '81px' }}
        >
          <div className="drawerContainer">
            <div className="drawerHeader">
              <Typography>Merge Transactions</Typography>
              <CloseIcon
                onClick={() =>
                  this.setState({
                    click1: false,
                    click2: false,
                    openedStatementForMerge: undefined,
                  })
                }
              />
            </div>
            <div className="drawerContent" style={{ width: 300 }}>
              <Box
                display="flex"
                // justifyContent="center"
                // className="statementDetails"
                className="expenseDetails"
              >
                <div style={{ background: '#FFFFFF', height: '20em' }}>
                  {this.state.openedStatementForMerge && this.state.click1 && this.state.click2 ? (
                    <>
                      <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          Transaction 1
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <dl>
                            <dt>Description</dt>
                            <dd>{this.state.click1.description}</dd>
                            <dt>Amount</dt>
                            <dd>{this.state.click1.amount}</dd>
                          </dl>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          Transaction 2
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <dl>
                            <dt>Description</dt>
                            <dd>{this.state.click2.description}</dd>
                            <dt>Amount</dt>
                            <dd>{this.state.click2.amount}</dd>
                          </dl>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </>
                  ) : null}
                </div>
              </Box>
            </div>
            <div
              className="drawerFooter"
              style={{
                position: 'fixed',
                bottom: '10px',
                width: '300px',
              }}
            >
              {this.state.openedStatementForMerge ? (
                <>
                  <SimpleButton full onClick={this.merge()}>
                    Merge Transaction 2 into Transaction 1
                  </SimpleButton>
                </>
              ) : null}
            </div>
          </div>
        </Drawer>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.EmployeeReducer.employees,
    statements: state.CompanyReducer.statements,
    currentCompany: state.CompanyReducer.currentCompany,
    creditCards: state.CreditCardReducer.creditCards,
    currentRole: state.UserReducer.currentRole,
  };
};

export default connect(mapStateToProps, {
  getStatements,
  uploadStatements,
  getEmployees,
  getCreditCards,
})(Statements);
