import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Parashift } from '../../externalAPI';
import dbTask from '../../firebasecomponents/task';

const fields = [
  "created_at",
  "updated_at",
  "invoice_date",
  "delivery_date",
  "currency",
  "discount_rate",
  "number",
  "order_number",
  "including_tax",
  "payment_method",
  "amount_total",
  // "reference",
  // "esr_reference",
  // "esr_reference_data",
  "nominal_account",
  // "bank_accounts",
  // "items",
]

function ParashiftDetails(props) {
  const task = props.data;
  const parashift = props.data.parashift;
  const details = parashift && parashift.included ? parashift.included[0].attributes : {};
  const errors = parashift && parashift.data ? parashift.data.attributes.error_codes : []; //.map(m => <div style={{color:'red'}} key={m}>Parashift error: {m}</div>) : [];

  const [loadingParashift, setLoadingParashift] = useState(false);

  const sendDoc = (base64file, taskId, extraction) => {
    setLoadingParashift(true);
    Parashift.sendDoc(base64file, taskId, extraction)
      .then(result => {
        const data = result.data.data
        return dbTask.updateTask({
          taskId: task.taskId,
          parashift: {
            id: data.id,
            current_status: data.attributes.current_status,
            extraction_process: data.attributes.extraction_process,
            created_at: data.attributes.created_at
          }
        })
      })
      .then(result => {
        setLoadingParashift(false);
      })
      .catch(err => console.log(err))
  }

  const getDoc = (id) => {
    setLoadingParashift(true);
    Parashift.getDoc(id)
      .then(result => {
        return dbTask.updateTask({
          taskId: task.taskId,
          'parashift.data': result.data,
          'parashift.included': result.included,
          'parashift.current_status': result.data.attributes.current_status
        })
      })
      .then(result => {
        setLoadingParashift(false);
      })
      .catch(err => {
        setLoadingParashift(false);
      })
  }


  return (<Grid item xs={12}>
    {parashift === undefined ?
      <span>
        <Button onClick={() => sendDoc(task.base64Value, task.taskId, 'full-extraction')}>send for full-extraction</Button>
        <Button onClick={() => sendDoc(task.base64Value, task.taskId, 'self-validation')}>send for self-validation</Button>
      </span>
      :
      parashift.current_status !== 'done' ?
        // <span>Waiting for data to be delivered</span>:
        <span>Waiting for data to be delivered <Button onClick={() => getDoc(parashift.id)}>get automate extraction result</Button> </span> :
        <div style={{ padding: '10px', border: '1px solid #eaeaea', borderRadius: '5px', margin: "0px 0px 5px 20px", backgroundColor: "#fafafa" }}>
          <h3 style={{ marginBottom: '10px' }}>Parashift extract data results:</h3>
          {parashift && errors.length > 0 ?
            <div style={{ color: 'red' }}>
              Errors during parashift extraction:<br />
              <strong>{errors.join(', ')}</strong>
            </div>
            : null}
          {fields.map((f, i) => <div key={i}>{f}: <strong>{details[f]}</strong></div>)}
        </div>}
    {loadingParashift ? <CircularProgress></CircularProgress> : null}
    {/* <Button onClick={() => getDoc(parashift.id)}>get automate extraction result</Button> */}
  </Grid>);


}

export default ParashiftDetails;