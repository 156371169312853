import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { ActionButton } from '../Buttons'

import PolicyForm from './PolicyForm';

import './CompanyForm.css'

class CompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = { details: props.data };

    this.handleChange = this.handleChange.bind(this);
    this.addPolicy = this.addPolicy.bind(this);
    this.deletePolicy = this.deletePolicy.bind(this);
  }

  handleChange = (e) => {
    const companyDetails = this.state.details;
    companyDetails[e.target.name] = e.target.value;
    this.setState({ details: companyDetails });
    this.props.onChange(companyDetails);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ details: this.props.data })
    }
  }

  addPolicy() {
    const details = this.state.details;
    const newPolicy = {
      name: '',
      type: 'Steuerkonferenz',
      policyDetails: {}
    };
    if (details.policies === undefined) {
      details.policies = [newPolicy];
    } else {
      details.policies.push(newPolicy)
    }
    this.setState({ details });
  }

  setPolicy(idx, data) {
    const details = this.state.details;
    details.policies[idx] = data;
    this.setState({ details })
  }

  deletePolicy(idx) {
    const details = this.state.details;
    details.policies.splice(idx, 1);
    this.setState({ details })
  }

  render() {
    const data = this.state.details;
    const policies = data.policies ? data.policies.map((p, idx) => (
      <div key={idx} style={{ position: 'relative' }}>
        {this.props.isReadOnly ? null : <IconButton onClick={() => this.deletePolicy(idx)} style={{ position: 'absolute', right: '0' }}>
          <DeleteIcon />
        </IconButton>
        }
        <PolicyForm
          key={idx}
          name={p.name}
          type={p.type}
          isReadOnly={this.props.isReadOnly}
          policyDetails={p.policyDetails}
          onChange={(data) => this.setPolicy(idx, data)}
        >
          <FormLabel>{idx + 1}.</FormLabel>
        </PolicyForm >
      </div>
    )) : null;

    return (<div className="form">

      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Typography variant="h5">Address</Typography>
        </Grid>
        <Grid item xs={9} container spacing={4}>
          <Grid item xs={4}>
            <TextField InputProps={{ readOnly: this.props.isReadOnly }}
              fullWidth
              name="streetAndNumber"
              label="Street and Number"
              value={(data.streetAndNumber)}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              InputProps={{ readOnly: this.props.isReadOnly }}
              label="Post Code"
              name="postCode"
              onChange={this.handleChange}
              value={(data.postCode)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              InputProps={{ readOnly: this.props.isReadOnly }}
              name="city"
              label="City"
              value={(data.city)}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
      {/*</Grid>*/}
      {/*<Grid container spacing={4}>*/}
        <Grid item xs={3}>
          <Typography variant="h5">Responsible Person</Typography>
        </Grid>
        <Grid item xs={9} container spacing={4}>
          <Grid item xs={6}>
            <TextField fullWidth required
              name="firstName"
              label="First Name"
              InputProps={{ readOnly: this.props.isReadOnly }}
              value={(data.firstName)}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth required
              InputProps={{ readOnly: this.props.isReadOnly }}
              name="lastName"
              label="Last Name"
              value={(data.lastName)}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth
              name="telephoneNumber"
              label="Telephone Number"
              InputProps={{ readOnly: this.props.isReadOnly }}
              value={(data.telephoneNumber)}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth disabled={!this.props.newCompany}
              InputProps={{ readOnly: this.props.isReadOnly }}
              name="emailId"
              label="Email"
              value={(data.emailId)}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
      {/*</Grid>*/}
      {/*<Grid container spacing={4}>*/}
        <Grid item xs={3}>
          <Typography variant="h5">Policy</Typography>
        </Grid>
        <Grid item xs={9} container spacing={4}>
          <Grid item xs={12}>
            {this.props.isReadOnly ? null : <ActionButton onClick={this.addPolicy} color="secondary" style={{ marginTop: '10px' }}><AddIcon />Add new policy</ActionButton>}
          </Grid>
          <Grid item xs={12}>
            {policies}
          </Grid>
        </Grid>
      </Grid>
    </div >)
  }
}


export default CompanyForm
