import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Button, FormControl, Input, InputLabel } from '@material-ui/core'

import { SmallPage } from '../page'

import firebase from '../../components/firebase';


class Login extends React.Component {
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);

    this.state = {
      email: '',
      password: ''
    };
  }



  render() {
    return (
      <SmallPage title="Sign in">
        <form onSubmit={e => e.preventDefault() && false} style={{ marginTop: '8px' }}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email" name="email" autoComplete="off" autoFocus value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password" type="password" id="password" autoComplete="off" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={this.login}>
            Sign in
                  </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to="/register">
            Register
                  </Button>
        </form>
      </SmallPage>
    )
  }

  async login() {
    try {
      await firebase.login(this.state.email, this.state.password)
    } catch (error) {
      alert(error.message)
    }
  }

}

export default withRouter(Login)