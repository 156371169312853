import React from 'react';
import { connect } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';

import { SimpleButton } from '../../newComponents/Button';
import TextField from '../../newComponents/TextField';

import { companyClearDone, updateCompany } from '../../store/actions';

import '../Settings/settings.scss';
import Grid from '@material-ui/core/Grid';
import { TrashIcon } from '../../newComponents/Icons';

class ApproverInformation extends React.Component {
  constructor(props) {
    super(props);
    // if(this.props.currentCompany){
      this.state = {
        approvers: this.props.currentCompany.approverEmailId || [],
        changed: false,
      };
    // }

    this.accept = this.accept.bind(this);
    this.addApprover = this.addApprover.bind(this);
    this.change = this.change.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.done !== this.props.done) {
      if (this.props.done) {
        this.props.companyClearDone();
      }
      this.setState({ changed: false, done: false });
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.setState({
        approvers: this.props.currentCompany.approverEmailId || [],
        changed: false,
      });
    }
  }

  addApprover() {
    console.log('add approver');
    // return () => {
    const list = this.state.approvers;
    list.push('');
    console.log('list: ', list);
    this.setState({
      approvers: list,
      changed: true,
    });
  }

  change(idx, data) {
    console.log('idx', idx, 'data', data);
    const list = this.state.approvers;
    list[idx] = data;
    console.log('idx', idx, 'data', data);
    this.setState({
      approvers: list,
    });
  }

  del(idx) {
    const list = this.state.approvers;
    list.splice(idx, 1);
    this.setState({
      approvers: list,
      changed: true,
    });
  }

  accept() {
    this.props.updateCompany(this.props.currentCompany.companyId, {
      approverEmailId: this.state.approvers,
    });
    this.setState({ changed: false });
  }

  render() {
    const approvers = this.state.approvers;
    return (
      <div className="costObjectPanel" style={{width:'100%', borderTop:'1px solid #DADADA'}}>
        <SimpleButton
          className="actionButton"
          full
          icon={AddIcon}
          onClick={() => this.addApprover()}
        >
          Add Approver
        </SimpleButton>
        {approvers.map((e, idx) => (
          <div key={idx} style={{height:'65px'}}>
            {/*<span>{idx + 1}</span>*/}
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div style={{marginLeft:'20px', marginTop:'15px'}} >
                  {idx + 1}. Approver Mail
                </div>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  value={e}
                  onChange={(data) => this.change(idx, data)}
                />
              </Grid>
              <Grid item xs={1}  >
                <div style={{marginTop:'15px', marginLeft:'5px'}}>
                  <TrashIcon onClick={() => this.del(idx)} />
                </div>
              </Grid>
            </Grid>
          </div>
        ))}

        {this.state.changed ? (
          <div style={{height:'65px'}}>
            <SimpleButton
              bordered
              style={{ marginRight: '10px', marginLeft:'20px' }}
              onClick={this.cancel}
            >
              Cancel
            </SimpleButton>
            <SimpleButton
              loading={this.state.acceptLoading}
              full
              style={{marginLeft:'10px' }}
              onClick={this.accept}
            >
              Save Card
            </SimpleButton>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updateDone: state.CompanyReducer.done,
    currentCompany: state.CompanyReducer.currentCompany,
  };
};
export default connect(mapStateToProps, { updateCompany, companyClearDone })(
  ApproverInformation,
);
