
import React, { Component } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react'

import Grid from '@material-ui/core/Grid';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';

import Dropzone from '../../../newComponents/Dropzone';
import TextField from '../../../newComponents/TextField';
import Select from '../../../newComponents/Select';

class SteuerkonferenzPolicy extends Component {
  constructor(props) {
    super(props);
    const { details } = props;

    this.state = {
      minAmount: 0,
      trainClass: '',
      planeClass: '',
      perKilometer: 0,
      maxBreakfast: 0,
      maxLunch: 0,
      maxDinner: 0,
      hotelClass: '',
      giftAmount: 0,
      ...details
    }

    this.handleChange = this.handleChange.bind(this);
  }

  // handleChange(e) {
  //   const state = this.state;
  //   state[e.target.name] = e.target.value;
  //   this.setState({ ...state });
  //   this.props.onChange(state);
  // }

  handleChange = (field) => (newValue) => {
    const state = { ...this.state, [field]: newValue }
    this.setState(state);
    this.props.onChange(state);
  };

  render() {
    const data = this.state;
    return (
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <TextField required
            label="Minimum Amount per Expense"
            onChange={this.handleChange('minAmount')}
            value={data.minAmount}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            onChange={this.handleChange('trainClass')}
            value={data.trainClass}
            label="Train Class"
          >
            <MenuItem value="1st">1st</MenuItem>
            <MenuItem value="2nd">2nd</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Select
            name="planeClass"
            onChange={this.handleChange('planeClass')}
            value={data.planeClass}
            label="PlaneClass"
          >
            <MenuItem value="Eco">Economy</MenuItem>
            <MenuItem value="Business">Business</MenuItem>
            <MenuItem value="First">First</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <TextField required type="number"
            onChange={this.handleChange('perKilometer')}
            value={data.perKilometer}
            label="Reimbursement per kilometer" />
        </Grid>
        <Grid item xs={12}>
          <TextField required type="number"
            onChange={this.handleChange('maxBreakfast')}
            value={data.maxBreakfast}
            label="Max. Breakfast" />
        </Grid>
        <Grid item xs={12}>
          <TextField required type="number"
            onChange={this.handleChange('maxLunch')}
            value={data.maxLunch}
            label="Max. Lunch" />
        </Grid>
        <Grid item xs={12}>
          <TextField required type="number"
            onChange={this.handleChange('maxDinner')}
            value={data.maxDinner}
            label="Max. Dinner" />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Regular Hotel Class"
            onChange={this.handleChange('hotelClass')}
            value={data.hotelClass}
          >
            <MenuItem value="low">Low tier</MenuItem>
            <MenuItem value="mid">Mid tier</MenuItem>
            <MenuItem value="high">High tier</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <TextField type="number"
            onChange={this.handleChange('giftAmount')}
            value={data.giftAmount}
            label="Max. Amount for gift to hosts (overnight stay)" />
        </Grid>
      </Grid>)
  }
}

class Rz52Policy extends Component {
  constructor(props) {
    super(props);
    const { details } = props;

    this.state = {
      perKilometer: 0,
      maxAmount: 0,
      ...details
    }

    this.handleChange = this.handleChange.bind(this);
  }

  // handleChange(e) {
  //   const state = this.state;
  //   state[e.target.name] = e.target.value;
  //   this.setState({ ...state });
  //   this.props.onChange(state);
  // }

  handleChange = (field) => (newValue) => {
    const state = { ...this.state, [field]: newValue }
    this.setState(state);
    this.props.onChange(state);
  };

  render() {
    const data = this.state;
    return (
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <TextField
            inputProps={{ min: "0", max: "35" }}
            type="number"
            label="Max effective Amount for lunch and dinner (&le; 35)"
            onChange={this.handleChange('maxAmount')}
            value={data.maxAmount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ min: "0", max: "0.7", step: '0.1' }}
            type="number"
            label="Reimbursement per kilometer (&le; 0.7)"
            onChange={this.handleChange('perKilometer')}
            value={data.perKilometer}
          />
        </Grid>
      </Grid>)
  }
}


class PolicyForm extends Component {

  constructor(props) {
    super(props);

    const { policy } = props;

    this.state = {
      id: Math.random().toString(36).substring(2, 8),
      name: '',
      type: 'Steuerkonferenz',
      attachment: null,
      policyDetails: {},
      ...policy
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.policyDetails !== this.props.policyDetails) {
      this.setState({ policyDetails: this.props.policyDetails })
    }
    if (prevProps.name !== this.props.name) {
      this.setState({ name: this.props.name })
    }
    if (prevProps.type !== this.props.type) {
      this.setState({ type: this.props.type })
    }
    if (prevProps.attachment !== this.props.attachment) {
      this.setState({ attachment: this.props.attachment })
    }
  }

  handleChange = (field) => (newValue) => {
    const state = { ...this.state, [field]: newValue }
    this.setState(state);
    this.props.onChange(state);
  };


  render() {
    const attachment = this.state.attachment;
    let imagePreviewUrl = attachment ? attachment.data : null;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview = imagePreviewUrl.includes('.jpg') ||
      imagePreviewUrl.includes('.jpeg') ||
      imagePreviewUrl.includes('.PNG') ||
      imagePreviewUrl.includes('.png') ?
        (<img id='uploadedImage' src={imagePreviewUrl} border='1'
          align="middle" alt=''
          className='center'
        />) :
        <object data={imagePreviewUrl}
                type='application/pdf'
                width='100%'
                height='600px'/>
        // <PDFViewer document={{ url: imagePreviewUrl }} />
        //(<embed src={imagePreviewUrl} width="100%" height="100%" type="application/pdf" />);
    } else {
      imagePreview = <Dropzone
        bucket="policy"
        label="Click or drag Statements file to this area to upload"
        onChange={this.handleChange('attachment')} />;
    }

    return (
      <Grid container spacing={3} style={{ marginTop: '10px' }}>
        <Grid item xs={6}>
          {imagePreview}
        </Grid>
        <Grid container spacing={1} item xs={6}>
          <Grid item xs={12}>
            <TextField
              label="Name of the policy instance"
              onChange={this.handleChange('name')}
              value={this.state.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                className="policyRadio"
                aria-label="Policy type"
                name="type"
                onChange={(e) => this.handleChange('type')(e.target.value)}
                value={this.state.type}
              >
                <FormControlLabel value="Steuerkonferenz" control={<Radio />} label="Steuerkonferenz" />
                <FormControlLabel value="Rz52" control={<Radio />} label="Rz. 52" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {this.state.type === 'Steuerkonferenz' ?
              <SteuerkonferenzPolicy onChange={this.handleChange('policyDetails')} details={this.state.policyDetails} /> :
              <Rz52Policy onChange={this.handleChange('policyDetails')} details={this.state.policyDetails} />
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

}


export default PolicyForm;
