import firebase from "../components/firebase";


const db = firebase.db;

const Accountant = {

  addAccountantDetails: (accountantDetails, docId) => {
    return new Promise((resolve, reject) => {
      db.collection('accountant')
        .doc(docId)
        .set(accountantDetails)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })

  },

  updateAccountantDetails: (request) => {
    return new Promise((resolve, reject) => {

      let docId = request.docId;
      let accountantDetails = request.accountantData;

      db.collection('accountant')
        .doc(docId)
        .update(accountantDetails)
        .then(ref => resolve(ref))
        .catch(error => reject(error))
    })
  },

  getAccountantProfileData: (accountantId) => {
    return new Promise((resolve, reject) => {
      db.collection('accountant')
        .doc(accountantId)
        .get()
        .then(doc => resolve(doc.data()))
        .catch(err => reject(err))
    })
  },

  getAllAccountants: async (callback) => {
    try {
      db.collection("accountant")
        .onSnapshot(doc =>
          callback(doc.docs.map(a =>
            ({
              name: a.data().firstName + ' ' + a.data().lastName,
              id: a.id
            })
          ))
        )
    } catch (error) {
      callback(null);
    }
  },

  getMsTeamsConnection(email) {
    return new Promise((resolve, reject) => {
      db.collection('teams')
        .doc(email)
        .get()
        .then(doc => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            resolve(undefined);
          }
        })
        .catch(e => reject(e))
    });
  },
  activateMsTeamsConnection(email, confirm) {
    return new Promise((resolve, reject) => {
      if (confirm) {
        db.collection('teams')
          .doc(email)
          .update({ activated: true })
          .then(() => resolve(true))
          .catch(e => reject(e))
      } else {
        db.collection('teams')
          .doc(email)
          .delete()
          .then(() => resolve(true))
          .catch(e => reject(e))
      }
    });
  }

}
export default Accountant;
