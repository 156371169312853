import React, { useEffect, useState } from 'react';
import Page from '../page';
import SyncIcon from '@material-ui/icons/Sync';
import { ActionButton, LinkButton } from '../../components/Buttons';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Topal from '../../firebasecomponents/topal';

const SyncTask = () => {
  const [onlineExp, setOnlineExp] = useState(false);
  const [log, setLog] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //on mounting check whether server is online
    const fetch = async () => {
      if (await Topal.checkTopalOnline({ isExpense: true })) {
        setOnlineExp(true);
      }
    };
    fetch();
  }, []);

  const syncExpenses = async () => {
    console.log('sync exp');
    setLoading(true);
    const data = await Topal.checkPaidExpensesTopal();
    if (data && data.data) {
      console.log('data', data);
      try{
        setLog(data.data.join(''));
      }catch (e) {
        console.log(' - could not join - ');
        setLog(data.data);
      }
    } else {
      setLog(data);
    }
    setLoading(false);
  };

  const syncInvoices = async () => {
    console.log('sync inv');
    setLoading(true);
    const data = await Topal.checkPaidInvoicesTopal();
    if (data && data.data) {
      console.log('data', data);
      try {
        setLog(data.data.join(''));
      } catch (e) {
        console.log(' - could not join - ');
        setLog(data.data);
      }
    } else {
      setLog(data);
    }
    setLoading(false);
  };

  return (
    <Page>

      <ActionButton
        color="muted"
        className="syncButton"
        onClick={syncExpenses}
        disabled={!onlineExp || loading ? true : false}
      >
        {!onlineExp || loading ? (
          <CircularProgress
            color="secondary"
            style={{ height: '24px', width: '24px' }}
          />
        ) : (
          <SyncIcon />
        )}
        Sync Expenses
      </ActionButton>

      <ActionButton
        color="muted"
        className="syncButton"
        onClick={syncInvoices}
        disabled={loading ? true : false}
      >
        {loading ? (
          <CircularProgress
            color="secondary"
            style={{ height: '24px', width: '24px' }}
          />
        ) : (
          <SyncIcon />
        )}
        Sync Invoices
      </ActionButton>

      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography>Log: </Typography>
              <TextField multiline variant="outlined" value={log} fullWidth />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <LinkButton fullWidth to="/" color="muted">
              Cancel
            </LinkButton>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default SyncTask;
