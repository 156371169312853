import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { LinkButton, SaveButton } from '../Buttons';
import dbTask from "../../firebasecomponents/task";
import {Averix} from "../../externalAPI";

class Clarification extends Component {
  constructor(props) {
    super(props);
    this.updatetask = this.updatetask.bind(this);

    this.state = {
      taskDetails: this.props.task,
    };
  }

  async updatetask(readable) {
    console.log('udpate');
    this.setState({ loading: true });

    const taskDetails = this.state.taskDetails;
    let { taskId} = taskDetails;

    // if(readable){
    //
    // }
    // else{
    //
    // }
    const fields={
      taskId,
      task_defination: readable ? 'extract-data' : 'non-readable',
    };

    dbTask.updateTask(fields).then(res => {
      taskDetails.task_defination = readable ? 'extract-data' : 'non-readable';
      // return Averix.extractData({
      //   extracted: readable,
      //   expenseId: taskId,
      //   extractedData: readable ? {
      //   } : undefined
      // });
    }).then(res => {
      this.setState({ loading: false });
      this.props.onChange({
        task: {
          ...taskDetails,
          taskId,
        }, success: 'Great! You have saved the data!'
      });
    }).catch(err => {
      this.setState({ loading: false });
      this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>
            {this.state.taskDetails.task_defination}
          </h1>
        </Grid>
        <Grid item xs={4}>
          <SaveButton
            fullWidth
            onClick={() => this.updatetask(true)}
            loading={this.state.loading}
          >
            To Extract Data
          </SaveButton>
        </Grid>
        <Grid item xs={4}>
          <SaveButton
            fullWidth
            onClick={() => this.updatetask(false)}
            loading={this.state.loading}
          >
            Receipt is not readable
          </SaveButton>
        </Grid>
        <Grid item xs={4}>
          <LinkButton fullWidth to='/' color="muted">Cancel</LinkButton>
        </Grid>
      </Grid>
    );
  }
}

export default Clarification;
