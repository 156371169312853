export const taskSteps = {
  'extract-data': {
    no: '1',
    txt: 'Receipt Extraction'
  },
  'compliance-check': {
    no: '2',
    txt: 'Expense Policy Compliance'
  },
  'manager-approval': {
    no: '3',
    txt: 'Manager Approval'
  },
  'reimbursability-check': {
    no: '4',
    txt: 'Reimbursability Check'
  },
  'arrange-payment': {
    no: '5',
    txt: 'Arrange Payment'
  },
  'payment-confirmation': {
    no: '6',
    txt: 'Payment Confirmation'
  },
  'payment-done': {
    no: '-',
    txt: 'Payment is done'
  },
  'manager-declined': {
    no: '-',
    txt: 'Manager Declined'
  },
  'non-reimbursible task': {
    no: '-',
    txt: 'Non-reimbursible Task'
  },
  'non-readable': {
    no: '-',
    txt: 'Receipt is not readable'
  },
  'clarification': {
    no: '-',
    txt: 'Clarification needed'
  },
}

export const taskStepName = (taskValue) => taskSteps[taskValue]? taskSteps[taskValue].no + '. ' + taskSteps[taskValue].txt : '--';

