import moment from 'moment';

export const LocaleFormat = {
  style: 'currency',
  currency: 'CHF',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const calculateCreditorInvoicesTotal = (invoices) => {
  let totalCreditorSum = 0;
  let totalCreditorOverdue = 0;
  let totalCreditor10d = 0;
  let totalCreditorsNotApproved = 0;
  let totalCreditorsNotApproved10d = 0;
  let totalCreditorsNotApprovedOverdue = 0;
  let notApprovedAmount = 0;

  const today = moment();
  const due = moment().add(10, 'days');
  for (const invoice of invoices) {
    let tempSum = 0;
    if (!invoice.amounts || !isCreditor(invoice)) {
      continue;
    }

    const invoiceDueDate = moment(invoice.dueDate);
    const isBefore = invoiceDueDate.isBefore(today, 'date');

    for (const amts of invoice.amounts) {
      tempSum += amts.amount;
    }
    //total creditor
    if (stateCheck(invoice.state)) {
      totalCreditorSum += tempSum;
    }
    // total creditor Not approved
    if (stateCheckNotApproved(invoice.state)) {
      totalCreditorsNotApproved += tempSum;
      notApprovedAmount++;
    }
    //overdue
    if (stateCheck(invoice.state) && isBefore) {
      totalCreditorOverdue += tempSum;
    }
    //overdue not approved
    if (stateCheckNotApproved(invoice.state) && isBefore) {
      totalCreditorsNotApprovedOverdue += tempSum;
    }
    //10d
    if (
      stateCheck(invoice.state) &&
      isTodayOrInNext10DInclusive(invoice, today, due)
    ) {
      totalCreditor10d += tempSum;
    }
    //Not Approved10d
    if (
      stateCheckNotApproved(invoice.state) &&
      isTodayOrInNext10DInclusive(invoice, today, due)
    ) {
      totalCreditorsNotApproved10d += tempSum;
    }
  }

  return {
    totalCreditorSum: formatCurrencyToLocale(totalCreditorSum),
    totalCreditorOverdue: formatCurrencyToLocale(totalCreditorOverdue),
    totalCreditor10d: formatCurrencyToLocale(totalCreditor10d),
    totalCreditorNotApproved: formatCurrencyToLocale(totalCreditorsNotApproved),
    totalCreditorsNotApprovedOverdue: formatCurrencyToLocale(totalCreditorsNotApprovedOverdue),
    totalCreditorsNotApproved10d: formatCurrencyToLocale(totalCreditorsNotApproved10d),
    notApprovedAmount,
  };
};

export const calculateExpensesTotal = (tasks) => {
  let totalSum = 0;
  let totalSumReimbursable = 0;
  let emplMap = {};
  let emplMapReimbursable = {};
  let empCount = {};

  for (const task of tasks) {
    let tempSum = 0;
    if (!task.amounts) {
      continue;
    }
    for (const amount of task.amounts) {
      //chf check
      if (amount.amountCHF) {
        tempSum += amount.amountCHF;
      } else {
        tempSum += amount.amount;
      }
    }
    // reimbursable
    if (task.reimbursable) {
      totalSumReimbursable -= tempSum;
      emplMapReimbursable[task.empl_name] = emplMapReimbursable[task.empl_name]
        ? (emplMapReimbursable[task.empl_name] -= tempSum)
        : -tempSum;
    } else {
      // check if it exists, if it does not exist yet, set to 0
      emplMapReimbursable[task.empl_name] = emplMapReimbursable[task.empl_name] ?
        emplMapReimbursable[task.empl_name] : 0;
    }
    //
    totalSum += tempSum;
    emplMap[task.empl_name] = emplMap[task.empl_name]
      ? (emplMap[task.empl_name] += tempSum)
      : tempSum;
    // count
    empCount[task.empl_name] = empCount[task.empl_name]
      ? (empCount[task.empl_name] += 1)
      : 1;
  }

  return {
    totalSum: formatCurrencyToLocale(totalSum),
    totalSumReimbursable: formatCurrencyToLocale(totalSumReimbursable),
    emplMap,
    emplMapReimbursable,
    empCount,
  };
};

export const calculateDebitorInvoicesTotal = (invoices) => {
  let totalDebitorSum = 0;
  let totalDebitorOverdue = 0;
  let totalDebitor10d = 0;
  let totalDebitorsNotApproved = 0;
  let totalDebitorsNotApproved10d = 0;
  let totalDebitorsNotApprovedOverdue = 0;
  let notApprovedAmount = 0;

  const today = moment();
  const due = moment().add(10, 'days');
  for (const invoice of invoices) {
    let tempSum = 0;
    if (!invoice.amounts || !isDebitor(invoice)) {
      continue;
    }
    const invoiceDueDate = moment(invoice.dueDate);
    const isBefore = invoiceDueDate.isBefore(today, 'date');

    for (const amts of invoice.amounts) {
      tempSum += amts.amount;
    }
    //total Debitor
    if (stateCheck(invoice.state)) {
      totalDebitorSum += tempSum;
    }
    // total Debitor Not approved
    if (stateCheckNotApproved(invoice.state)) {
      totalDebitorsNotApproved += tempSum;
      notApprovedAmount++;
    }
    //overdue
    if (stateCheck(invoice.state) && isBefore) {
      totalDebitorOverdue += tempSum;
    }
    //overdue not approved
    if (stateCheckNotApproved(invoice.state) && isBefore) {
      totalDebitorsNotApprovedOverdue += tempSum;
    }
    //10d
    if (
      stateCheck(invoice.state) &&
      isTodayOrInNext10DInclusive(invoice, today, due)
    ) {
      totalDebitor10d += tempSum;
    }
    //Not Approved10d
    if (
      stateCheckNotApproved(invoice.state) &&
      isTodayOrInNext10DInclusive(invoice, today, due)
    ) {
      totalDebitorsNotApproved10d += tempSum;
    }
  }
  return {
    totalDebitorSum: formatCurrencyToLocale(totalDebitorSum),
    totalDebitorOverdue: formatCurrencyToLocale(totalDebitorOverdue),
    totalDebitor10d: formatCurrencyToLocale(totalDebitor10d),
    totalDebitorsNotApproved: formatCurrencyToLocale(totalDebitorsNotApproved),
    totalDebitorsNotApprovedOverdue: formatCurrencyToLocale(
      totalDebitorsNotApprovedOverdue,
    ),
    totalDebitorsNotApproved10d: formatCurrencyToLocale(
      totalDebitorsNotApproved10d,
    ),
    notApprovedAmount,
  };
};

// inclusive today, inclusive next 10d
const isTodayOrInNext10DInclusive = (invoice, today, due) => {
  return (
    moment(invoice.dueDate).isSameOrAfter(today, 'date') &&
    moment(invoice.dueDate).isSameOrBefore(due, 'date')
  );
};

const stateCheck = (state) => {
  return state === 'open' || state === 'pending' || state === 'approved';
};
const stateCheckNotApproved = (state) => {
  return state === 'open';
};

export const formatCurrencyToLocale = (str) => {
  if(str || str === 0) {
    return str.toLocaleString('de-CH', LocaleFormat);
  } else {
    return '';
  }
};

const isCreditor = (invoice) => {
  return invoice.type === 'Kreditor';
};

const isDebitor = (invoice) => {
  return invoice.type === 'Debitor';
};
