import firebase from '../components/firebase';
import { currentDateStamp } from '../components/dateHelpers';
import * as moment from 'moment';
const db = firebase.db;

const invoice = {
  getInvoicesBasedOnCompanyId: async (request, callback) => {
    let companyId = request.companyId;
    let query = db.collection('invoices').where('company_id', '==', companyId);
    try {
      query.onSnapshot(function (doc) {
        callback(
          doc.docs.map((d) => {
            return {
              ...d.data(),
              id: d.id,
            };
          }),
        );
      });
    } catch (error) {
      callback(null);
    }
  },

  getInvoiceData: async (request, callback) => {
    // using .onSnapshot for realtime updates
    try {
      db.collection('invoices').doc(request)
        .onSnapshot(doc => {
          if (doc.exists) {
            callback(doc.data());
          } else {
            console.log('doc not found');
          }
        })
    } catch (e) {
        console.log('error getting doc', e);
      }
  },

  updateInvoice: async (request) => {
    console.log('updateinvoice', request);
    return new Promise((resolve, reject) => {
      const { invoiceId, ...rest } = request;
      db.collection('invoices')
        .doc(invoiceId)
        .update({
          updatedAt: new Date(),
          ...rest,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addInvoice: async (invoiceDetails, isTask = false) => {
    return new Promise((resolve, reject) => {
      const now = new Date();
      console.log('invoiceDetails', invoiceDetails);
      db.collection('company')
        .doc(invoiceDetails.company_id)
        .get()
        .then((doc) => {
          console.log('company', doc.data());
          const company = doc.data();
          const companyNo = company.companyNo;
          // console.log('compNo', companyNo);
          const no = company.lastInvoiceNo ? company.lastInvoiceNo + 1 : 1;
          // console.log('no', no);
          const receiptNo = companyNo + String(no).padStart(5, '0');
          let invoiceId;
          let invoice = {};

          //INVOICE----------------------------------------------------
          if (!isTask) {
            console.log('is invoice, not expense');
            invoiceId =
              'I-' +
              receiptNo +
              '-' +
              Math.random().toString(36).substring(2, 16);

            const merchant = {
              name: '',
              city: '',
              zip: '',
              address1: '',
              country: '',
            };
            const payment = {
              account_number: '',
              esr: '',
              iban: '',
            };
            invoice = {
              invoiceId,
              id:invoiceId,
              ...invoiceDetails,
              createdAt: now,
              updatedAt: now,
              receiptNo: receiptNo,
              merchant: merchant,
              payment: payment,
              freeInvoiceNum: '',
              vatNumber: '',
            };
            return db
              .collection('invoices')
              .doc(invoiceId)
              .set(invoice)
              .then(() => {
                db.collection('company').doc(invoiceDetails.company_id).update({
                  lastInvoiceNo: no,
                });
                resolve(invoice);
              })
              .catch((err) => {
                reject(err);
              });
          }

          //TASK----------------------------------------------
          else {
            console.log('is task');
            //create invoice from task
            const id = invoiceDetails.taskId ? String(invoiceDetails.taskId) : invoiceDetails.id ? String(invoiceDetails.id) : receiptNo;
            invoiceId = String('I' + id.slice(1));
            console.log('invoiceId', invoiceId);

            const dueDate = moment(invoiceDetails.date)
              .add(30, 'days')
              .format('YYYY-MM-DD');
            console.log('dueDate', dueDate);

            let employee = {};
            db.collection('employee')
              .where('emailid', '==', invoiceDetails.empl_emailId)
              .get()
              .then((doc) => {
                // const empl = doc.docs[0].data();
                // console.log('employee', doc.docs[0].data());
                employee = doc.docs[0].data();
                console.log('employee', employee);
                const payment = {
                  iban: employee.ibanNr,
                  bankName: employee.bankName,
                  bankPlace: employee.bankplace,
                };
                console.log('payment', payment);
                const merchant = {
                  name: invoiceDetails.empl_name,
                  city: employee.city,
                  zip: employee.postcode,
                  address1: employee.streetAndNumber,
                  country: employee.country ? employee.country : 'CH', //TODO
                };
                console.log('merchant', merchant);
                console.log('empl name', employee.empl_name);
                let amounts = invoiceDetails.amounts;
                const expenseTypes = company.mapping.expense;
                const customTypes = company.mapping.custom;
                console.log('amounts', amounts)
                if (invoiceDetails.reimbursable) {
                  amounts.map((item) => {
                    let bookingAccount = {};
                    expenseTypes.map((exp) => {
                      console.log('item, exp', item, exp);
                      if (exp.expenseType === item.type) {
                        bookingAccount.number = exp.number;
                        bookingAccount.accountType = exp.accountType;
                        bookingAccount.name = exp.name;
                        item.bookingAccount = bookingAccount;
                      }
                    });
                    customTypes.map((cus) => {
                      console.log('item, cus', item, cus);
                      if (item.type === cus.name) {
                        bookingAccount.number = cus.number;
                        bookingAccount.name = cus.name;
                        bookingAccount.accountType = cus.accountType;
                        item.bookingAccount = bookingAccount;
                      }
                    });
                  });
                }
                //TODO credit card non reimbursable
                // else {
                //   console.log('task not reimbursable');
                //   const search = async () => {
                //     const {ccData} = await db
                //       .collection('creditcard')
                //       .where('users', 'in', employee.employeeId)
                //       .get();
                //
                //     console.log('credit card', ccData);
                //   };
                //
                //   search();
                // }
                console.log('amounts', amounts);
                invoice = {
                  invoiceId,
                  ...invoiceDetails,
                  amounts: amounts,
                  amount_total : invoiceDetails.amounts ? (invoiceDetails.amounts.reduce((prev, curr) => prev + curr.amount, 0)).toString() : 0,
                  createdAt: now,
                  updatedAt: now,
                  teams: '',
                  invoiceDate: invoiceDetails.date,
                  dueDate: dueDate,
                  type: 'Kreditor',
                  extraction: 'manual',
                  extracted: true,
                  state: 'open',
                  payment: payment,
                  merchant: merchant,
                };
                return db
                  .collection('invoices')
                  .doc(invoiceId)
                  .set(invoice)
                  .then(() => {
                    db.collection('company')
                      .doc(invoiceDetails.company_id)
                      .update({
                        lastInvoiceNo: no,
                      });
                    resolve(invoice);
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
          }
        });
    });
  },
};

export default invoice;
