
import task from '../../firebasecomponents/task';

const TASKS_LOADED = "TASKS_LOADED";
const TASKS_UPDATED = "TASKS_UPDATED";
const CLEAR_DONE = "TASKS_CLEAR_DONE";
const QUESTIONS_LOADED = "QUESTIONS_LOADED";
const QUESTION_DELETED = "QUESTION_DELETED";
const CLEAR_TASKS = "CLEAR_TASKS";


/** reducer */
export default function reducer(state = {}, action) {

  if (action.type === TASKS_LOADED) {
    return { ...state, tasks: action.payload };
  }
  if (action.type === TASKS_UPDATED) {
    return { ...state, done: true };
  }
  if (action.type === CLEAR_DONE) {
    return { ...state, done: false };
  }
  if (action.type === QUESTIONS_LOADED) {
    return { ...state, questions: action.payload };
  }
  if (action.type === CLEAR_TASKS) {
    state.tasks=undefined;
    return { ...state };
  }
  return state;
};

/** actions */

export function getData(companyId, filter) {
  return function (dispatch) {
    task.getTaskBasedOnComapnyId({ companyId, filter }, response => dispatch({ type: TASKS_LOADED, payload: response }));
  };
};

export function updateTask(taskDetails) {
  return function (dispatch) {
    task.updateTask(taskDetails)
      .then((res) => {
        dispatch({ type: TASKS_UPDATED, payload: res });
      })
  };
};

export function updateTasks(tasksArray) {
  return function (dispatch) {
    const promises = tasksArray.map(taskDetails => task.updateTask(taskDetails));

    Promise.all(promises)
      .then((res) => {
        dispatch({ type: TASKS_UPDATED, payload: res });
      })
  };
};

export function getQuestions(companyId) {
  return function (dispatch) {
    task.getQuestions(companyId, (res) => {
        dispatch({ type: QUESTIONS_LOADED, payload: res });
      })
  };
}
export function deleteQuestion(questionId) {
  return function (dispatch) {
    task.deleteQuestion(questionId)
      .then((res) => {
        dispatch({ type: QUESTION_DELETED, payload: res });
      })
  };
}

export function clearDone() {
  return function (dispatch) {
    dispatch({ type: CLEAR_DONE, payload: {} });
  };
};
