import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

import { formatDate } from '../dateHelpers';
import { SaveButton, LinkButton } from '../Buttons';

import TaxAmount from './TaxAmount';
import ParashiftDetails from './ParashiftDetails';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import dbTask from '../../firebasecomponents/task';
import dbCompany from '../../firebasecomponents/company';
import dbEmployee from '../../firebasecomponents/employee';

import { Averix, Pwebapps, Parashift } from '../../externalAPI';
import Predictions from '../../firebasecomponents/predictions';
import Log from '../../newHelpers/Log';
const currencies = require('../../currency.json');
const Logger = new Log('ExtractDataForm');
const _ = require('lodash');

const formatNumber = (str) => str.replace(/ /g, '').replace(/,/g, '.');
const numberCheckFails = (str) => isNaN(parseFloat(formatNumber(str)));

class ExtractDataForm extends Component {
  constructor(props) {
    super(props);
    const taskDetails = this.mapParashiftTask(this.props.task);

    this.state = {
      taskDetails,
      amounts: taskDetails.amounts || [],
      policyFields: {},
      loading: false,
      errors: {},
      splits: 1,
      parashiftSendRequest: false,
      loadingParashift: false,
    };

    Logger.log('task details', this.state.taskDetails);

    this.handleDelete = this.handleDelete.bind(this);
    this.updatetask = this.updatetask.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.addPosition = this.addPosition.bind(this);

    dbCompany.fetchCompanyDetails(this.props.task.company_id).then((details) => {
      this.setState({ expenseTypes: details.mapping.expense.map((e) => e.expenseType) });
    });
    //
    if (!this.state.taskDetails.costCenter) {
      dbEmployee.getEmployeeByNameAndCompanyName(
        { companyName: this.props.task.company_name, emplName: this.props.task.empl_name },
        (response) => {
          const cc = response && response[0].fixedCostCenter ? response[0].fixedCostCenter : null;
          if (cc !== null) {
            let task = this.state.taskDetails;
            task.costCenter = cc;
            this.setState({ taskDetails: task });
          }
        },
      );
    }
  }

  handleDelete = (idx) => {
    return () => {
      let amounts = this.state.amounts;
      let task = this.state.taskDetails;
      amounts.splice(idx, 1);
      this.setState({ amounts: amounts, taskDetails: task });
    };
  };

  addPosition() {
    const taskDetails = this.state.taskDetails;
    if (taskDetails.amounts) {
      taskDetails.amounts.push({
        amount: 0,
        item_name: '',
        policyFields: {},
        type: '',
        vat_amount: 0,
        vat_percentage: {
          name: '7.7%',
          value: 7.7,
        },
      });
      this.setState({
        taskDetails: taskDetails,
      });
    } else {
      this.setState({
        splits: this.state.splits + 1,
      });
    }
  }

  mapParashiftTask(task) {
    return task.parashift && task.parashift.current_status === 'done'
      ? Parashift.mapParashiftToTask(task)
      : task;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.task !== this.props.task) {
      this.setState({ taskDetails: this.mapParashiftTask(this.props.task) });
    }
  }

  componentWillUnmount() {}

  updateAmount(idx) {
    return (data) => {
      const amounts = this.state.amounts;
      amounts[idx] = data;
      this.setState({ amounts });
    };
  }

  async updatetask(readable) {
    this.setState({ loading: true });

    const taskDetails = this.state.taskDetails;

    const messages = [];
    const errors = {};

    let { taskId, merchant, currency, date, amount_total, description, costCenter } = taskDetails;
    date = formatDate(date);
    let amounts; // = this.state.amounts;

    if (description === undefined) {
      description = '';
    }

    if (readable) {
      if (!taskId) messages.push('There is no task id');

      if (!merchant) {
        messages.push('There is no merchant');
        errors.merchant = 'Merchant is required';
      }

      if (!date) {
        messages.push('There is no date');
        errors.date = 'Date is required';
      }

      if (!amount_total) {
        messages.push('Total amount missing');
        errors.amount_total = 'Total amount is required';
      }

      const ams = {};
      this.state.amounts.forEach((am, idx) => {
        let err = {};
        if (am.type === undefined || am.type === '') {
          messages.push('There is no type in ' + idx + '. subamount');
          err = { type: 'Select type of the expense from the list' };
        }
        if (am.amount === undefined) {
          messages.push('There is no type in ' + idx + '. subamount');
          err = { ...err, amount: 'Amount is required' };
        }
        if (am.vat_amount === undefined || isNaN(am.vat_amount) || am.vat_percentage === 0) {
          messages.push('There is no VAT amount in ' + idx + '. subamount');
          err = { ...err, vat_amount: 'VAT amount is required' };
        }
        if (Object.keys(err).length > 0) {
          (errors.amounts = errors.amounts || {})[idx] = err;
          return;
        }
        // group items by vat percentage and merge
        const key =
          am.vat_percentage.name + '-' + am.type + '-' + Object.values(am.policyFields).join('-');
        if (ams[key] === undefined) {
          ams[key] = am;
        } else {
          ams[key] = {
            type: am.type,
            amount: _.round(ams[key].amount + am.amount, 2),
            vat_amount: _.round(ams[key].vat_amount + am.vat_amount, 2),
            vat_percentage: am.vat_percentage,
            policyFields: am.policyFields,
          };
        }
      });

      // let vats;
      // Object.values(ams).forEach(e => {
      //   vats[e.vat_percentage.name] = e.amount + (vats[e.vat_percentage.name] ? vats[e.vat_percentage.name] : 0)
      // })

      //check if given total amount (from parashift, or input manually is equal to the items)
      // if(taskDetails.parashift && taskDetails.parashift.included && taskDetails.parashift.included[0].attributes.amount_total){
      //   const amount_total = taskDetails.parashift.included[0].attributes.amount_total;
      let itemAmounts = taskDetails.amounts
        ? taskDetails.amounts
        : this.state.amounts
        ? this.state.amounts
        : [];
      let total_sum = 0;
      for (let idx in itemAmounts) {
        let item = itemAmounts[idx].amount;
        total_sum += item;
      }
      if (Math.abs(parseFloat(amount_total) - parseFloat(total_sum)) > 0.05) {
        messages.push('Total amount error');
      }

      amounts = Object.values(ams).map((a) => {
        const vat = a.vat_percentage.value;
        delete a['amountNoVat'];
        return {
          ...a,
          vat_amount: +((a.amount * vat) / (100 + vat)).toFixed(2),
        };
      });

      if (!currency) {
        messages.push('There is no currency selected');
        errors.currency = 'Select a currency from the list';
      }
      if (messages.length > 0) {
        this.setState({ loading: false, errors });
        this.props.onChange({
          task: taskDetails,
          error: messages.map((m) => <div key={m}>{m}</div>),
        });
        return;
      }
    } else {
      merchant = '';
      date = '';
      currency = '';
      amounts = [];
    }

    let rate = {};
    if (currency !== 'CHF' && currency !== '') {
      const { value, multiple } = await Pwebapps.getRateDaily(date, currency);
      rate = { rate: value / multiple };
      amounts.forEach((taxAmount) => {
        // taxAmount.amountCHF = (taxAmount.amount - taxAmount.vat_amount) * value / multiple
        taxAmount.amountCHF = parseFloat(((taxAmount.amount * value) / multiple).toFixed(2));

        taxAmount.vat_amountCHF = parseFloat(
          ((taxAmount.vat_amount * value) / multiple).toFixed(2),
        );
      });
    }

    const fields = {
      taskId,
      merchant,
      date,
      currency,
      amounts,
      description,
      ...rate,
      costCenter,
      task_defination: readable ? 'compliance-check' : 'non-readable',
    };
    if (costCenter === undefined) {
      delete fields.costCenter;
    }

    dbTask
      .updateTask(fields)
      .then((res) => {
        taskDetails.task_defination = readable ? 'compliance-check' : 'non-readable';
        return Averix.extractData({
          extracted: readable,
          expenseId: taskId,
          extractedData: readable
            ? {
                // amount: formatNumber(amount),
                // vatAmount: formatNumber(vat_amount),
                // vatPercentage: parseInt(vat_percentage),
                // type,
                currency,
                merchantName: merchant,
                date,
              }
            : undefined,
        });
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.onChange({
          task: {
            ...taskDetails,
            date,
            taskId,
            amounts,
            ...rate,
          },
          success: 'Great! You have saved the data!',
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
      });
  }

  handleChange = (e) => {
    if (e.target.type === 'number' && numberCheckFails(e.target.value)) return;
    const task = { ...this.state.taskDetails, [e.target.name]: e.target.value };
    this.setState({ taskDetails: task });
  };
  handleDateChange = (date) => {
    const task = { ...this.state.taskDetails, date };
    this.setState({ taskDetails: task });
  };

  predict = () => {
    Logger.log('predict called', this.state.taskDetails);
    Predictions.sendPrediction(this.state.taskDetails.taskId).then((r) => {
      Logger.log('predict res: ', r);
    });
  };

  render() {
    const task = this.state.taskDetails; //.parashift.current_status !== 'done' ? this.state.taskDetails : Parashift.mapParashiftToTask(this.state.taskDetails);
    const errors = this.state.errors;

    const expenseTypes = this.state.expenseTypes;
    return (
      <Grid container spacing={3}>
        {/* {task.parashift === undefined ?
          <Grid item xs={12}><Button onClick={() => this.sendDoc(task.base64Value)}>send for automate extraction</Button></Grid> :
          task.parashift.current_status !== 'done' || this.state.parashiftSendRequest ?
            <Grid item xs={12}><span>Waiting for data to be delivered</span></Grid> :
            // <span>Waiting for data to be delivered <Button onClick={() => this.getDoc(task.parashift.id)}>get automate extraction result</Button> </span> :
          } */}
        <ParashiftDetails data={task} />

        {task.reimbursable !== undefined ? (
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Expense is proposed to be{' '}
              <strong>{task.reimbursable ? '' : 'non-'}reimbursable</strong>
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              name="merchant"
              label="Merchant"
              error={!!errors.merchant}
              helperText={errors.merchant}
              onChange={this.handleChange}
              value={task.merchant || ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                id="date-picker-inline"
                label="Date"
                autoOk
                maxDate={new Date()}
                value={task.date}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              name="amount_total"
              label="Total (with tax)"
              error={!!errors.amount_total}
              onChange={this.handleChange}
              value={task.amount_total ? task.amount_total : ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth error={!!errors.currency}>
            <InputLabel htmlFor="currency">Currency</InputLabel>
            <Select
              value={task.currency || ''}
              onChange={this.handleChange}
              name="currency"
              labelId="currency"
            >
              <MenuItem key="defaultCHF" value={'CHF'}>
                CHF
              </MenuItem>
              <MenuItem key="defaultEUR" value={'EUR'}>
                EUR
              </MenuItem>
              <MenuItem key="defaultUSD" value={'USD'}>
                USD
              </MenuItem>
              <MenuItem key="defaultJPY" value={'JPY'}>
                JPY
              </MenuItem>
              <MenuItem key="defaultGBP" value={'GBP'}>
                GBP
              </MenuItem>
              <span key="select">_________</span>
              {Object.keys(currencies).map((k) => {
                return (
                  <MenuItem key={k} value={k}>
                    {k} : {currencies[k].name}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.currency ? <FormHelperText>{errors.currency}</FormHelperText> : null}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              name="description"
              label="Description"
              onChange={this.handleChange}
              multiline
              value={task.description ? task.description : ''}
            />
          </FormControl>
        </Grid>
        {task.amounts
          ? task.amounts.map((element, idx) => (
              <TaxAmount
                key={idx}
                errors={errors.amounts ? errors.amounts[idx] : {}}
                onChange={this.updateAmount(idx)}
                amount={element}
                expenseTypes={expenseTypes}
                handleDelete={this.handleDelete(idx)}
                expenseTypePredictions={
                  task.expenseTypePrediction ? task.expenseTypePrediction : null
                }
              />
            ))
          : [...Array(this.state.splits).keys()].map((element) => (
              <TaxAmount
                key={element}
                errors={errors}
                onChange={this.updateAmount(element)}
                expenseTypes={expenseTypes}
              />
            ))}
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            onClick={() => {
              this.addPosition();
            }}
          >
            <CallSplitIcon style={{ transform: 'rotate(180deg)' }} />
            Split VAT
          </Button>
          {this.state.splits > 1 ? (
            <Button
              onClick={() => {
                this.setState({ splits: this.state.splits - 1 });
              }}
            >
              <CallMergeIcon />
              Remove last split
            </Button>
          ) : null}
        </Grid>

        <Grid item xs={4}>
          <SaveButton fullWidth onClick={() => this.updatetask(true)} loading={this.state.loading}>
            Save
          </SaveButton>
        </Grid>
        <Grid item xs={4}>
          <SaveButton fullWidth onClick={() => this.updatetask(false)} loading={this.state.loading}>
            Receipt is not readable
          </SaveButton>
        </Grid>
        <Grid item xs={4}>
          <LinkButton fullWidth to="/" color="muted">
            Cancel
          </LinkButton>
        </Grid>
        <Grid item xs={4}>
          <SaveButton fullWidth onClick={() => this.predict()} loading={this.state.loading}>
            Predict Types
          </SaveButton>
        </Grid>
      </Grid>
    );
  }
}
export default ExtractDataForm;
