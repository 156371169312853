import { createMuiTheme } from '@material-ui/core/styles'
import { spaceGroteskBold, spaceGroteskRegular } from './fonts'
import { red } from '@material-ui/core/colors';


const violet = '#bd2b82';
const grey = '#9a9fa8';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#321e60',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: '#0066ff',
      main: violet,
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff',
    },
    // error: will use the default color
  },
  typography: {
    fontFamily: "Space Grotesk",
    h2: {
      fontSize: 24,
      color: '#354052',
      fontWeight: 400
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [spaceGroteskRegular, spaceGroteskBold],
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#fff',
        '&$hover': {
          '&:hover': {
            backgroundColor: '#ffa4f02e'
          }
        },
        '&$selected': {
          backgroundColor: '#ffa4f00e'
        },
      },
      head: {
        backgroundColor: '#F5F8FA'

      }
    },
    MuiTable: {
      root: {
        border: '1px solid #E6EAEE'
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1rem'
      }
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        '&:before': {
          backgroundColor: red,
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        flexDirection: 'row-reverse',
        '&$expanded': {
          minHeight: 48
        }
      },
      content: {
        margin: '0 0 0 17px',
        letterSpacing: '0.565094px',
        fontWeight: 'bold',
        fontSize: 16,
        '&$expanded': {
          margin: '0 0 0 17px'
        },
      },
      expandIcon: {
        color: violet,
        paddingRight: 0,
        paddingLeft: 0
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff',
        fontSize: 14,
        '& fieldset': {
          //borderColor: 'greyLight',
          border:'none'
        },
        '&:hover fieldset': {
          borderColor: '#DFE3E9'
        },
        '&$focused fieldset': {
          borderColor: '#DFE3E9'
        }
      },
      input: {
        padding: '12px 10px 8px',
        '&$::placeholder':{
          color: grey
        }
      },
      adornedEnd: {
        paddingRight: 10,
        color: grey
      },
      focused: {}
    },
    MuiInputBase:{
      root:{
        '&$disabled':{
          backgroundColor: 'rgba(0,0,0,0.1)'
        }
      }
    },
    MuiTabs: {
      fixed:{
        overflow:'visible !important'
      }
    },
    MuiTab: {
      root: {
        background: 'linear-gradient(0deg, #F4F7FA 0%, #FFFFFF 100%)',
        border: '1px solid #dadada',
        borderBottom:'none',
        borderRadius: '6px 6px 0 0',
        fontSize: '14px',
        textTransform: 'none',
        marginRight: '4px',
        opacity: '1 !important',
        color: '#000',
        fontWeight: 'bold',
        padding: '3px 12px',
        marginTop:'6px',

        '&$selected': {
          background: '#fff',
          fontSize: '18px',
          padding: '6px 20px',
          marginTop:'0px',
        }
      }
    },
    PrivateTabIndicator: {
      root:{
        borderLeft: '1px solid #dedede',
        borderRight: '1px solid #dedede',
        backgroundColor: '#fff !important',
        bottom: '-1px',
        transition: 'none'
      }
    },
    // MuiInput:{
    //   'underline:after':{borderBottom: '10px'}
    // }
  },
});


export default theme;
