import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

class ApproverForm extends Component {
  constructor(props) {
    super(props);
    this.state = { details: props.data };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    const approverDetails = this.state.details;
    approverDetails[e.target.name] = e.target.value;
    this.setState({ details: approverDetails });
    this.props.onChange(approverDetails);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ details: this.props.data });
    }
  }

  render() {
    const data = this.state.details;
    return (
      <div className="form">
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Typography variant="h5">Responsible Person</Typography>
          </Grid>
          <Grid item xs={9} container spacing={4}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="firstName"
                label="firstName"
                InputProps={{ readOnly: this.state.isReadOnly }}
                onChange={(e) => this.handleChange(e)}
                value={data.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                InputProps={{ readOnly: this.state.isReadOnly }}
                name="lastName"
                label="Last Name"
                onChange={(e) => this.handleChange(e)}
                value={data.lastName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                         InputProps={{ readOnly: this.state.isReadOnly }}
                         name="emailId"
                         label='Email'
                         disabled={!this.props.newApprover}
                         onChange={e => this.handleChange(e)}
                         value={data.emailId}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default ApproverForm;
