import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const options = {
  train: ['First', 'Second'],
  plane: ['First', 'Business', 'Economy'],
  meal: ['Breakfast', 'Lunch', 'Dinner'],
  level: ['Low tier', 'Mid tier', 'High tier', 'Guest Gift']
}

class ExpenseAdditionalFields extends Component {

  constructor(props) {
    super(props);
    const { type } = props;
    this.state = {
      ...this.intialComponentState(type)
    };

    this.handleChange = this.handleChange.bind(this);
  }

  intialComponentState(type) {
    switch (type) {
      case "travel expense":
        return {
          train: '',
          plane: '',
          meal: undefined,
          level: undefined
        };
      case "food expense":
        return {
          meal: '', train: undefined, plane: undefined, level: undefined
        }
      case "lodging costs":
        return {
          level: '',
          train: undefined, plane: undefined, meal: undefined
        }
      default:
        return {train: undefined, plane: undefined, level: undefined, meal: undefined}
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.type !== this.props.type){
      this.setState(this.intialComponentState(this.props.type))
    }
  }

  handleChange(e){
    const a = {}
    a[e.target.name] = e.target.value;
    this.setState({...a});
    this.props.onChange(a);
  }


  render() {
    const state = this.state;
    const fields = Object.keys(state).map(f =>
      (state[f] !== undefined ? <FormControl key={f} fullWidth>
        <InputLabel htmlFor={f}>{f}</InputLabel>
        <Select value={state[f]} onChange={this.handleChange} name={f} labelId={f}>
          {options[f].map(k => {
            return (<MenuItem key={k} value={k}>{k}</MenuItem>)
          })}
        </Select>
      </FormControl> : null)
    )

    return fields.length > 0 ? <Grid item xs={12}>{fields}</Grid> : null
  }

}
export default ExpenseAdditionalFields;


