import React from 'react';

export const ImageComponent = ({ imagePreviewUrl }) => {
  if (imagePreviewUrl) {
    if (
      imagePreviewUrl.includes('.jpg') ||
      imagePreviewUrl.includes('.PNG') ||
      imagePreviewUrl.includes('.jpeg') ||
      imagePreviewUrl.includes('.png')
    ) {
      return (
        <img
          id="uploadedImage"
          src={imagePreviewUrl}
          align="middle"
          alt=""
          className="center"
        />
      );
    } else {
      // #view=FitH
      return (
        <object
          data={`${imagePreviewUrl}`}
          type="application/pdf"
          width="100%"
          height="600px"
          style={{ height: '-webkit-fill-available' }}
        />
      );
    }
  } else {
    return (
      <div className="previewText">Please select an Image for Preview</div>
    );
  }
};
