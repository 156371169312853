import React from 'react';
import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import logo from '../images/clexx1xc.png';
import logoW from '../images/clexx1x.png';

import Logout from '../components/Logout';
import './page.css'


export function  SmallPage(props) {

    const { title, style,  ...rest } = props;

    return (

        <Paper className="smallPaper" style={style}  {...rest}>
          <img id='logo' src={logoW} alt="clexx logo"
            align="middle"
            style={{ width: '83px', height: '79px', marginBottom: '32px', marginTop: '12px' }}
            className='center'
          />
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>

          {props.children}
        </Paper>

    );
}


function Page(props) {

  const { className, ...rest } = props;

  return (<Grid container spacing={2} style={{ margin: '0 auto', width: '929px' }}>
    <Grid item container xs={12} style={{ margin: '20px 0' }}>
      <Grid item xs={8}>
        <Link to="/"><img id='logo' src={logo} alt="clexx logo"
          align="middle"
          style={{ width: '108px', height: '105px' }}
          className='center'
        />
        </Link>
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right' }}>
        <Logout></Logout>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Paper className={"paper " + (className ? className : '')} {...rest}>{props.children}</Paper>
    </Grid>
  </Grid>
  );

}

export default Page;
