import Modal from "@material-ui/core/Modal";
import React from "react";

const ModalComponent =({statement, transaction, onClose, onSubmit, open, body}) => {
  // console.log('body', body);
  return(
    <Modal open={open}
           onClose={onClose}
           >{body}</Modal>
  );
}

export default ModalComponent;
