import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbars-custom';
import {calculateExpensesTotal, formatCurrencyToLocale} from '../navigationHelper';

const ExpenseView = ({ url, company, tasks }) => {
  const companyData = company ? company : {};

  const [totalSum, setTotalSum] = useState('0');
  const [totalSumReimbursable, setTotalSumReimbursable] = useState('0');
  const [employeeMap, setEmployeeMap] = useState({});
  const [employeeMapReimbursable, setEmployeeMapReimbursable] = useState({});
  const [empCount, setEmpCount] = useState({});

  useEffect(() => {
    if (tasks) {
      const calcVals = calculateExpensesTotal(tasks);
      setTotalSum(calcVals.totalSum);
      setTotalSumReimbursable(calcVals.totalSumReimbursable);
      setEmployeeMap(calcVals.emplMap);
      setEmployeeMapReimbursable(calcVals.emplMapReimbursable);
      setEmpCount(calcVals.empCount);
    }
  }, [tasks]);

  return (
    <Box display="flex" flexDirection="column" className="box-style expenseBox">
      <div className="title">
        <Typography variant="h6" className="grey2">
          Expense Approval
        </Typography>
        <Link
          to={{
            pathname: url,
            state: { showAll: true },
          }}
          style={{ textDecorationLine: 'none', alignSelf: 'flex-end' }}
        >
          <Typography className="purple">Show All</Typography>
        </Link>
      </div>

      <div className="borderDiv" />
      <div style={{ marginTop: '18px' }} />
      <div>
        <Typography>{companyData.companyName}</Typography>
        <Typography variant="h4" className="black">
          {totalSum}
        </Typography>
        <div className="marginS" />
        <Typography>Reimbursable:</Typography>
        <Typography variant="h5" className="red">
          {totalSumReimbursable}
        </Typography>
      </div>
      <div className="marginL" />

      <ScrollArea native>
        <Grid
          container
          spacing={0}
          style={{
            minWidth: '100%',
          }}
        >
          {Object.keys(employeeMap).map((e, idx) => {
            const t1 = employeeMap[e];
            const t1R = formatCurrencyToLocale(t1);
            const t2 = employeeMapReimbursable[e];
            const t2R = formatCurrencyToLocale(t2);
            const total = empCount[e];
            return (
              <div className="emplTable" key={`e-${idx}`}>
                <Grid item xs={5}>
                  <Typography className="black s">{e}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="blackalt s">{t1R}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="red s">{t2R}</Typography>
                </Grid>
                <Grid item xs={1} style={{ position: 'inherit' }}>
                  <Link
                    to={{
                      pathname: url,
                      state: { employeeFilter: e },
                    }}
                    style={{
                      textDecorationLine: 'none',
                      verticalAlign: 'baseline',
                    }}
                  >
                    <div className="circle-multiline">{total}</div>
                  </Link>
                </Grid>
              </div>
            );
          })}
        </Grid>
      </ScrollArea>
      {/*</div>*/}
    </Box>
  );
};
export default ExpenseView;
