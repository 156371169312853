import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


const AntSwitch = withStyles(theme => ({
  root: {
    width: 44,
    height: 22,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(22px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#BD2B82',
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: '#fff',
  },
  track: {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: '#BFBFBF',
  },
  checked: {},
}))(Switch);


const useStyles = makeStyles({
  root: {
    display:'flex',
    margin: '5px'
  },
  label: {
    fontSize:'16px',
    marginLeft: '10px'
  },
  switch:{
  }
});

function LabeledSwitch(props) {
  const { label, onChange, checked, ...rest } = props;

  const classes = useStyles(props)

  const [on, setOn] = useState(checked);

  const handleChange = event => {
    setOn(event.target.checked);
    onChange(event.target.checked);
  };

  return <div className={classes.root} {...rest}>
    <AntSwitch className={classes.switch} checked={on} onChange={handleChange}/>
    {label ? <label className={classes.label}>{label}</label> : null}
  </div>

}

export default LabeledSwitch;