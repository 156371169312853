
import dbEmployee from '../../firebasecomponents/employee';

const dbCard = dbEmployee.creditCards;

const CARD_UPDATED = "CARD_UPDATED";
const CARDS_LOADED = "CARDS_LOADED";
const ERROR = "CARDS_ERROR";

/** reducer */
export default function reducer(state = {}, action) {
  if (action.type === CARD_UPDATED) {
    return { ...state, error: undefined };
  }
  if (action.type === CARDS_LOADED) {
    return { ...state, creditCards: action.payload, error: undefined };
  }
  if (action.type === ERROR) {
    return { ...state, error: action.payload };
  }

  return state;
};

/** actions */

export function updateCreditCard(cardData, del = false) {
  return function (dispatch, getState) {
    let modifyFunction;
    if (del) {
      modifyFunction = dbCard.deleteCreditCard(cardData)
    }
    else if (cardData.id) {
      modifyFunction = dbCard.updateCreditCard(cardData)
    }
    else {
      const companyId = getState().CompanyReducer.currentCompany.companyId;
      modifyFunction = dbCard.addCreditCard(companyId, cardData)
    }
    modifyFunction.then((response) => dispatch({ type: CARD_UPDATED, payload: response }))
  };
};

export function getCreditCards(filter) {
  return function (dispatch) {
    if (filter.company) {
      dbCard.getCreditCards(filter.company, (response) => dispatch({ type: CARDS_LOADED, payload: response }))
    }
    // else if(filter.employee){
    //   dbCard.getEmployeeCreditCards(filter.employee, (response) => dispatch({ type: EMPLOYEE_CARDS_LOADED, payload: response }))
    // }
  };
};

export function modifyCardUser(card, userId) {
  return function (dispatch) {
      const cardData = {...card};
      if(cardData.users.includes(userId)){
        cardData.users = cardData.users.filter(u => u !== userId)
      } else {
        cardData.users.push(userId)
      }
      dbCard.updateCreditCard(cardData).then((response) => dispatch({ type: CARD_UPDATED, payload: response }))
    
  };
};
