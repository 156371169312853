import React from 'react';

export function TrashIcon(props){
  const src = require("../../assets/icons/trash.png");
  return <img src={src} alt="trash ico" {...props} style={{width:'16px', height:'17px'}}/>
}

export function PlusCircleIcon(props){
  const src = require("../../assets/icons/plus-circle.png");
  return <img src={src} alt="trash ico" {...props} style={{width:'24px', height:'24px'}}/>
}

export function MinusCircleIcon(props){
  const src = require("../../assets/icons/minus-circle.png");
  return <img src={src} alt="trash ico" {...props} style={{width:'24px', height:'24px'}}/>
}

export function ExportIcon(props){
  const src = require("../../assets/icons/export.png");
  return <img src={src} alt="export ico" {...props} style={{width:'37px', height:'34px'}}/>
}