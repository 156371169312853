import React from 'react';

import Grid from '@material-ui/core/Grid';

import MenuItem from '@material-ui/core/MenuItem';

import TextField from '../../../newComponents/TextField';
import Select from '../../../newComponents/Select';


const codePresets = require('../../../vatCodes.json');

const [m77, m37, m25,m00, i77, i37, i25,i00, acq] =
["7.7 Material and supplies of services",
  "3.7 Material and supplies of services",
  "2.5 Material and supplies of services",
  "0.0 Material and supplies of services",
  "7.7 Investments and other operating costs",
  "3.7 Investments and other operating costs",
  "2.5 Investments and other operating costs",
  "0.0 Investments and other operating costs",
  "Acquisition tax",
]

function VatCodePreset(props) {

  const data = props.data || {};

  const handleChange = (field) => (value) => {
    const modifiedName = data.preset ? data.preset.replace(' (modified)','') + ' (modified)' : 'custom';
    props.onChange({...data, [field]:value, preset: data.preset !== 'custom'? modifiedName: 'custom'})
  }

  const softwareChange = (name) =>{
    props.onChange({...codePresets[name], preset: name})
  }

  return <div className="vatCodes">
    <Grid container spacing={2}>
      <Grid item xs={3}>VAT Code Preset</Grid>
      <Grid item xs={3}>
        <Select
          onChange={softwareChange}
          value={data.preset || '-'}
        >
          <MenuItem value={'-'} disabled>{'Select Software'}</MenuItem>
          {Object.keys(codePresets).map((e, i) => <MenuItem key={i} value={e} >{e}</MenuItem>)}
          {data.preset && data.preset.includes('modified') ? <MenuItem value={data.preset} >{data.preset}</MenuItem>: null}
        </Select>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}><strong>Material and supplies of services</strong></Grid>
      <Grid item xs={3}><strong>Investments and other operating costs</strong></Grid>
      <Grid item xs={3}><strong>Acquisition tax</strong></Grid>

      <Grid item xs={3} className="right"><strong>7.7%</strong></Grid>
      <Grid item xs={3}>
        <TextField value={data[m77]} placeholder="Enter Code" onChange={handleChange(m77)} />
      </Grid>
      <Grid item xs={3}>
        <TextField value={data[i77]} placeholder="Enter Code" onChange={handleChange(i77)} />
      </Grid>
      <Grid item xs={3}></Grid>

      <Grid item xs={3} className="right"><strong>3.7%</strong></Grid>
      <Grid item xs={3}>
        <TextField value={data[m37]} placeholder="Enter Code" onChange={handleChange(m37)} />
      </Grid>
      <Grid item xs={3}>
        <TextField value={data[i37]} placeholder="Enter Code" onChange={handleChange(i37)} />
      </Grid>
      <Grid item xs={3}></Grid>

      <Grid item xs={3} className="right"><strong>2.5%</strong></Grid>
      <Grid item xs={3}>
        <TextField value={data[m25]} placeholder="Enter Code" onChange={handleChange(m25)} />
      </Grid>
      <Grid item xs={3}>
        <TextField value={data[i25]} placeholder="Enter Code" onChange={handleChange(i25)} />
      </Grid>
      <Grid item xs={3}></Grid>

      <Grid item xs={3} className="right"><strong>0.0%</strong></Grid>
      <Grid item xs={3}>
        <TextField value={data[m00]} placeholder="Enter Code" onChange={handleChange(m00)} />
      </Grid>
      <Grid item xs={3}>
        <TextField value={data[i00]} placeholder="Enter Code" onChange={handleChange(i00)} />
      </Grid>
      <Grid item xs={3}>
      <TextField value={data[acq]} placeholder="Enter Code" onChange={handleChange(acq)} />
      </Grid>
    </Grid>
  </div>
}

export default VatCodePreset;
