import React, { useState, useEffect } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpenseAdditionalFields from '../Policy/ExpenseAdditionalFields';
import isEmpty from '../../newHelpers/isEmpty';
import IconButton from '@material-ui/core/IconButton';
import { PlayArrow } from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Log from '../../newHelpers/Log';
const defaultExpenseTypes = require('../../expenses.json').expenses;
const Logger = new Log('TaxAmount');

function VatPercentage(props) {
  const [selected, setSelected] = useState(props.value || {});
  const values = { '7.7%': 7.7, '3.7%': 3.7, '2.5%': 2.5, '0.0%': 0, Bezugsteuer: 0 };

  const onSelect = (data) => () => {
    setSelected(data);
    props.onChange(data);
  };

  return (
    <div>
      {Object.entries(values).map(([k, v]) => (
        <Button
          size="small"
          variant={selected.name === k ? 'contained' : 'outlined'}
          key={k}
          disableElevation
          style={{ margin: '3px', padding: '3px 3px 0', minWidth: 0 }}
          onClick={onSelect({ name: k, value: v })}
        >
          {k}
        </Button>
      ))}
    </div>
  );
}

function TaxAmount(props) {
  Logger.log('taxAmount props', props);
  const errors = props.errors || {};
  const vatAmount = props.amount || {
    type: '',
    amount: 0,
    amountNoVat: 0,
    vat_amount: 0,
    vat_percentage: 0,
    policyFields: {},
  };

  // vatAmount.amountNoVat = +(vatAmount.amount - vatAmount.vat_amount).toFixed(2);
  // vatAmount.vat_percentage = vatAmount.vat_percentage !==0 ? vatAmount.vat_percentage : { name: '0.0%', value: 0.0 };
  // vatAmount.vat_percentage = vatAmount.vat_percentage.name !== '0%'? vatAmount.vat_percentage : { name: '0.0%', value: 0.0 };

  const [task, setTask] = useState(vatAmount);

  useEffect(() => {
    const vatAmount = props.amount ||
      task || {
        type: '',
        amount: 0,
        amountNoVat: 0,
        vat_amount: 0,
        vat_percentage: 0,
        policyFields: {},
      };

    vatAmount.amountNoVat = +(vatAmount.amount - vatAmount.vat_amount).toFixed(2);
    vatAmount.vat_percentage =
      vatAmount.vat_percentage !== 0 ? vatAmount.vat_percentage : { name: '0.0%', value: 0.0 };
    vatAmount.vat_percentage =
      vatAmount.vat_percentage.name !== '0%'
        ? vatAmount.vat_percentage
        : { name: '0.0%', value: 0.0 };
    setTask(vatAmount);
  }, [props]);

  const setField = (name, value) => {
    const newValue = { ...task, [name]: value };
    if (
      newValue.amount &&
      newValue.vat_percentage &&
      (name === 'amount' || name === 'vat_percentage')
    ) {
      const vat = newValue.vat_percentage.value;
      newValue.vat_amount = +((newValue.amount * vat) / (100 + vat)).toFixed(2);
      newValue.amountNoVat = (newValue.amount - newValue.vat_amount).toFixed(2);
    } else if (
      newValue.amountNoVat &&
      newValue.vat_percentage &&
      (name === 'amountNoVat' || name === 'vat_percentage')
    ) {
      Logger.log('amountNoVat', newValue.amountNoVat);
      const vat = newValue.vat_percentage.value;
      Logger.log('vat', vat);
      newValue.vat_amount = +(newValue.amountNoVat * (vat / 100)).toFixed(2);
      Logger.log('vat amount', newValue.vat_amount);
      newValue.amount = +(newValue.amountNoVat + newValue.vat_amount).toFixed(2);
      Logger.log('amount', newValue.amount);
    }

    setTask(newValue);
    props.onChange(newValue);
  };

  const handleChange = (e) => {
    setField(
      e.target.name,
      e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value,
    );
  };

  const handleSwap = (e) => {
    setField('amountNoVat', task.amount);
  };

  // const expenseTypes = props.expenseTypes || defaultExpenseTypes;
  let expenseTypes =
    props.expenseTypes && !isEmpty(props.expenseTypes) ? props.expenseTypes : defaultExpenseTypes;
  const expenseTypePredictions = props.expenseTypePredictions;
  let expensePredictionMatch;
  let expenseTypesScores = {};
  if (expenseTypePredictions) {
    const match = expenseTypePredictions.find((x) => x.title === props.amount.item_name);
    if (match) {
      expensePredictionMatch = match.predictions;
      expenseTypes.map((e) => {
        const scoreMatch = expensePredictionMatch.find((x) => e === x.type);
        expenseTypesScores[e] = scoreMatch ? scoreMatch.score : 0;
      });
    }

    expenseTypes = expenseTypes.sort((a, b) => {
      return expenseTypesScores[b] - expenseTypesScores[a];
    });
    Logger.log('expense types sc', expenseTypesScores);
    Logger.log('expense types', expenseTypes);
  }

  return (
    <div
      style={{
        padding: '10px',
        border: '1px solid #eaeaea',
        borderRadius: '5px',
        margin: '0px 0px 5px 20px',
        backgroundColor: '#fafafa',
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {/*{vatAmount.item_name ? <span>{vatAmount.item_name}</span> : null}*/}
          <TextField
            value={vatAmount.item_name}
            label="Item Name"
            fullWidth
            multiline
            name="item_name"
            onChange={handleChange}
          />
          <FormControl fullWidth error={!!errors.type}>
            <InputLabel htmlFor="type-of-expense">Type of expense</InputLabel>
            <Select
              value={task.type || ''}
              onChange={handleChange}
              name="type"
              labelId="type-of-expense"
              autoWidth
            >
              {expenseTypes && expenseTypePredictions
                ? expenseTypes.map((e) => {
                    return (
                      <MenuItem key={e} value={e}>{`${e} - ${
                        expenseTypesScores[e] ? expenseTypesScores[e] : ''
                      }`}</MenuItem>
                    );
                  })
                : expenseTypes.map((e) => {
                    return (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
              <MenuItem value="Other">Other</MenuItem>
            </Select>
            {errors.type ? <FormHelperText>{errors.type}</FormHelperText> : null}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <VatPercentage
            value={task.vat_percentage}
            onChange={(data) => setField('vat_percentage', data)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              type="number"
              name="amount"
              label="Amount (with tax)"
              error={!!errors.amount}
              helperText={errors.amount}
              onChange={handleChange}
              value={task.amount || ''}
              inputProps={{ min: '0', step: '0.01' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="secondary" style={{ marginLeft: '-10px' }} onClick={handleSwap}>
            <PlayArrow />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              type="number"
              name="amountNoVat"
              label="Amount (without tax)"
              error={!!errors.amountNoVat}
              helperText={errors.amountNoVat}
              onChange={handleChange}
              value={task.amountNoVat || 0}
              inputProps={{ min: '0', step: '0.01' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              type="number"
              name="vat_amount"
              label="VAT Amount"
              error={!!errors.vat_amount}
              helperText={errors.vat_amount}
              onChange={handleChange}
              value={task.vat_amount}
              inputProps={{ min: '0', max: task.amount, step: '0.01' }}
              disabled
            />
          </FormControl>
        </Grid>

        <ExpenseAdditionalFields
          onChange={(data) => setField('policyFields', data)}
          type={task.type}
        />
        {props.handleDelete ? (
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button onClick={() => props.handleDelete()}>
              <DeleteForeverIcon />
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default TaxAmount;
