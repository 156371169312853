
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import task from '../../firebasecomponents/task';
import employee from '../../firebasecomponents/employee';

import './Companytasklist.css';

import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import GenerateReport from '../../components/GenerateReport';
import Loading from '../../components/Loading';
import { LinkButton } from '../../components/Buttons';
import { TaskOverview } from '../../components/TaskDetails';
import firebase from '../../components/firebase';

import Page from '../../pages/page.js';

class Companytasklist extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameOfTheEmployees: null,
      tasks: null,
      companyId: undefined,
      companyName: undefined,
      years: [],
      openDownload: false,
      mainPage: true,
      task: null
    }

    this.back = this.back.bind(this);
    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
  }

  back = () => {
    this.props.history.goBack();
  }

  componentDidMount() {
    let companyId, companyName;
    if (this.props.match === undefined || this.props.match.params.companyId === undefined) {
      companyId = firebase.getCurrentUserId();
      companyName = firebase.getCurrentUsername();
    } else {
      companyId = this.props.match.params.companyId;
      companyName = this.props.match.params.companyName;
      this.setState({ mainPage: false })
    }

    this.setState({ companyId, companyName })

    task.getTaskBasedOnComapnyId({ companyId }, response => {

      const years = [];
      response.forEach(t => {
        const y = (new Date(Date.parse(t.date))).getFullYear();

        if (!years.includes(y) && !isNaN(y)) {
          years.push(y);
        }
      });

      this.setState({ tasks: response, years });

    });

    employee.getEmployeeNames({ companyId }, response => {
      this.setState({ nameOfTheEmployees: response });
    });
  }

  // prepareTasks (response){

  // }

  accept(id) {
    return () => {
      this.setState({ task: null })
      task.updateTask({
        taskId: id,
        approved: true,
        task_defination: 'reimbursability-check'
      })
    }
  }
  decline(id) {
    return () => {
      this.setState({ task: null })
      task.updateTask({
        taskId: id,
        approved: false,
        task_defination: 'manager-declined'
      })
    }
  }

  render() {
    const employeeNames = this.state.nameOfTheEmployees ? this.state.nameOfTheEmployees.map(employee =>
      (<LinkButton
        color='primary'
        style={{ minWidth: 800 }}
        to={`/employee/${employee.emailid}`}
        key={employee.id}
        value={employee.name}
      >{employee.name}</LinkButton>)
    ) : null;

    const tasks = this.state.tasks ? this.state.tasks.map(task =>
      (<LinkButton
        color='primary'
        style={{ minWidth: 800 }}
        to={'/task/' + task.id}
        key={task.id}
        value={task.task_defination}
      >{task.task_defination} - {task.empl_name} [created:{task.createdAt} | date:{task.date}]</LinkButton>)
    ) : null;

    const managerApproval = this.state.tasks && this.state.mainPage ? this.state.tasks
      .filter(task => task.task_defination === 'manager-approval')
      .map(task => {
        const compliant = task.compliant === true ? 'ACCEPTED' : task.compliant === false ? 'DECLINED' : 'CONTEXT INFO NEEDED'
        return <ListItem button key={task.id} selected={this.state.task && task.id === this.state.task.id}>
          <ListItemText
            primary={`${compliant} - ${task.empl_name} [created:${task.createdAt} | date:${task.date}]`}
            onClick={() => this.setState({ task })}
          />
        </ListItem>
      }) : null;

    const task = this.state.task ? (
      <TaskOverview task={this.state.task} managerApproval={<div><Tooltip title="Accept" placement="top">
        <Button size="small" color="primary" style={{ marginRight: '10px' }} onClick={this.accept(this.state.task.id)}>
          <CheckIcon />Accept
      </Button>
      </Tooltip>
        <Tooltip title="Decline" placement="top">
          <Button size="small" color="secondary" onClick={this.decline(this.state.task.id)} >
            <ClearIcon />Decline
        </Button>
        </Tooltip></div>} />) : null;

    const years = this.state.years ? this.state.years.map(y =>
      (<LinkButton
        color='primary'
        style={{ minWidth: 800 }}
        to={'/monthOverview/' + this.state.companyId + '/' + y}
        key={y}
      >{y}</LinkButton>)
    ) : null;

    return (
      <Page>
        <div className='left' style={{ textAlign: 'left', marginBottom: '20px' }}>
          {this.state.mainPage ? null :
            <IconButton onClick={this.back} style={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>}
          <h2 style={{ color: '#c0548e' }}>
            {this.state.companyName}
            <LinkButton to={'/company/profile/' + this.state.companyId} className="settingsButton">
              <SettingsIcon />View profile
              </LinkButton>
            <Button onClick={() => this.setState({ openDownload: true })} className="settingsButton"><GetAppIcon />Download</Button>
          </h2>
        </div>
        <LinkButton color='secondary' to={'/employee/add/' + this.state.companyId + '/' + this.state.companyName} className="addButton">
          <AddIcon />Add Employee
        </LinkButton>
        <LinkButton color='primary' to={'/company/managerApproval/' + this.state.companyId + '/' + this.state.companyName}  className="addButton" style={{ marginLeft:'10px'}}>
          Manager Approval Page
        </LinkButton>
        <LinkButton color='primary' to={`/company/invoiceManagement/${this.state.companyId}/${this.state.companyName}`}  className="addButton" style={{ marginLeft:'10px'}}>
          Invoice Management
        </LinkButton>

        <Tabs>
          <TabList>
            <Tab>Tasks</Tab>
            {this.state.mainPage ? <Tab><strong>Manager Approval</strong></Tab> : null}
            <Tab>Employee</Tab>
            <Tab>Years Overview</Tab>
          </TabList>
          <TabPanel>
            <Loading loading={!tasks} />
            {tasks}
          </TabPanel>
          {this.state.mainPage ? <TabPanel>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <List>{managerApproval}</List>
              </Grid>
              <Grid item xs={8}>
                {task}
              </Grid>
            </Grid>
          </TabPanel>
            : null}
          <TabPanel>
            <Loading loading={!employeeNames} />
            {employeeNames}
          </TabPanel>
          <TabPanel>
            {years}
          </TabPanel>
        </Tabs>
        {this.state.openDownload ? <GenerateReport companyId={this.state.companyId} closed={() => this.setState({ openDownload: false })} /> : null}
      </Page>
    );
  }
}

export default Companytasklist;
