import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { SaveButton, LinkButton, ActionButton } from '../Buttons';

import task from '../../firebasecomponents/task';

import { Averix } from '../../externalAPI';
import TextField from "../../newComponents/TextField";

class PaymentConfirmationForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      taskDetails: this.props.task,
      loading: false
    };

    this.updatetask = this.updatetask.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  async updatetask() {
    this.setState({ loading: true });

    const taskDetails = this.state.taskDetails;
    const { taskId } = taskDetails;

    task.updateTask({
      taskId,
      task_defination: 'payment-done'
    }).then(res => {
      taskDetails.task_defination = 'payment-done';
      return Averix.paymentconfirmationCheck({
        executed:true,
        expenseId: taskId
      });
    }).then(res => {
      this.setState({ loading: false });
      this.props.onChange({ task: taskDetails, success: 'You have confirmed payment!' });
    }).catch(err => {
      this.setState({ loading: false });
      this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
    });
  }

  async cancel () {
    console.log('cancel')
    this.setState({ loading: true });

    const taskDetails = this.state.taskDetails;
    const { taskId } = taskDetails;

    task.updateTask({
      taskId,
      task_defination: 'arrange-payment',
      topalState:'-'
    }).then(res => {
      taskDetails.task_defination = 'arrange-payment';
      this.setState({ loading: false });
    }).catch(err => {
      this.setState({ loading: false });
      this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
    });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1> Is Payment done ?</h1>
        </Grid>
        {this.state.taskDetails.fibuError ?
          <Grid item xs={12}>
            <TextField label={'Fibu Error:'} value={this.state.taskDetails.fibuError} disabled/>
          </Grid>: null}
        <Grid item xs={12}>
          <SaveButton fullWidth loading={this.state.loading} onClick={this.updatetask}>Confirm that payment is done</SaveButton>
        </Grid>
        <Grid item xs={6}>
          <ActionButton fullWidth style={{color:'red', marginBottom:'5px'}} color='muted' onClick={this.cancel}>Back To Arrange-Payment</ActionButton>
          <LinkButton fullWidth to='/' color="muted">Go back to task list</LinkButton>
        </Grid>
      </Grid>
    );
  }
}
export default PaymentConfirmationForm;


