import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'

import MsTeams from '../../externalAPI/MsTeams'
import { SmallPage } from '../page'
import { CircularProgress } from '@material-ui/core';

function ConnectionConfirm(props) {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token')

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    MsTeams.confirm(token)
      .then(() => setLoading(false))
      .catch(err => {
        setLoading(false)
        console.log(err, typeof err)
        setError(err)
      })
  }, [])

  if (token === undefined) {
    return <SmallPage>Token not found</SmallPage>
  }
  if (error !== '') {
  return <SmallPage>Error: {error}</SmallPage>
  }

  return (
    <SmallPage>
      {loading ? <CircularProgress /> :
        <>
          <p style={{ textAlign: 'center', marginBottom: '20px' }}><strong>Thank you!</strong></p>
          <p style={{ marginBottom: '20px' }}>You have confired connection between your Clexx and Ms Teams accounts.</p>
          <p>Now you can upload expensed through
      <Button
              type="submit"
              fullWidth
              variant="contained"
              target="_blank"
              href="https://teams.microsoft.com/"
              color="secondary">
              Clexx Bot
      </Button>
          </p>
        </>
      }
    </SmallPage>
  )
}

export default ConnectionConfirm;