import React from 'react';

import Slide from '@material-ui/core/Slide';

import './drawer.scss'


function Drawer(props) {
  const { direction, opened, children, style } = props



  const lineClass = {
    up: 'horizontal bottom left',
    down: 'horizontal top left',
    left: 'vertical right top',
    right: 'vertical left top'
  }[direction];

  return <Slide direction={direction} in={opened} mountOnEnter unmountOnExit style={style}>
    <div className={'drawer ' + lineClass}>
      {children}
    </div>
  </Slide>
}

export default Drawer
