import React from 'react'
import { Button } from '@material-ui/core'

import { withRouter } from 'react-router-dom';

import firebase from '../firebase'

function Logout(props) {

  return (
    <Button
      type="submit"
      variant="contained"
      color="secondary"
      onClick={logout}
      style={{ 'margin': '08px', minWidth: '96px!important' }}
    >
      Logout
    </Button>
  )

  async function logout() {
    await firebase.logout();
    props.history.push('/')
    window.location.reload()
  }
}

export default withRouter(Logout);
