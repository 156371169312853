import firebase from '../components/firebase';
const db = firebase.db;

const Reports = {
  deleteReport(report) {
    console.log('report', report);
    const type= report.type;
    console.log('type', type);
    const tagType = type === 'Booking Report' ? 'tagBookingReport' :
      type === 'Employee Report' ? 'tagEmployeeReport' :
        type === 'Project Report' ? 'tagProjectReport' :
          type === 'Customer Report' ? 'tagCustomerReport':
            'tag';
    console.log('type', tagType);

    return new Promise((resolve, reject) => {
      const tasks = report.tasks;
      if (tasks && tasks.length > 0) {
        console.log('has tasks');
        tasks.map((item) => {
          console.log('item', item);
          db.collection('task')
            .doc(item)
            .update({ [tagType]: '' })
            .catch((err) => {
              console.error('deleting tag went wrong');
              reject(err);
            });
        });
      }
      console.log('deleteReport', report);
      db.collection('reports')
        .doc(report.id)
        .delete()
        .then((res) => {
          console.log('document successfully deleted');
          resolve(res);
        })
        .catch(function (error) {
          console.error('Error removing document: ', error);
        });
    });
  },

  updateReport(report) {
    return new Promise((resolve, reject) => {
      db.collection('reports')
        .doc(report.id)
        .update(report)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },

  addReport(report) {
    return new Promise((resolve, reject) => {
      db.collection('reports')
        .add({ ...report, downloaded: false })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};

export default Reports;
