import React from 'react';

import { CircularProgress, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  label: {
    position: 'absolute',
    color: '#321e60',
    top: '10px',
    left: '0',
    width: '100%',
    textAlign: 'center'
  },
});


function Progress(props) {
  const classes = useStyles(props);

  return <div className={classes.root}>
    <CircularProgress value={props.progress} variant="static"/>
    <Typography className={classes.label}>{Math.round(props.progress)}</Typography>
  </div>
}

export default Progress;