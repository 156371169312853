import task from '../../firebasecomponents/task';


//due to async of componentWillUnmount, even though the tasks get cleared from previous pages that reuse tasks
// from redux, the clearing might happen after the component had been mounted, thus leading to rendering errors.
// since refactoring manager approval lead to task inconsistencies due to timing,
// expenses was added alongside tasks to avoid these issues (time constraints).

const EXPENSE_LOADED = "EXPENSE_LOADED";
const EXPENSE_UPDATED = "EXPENSE_UPDATED";
const CLEAR_DONE = "TASKS_CLEAR_DONE";
const CLEAR_EXPENSES = "CLEAR_EXPENSES";

/** reducer */
export default function reducer(state = {}, action) {

  if (action.type === EXPENSE_LOADED) {
    return { ...state, expenses: action.payload };
  }
  if (action.type === EXPENSE_UPDATED) {
    return { ...state, done: true };
  }

  if (action.type === CLEAR_EXPENSES) {
    state.expenses=undefined;
    return { ...state };
  }
  return state;
};


/** actions */

export function getExpenses(companyId, filter) {
  return function (dispatch) {
    task.getTaskBasedOnComapnyId({ companyId, filter }, response => dispatch({ type: EXPENSE_LOADED, payload: response }));
  };
};

export function updateExpenses(tasksArray) {
  return function (dispatch) {
    const promises = tasksArray.map(taskDetails => task.updateTask(taskDetails));

    Promise.all(promises)
      .then((res) => {
        dispatch({ type: EXPENSE_UPDATED, payload: res });
      })
  };
};

export function updateExpense(taskDetails) {
  return function (dispatch) {
    task.updateTask(taskDetails)
      .then((res) => {
        dispatch({ type: EXPENSE_UPDATED, payload: res });
      })
  };
};

export function clearExpenses() {
  console.log('clear tasks')
  return function (dispatch) {
    dispatch({ type: CLEAR_EXPENSES, payload: {} });
  };
};
