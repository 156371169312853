import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline, CircularProgress } from '@material-ui/core';

import Page404 from '../pages/Page404'
import Login from '../pages/AuthPages/Login';
import Register from '../pages/AuthPages/Register';

import firebase from '../components/firebase';

import Dashboard from '../pages/Dashboard';
import AccountantTaskList from '../pages/AccountanttaskList'
import CompanyTaskList from '../pages/CompanytaskList'

import EmployeDetails from '../pages/EmployeeDetails';

import editemployee from '../pages/EditEmployee';
import CompanyProfile from '../pages/CompanyProfile'

import AccountantProfile from '../pages/AccountantProfile'
import MonthOverview from '../pages/MonthOverview';

import CompanyDashboard from '../pages/Dashboard/Company';

import newCompanyManagerApproval from '../newPages/ManagerApproval';
import newCompanyCustomization from '../newPages/Customization';
import newCompanySettings from '../newPages/Settings';
import newCompanyEmployees from '../newPages/Employees';
import newCompanyReports from '../newPages/Reports';
import newCompanyStatements from '../newPages/Statements';
import newCompanyQuestions from '../newPages/Questions';
import newCompanyReconciliation from '../newPages/Reconciliation';
import newCompanyNavigationDashboard from '../newPages/NavigationDashboard';
import newCompanyInvoiceManagement from '../newPages/InvoiceManagement/InvoiceManagement';
import newCompanyTaskOverview from '../newPages/Overview';

import { TaskDetails, AddTask, SyncTask } from '../pages/Task';
import ConnectionConfirm from '../pages/ConnectionConfirm';

import {authStateChanged} from '../store/actions'

import 'react-tabs/style/react-tabs.css';
import './styles.css'


import theme from './theme'
import AddInvoicePage from "../newPages/InvoiceManagement/components/AddInvoicePage";
import AddedInvoiceDetails from "../newPages/InvoiceManagement/components/AddedInvoiceDetails";
import Log from "../newHelpers/Log";
const Logger = new Log('App/index.js');


function RoleRoute(props) {
  return (
    props.roles.includes(props.role) ?
      <Route {...props} /> :
      <Redirect to={{ pathname: '/' }} />
  );
}

function App(props) {

  const [firebaseInitialized, setFirebaseInitialized] = useState(false)

  useEffect(() => {
    firebase.auth.onAuthStateChanged((u) => {
      Logger.log('useeffect index.js auth changed')
      props.authStateChanged();
    })
  })

  const role = props.role;
  if(role !== undefined && !firebaseInitialized){
    setFirebaseInitialized(true)
  }
  Logger.log('role: ', role)
  return firebaseInitialized ? (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path='/' exact component={Dashboard} />
          <Route path='/login' exact component={Login} />
          <Route path='/register' exact component={Register} />

          <RoleRoute role={role} path='/accountant/add' exact component={AccountantProfile} roles={['Employee', 'Company']} />
          <RoleRoute role={role} path='/accountant/profile/:accountantId' exact component={AccountantProfile} roles={['Employee', 'Accountant', 'Company']} />
          <RoleRoute role={role} path='/accountanttasklist' exact component={AccountantTaskList} roles={['Accountant']} />
          <RoleRoute role={role} path='/accountanttasklist/:accountantId' exact component={AccountantTaskList} roles={['Employee', 'Company']} />

          <RoleRoute role={role} path='/employee/:emplId' exact component={EmployeDetails} roles={['Employee', 'Accountant', 'Company']} />
          <RoleRoute role={role} path='/employee/profile/:emplId' exact component={editemployee} roles={['Employee', 'Accountant', 'Company']} />
          <RoleRoute role={role} path='/employee/add/:companyId/:companyName' exact component={editemployee} roles={['Accountant', 'Company', 'Employee']} />

          <RoleRoute role={role} path='/company' exact component={CompanyDashboard} roles={['Accountant', 'Employee', 'Company', 'Approver']} />
          <RoleRoute role={role} path='/company/profile/:companyId' exact component={CompanyProfile} roles={['Accountant', 'Employee', 'Company', 'Approver']} />

          {/*not needed anymore since this would mean that in the old page view we can add a new company. keep it if want to do this later on again*/}
          {/*<RoleRoute role={role} path='/company/add' exact component={CompanyProfile} roles={['Accountant', 'Employee', 'Approver']} />*/}

          <RoleRoute role={role} path='/company/add/:accountantId' exact component={CompanyProfile} roles={['Accountant', 'Employee', 'Approver']} />
          <RoleRoute role={role} path='/companytasklist' exact component={CompanyTaskList} roles={['Company', 'Approver']} />
          <RoleRoute role={role} path='/companytasklist/:companyId/:companyName' exact component={CompanyTaskList} roles={['Employee', 'Accountant', 'Approver']} />

          <RoleRoute role={role} path='/addtask' exact component={AddTask} roles={['Employee']} />
          <RoleRoute role={role} path='/task/:taskId' exact component={TaskDetails} roles={['Employee']} />
          <RoleRoute role={role} path='/syncExpenses' exact component={SyncTask} roles={['Employee']} />

          <RoleRoute role={role} path='/monthOverview/:companyId/:year' exact component={MonthOverview} roles={['Accountant', 'Employee', 'Company']} />

          <RoleRoute role={role} path='/company/managerApproval' exact component={newCompanyManagerApproval} roles={['Company', 'Approver']} />
          <RoleRoute role={role} path='/company/managerApproval/:companyId/:companyName' exact component={newCompanyManagerApproval} roles={['Employee', 'Approver']} />

          <RoleRoute role={role} path='/company/overview' exact component={newCompanyTaskOverview} roles={['Approver', 'Company']} />
          <RoleRoute role={role} path='/company/overview/:companyId/:companyName' exact component={newCompanyTaskOverview} roles={['Employee', 'Approver', 'Company']} />

          <RoleRoute role={role} path='/company/settings' exact component={newCompanySettings} roles={['Company']} />
          <RoleRoute role={role} path='/company/settings/:companyId/:companyName' exact component={newCompanySettings} roles={['Employee']} />

          <RoleRoute role={role} path='/company/customization' exact component={newCompanyCustomization} roles={['Company']} />
          <RoleRoute role={role} path='/company/customization/:companyId/:companyName' exact component={newCompanyCustomization} roles={['Employee']} />

          <RoleRoute role={role} path='/company/employees' exact component={newCompanyEmployees} roles={['Company']} />
          <RoleRoute role={role} path='/company/employees/:companyId/:companyName' exact component={newCompanyEmployees} roles={['Employee']} />

          <RoleRoute role={role} path='/company/reports' exact component={newCompanyReports} roles={['Company']} />
          <RoleRoute role={role} path='/company/reports/:companyId/:companyName' exact component={newCompanyReports} roles={['Employee']} />

          <RoleRoute role={role} path='/company/statements' exact component={newCompanyStatements} roles={['Company']} />
          <RoleRoute role={role} path='/company/statements/:companyId/:companyName' exact component={newCompanyStatements} roles={['Employee']} />

          <RoleRoute role={role} path='/company/questions' exact component={newCompanyQuestions} roles={['Company']} />
          <RoleRoute role={role} path='/company/questions/:companyId/:companyName' exact component={newCompanyQuestions} roles={['Employee']} />

          <RoleRoute role={role} path='/company/dashboard' exact component={newCompanyNavigationDashboard} roles={['Company']}/>
          <RoleRoute role={role} path='/company/dashboard/:companyId/:companyName' exact component={newCompanyNavigationDashboard} roles={['Employee', 'Company']} />

          <RoleRoute role={role} path='/company/invoiceManagement' exact component={newCompanyInvoiceManagement} roles={['Company']}/>
          <RoleRoute role={role} path='/company/invoiceManagement/:companyId/:companyName' exact component={newCompanyInvoiceManagement} roles={['Employee', 'Company']}/>

          <RoleRoute role={role} path='/company/invoiceManagement/:companyId/:companyName/addInvoice' exact component={AddInvoicePage} roles={['Employee', 'Company']}/>
          <RoleRoute role={role} path='/company/invoiceManagement/:companyId/:companyName/invoice/:invoiceId' exact component={AddedInvoiceDetails} roles={['Employee', 'Company']}/>

          <RoleRoute role={role} path='/company/statements/:id/reconciliation/:companyId/:companyName' exact component={newCompanyReconciliation} roles={['Employee']} />

          <Route path='/connectionConfirm' component={ConnectionConfirm} />
          <Route path='*' exact component={Page404} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  ) : <div id='loader'><CircularProgress /></div>
}

const mapStateToProps = state => {
  return {
    role: state.UserReducer.currentRole
  };
}

export default connect(mapStateToProps, { authStateChanged })(App);
