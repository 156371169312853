import React, { Component } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react'

import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import Compressor from 'compressorjs';


import { MsTeams } from '../../externalAPI';

import Form from '../../components/Form';
import { SaveButton, LinkButton } from '../../components/Buttons';
import EmployeeAutoSuggest from '../../components/EmployeeAutoSuggest';


import employee from '../../firebasecomponents/employee';
import task from '../../firebasecomponents/task';
import Storage from '../../firebasecomponents/storage';
import Page from '../page';

class AddTask extends Component {

  constructor(props) {
    super(props);
    this.state = {
      taskId: null,
      base64: '',
      base64Intial: 'data:image/jpeg;base64,',
      file: '', imagePreviewUrl: '',
      reimbursable: true,
      employee: null,
      success: null,
      error: null,
      loading: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.taskSubmitClick = this.taskSubmitClick.bind(this);
    this.reimbursableChange = this.reimbursableChange.bind(this);
    this.selectEmployee = this.selectEmployee.bind(this);
  }

  reimbursableChange = () => {
    this.setState({ reimbursable: !this.state.reimbursable });
  }

  handleChange(e) {
    if (e.success) {
      this.setState({ success: e.success });
    }
    if (e.error) {
      this.setState({ error: e.error });
    }
    this.setState({ taskDetails: e.task });
  }

  selectEmployee(employee) {

  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    const self = this;

    reader.onloadend = () => {
      self.setState({
        file: file.name,
        imagePreviewUrl: reader.result
      });
    }


    // handle large images
    if (file['type'].split('/')[0] === 'image') {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 700,
        success(result) {
          if (result.size > 10485760) {
            self.setState({ error: "File is too big" });
          } else {
            self.setState({ error: null });
            const a = new FileReader();
            a.onloadend = () => {
              self.setState({
                file: file.name,
                imagePreviewUrl: a.result
              });
            };
            a.readAsDataURL(result);
          }
        },
        error(err) {
          this.setState({ error: err.message });
        },
      });

    } else { //handle pdf
      if (file['type'].split('/')[1] !== 'pdf') {
        self.setState({ error: "We support only images and PDF files" });
      }
      else if (file.size > 10485760) {
        self.setState({ error: "Pdf file is too big" });
      } else {
        this.setState({ error: null });
        reader.readAsDataURL(file)
      }
    }
  };

  taskSubmitClick() {
    this.setState({ loading: true });
    let selectedValue = document.getElementById('autoSuggestEnteredValue').value;

    let request = {};
    request.selectedValue = document.getElementById('autoSuggestEnteredValue').value;
    request.companyName = selectedValue.substring(0, selectedValue.indexOf(","));
    request.emplName = selectedValue.substring(selectedValue.indexOf(",") + 1);

    employee.getEmployeeByNameAndCompanyName(request, response => {

      let taskDetails = {
        // taskId: "C-" + Math.random().toString(36).substring(2, 16),
        //accountant_id: response[0].accountId,
        company_name: response[0].companyName,
        company_id: response[0].companyId,
        empl_emailId: response[0].emailid,
        task_defination: 'extract-data',
        empl_name: response[0].firstName + ' ' + response[0].lastName,
        // project: '',
        // amount: '',
        // date: '',
        // currency: '',
        // vat_amount: '',
        // vat_percentage: '',
        deliverymethod: 'clexx',
        reimbursable: this.state.reimbursable,
        base64Value: this.state.imagePreviewUrl.length
      }

      console.log("imageurl : " + this.state.imagePreviewUrl)

      // const averixParams = {
      //   content: this.state.imagePreviewUrl, //.replace(/^data:image\/(png|jpg);base64,/, ''),
      //   filename: this.state.file,
      //   documentType: "Expense receipt",
      //   usermail: taskDetails.empl_emailId,
      //   deliveryMethod: "clexx",
      //   processId: taskDetails.taskId
      // }

      // Averix.uploadDocument(averixParams)
      //   .then(res =>

      Storage.upload('expenses/' + taskDetails.company_id, { name: this.state.file, data: this.state.imagePreviewUrl },
        (progress) => {
          this.setState({ progress })
        })
        .then(url => task.addTask({ ...taskDetails, base64Value: url }))
        .then(task => MsTeams.newTaskNotification(task))
        .then(task => {
          this.setState({ loading: false, success: 'You have uploaded this document' });
          setTimeout(() => this.props.history.replace('task/' + task.taskId), 1500);
        })
        .catch(err => {
          this.setState({ loading: false, error: JSON.stringify(err) });
        });

    });
  };

  render() {

    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = imagePreviewUrl.includes('image')  ||
      imagePreviewUrl.includes('.jpg') ||
      imagePreviewUrl.includes('.jpeg') ||
      imagePreviewUrl.includes('.PNG') ||
      imagePreviewUrl.includes('.png') ?
       (<img id='uploadedImage' src={imagePreviewUrl} border='1'
        align="middle" alt=''
        style={{ width: '341px', height: '550px' }}
        className='center'
      />) : <PDFViewer document={{ url: imagePreviewUrl }} />
      //(<embed src={imagePreviewUrl} type="application/pdf" />);
    } else {
      $imagePreview = (<div className="previewText">Please select an Image for Preview [Max size 200KB]</div>);
    }

    return (
      <Page>
        <Form success={this.state.success} error={this.state.error}>
          <FormLabel className='left' style={{ textAlign: 'left', margin: '10px 0px 30px 0px', display: 'block' }}>
            <h2 style={{ color: '#c0548e' }}>Add new task</h2>
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <EmployeeAutoSuggest onSelect={this.selectEmployee}></EmployeeAutoSuggest>
              <FormControlLabel style={{ marginLeft: '10px!important', marginBottom: '30px' }}
                control={
                  <Checkbox checked={this.state.reimbursable}
                    onChange={() => this.reimbursableChange()}
                  />
                }
                label=" Reimbursable ?"
              />
              <br />
              <SaveButton fullWidth loading={this.state.loading}
                disabled={!imagePreviewUrl}
                onClick={this.taskSubmitClick}>Submit</SaveButton>
              <LinkButton fullWidth to='/' color="muted">Cancel</LinkButton>
            </Grid>

            <Grid item xs={6}>
              <div className="previewComponent">
                <form>
                  <input className="fileInput"
                    type="file"
                    //accept="image/*"
                    onChange={(e) => this.handleImageChange(e)} id='upload'
                  // value = ''
                  />
                </form>
                {/* <div className="imgPreview"> */}
                <div className="">
                  {$imagePreview}

                </div>
              </div>
            </Grid>
          </Grid>
        </Form>
      </Page>
    );
  }
}
export default AddTask;

