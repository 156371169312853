import React from 'react'

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

import CheckIcon from '@material-ui/icons/Check';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  root: {
    textTransform: 'capitalize;',
    color: '#757575',
    fontSize: 14,
  },
  icon: {
    width: 20,
    height: 20,
    backgroundColor: props => props.color,
    borderRadius: 10,
    color: '#fff',
    marginRight: 9,
    marginLeft: '-9px',
    position: 'relative',
    padding: 1
  },
  loading: {
    position: 'absolute',
    top: '-5px',
    left: '-5px',
    color: props => props.color
  },
  minimized: {
    padding: 0,
    minWidth: 20,
    margin: 5,
    '& $icon': {
      marginRight: 0,
      marginLeft: 0
    }
  },
  full: {
    backgroundColor: props => props.color,
    borderRadius: 20,
    color: '#fff',
    paddingRight: 20,
    paddingLeft: 20,
    '& $loading': {
      color: '#fff'
    }
  },
  bordered:{
    borderColor: props => props.color,
    borderWidth: 2,
    borderStyle:'solid',
    borderRadius: 20,
    color: props => props.color,
    paddingRight: 20,
    paddingLeft: 20
  }
});

export function ActionButton(props) {
  const { loading, icon, color, iconified, full, bordered, className, children, ...rest } = props;
  const classes = useStyles(props);

  const iconify = iconified !== undefined;
  const filled = full !== undefined;

  const button = <Button {...rest}
    className={
      className + ' ' +
      classes.root + ' ' +
      (iconify ? classes.minimized : '') + ' ' +
      (filled ? classes.full : '') + ' ' +
      (bordered ? classes.bordered : '')
    }>
    {icon ? <span className={classes.icon}>
      {icon}
      {loading ? <CircularProgress className={classes.loading} size={30} /> : null}
    </span>: null}
    {iconify ? null : children}
  </Button>;

  return iconify ?
    <Tooltip title={children} arrow>{button}</Tooltip> : button
}


export function SuccessButton(props) {
  return <ActionButton color="#3BB866" icon={<CheckIcon fontSize='small' />} {...props} />
}
export function SuccessButtonOrange(props) {
  return <ActionButton color="#F5A623" icon={<CheckIcon fontSize='small' />} {...props} />
}
export function FailButton(props) {
  return <ActionButton color="#EF303B" icon={<CloseIcon fontSize='small' />} {...props} />
}
export function CancelButton(props) {
  return <ActionButton color="#C5D0DE" icon={<ChevronLeftIcon fontSize='small' />} {...props} />
}

export function SimpleButton(props) {
  const { icon, ...rest } = props;
  const Icon = props.icon;
  return <ActionButton color={props.disabled ? "#efefef":"#BD2B82"} icon={props.icon ? <Icon fontSize='small' /> : null} {...rest} />
}

