import React from 'react'

import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {

  },
  label: {
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'block',
    marginBottom: '10px'
  },
  input: {
    fontSize: '14px',
    border: '1px solid #DFE3E9',
    borderRadius: '4px',
    height: '36px',
    padding: '0 10px',
    width: '100%',
    color: '#000'
  },
});

function camelize(str = '') {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

function StyledSelect(props) {

  const { label, onChange, SelectProps, ...rest } = props;
  const classes = useStyles(props);

  const id = camelize(label) + Math.random().toString(36).substring(2, 8);

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  // return <Select 
  // IconComponent={KeyboardArrowDownIcon}
  // {...rest}
  // />

  return <div className={classes.root} {...rest}>
    {label ? <label className={classes.label} htmlFor={id}>{label}</label> : null}
    <Select
      className={classes.input}
      id={id}
      onChange={handleChange}
      variant="outlined"
      IconComponent={KeyboardArrowDownIcon}
      {...rest}
      {...SelectProps}
    />
  </div>


}

export default StyledSelect