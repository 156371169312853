import { fbFunctions } from './fbFunctions';
import Log from '../newHelpers/Log';
const Logger = new Log('FirebaseComponent Predictions');

const Predictions = {
  sendPrediction: async (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const sendPrediction = fbFunctions.httpsCallable('sendPrediction');
        const res = await sendPrediction(params);
        Logger.log('sendPrediction Res:', res);
        resolve(res);
      } catch (error) {
        Logger.log('error', error);
        Logger.log(error.response);
        if (error.response && error.response.data) {
          Logger.log('has error data', error.response.data);
          reject(error.response.data);
        } else {
          Logger.log('rejecting');
          reject(JSON.stringify(error));
        }
      }
    });
  },
};

export default Predictions;
