import React, { Component } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import Form from '../../components/Form';
import { taskStepName } from '../../components/taskRedirect';

import {
  ExtractData,
  ComplianceCheck,
  ManagerApproval,
  ReimbursabilityCheck,
  ArrangePayment,
  PaymentConfirmation,
  FinishedTask,
  Clarification,
} from '../../components/TaskDetails';

import MsTeams from '../../externalAPI/MsTeams';

import dbTask from '../../firebasecomponents/task';
import Page from '../page';
import moment from 'moment';
import Log from '../../newHelpers/Log';
const Logger = new Log('TaskDetails');

class TaskDetails extends Component {
  constructor(props) {
    super(props);
    this.state = this.intialComponentState;

    this.getChildForm = this.getChildForm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  get intialComponentState() {
    return {
      taskDetails: null,
      taskId: null,
      success: null,
      error: null,
      loading: true,
      openQuestion: false,
    };
  }

  cancel() {
    this.props.history.replace('/');
  }

  async componentDidMount() {
    let taskId = this.props.match.params.taskId;
    await dbTask.getTaskData({ taskId }, (response) => {
      this.setState({
        taskDetails: { ...response, taskId },
        loading: false,
      });
    });
    Logger.log('taskDetails: ', this.state.taskDetails);
    await dbTask.getQuestionsOnTaskId(taskId, (response) => {
      this.setState({ questions: response });
    });
  }

  handleChange(e) {
    if (e.success) {
      this.setState({ success: e.success });
    }
    if (e.error) {
      this.setState({ error: e.error });
    }
    this.setState({ taskDetails: e.task });
  }

  getChildForm(task) {
    switch (task.task_defination) {
      case 'clarification':
        return <Clarification task={task} onChange={this.handleChange} />;
      case 'extract-data':
        return <ExtractData task={task} onChange={this.handleChange} />;
      case 'compliance-check':
        return <ComplianceCheck task={task} onChange={this.handleChange} />;
      case 'manager-approval':
        return <ManagerApproval task={task} onChange={this.handleChange} />;
      case 'reimbursability-check':
        return <ReimbursabilityCheck task={task} onChange={this.handleChange} />;
      case 'arrange-payment':
        return <ArrangePayment task={task} onChange={this.handleChange} />;
      case 'payment-confirmation':
        return <PaymentConfirmation task={task} onChange={this.handleChange} />;
      case 'manager-declined':
        return <FinishedTask task={task} onChange={this.handleChange} />;
      case 'non-reimbursible task':
        return <FinishedTask task={task} onChange={this.handleChange} />;
      case 'payment-done':
        return <FinishedTask task={task} onChange={this.handleChange} />;
      default:
        return '--';
    }
  }

  render() {
    const task = this.state.taskDetails;
    const text =
      task && task.userInfo && task.userInfo.description
        ? task.userInfo.description
        : task
        ? task.text
        : '';
    let msTeamsText =
      task && task.task_defination === 'extract-data' && task.userInfo
        ? Object.entries(task.userInfo).map(function (e) {
            if (e[1] !== '' && e[0] !== 'CostCenter' && e[0] !== 'project') {
              return e[0] + ': ' + e[1] + '\n';
            } else return;
          })
        : [];
    Logger.log('msTeamsText: ', msTeamsText);
    msTeamsText = msTeamsText.join('');

    const questions = this.state.questions
      ? this.state.questions.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
      : null;

    const form = task ? this.getChildForm(task) : null;
    let imagePreviewUrl = task ? task.base64Value : null;
    // let imagePreviewUrl = task ? task.thumbnail : null;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview =
        imagePreviewUrl.includes('image') ||
        imagePreviewUrl.includes('.jpg') ||
        imagePreviewUrl.includes('.jpeg') ||
        imagePreviewUrl.includes('.PNG') ||
        imagePreviewUrl.includes('.png') ? (
          <img
            id="uploadedImage"
            src={imagePreviewUrl}
            border="1"
            align="middle"
            alt=""
            style={{ width: '341px', height: '550px' }}
            className="center"
          />
        ) : (
          <object data={imagePreviewUrl} type="application/pdf" width="100%" height="600px" />
        );
      // <PDFViewer document={{ url: imagePreviewUrl }} />
      //<embed src={imagePreviewUrl} width="100%" height="100%" type="application/pdf" />;
    } else {
      imagePreview = <div className="previewText">Please select an Image for Preview</div>;
    }

    const taskForm = task ? (
      <Page>
        <FormLabel className="left" style={{ textAlign: 'left', marginTop: '23px' }}>
          <IconButton onClick={this.cancel} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
          <h2 style={{ color: '#c0548e' }}>{taskStepName(task.task_defination)}</h2>
        </FormLabel>

        <Form success={this.state.success} error={this.state.error}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormLabel style={{ textAlign: 'left', color: '#c0548e', marginLeft: '10px' }}>
                <h5>Company: {task.company_name}</h5>
                <h5>Employee: {task.empl_name}</h5>
                <h5>Project: {task.project}</h5>
                {task.teams && (
                  <Button
                    variant="outlined"
                    style={{ marginTop: 15 }}
                    onClick={() => this.setState({ openQuestion: true })}
                  >
                    Ask employee a question via MsTeams
                  </Button>
                )}
              </FormLabel>
              <br />
              <br />
              {form}
              <br />
            </Grid>
            <Grid item xs={6}>
              {imagePreview}
              {task.base64Value === '' ? <h3 className="previewText">No image uploaded</h3> : null}
              {task.task_defination === 'extract-data' ? (
                <TextField
                  name="emailText"
                  label="Email Text"
                  value={text}
                  fullWidth
                  multiline
                  style={{ marginTop: '2em' }}
                />
              ) : null}
              {task.task_defination === 'extract-data' && task.userInfo ? (
                <TextField
                  name="msTeamsText"
                  label="MSTeams User Info"
                  fullWidth
                  multiline
                  value={msTeamsText}
                  style={{ marginTop: '2em' }}
                />
              ) : null}
            </Grid>
          </Grid>
        </Form>
        {questions && questions.length > 0 ? (
          <>
            <h2>Question History:</h2>
            {questions.map((q, idx) => {
              return (
                <dl
                  key={idx}
                  style={{
                    paddingBottom: '5px',
                    marginBottom: '5px',
                    borderBottom: '1px solid #eaeaea',
                  }}
                >
                  <dt>Question:</dt>
                  <dd>
                    {moment(q.createdAt).format('DD-MM-YYYY')} - {q.question}
                  </dd>
                  <dt>Answer:</dt>
                  <dd>{q.answer}</dd>
                </dl>
              );
            })}
          </>
        ) : null}
        {this.state.openQuestion && (
          <div className="question-popup">
            <div>
              <p>Write a question to employee "{this.state.question}"</p>
              <TextField
                multiline
                variant="outlined"
                rows="4"
                onChange={(ev) => this.setState({ question: ev.target.value })}
              />
              <div className="buttons">
                <Button variant="contained" onClick={() => this.setState({ openQuestion: false })}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    MsTeams.askQuestion(task, this.state.question).then(() =>
                      this.setState({ openQuestion: false }),
                    )
                  }
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        )}
      </Page>
    ) : null;

    return this.state.loading ? <CircularProgress /> : taskForm;
  }
}
export default TaskDetails;
