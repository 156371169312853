import {production} from "../config";

class Log {
  constructor(component) {
    this.component = component;
  }

  log(prefix, msg = '', ...rest) {
    if(!production) {
      console.log(this.component, ': ', prefix, msg, ...rest);
    }
  }
}

export default Log;
