import React, { Component } from 'react'
import { connect } from "react-redux";
import ScrollArea from 'react-scrollbars-custom';

import { CircularProgress, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { saveAs } from 'file-saver';

import { getReports } from "../../store/actions";

import { ActionButton, SimpleButton } from '../../newComponents/Button';
import Drawer from '../../newComponents/Drawer'
import Table from '../../newComponents/Table'
import Checkbox from '../../newComponents/Checkbox'
import Message from '../../newComponents/Message'
import Search from '../../newComponents/Search'
import QuickFilters from '../../newComponents/QuickFilters'
import Progress from '../../newComponents/Progress'

import ReportDetails, { pertiodForm } from './components/ReportDetails';

import Storage from '../../firebasecomponents/storage';
import dbCompany from '../../firebasecomponents/company';
import dbReport from '../../firebasecomponents/reports';

import Page from '../page';

import './reports.scss'
import {getCollapsed, getShowExpenseItems} from "../../newHelpers/pageHelper";

var JSZip = require("jszip");


class Reports extends Component {

  constructor(props) {
    super(props)
    this.state = this.initialState;

    this.opening = this.opening.bind(this);
    this.newReport = this.newReport.bind(this);
    this.download = this.download.bind(this);
    this.downloadSelected = this.downloadSelected.bind(this);
    this.keyListener = this.keyListener.bind(this);

    this.save = this.save.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);

    this.typeFilter = this.typeFilter.bind(this);
    this.stateFilter = this.stateFilter.bind(this);
    this.searchFilter = this.searchFilter.bind(this);

    if (this.props.currentCompany) {
      this.props.getReports(this.props.currentCompany.companyId);
    }

  }

  get initialState() {
    return {
      openedReport: undefined,
      selected: [],
      downloadLoading: false,
      dataLoading: false,
      new: false,
      changed:false,
    }
  }

  download(report) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      saveAs(xhr.response, report.src.name);
      if (this.props.currentRole === 'Company') {
        dbReport.updateReport({ ...report, downloaded: true }).then();
      }
    }
    xhr.onerror = err => this.setState({ error: err })
    xhr.open('GET', report.src.data);
    xhr.send();
  }

  downloadSelected() {
    this.setState({ downloadLoading: true })

    const reportsToDownload = this.state.reports.filter(r => this.state.selected.includes(r.id))
    const zip = new JSZip();

    const dataDownloadPromises = reportsToDownload.map(d =>
      new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (this.props.currentRole === 'Company') {
            dbReport.updateReport({ ...d, downloaded: true }).then();
          }
          resolve(zip.file(d.src.name, xhr.response));
        }
        xhr.onerror = err => reject(err);
        xhr.open('GET', d.src.data);
        xhr.send();
      })
    )

    Promise.all(dataDownloadPromises)
      .then(() => zip.generateAsync({ type: "blob" }))
      .then(blob => {
        saveAs(blob, this.props.currentCompany.companyName + " reports.zip");
        this.setState({ downloadLoading: false, selected: [] });
      })
      .catch(err => this.setState({ error: err }))
  }

  componentDidMount() {
    window.addEventListener("keydown", this.keyListener)
  }
  componentWillUnmount() {
    window.removeEventListener("keyup", this.keyListener)
  }

  keyListener(e) {
    if (e.key === 'Escape') {
      this.setState({ openedReport: undefined })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateDone !== this.props.updateDone) {
      if (this.props.updateDone) {
        this.props.clearDone()
      }
      this.setState(this.initialState)
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.setState({ dataLoading: true })
      this.props.getReports(this.props.currentCompany.companyId);
    }
    if (prevProps.reports !== this.props.reports) {
      this.setState({ reports: this.props.reports })
    }
  }



  typeFilter(item) {
    const filter = this.state.policyFilter
    if (filter === undefined || filter.length === 0) return true
    return (item.type === 'Booking Report' && filter.includes('Booking Report')) ||
      (item.type === 'Customers Report' && filter.includes('Customers Report')) ||
      (item.type === 'Employee Report' && filter.includes('Employee Report')) ||
      (item.type === 'Project Report' && filter.includes('Project Report'))
  }
  stateFilter(item) {
    const filter = this.state.reimbursabilityFilter;
    if (filter === undefined || filter.length === 0) return true

    return (!item.downloaded && filter.includes('New')) || (item.downloaded && filter.includes('Downloaded'))
  }
  searchFilter(item) {
    const filter = this.state.searchFilter
    if (filter === undefined || filter === '') return true;
    return JSON.stringify(item).includes(filter)
  }

  opening(report) {
    return () => this.setState({ openedReport: report, new: false })
  }
  newReport() {
    return this.setState({ openedReport: {}, new: true })
  }
  save() {
    this.setState({ dataLoading: true, progress:0 })
    const report = this.state.openedReport;
    if (!report.period || !report.src || !report.type) {
      const msg = [!report.period ? 'Specify period' : '', !report.src ? 'Select file' : '', !report.type ? 'Select report type':'']
      this.setState({ error: msg.join(', ') })
      return;
    }
    Storage.upload('reports/' + this.props.currentCompany.companyId, { name: report.src.fileName, data: report.src.data },
      (progress) => {
        this.setState({ progress })
      })
      .then(url => {
        const period = report.period
        const data = {
          ...report,
          src: { name: report.src.fileName, data: url },
          companyId: this.props.currentCompany.companyId,
          period: period.getFullYear() + '#' + period.getMonth()
        }
        return dbReport.addReport(data);
      })
      .then(() => {
        this.setState({ dataLoading: false, openedReport: undefined, progress:undefined })
      })
      .catch(err => this.setState({ error: err }))
  }

  update(){
    console.log('update');
    this.setState({ dataLoading: true, progress:0 })
    const report = this.state.openedReport;
    Storage.upload('reports/' + this.props.currentCompany.companyId, { name: report.src.fileName, data: report.src.data },
      (progress) => {
        this.setState({ progress })
      })
      .then(url => {
        const period = report.period
        const data = {
          ...report,
          src: { name: report.src.fileName, data: url },
        }
        return dbReport.updateReport(data);
      })
      .then(() => {
        this.setState({ dataLoading: false, openedReport: undefined, progress:undefined })
      })
      .catch(err => this.setState({ error: err }))
  }

  delete(){
    console.log('delete');
    this.setState({ dataLoading: true, progress:0 })
    const report = this.state.openedReport;
    dbReport.deleteReport(report)
      .then(() => {
        console.log('deleted');
        this.setState({ dataLoading: false, openedReport: undefined, progress:undefined })
      })
      .catch(err => this.setState({ error: err }))
  }

  render() {
    const tableData = this.state.reports ?
      this.state.reports
        .filter(this.typeFilter)
        .filter(this.stateFilter)
        .filter(this.searchFilter) : []

    const downloadBtn = this.props.currentRole === 'Employee' ? <SimpleButton full icon={AddIcon} onClick={this.newReport}>Add Report</SimpleButton> : null;
    const progressing = this.state.progress && this.state.progress <= 100;

    return (
      <Page role='Company'
            currentPage="reports"
            header="Reports"
            actions={downloadBtn}
            collapsedNavBar={getCollapsed(this.props)}
            collapsedExpItems={getShowExpenseItems(this.props)}
      >
        {this.state.error && !this.state.openedReport ? <Message message={this.state.error} type="error"/> : null}
        <div className="tableBar">
          <QuickFilters className="quickFilters" name="Report Type:" values={['Booking Report', 'Customers Report', 'Employee Report', 'Project Report']} onChange={e => this.setState({ policyFilter: e })} />
          <QuickFilters className="quickFilters" name="Status:" values={['New', 'Downloaded']} onChange={e => this.setState({ reimbursabilityFilter: e })} />
          <Search className="search" onChange={e => this.setState({ searchFilter: e })} />
        </div>
        {tableData ?
          <Table
            data={{
              head: {
                type: <strong>Report Type</strong>,
                period: <span>Period</span>,
                downloaded: <span>Status</span>,
                action: '',
              },
              content: tableData.map(e => {
                return {
                  row: {
                    type: <strong>{e.type}</strong>,
                    period: <span>{pertiodForm(e.period)}</span>,
                    downloaded: <span>{e.status}
                      {e.downloaded ?
                        <ActionButton full color="#C5D0DE" onClick={() => this.download(e)}>Downloaded</ActionButton> :
                        <ActionButton full color="#3BB866" onClick={() => this.download(e)}>Download</ActionButton>}
                    </span>,
                    action: <ChevronRightIcon onClick={this.opening(e)} />
                  },
                  sortData: e
                }
              }
              )
            }}
            sortable={true}
            checkable="multi"
            onSelect={(selected) => this.setState({ selected })}
          />
          : <CircularProgress />}
        <Drawer direction="left" opened={this.state.openedReport !== undefined}>
          {this.state.openedReport ?
            <div className="drawerContainer">
              <div className="drawerHeader">
                <Typography>Report Details</Typography>
                <CloseIcon onClick={() => this.setState({ openedReport: undefined })} />
              </div>
              <div className="drawerContent" style={{ width: 775 }}>
              {this.state.error ? <Message message={this.state.error} type="error"/> : null}
                <ScrollArea style={{ height: '100%', marginTop: '10px' }} noScrollX >
                  <ReportDetails
                    add={this.state.new}
                    report={this.state.openedReport}
                    onChange={newDetails => this.setState({ openedReport: newDetails, changed: true })}
                    onFileChange={data => this.setState({ openedReport: {...this.state.openedReport, src:data}, changed:true })}
                  />
                </ScrollArea>
              </div>
              {this.state.new ?
                <div className="drawerFooter">
                  {this.state.progress && this.state.progress <= 100 ?
                    <Progress progress={this.state.progress} style={{marginRight:'10px'}}/>
                    : null}
                  <SimpleButton
                    bordered disabled={progressing || false}
                    style={{ marginRight: '10px' }}
                    onClick={() => this.setState({ openedReport: undefined })}
                  >
                    Cancel
                </SimpleButton>
                  <SimpleButton disabled={progressing || false}
                    loading={this.state.dataLoading}
                    full
                    onClick={this.save}
                  >
                    Save Report
                </SimpleButton>
                </div>
                :
                <div className="drawerFooter">
                  <SimpleButton bordered
                    icon={GetAppIcon}
                    loading={this.state.downloadLoading}
                    onClick={() => this.download(this.state.openedReport)}
                  >
                    Download
                </SimpleButton>
                  {this.state.changed?
                    <SimpleButton
                      bordered
                      icon={UpdateIcon}
                      onClick={this.update}
                      style={{marginLeft:'10px'}}
                                  >
                      Update
                    </SimpleButton> : null}
                    <SimpleButton
                      bordered
                      onClick={this.delete}
                      icon={DeleteForeverIcon}
                      style={{marginLeft:'10px'}}
                      >Delete This Report
                    </SimpleButton>
                </div>}
            </div>
            : null}
        </Drawer>
        <Drawer direction="up" opened={this.state.selected.length > 0}>
          <div className="bottomSelect">
            <div>
              <Checkbox checked />
              <span className="itemSelected">{this.state.selected.length}</span>
              item{this.state.selected.length > 1 ? 's' : ''} selected
            </div>
            <div>
              <SimpleButton bordered
                icon={GetAppIcon}
                loading={this.state.downloadLoading}
                onClick={this.downloadSelected}
              >
                Download
                </SimpleButton>
            </div>
            <div><span>shift+click</span> to select, <span>esc</span> to deselect all</div>
          </div>
        </Drawer>
      </Page>
    )
  }
}


const mapStateToProps = state => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
    reports: state.CompanyReducer.reports,
    currentRole: state.UserReducer.currentRole
  };
}

export default connect(mapStateToProps, { getReports })(Reports);
