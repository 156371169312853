import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import TextField2 from '../../newComponents/TextField';
import FormControl from '@material-ui/core/FormControl';
import Form from '../../components/Form';
import '../Customization/customization.scss';
import Grid from '@material-ui/core/Grid';
import { updateCompany, getCompanies } from '../../store/actions';
import { SaveButton } from '../../components/Buttons';
import Topal from '../../firebasecomponents/topal';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Select from '../../newComponents/Select';
import MenuItem from '@material-ui/core/MenuItem';

const banks = ['ZKB', 'Credit Suisse', 'Migros Bank', 'Raiffeisen', 'AKB', 'UBS', 'Julius Bär'];

function TopalSettings(props) {
  //topal client needs through api some details that must be provided here.
  //must be entered before invoices are transferred
  //which details to use depends on how topal was set up

  const [guid, setGuid] = useState('');
  const [fiscalYear, setFiscalYear] = useState('');
  const [topalBankAccounts, setTopalBankAccounts] = useState('');
  const [topalAccounts, setTopalAccounts] = useState('');
  const [topalFiscalYears, setTopalFiscalYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [topalVats, setTopalVats] = useState('');
  const [accountsPayableAccount, setAccountsPayableAccount] = useState('');
  const [accountsDebtorAccount, setAccountsDebtorAccount] = useState('');

  useEffect(() => {
    //after first load or after props currentcompany changed, set details
    if (props.currentCompany !== undefined) {
      console.log('props', props);
      if (props.currentCompany.topal) {
        setFiscalYear(props.currentCompany.topal.fiscalYear || '');
        setGuid(props.currentCompany.topal.guid || '');
        setTopalBankAccounts(props.currentCompany.topalBankAccounts || []);
        setTopalVats(props.currentCompany.topalVats || []);
        setTopalFiscalYears(props.currentCompany.topalFiscalYears || []);
        setAccountsPayableAccount(props.currentCompany.topal.accountsPayableAccount || '');
        setAccountsDebtorAccount(props.currentCompany.topal.accountsDebtorAccount || '');
      }

      console.log('guid', guid, 'fiscalyear', fiscalYear, 'bankAccounts=', topalBankAccounts);
    }
    const fetch = async () => {
      if (
        await Topal.checkTopalOnline({
          companyID: props.currentCompany.companyId,
        })
      ) {
        setLoading(false);
      }
    };
    fetch();
  }, [props.currentCompany]);

  const history = useHistory();

  const saveTopal = () => {
    console.log(fiscalYear, 'fiscalYear');
    console.log('topalBankAccounts', topalBankAccounts);
    console.log('topalVats', topalVats);
    console.log('accountsPayableAccount', accountsPayableAccount);
    console.log('props company', props.currentCompany.topal);
    const topalUpdated = {
      ...props.currentCompany.topal,
      fiscalYear,
      accountsPayableAccount,
      accountsDebtorAccount,
    };

    props.updateCompany(props.currentCompany.companyId, {
      topal: topalUpdated,
      topalBankAccounts: topalBankAccounts,
      topalVats: topalVats,
    });
    props.getCompanies();
    history.push('/company/settings');
  };

  const setDefault = (idx) => {
    console.log('e', idx);
    let topalBankAccountsList = [...topalBankAccounts];
    for (const i in topalBankAccountsList) {
      delete topalBankAccountsList[i].default;
    }
    topalBankAccountsList[idx].default = true;
    console.log(topalBankAccountsList);
    setTopalBankAccounts(topalBankAccountsList);
  };

  const setHide = (idx) => {
    console.log('e', idx);
    let temp = topalVats;
    temp[idx].hidden = true;
    setTopalVats(temp);
  };

  const syncPayTerms = async () => {
    setLoading(true);
    await Topal.syncPayTerms(props.currentCompany.companyId);
    setLoading(false);
  };
  const syncBankAccounts = async () => {
    setLoading(true);
    const res = await Topal.syncBankAccounts(props.currentCompany.companyId);
    console.log('sync res: ', res);
    if (res && res.status === 200) {
      setTopalBankAccounts(res.data);
    }
    setLoading(false);
  };
  const syncAccounts = async () => {
    setLoading(true);
    const res = await Topal.syncAccounts(props.currentCompany.companyId);
    console.log('sync res: ', res);
    if (res && res.status === 200) {
      setTopalAccounts(res.data);
    }
    setLoading(false);
  };
  const syncVats = async () => {
    setLoading(true);
    await Topal.syncVats(props.currentCompany.companyId);
    setLoading(false);
  };
  const syncFiscalYears = async () => {
    setLoading(true);
    await Topal.syncFiscalYears(props.currentCompany.companyId);
    setLoading(false);
  };
  const syncCostCenters = async () => {
    setLoading(true);
    await Topal.syncCostCenters(props.currentCompany.companyId);
    setLoading(false);
  };
  const changeBank = (bank, index) => {
    const banks = [...topalBankAccounts];
    console.log('bank', bank, 'index', index);

    banks[index].bank = bank;
    setTopalBankAccounts(banks);
  };

  return (
    <div>
      <Form style={{ padding: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              name="topalId"
              label="Client's GUID (check API)"
              onChange={(id) => setGuid(id.target.value)}
              value={guid || ''}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <TextField
                label="Default Creditor Account"
                value={accountsPayableAccount}
                onChange={(e) => setAccountsPayableAccount(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <TextField
                label="Default Debtor Account"
                value={accountsDebtorAccount}
                onChange={(e) => setAccountsDebtorAccount(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} />

          <Grid item xs={2}>
            <Select label="Current Fiscal Year" value={fiscalYear} onChange={(e) => setFiscalYear(e)}>
              {topalFiscalYears.map((e, i) => (
                <MenuItem key={`fyear-${i}`} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={10} />

          {/*<Grid item xs={8} />*/}

          {/*newline*/}
          <Grid item xs={4}>
            <SaveButton fullWidth onClick={saveTopal}>
              Save
            </SaveButton>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={2}>
            <SaveButton fullWidth onClick={syncPayTerms} disabled={loading}>
              Sync Topal PayTerms
            </SaveButton>
          </Grid>
          <Grid item xs={2}>
            <SaveButton fullWidth onClick={syncBankAccounts} disabled={loading}>
              Sync Topal Bank Accounts
            </SaveButton>
          </Grid>
          <Grid item xs={2}>
            <SaveButton fullWidth onClick={syncAccounts} disabled={loading}>
              Sync Topal Accounts
            </SaveButton>
          </Grid>
          <Grid item xs={6} />

          <Grid item xs={2}>
            <SaveButton fullWidth onClick={syncVats} disabled={loading}>
              Sync Topal Vats
            </SaveButton>
          </Grid>
          <Grid item xs={2}>
            <SaveButton fullWidth onClick={syncCostCenters} disabled={loading}>
              Sync Topal Cost Centers
            </SaveButton>
          </Grid>
          <Grid item xs={2}>
            <SaveButton fullWidth onClick={syncFiscalYears} disabled={loading}>
              Sync Topal Fiscal Years
            </SaveButton>
          </Grid>
        </Grid>
        {topalBankAccounts ? (
          <div className="containerBox">
            <Typography variant={'h5'}>Topal Bank Accounts</Typography>
            <Typography variant="caption">
              Imported from Topal. Change in Topal Directly.
            </Typography>
            {topalBankAccounts.map((e, idx) => {
              console.log('e', e);
              return (
                <div key={idx}>
                  <span>{idx + 1}</span>
                  <Grid container key={idx} spacing={2}>
                    <Grid item xs={2}>
                      <TextField2 value={e.accountCode} label={'Account'} disabled />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField2 value={e.name} label={'Name'} disabled />
                    </Grid>
                    <Grid item xs={2}>
                      {/*<TextField2 value={e.name} label={'Bank'} disabled/>*/}
                      <Select
                        onChange={(e) => changeBank(e, idx)}
                        value={e.bank || '-'}
                        label="Bank"
                      >
                        <MenuItem value={'-'} disabled>
                          {'Select Bank'}
                        </MenuItem>
                        {banks.map((bank, i) => (
                          <MenuItem key={i} value={bank}>
                            {bank}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField2 value={e.iban} label={'IBAN'} disabled />
                    </Grid>
                    <Grid item xs={2}>
                      <h5 style={{ fontSize: '14px' }}>Default</h5>
                      <Radio
                        checked={e.default ? true : false}
                        value={e}
                        onChange={() => setDefault(idx)}
                        style={{ marginTop: '7px' }}
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </div>
        ) : null}
        {topalVats ? (
          <div className="containerBox">
            <Typography variant={'h5'}>Topal VAT Codes</Typography>
            <Typography variant="caption">
              Imported from Topal. Change in Topal Directly.
            </Typography>
            {topalVats
              .sort((a, b) => a.value - b.value)
              .map((e, idx) => {
                return (
                  <div key={idx}>
                    <span>{idx + 1}</span>
                    <Grid container key={idx} spacing={2}>
                      <Grid item xs={2}>
                        <TextField2 value={e.code} label={'Code'} disabled />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField2 value={e.value.toString()} label={'Value'} disabled />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField2 value={e.name} label={'Description'} disabled />
                      </Grid>
                      <Grid item xs={1}>
                        <h5 style={{ fontSize: '14px' }}>Hidden</h5>
                        <FormControlLabel
                          control={<Checkbox checked={e.hidden} onChange={() => setHide(idx)} />}
                        />
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
          </div>
        ) : null}
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
  };
};
export default connect(mapStateToProps, { updateCompany, getCompanies })(TopalSettings);
