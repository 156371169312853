import React, { Component } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { TrashIcon } from '../../newComponents/Icons';
import AddIcon from '@material-ui/icons/Add';

import { SimpleButton } from '../../newComponents/Button';

import { updateCompany, companyClearDone } from '../../store/actions';

import ExpenseMap from './components/ExpenseMap';
import VatCodePreset from './components/VatCodePreset';

import './settings.scss';

const expenseTypes = require('../../expenses.json').expenses;

class AccountMapping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapping: this.props.currentCompany.mapping || {},
      vatCodes: this.props.currentCompany.vatCodes || {},
      changed: false,
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.addMap = this.addMap.bind(this);
    this.changeMap = this.changeMap.bind(this);
    this.delMap = this.delMap.bind(this);

    this.accept = this.accept.bind(this);
    this.cancel = this.accept.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.done !== this.props.done) {
      if (this.props.done) {
        this.props.companyClearDone();
      }
      this.setState({ changed: false, done: false });
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.setState({
        mapping: this.props.currentCompany.mapping || {},
        changed: false,
      });
    }
  }

  handleChange(field) {
    return (value) => {
      this.setState({
        mapping: { ...this.state.mapping, [field]: value },
        changed: true,
      });
    };
  }

  addMap(type) {
    return () => {
      const mapList = this.state.mapping[type] || [];
      mapList.push({ id: Math.random().toString(36).substring(2, 8) });
      this.setState({
        mapping: { ...this.state.mapping, [type]: mapList },
        changed: true,
      });
    };
  }
  changeMap(type, idx, data) {
    const mapList = this.state.mapping[type];
    mapList[idx] = data;
    this.setState({
      mapping: { ...this.state.mapping, [type]: mapList },
      changed: true,
    });
  }
  delMap(type, idx) {
    const mapList = this.state.mapping[type];
    mapList.splice(idx, 1);
    this.setState({
      mapping: { ...this.state.mapping, [type]: mapList },
      changed: true,
    });
  }

  accept() {
    const check = (field) => {
      return field === undefined || field === '' || field === '-';
    };

    const {
      expense,
      credit,
      custom,
    } = this.state.mapping;
    const errors = {
      expense: [],
      credit: [],
      custom: [],
    };
    let isError = false;
    (expense || []).forEach((e) => {
      const err =
        check(e.expenseType) ||
        check(e.name) ||
        check(e.number) ||
        check(e.accountType);
      errors.expense.push(err);
      isError = err || isError;
    });
    (credit || []).forEach((e) => {
      const err = check(e.name) || check(e.number);
      errors.credit.push(err);
      isError = err || isError;
    });
    (custom || []).forEach((e) => {
      const err = check(e.name) || check(e.number) || check(e.accountType);
      errors.custom.push(err);
      isError = err || isError;
    });

    if (isError) {
      this.setState({ errors });
    } else {
      this.props.updateCompany(this.props.currentCompany.companyId, {
        mapping: this.state.mapping,
        vatCodes: this.state.vatCodes,
      });
      this.setState({ changed: false, errors: {} });
    }
  }
  cancel() {
    this.setState({
      mapping: this.props.currentCompany.mapping || {},
      changed: false,
      errors: {},
    });
  }

  render() {
    const reimbursement = this.state.mapping.reimbursement || {};
    const expenseMap = this.state.mapping.expense || [];
    const creditCardAccounts = this.state.mapping.credit || [];
    const customAccounts = this.state.mapping.custom || [];
    const reconciliationAccounts = this.state.mapping.reconciliation || [];

    const expenseMapError = this.state.errors.expense || [];
    const creditCardAccountsError = this.state.errors.credit || [];
    const customAccountsError = this.state.errors.custom || [];
    const reconciliationAccountsError = this.state.errors.reconciliation || [];

    const accountTypes = [
      'Material and supplies of services',
      'Investments and other operating costs',
    ];

    const filteredExpenseTypes = this.state.mapping.expense
      ? expenseTypes.filter(
          (e) =>
            !this.state.mapping.expense.map((c) => c.expenseType).includes(e),
        )
      : expenseTypes;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <strong>Reimbursement Account</strong>
          </Grid>
          <Grid item xs={9}>
            <ExpenseMap
              data={reimbursement}
              onChange={(data) =>
                this.setState({
                  mapping: { ...this.state.mapping, reimbursement: data },
                  changed: true,
                })
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <VatCodePreset
            data={this.state.vatCodes}
            onChange={(newCodes) =>
              this.setState({ vatCodes: newCodes, changed: true })
            }
          />
        </Grid>
        <div className="containerBox">
          <h3>
            Expense Mapping
            <SimpleButton full icon={AddIcon} onClick={this.addMap('expense')}>
              Add Expense Mapping
            </SimpleButton>
          </h3>
          {expenseMap.map((e, idx) => (
            <div key={idx} className={expenseMapError[idx] ? 'error' : null}>
              <span>{idx + 1}</span>
              <ExpenseMap
                data={e}
                idx={idx}
                expenseTypes={filteredExpenseTypes}
                accountTypes={accountTypes}
                onChange={(data) => this.changeMap('expense', idx, data)}
              />
              <TrashIcon onClick={() => this.delMap('expense', idx)} />
            </div>
          ))}
        </div>
        <div className="containerBox">
          <h3>
            Credit Card Accounts
            <SimpleButton full icon={AddIcon} onClick={this.addMap('credit')}>
              Add Credit Card Account
            </SimpleButton>
          </h3>
          {creditCardAccounts.map((e, idx) => (
            <div
              key={idx}
              className={creditCardAccountsError[idx] ? 'error' : null}
            >
              <span>{idx + 1}</span>
              <ExpenseMap
                data={e}
                idx={idx}
                onChange={(data) => this.changeMap('credit', idx, data)}
              />
              <TrashIcon onClick={() => this.delMap('credit', idx)} />
            </div>
          ))}
        </div>

        <div className="containerBox">
          <h3>
            Custom Accounts
            <SimpleButton full icon={AddIcon} onClick={this.addMap('custom')}>
              Add Custom Account
            </SimpleButton>
          </h3>
          {customAccounts.map((e, idx) => (
            <div
              key={idx}
              className={customAccountsError[idx] ? 'error' : null}
            >
              <span>{idx + 1}</span>
              <ExpenseMap
                data={e}
                idx={idx}
                accountTypes={accountTypes}
                onChange={(data) => this.changeMap('custom', idx, data)}
              />
              <TrashIcon onClick={() => this.delMap('custom', idx)} />
            </div>
          ))}
        </div>

        <div className="containerBox">
          <h3>
            Reconciliation Accounts
            <SimpleButton full icon={AddIcon} onClick={this.addMap('reconciliation')}>
              Add Reconciliation Account
            </SimpleButton>
          </h3>
          {reconciliationAccounts.map((e, idx) => (
            <div
              key={idx}
              className={reconciliationAccountsError[idx] ? 'error' : null}
            >
              <span>{idx + 1}</span>
              <ExpenseMap
                data={e}
                idx={idx}
                accountTypes={accountTypes}
                onChange={(data) => this.changeMap('reconciliation', idx, data)}
              />
              <TrashIcon onClick={() => this.delMap('reconciliation', idx)} />
            </div>
          ))}
        </div>


        {this.state.changed ? (
          <div>
            <SimpleButton
              bordered
              style={{ marginRight: '10px' }}
              onClick={this.cancel}
            >
              Cancel
            </SimpleButton>
            <SimpleButton
              loading={this.state.acceptLoading}
              full
              onClick={this.accept}
            >
              Save Card
            </SimpleButton>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updateDone: state.CompanyReducer.done,
    currentCompany: state.CompanyReducer.currentCompany,
  };
};

export default connect(mapStateToProps, { updateCompany, companyClearDone })(
  AccountMapping,
);
