import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { setCompany } from '../store/actions';

import Navigation from '../newComponents/Navigation';
import Logout from '../newComponents/Logout';

import firebase from '../components/firebase';

import './page.scss';
import CompanySelection from '../newComponents/CompanySelection';
import company from '../firebasecomponents/company';
import Log from '../newHelpers/Log';

const Page = (props) => {
  const Logger = new Log('newPage');

  const {
    currentPage,
    role,
    header,
    actions,
    children,
    className,
    currentCompany,
    setCompany,
    currentUser,
    currentRole,
    collapsedNavBar,
    collapsedExpItems,
    ...rest
  } = props;
  const [collapsed, setCollapsed] = useState(collapsedNavBar || false);
  const [companyId, setCompanyId] = useState(useParams().companyId || null);
  const [companies, setCompanies] = useState([]);
  const [showExpenseItems, setShowExpenseItems] = useState(collapsedExpItems !== false);

  useEffect(() => {
    if (currentRole === 'Approver') {
      Logger.log('is approver');
      const approverId = currentUser.currentUser.uid;
      company.getCompanyDetailsFromApproverId({ approverId }, (response) => {
        Logger.log('setting from approver id');
        setCompanies(response);
      });
    }
  }, [currentRole]);

  useEffect(() => {
    Logger.log(
      'useeffect newpage: currentcompany: ',
      currentCompany,
      'companyId: ',
      companyId,
    );
    if (!currentCompany && (!companyId || _.isEmpty(companyId))) {
      //this case is only valid for the role company,
      // since the other roles can take directly the companyId from the url params
      firebase.getCompanyBasedOnUserId().then((res) => {
        Logger.log('no companyId, got company based on user id: ', res);
        setCompanyId(res);
        props.setCompany(res);
      });
    } else if (currentCompany) {
      setCompanyId(currentCompany.companyId);
    }
    if (
      !_.isEmpty(companyId) &&
      (!currentCompany || currentCompany.companyId !== companyId)
    ) {
      Logger.log('setting companyId: ', companyId, '-> fetching company data');
      props.setCompany(companyId);
      setCompanyId(companyId);
    }
  }, [companyId]);

  return companyId ? (
    <main id="page" className={className}>
      <div id="sidebar" className={collapsed ? 'collapsed' : 'full'}>
        <CompanySelection
          onCollapse={(collapsed) => setCollapsed(collapsed)}
          currentUser={currentUser}
          companies={companies}
          companyId={companyId}
          isCollapsed={collapsed}
        />
        <div className="navigation">
          <Navigation
            current={currentPage}
            role={currentRole}
            collapsed={collapsed}
            showExpenseItems={showExpenseItems}
            setShowExpenseItems={() => setShowExpenseItems(!showExpenseItems)}
          />
          <Logout />
        </div>
      </div>
      <div id="container" className={collapsed ? 'collapsed' : 'full'}>
        <div className="header">
          <Typography variant="h2">{header}</Typography>
          <div className="spacer"></div>
          {actions}
        </div>
        <div className="content" {...rest}>
          {children}
        </div>
      </div>
    </main>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
    currentUser: state.UserReducer,
    currentRole: state.UserReducer.currentRole,
  };
};

export default connect(mapStateToProps, { setCompany })(Page);
