import React from 'react'

import Checkbox from '@material-ui/core/Checkbox';

import checked from '../../assets/checked-checkbox.svg'
import unchecked from '../../assets/unchecked-checkbox.svg'


function StyledCheckbox(props){
  return <Checkbox 
  checkedIcon={<img src={checked} alt="checked"/>}
  icon={<img src={unchecked} alt="unchecked"/>}
  {...props}
  />
}

export default StyledCheckbox