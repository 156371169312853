import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollArea from 'react-scrollbars-custom';

import { CircularProgress, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';

import MenuItem from '@material-ui/core/MenuItem';

import {
  getData,
  updateTask,
  updateTasks,
  clearDone,
  getQuestions,
} from '../../store/actions';
import MsTeams from '../../externalAPI/MsTeams';

import {
  SuccessButton,
  FailButton,
  SimpleButton,
} from '../../newComponents/Button';
import Drawer from '../../newComponents/Drawer';
import Table from '../../newComponents/Table';
import Checkbox from '../../newComponents/Checkbox';
import Select from '../../newComponents/Select';

import Search from '../../newComponents/Search';
import QuickFilters from '../../newComponents/QuickFilters';
import makeUnique from '../../newHelpers/makeUnique';

import ExpenseDetails from './components/ExpenseDetails';

import Page from '../page';
import moment from 'moment';
import './managerApproval.scss';
import formatDate from '../../newHelpers/formatData';
import ReportDetails from '../Reports/components/ReportDetails';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import employee from '../../firebasecomponents/employee';
import Wrapper from '../../newComponents/Wrapper';
import { getCollapsed, getShowExpenseItems } from '../../newHelpers/pageHelper';

const defaultExpenseTypes = require('../../expenses.json').expenses;

class ManagerApproval extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.employeeFilter
    ) {
      this.state = {...this.state, userFilter: props.location.state.employeeFilter };
    }

    this.opening = this.opening.bind(this);
    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
    this.acceptSelected = this.acceptSelected.bind(this);
    this.declineSelected = this.declineSelected.bind(this);
    this.keyListener = this.keyListener.bind(this);
    this.updateExpense = this.updateExpense.bind(this);

    this.policyFilter = this.policyFilter.bind(this);
    this.reimbursabilityFilter = this.reimbursabilityFilter.bind(this);
    this.userFilter = this.userFilter.bind(this);
    this.searchFilter = this.searchFilter.bind(this);

    if (this.props.currentCompany) {
      this.props.getData(this.props.currentCompany.companyId, {
        task_defination: 'manager-approval',
      });
      this.props.getQuestions(this.props.currentCompany.companyId);
    }
  }

  get initialState() {
    return {
      openedTask: undefined,
      selected: [],
      acceptLoading: false,
      declineLoading: false,
      dataLoading: false,
      errors: [],
      changes: false,
      expensesCounter: 0,
      userFilter: '-',
      questions: [],
      edit: false,
    };
  }

  accept(taskDetails) {
    return () => {
      if (
        taskDetails.amounts.findIndex(
          (a) => a.type.toLowerCase() === 'other',
        ) >= 0
      ) {
        this.setState({
          errors: [
            'Type of expense cannot be `Other`. Select custom type of expense.',
          ],
        });
        return;
      }
      this.setState({ acceptLoading: true });
      const approver = this.props.currentUser.email;
      const now = formatDate(new Date());

      const task = {
        taskId: taskDetails.id,
        ...taskDetails,
        approved: true,
        approvedBy: approver,
        approvedDatum: now,
        task_defination: 'reimbursability-check',
      };

      this.props.updateTask(task);
      MsTeams.approvalNotification(task)
        .then()
        .catch((err) => console.log(err));
    };
  }
  decline(taskDetails) {
    return () => {
      this.setState({ declineLoading: true });
      const approver = this.props.currentUser.email;
      const now = formatDate(new Date());

      const task = {
        taskId: taskDetails.id,
        ...taskDetails,
        approved: false,
        approvedBy: approver,
        approvedDatum: now,
        task_defination: 'manager-declined',
      };
      this.props.updateTask(task);
      MsTeams.approvalNotification(task)
        .then()
        .catch((err) => console.log(err));
    };
  }

  acceptSelected() {
    const checkForOtherType = (selectedIds, tasks) => {
      const selectedTasks = this.props.tasks.filter((e) =>
        selectedIds.includes(e.id),
      );
      return selectedTasks
        .map(
          (e) =>
            e.amounts.findIndex((a) => a.type.toLowerCase() === 'other') >= 0,
        )
        .reduce((a, b) => a || b, false);
    };

    if (checkForOtherType(this.state.selected)) {
      this.setState({
        errors: [
          'One of selected expense has type `Other`. Select custom type of expense.',
        ],
      });
      return;
    }
    this.setState({ acceptLoading: true });
    const approver = this.props.currentUser.email;
    const now = formatDate(new Date());

    this.props.updateTasks(
      this.state.selected.map((id) => ({
        taskId: id,
        approved: true,
        approvedBy: approver,
        approvedDatum: now,
        task_defination: 'reimbursability-check',
      })),
    );

    const notifications = this.state.selected.map((taskId) => {
      console.log('creating list');
      return MsTeams.approvalNotification({
        ...this.props.tasks.find((t) => t.id === taskId),
        approved: true,
        approvedBy: approver,
        approvedDatum: now,
        task_defination: 'reimbursability-check',
      });
    });
    Promise.all(notifications)
      .then()
      .catch((err) => console.log(err));
    this.setState({ selected: [] });
  }
  declineSelected() {
    this.setState({ declineLoading: true });
    const approver = this.props.currentUser.email;
    const now = formatDate(new Date());

    this.props.updateTasks(
      this.state.selected.map((id) => ({
        taskId: id,
        approved: false,
        approvedBy: approver,
        approvedDatum: now,
        task_defination: 'manager-declined',
        managerComment:
          this.props.tasks.find((t) => t.id === id).managerComment ||
          'Batch decline',
      })),
    );

    const notifications = this.state.selected.map((id) => {
      return MsTeams.approvalNotification({
        ...this.props.tasks.find((t) => t.taskId === id),
        approved: false,
        approvedBy: approver,
        approvedDatum: now,
        task_defination: 'manager-declined',
      });
    });
    Promise.all(notifications)
      .then()
      .catch((err) => console.log(err));
    this.setState({ selected: [] });
  }

  updateExpense(taskDetails) {
    return () => {
      const task = {
        taskId: taskDetails.id,
        ...taskDetails,
      };
      this.props.updateTask(task);
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyListener);
  }
  componentWillUnmount() {
    window.removeEventListener('keyup', this.keyListener);
  }

  keyListener(e) {
    if (e.key === 'Escape') {
      this.setState({ openedTask: undefined });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateDone !== this.props.updateDone) {
      if (this.props.updateDone) {
        this.props.clearDone();
      }
      this.setState(this.initialState);
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.setState({ dataLoading: true });
      this.props.getData(this.props.currentCompany.companyId, {
        task_defination: 'manager-approval',
      });
      this.props.getQuestions(this.props.currentCompany.companyId);
    }
    if (prevProps.tasks !== this.props.tasks) {
      this.setState({
        dataLoading: false,
        expensesCounter: this.props.tasks.length,
      });
    }
    if (prevProps.questions !== this.props.questions) {
      this.setState({ questions: this.props.questions });
    }
  }

  policyFilter(item) {
    const filter = this.state.policyFilter;
    if (filter === undefined || filter.length === 0) return true;
    return (
      (item.compliant === true && filter.includes('According')) ||
      (item.compliant === false && filter.includes('Violating')) ||
      (item.compliant === 'unknown' && filter.includes('Unknown'))
    );
  }
  reimbursabilityFilter(item) {
    const filter = this.state.reimbursabilityFilter;
    if (filter === undefined || filter.length === 0) return true;

    return (
      (item.reimbursable && filter.includes('Reimbursable')) ||
      (!item.reimbursable && filter.includes('Non-reimbursable'))
    );
  }
  userFilter(item) {
    const filter = this.state.userFilter;
    if (filter === undefined || filter === '-') return true;

    return item.empl_name === filter;
  }
  searchFilter(item) {
    const filter = this.state.searchFilter;
    if (filter === undefined || filter === '') return true;
    // return JSON.stringify(item).includes(filter)
    let res = JSON.stringify(item).toLowerCase();
    return res.includes(filter.toLowerCase());
  }

  opening(task) {
    return () => {
      // console.log('task', task)
      employee.fetchEmployeeDetails(
        { employeeId: task.empl_emailId },
        async (user) => {
          const empl = user.userData;
          if (
            !empl ||
            !empl.requestConnection ||
            !empl.requestConnection.conversation
          ) {
            this.setState({ askQuestion: false });
          } else {
            this.setState({ askQuestion: true });
          }
        },
      );
      this.setState({
        openedTask: {
          ...task,
          amounts: [...task.amounts.map((a) => ({ ...a }))],
        },
      });
    };
  }

  render() {
    const userFilter = '-';

    const users = makeUnique(
      this.props.tasks ? this.props.tasks.map((t) => t.empl_name) : [],
    );

    const tableData = this.props.tasks
      ? this.props.tasks
          .filter(this.policyFilter)
          .filter(this.reimbursabilityFilter)
          .filter(this.userFilter)
          .filter(this.searchFilter)
      : [];

    const mapping = this.props.currentCompany
      ? this.props.currentCompany.mapping
      : undefined;
    // const expenseTypes = mapping && mapping.expense ? mapping.expense.map(e => e.expenseType) : undefined || defaultExpenseTypes;
    const expenseTypes =
      mapping && mapping.expense && mapping.expense.length > 0
        ? mapping.expense.map((e) => e.expenseType)
        : defaultExpenseTypes;
    const customExpenseTypes =
      mapping && mapping.custom
        ? mapping.custom.map((e) => ({ id: e.id, name: e.name }))
        : undefined;

    // console.log('mapping', mapping);
    // console.log('expense types', expenseTypes);
    // console.log('custom exp types', customExpenseTypes);

    return (
      <Page
        role="Company"
        currentPage="managerApproval"
        header="Expenses"
        // actions={<span>Actions</span>}
        collapsedNavBar={getCollapsed(this.props)}
        collapsedExpItems={getShowExpenseItems(this.props)}
      >
        {!this.state.openedTask && this.state.errors.length > 0 && (
          <div className="errorBox">
            <CloseIcon
              style={{ float: 'right' }}
              onClick={() => this.setState({ errors: [] })}
            />
            {this.state.errors.map((e, idx) => (
              <p key={idx}>{e}</p>
            ))}
          </div>
        )}
        <div className="tableBar">
          <QuickFilters
            className="quickFilters"
            name="Policy Compliance:"
            values={['According', 'Violating', 'Unknown']}
            onChange={(e) => this.setState({ policyFilter: e })}
          />
          <QuickFilters
            className="quickFilters"
            name="Reimbursability:"
            values={['Reimbursable', 'Non-reimbursable']}
            onChange={(e) => this.setState({ reimbursabilityFilter: e })}
          />
          <div className="selectFilter">
            <Typography>Employees</Typography>
            <Select
              variant="outlined"
              value={this.state.userFilter}
              onChange={(e) => this.setState({ userFilter: e })}
            >
              <MenuItem key="all" value="-">
                All
              </MenuItem>
              {users.map((k) => {
                return (
                  <MenuItem key={k} value={k}>
                    {k}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <Search
            className="search"
            onChange={(e) => this.setState({ searchFilter: e })}
          />
        </div>
        {/*<div>Showing: {tableData.length}/{this.state.expensesCounter}</div>*/}
        {this.props.tasks && !this.state.dataLoading ? (
          <ScrollArea style={{ height: '75vh' }} native>
            <Table
              data={{
                head: {
                  empl_name: <strong>Name of the employee</strong>,
                  type: <span>Expense type</span>,
                  merchant: <span> Merchant </span>,
                  amount: <span>Amount</span>,
                  date: <span>Date</span>,
                  receiptNo: <span>Expense No</span>,
                  action: '',
                },
                content: tableData.map((e) => {
                  const types = e.amounts
                    ? e.amounts
                        .map((taxAmount) => {
                          const type = taxAmount.type;
                          // console.log('type', type);
                          return expenseTypes.includes(type)
                            ? type
                            : type === 'Other'
                            ? 'Other'
                            : customExpenseTypes.find((e) => type === e.id)
                            ? customExpenseTypes.find((e) => type === e.id).name
                            : type;
                        })
                        .join(', ')
                    : e.type;
                  const total = e.amounts
                    ? e.amounts.reduce((prev, curr) => prev + curr.amount, 0)
                    : e.amount;
                  return {
                    row: {
                      empl_name: <strong>{e.empl_name}</strong>,
                      type: <span>{types}</span>,
                      amount: (
                        <span>
                          {total ? total.toFixed(2) : ''} {e.currency}
                        </span>
                      ),
                      merchant: <span>{e.merchant}</span>,
                      date: <span>{moment(e.date).format('DD-MM-YYYY')}</span>,
                      receiptNo: <span>{e.receiptNo}</span>,
                      action: <ChevronRightIcon onClick={this.opening(e)} />,
                    },
                    sortData: {
                      id: e.taskId || e.id,
                      empl_name: e.empl_name,
                      type: types.toUpperCase(),
                      merchant: e.merchant.toLowerCase(),
                      amount: total,
                      date: e.date,
                      receiptNo: e.receiptNo,
                    },
                  };
                }),
              }}
              sortable={true}
              checkable="multi"
              onSelect={(selected) => this.setState({ selected })}
            />
          </ScrollArea>
        ) : (
          <CircularProgress />
        )}

        {this.state.openQuestion && (
          <div className="question-popup">
            <div>
              <p>Ask the employee about this expense</p>
              <TextField
                multiline
                variant="outlined"
                rows="4"
                onChange={(ev) => this.setState({ question: ev.target.value })}
              />
              <div className="buttons">
                <SimpleButton
                  full
                  onClick={() => this.setState({ openQuestion: false })}
                >
                  Cancel
                </SimpleButton>
                <SuccessButton
                  full
                  onClick={() =>
                    MsTeams.askQuestion(
                      this.state.openedTask,
                      this.state.question,
                    ).then(() => this.setState({ openQuestion: false }))
                  }
                >
                  Send
                </SuccessButton>
              </div>
            </div>
          </div>
        )}

        <Drawer direction="left" opened={this.state.openedTask !== undefined}>
          {this.state.openedTask ? (
            <div className="drawerContainer">
              <div className="drawerHeader">
                <Typography>Expense Details</Typography>
                <CloseIcon
                  onClick={() =>
                    this.setState({
                      openedTask: undefined,
                      edit: false,
                      changes: false,
                    })
                  }
                />
              </div>
              {this.state.errors.length > 0 && (
                <div
                  className="errorBox"
                  style={{ margin: '-10px 10px 10px 10px' }}
                >
                  <CloseIcon
                    style={{ float: 'right' }}
                    onClick={() => this.setState({ errors: [] })}
                  />
                  {this.state.errors.map((e, idx) => (
                    <p key={idx}>{e}</p>
                  ))}
                </div>
              )}
              <div className="drawerContent" style={{ width: 775 }}>
                <ScrollArea style={{ height: '100%' }} noScrollX>
                  <ExpenseDetails
                    task={this.state.openedTask}
                    questions={this.state.questions}
                    onChange={(newDetails) =>
                      this.setState({ openedTask: newDetails, changes: true })
                    }
                    expenseTypes={this.props.currentCompany.mapping}
                    onFileChange={(data) =>
                      this.setState({
                        openedTask: {
                          ...this.state.openedTask,
                          base64Value: data.data,
                        },
                        changes: true,
                      })
                    }
                    editFields={this.state.edit}
                    onAskQuestions={() => this.setState({ openQuestion: true })}
                    askQuestions={this.state.askQuestion}
                  />
                </ScrollArea>
              </div>
              <div className="drawerFooter">
                {!this.state.edit ? (
                  <SimpleButton
                    full
                    onClick={() => this.setState({ edit: !this.state.edit })}
                    style={{ marginLeft: '10px' }}
                  >
                    Edit Expense
                  </SimpleButton>
                ) : null}

                {this.state.edit ? (
                  <FailButton
                    full
                    onClick={() =>
                      this.setState({
                        edit: !this.state.edit,
                        openedTask: undefined,
                        changes: false,
                      })
                    }
                    style={{ marginLeft: '10px' }}
                  >
                    Cancel
                  </FailButton>
                ) : null}
                {this.state.changes ? (
                  <SuccessButton
                    full
                    style={{ marginLeft: '10px' }}
                    onClick={this.updateExpense(this.state.openedTask)}
                  >
                    update
                  </SuccessButton>
                ) : null}
                {!this.state.edit ? (
                  <>
                    <FailButton
                      loading={this.state.declineLoading}
                      full
                      style={{ marginLeft: '10px' }}
                      onClick={this.decline(this.state.openedTask)}
                    >
                      decline expense
                    </FailButton>
                    <SuccessButton
                      loading={this.state.acceptLoading}
                      full
                      onClick={this.accept(this.state.openedTask)}
                      style={{ marginLeft: '10px' }}
                    >
                      accept expense
                    </SuccessButton>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </Drawer>
        <Drawer direction="up" opened={this.state.selected.length > 0}>
          <div className="bottomSelect">
            <div>
              <Checkbox checked />
              <span className="itemSelected">{this.state.selected.length}</span>
              item{this.state.selected.length > 1 ? 's' : ''} selected
            </div>
            <div>
              <SuccessButton
                loading={this.state.acceptLoading}
                onClick={this.acceptSelected}
              >
                accept
              </SuccessButton>
              <FailButton
                loading={this.state.declineLoading}
                onClick={this.declineSelected}
              >
                decline
              </FailButton>
            </div>
            <div>
              <span>shift+click</span> to select, <span>esc</span> to deselect
              all
            </div>
          </div>
        </Drawer>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tasks: state.TaskReducer.tasks,
    updateDone: state.TaskReducer.done,
    currentCompany: state.CompanyReducer.currentCompany,
    currentUser: state.UserReducer.currentUser,
    questions: state.TaskReducer.questions,
  };
};

export default connect(mapStateToProps, {
  getData,
  updateTask,
  updateTasks,
  clearDone,
  getQuestions,
})(ManagerApproval);
