import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Checkbox from '../Checkbox';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el.sortData, index, el]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[2]);
}

class EnhancedTable extends Component {
  constructor(props) {
    super();
    const { sortable } = props
    const { head, content } = props.data;
    const cols = Object.keys(head);

    const order = 'asc';
    const orderBy = '';
    const selected = [];
    const body = sortable ? stableSort(content, getSorting(order, orderBy)) : content;

    this.state = {
      sortable, head, body, content, cols,
      order, orderBy, selected
    }

    this.createSortHandler = this.createSortHandler.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.prepareContent = this.prepareContent.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.content !== this.props.data.content) {
      const body = this.prepareContent(this.props.data)
      let selected = {};
      if (prevProps.data.content.length !== this.props.data.content.length) {
        selected = { selected: [] };
      }
      this.setState({ ...body, ...selected });
    }
  }

  prepareContent(data) {
    const content = data.content;
    const body = this.state.sortable ? stableSort(content, getSorting(this.state.order, this.state.orderBy)) : content;
    return ({ content, body });
  }

  createSortHandler = property => event => {
    const order = this.state.orderBy === property && this.state.order === 'desc' ? 'asc' : 'desc';

    const body = stableSort(this.state.content, getSorting(order, property));
    this.setState({ order, orderBy: property, body })
  }

  handleSelectAllClick = event => {
    let newSelected = [];
    if (event.target.checked) {
      newSelected = this.state.body.map(row => row.sortData.id);
    }
    this.setState({ selected: newSelected });
    this.props.onSelect(newSelected)
  }

  handleClick = (event, name) => {
    const selected = this.state.selected;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (this.props.checkable === 'multi') {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
    } else if (this.props.checkable === 'single' || this.props.checkable === true) {
      if (selectedIndex === -1) {
        newSelected = [name];
      } else {
        newSelected = [];
      }
    }

    this.setState({ selected: newSelected });
    this.props.onSelect(newSelected)
  }

  isSelected = name => this.state.selected.indexOf(name) !== -1 

  render() {
    const { head, body, order, orderBy, selected, cols } = this.state;

    const numSelected = selected.length;
    return (
      <Table className={this.props.className}>
        <TableHead>
          <TableRow>
            {this.props.checkable !== undefined ? <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < body.length}
                checked={numSelected === body.length}
                onChange={this.handleSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell> : null}
            {cols.map(col => (
              <TableCell
                key={col}
                sortDirection={orderBy === col ? order : false}
              >
                {this.state.sortable ?
                  <TableSortLabel
                    active={orderBy === col}
                    direction={order}
                    onClick={this.createSortHandler(col)}
                  >
                    {head[col]}
                  </TableSortLabel> : head[col]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        
          {body.map((row, index) => {
            const isItemSelected = this.isSelected(row.sortData.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <React.Fragment key={index}>
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}>
                {this.props.checkable !== undefined ? <TableCell padding="checkbox">
                  <Checkbox
                    onClick={event => this.handleClick(event, row.sortData.id)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  /></TableCell> : null}
                {
                  cols.map(col => <TableCell key={col}>{row.row[col]}</TableCell>)
                }
              </TableRow>
              {row.row.subRow && <TableRow ><TableCell colSpan={cols.length}>{row.row.subRow}</TableCell></TableRow>}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default EnhancedTable;