import firebase from "../components/firebase";

// Create a storage reference from our storage service
const storage = firebase.storage.ref();

const Storage = {
  upload: (path, file, uploadCallback = () => { }) => {
    return new Promise((resolve, reject) => {
      const hash = Math.random().toString(36).substring(2, 6);

      const fileRef = storage.child(path + '/' + hash + '_' + file.name);

      const uploadTask = fileRef.putString(file.data, 'data_url');

      uploadTask.on('state_changed', snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        uploadCallback(progress)
      }, function (error) {
        reject(error);
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => resolve(downloadURL));
      });

    })
  }
}

export default Storage;
