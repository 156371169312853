import axios from 'axios';
import { clexxAPI } from './ClexxAPI';
import { fbFunctions } from '../firebasecomponents/fbFunctions';
import Log from '../newHelpers/Log';
const Logger = new Log('Averix API');

const Averix = {
  uploadDocument: (params) => {
    // const params={
    // "content": "iV",
    // "filename": "documents.png",
    // "documentType": "Expense receipt",
    // "usermail": "gosia.prolejko@esgroup.ch",
    // "deliveryMethod": "clexx",
    // "processId": "gosia123"
    // }
    return new Promise((resolve, reject) => {
      const headers = { 'Access-Control-Allow-Origin': '*' };
      axios
        .post(clexxAPI + 'uploadTaskToAverix', params, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  extractData: (params) => {
    // const params={
    //   "extracted": true,
    //   "expenseId": "gosia123",
    //   "amount": 10000,
    //   "vatAmount": 140,
    //   "vatPercentage": 22,
    //   "currency": "CHF",
    //   "merchantName": "BMW",
    //   "type": 'mileage',
    //   "date": "2019-10-30"
    //   }

    return new Promise((resolve, reject) => {
      const headers = { 'Access-Control-Allow-Origin': '*' };
      axios
        .post(clexxAPI + 'extractDataToAverix', params, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  complianceCheck: (params) => {
    return new Promise((resolve, reject) => {
      // const params = {
      //   "compliant": true,
      //   "expenseId": "vinod123"
      //   }

      const headers = { 'Access-Control-Allow-Origin': '*' };
      axios
        .post(clexxAPI + 'complianceCheckToAverix', params, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  reimbursabilityCheck: (params) => {
    return new Promise((resolve, reject) => {
      // const params = {
      //   "reimbursable": true,
      //   "expenseId": "vinod123"
      //   }

      const headers = { 'Access-Control-Allow-Origin': '*' };
      axios
        .post(clexxAPI + 'reimbursabilityCheckToAverix', params, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  paymentStatement: (params) => {
    return new Promise((resolve, reject) => {
      // const params = {
      //   expenseId, iban, reimbursedAmount, merchantName, receiptDate, repaymentExecutionDate
      //   }

      const headers = { 'Access-Control-Allow-Origin': '*' };
      axios
        .post(clexxAPI + 'reimbursabilityCheckToAverix', params, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  paymentconfirmationCheck: (params) => {
    return new Promise((resolve, reject) => {
      // const params = {
      //   "executed": true,
      //   "expenseId": "vinod123"
      //   }

      const headers = { 'Access-Control-Allow-Origin': '*' };
      axios
        .post(clexxAPI + 'paymentconfirmationCheckToAverix', params, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default Averix;
