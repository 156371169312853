import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import company from '../../firebasecomponents/company';

import CompanyForm from '../../components/CompanyForm';
import { SaveButton, CancelButton } from '../../components/Buttons';
import { Message, validateEmail } from '../../components/Form'

import Page from '../../pages/page.js';

class Company extends Component {

  constructor(props) {
    super(props);

    this.state = this.intialComponentState;

    this.handleChange = this.handleChange.bind(this);
    this.back = this.back.bind(this);
    this.saveCompany = this.saveCompany.bind(this);
    this.setName = this.setName.bind(this);
  }

  get intialComponentState() {
    return {
      companyDetails: {
        streetAndNumber: '',
        postCode: '',
        city: '',
        firstName: '',
        lastName: '',
        telephoneNumber: '',
        emailId: '',
        companyName: ''
      },
      isReadOnly: true,
      loading: false,
      message: '',
      newCompany: true
    }
  }

  componentDidMount() {

    let companyId = this.props.match.params.companyId;
    if (companyId) {
      company.fetchCompanyDetails(companyId).then(response => {
        this.setState({
          newCompany: false,
          companyDetails: response,
          isReadOnly: true
        });
      }).catch(err => {
      });
    } else {
      this.setState({
        isReadOnly: false
      });
    }
    let accountantId = this.props.match.params.accountantId;
    if (accountantId) {
      //TODO: adding company to accountant
    }

  }

  saveCompany() {
    this.setState({ loading: true });

    const docId = this.props.match.params.companyId
    const companyData = this.state.companyDetails;

    if (!validateEmail(companyData.emailId)) {
      this.setState({ message: 'Email is invalid', loading: false });
      return;
    }

    if (companyData.companyName === '') {
      this.setState({ message: 'Name of the company cannot be empty', loading: false });
      return;
    }

    // const call = docId === undefined ? company.addCompanyDetails(companyData,'xyz') : company.updateCompanyDetails({ docId, companyData });

    company.updateCompanyDetails({ docId, companyData })
      .then(response => {
        this.setState({ isReadOnly: true, loading: false });
        this.props.history.goBack();
      })
      .catch(error => {
        this.setState({ message: error.message, loading: false });
      });

  }

  back() {
    this.props.history.goBack();
  };

  handleChange = (e) => {
    this.setState({ companyDetails: e });
  }

  setName = (e) => {
    const companyDetails = this.state.companyDetails;
    companyDetails[e.target.name] = e.target.value;
    this.setState({ companyDetails: companyDetails });
  }


  render() {
    return (
      <Page className={this.state.isReadOnly ? 'read-only' : 'edit'}>

        <div className='left' style={{ textAlign: 'left', marginBottom: '20px' }}>
          <IconButton onClick={this.back} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
          <h2 style={{ color: '#c0548e' }}>
            {this.state.isReadOnly ? this.state.companyDetails.companyName :
              (<FormControl>
                <TextField required
                  fullWidth InputProps={{ readOnly: this.state.isReadOnly }}
                  name="companyName"
                  label="Company Name"
                  value={(this.state.companyDetails.companyName)}
                  onChange={this.setName}
                />
              </FormControl>)}
            {this.state.isReadOnly ?
              <Button onClick={() => this.setState({ isReadOnly: false })} className="settingsButton"><EditIcon />Edit profile</Button>
              : null}
          </h2>
        </div>
        <Message message={this.state.message} type="error" />
        <CompanyForm
          newCompany={this.state.newCompany}
          isReadOnly={this.state.isReadOnly}
          onChange={this.handleChange}
          data={this.state.companyDetails} />

        {!this.state.isReadOnly ?
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SaveButton fullWidth loading={this.state.loading} onClick={this.saveCompany}>
                {this.state.newCompany ? 'Save' : 'Update'}
              </SaveButton>

            </Grid>
            <Grid item xs={6}>
              <CancelButton fullWidth onClick={this.back}>Cancel</CancelButton>

            </Grid>
          </Grid> : null}
      </Page>
    );
  }
}
export default Company;
