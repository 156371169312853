import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class AccountantForm extends Component {
  constructor(props) {
    super(props);
    this.state = { details: props.data };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    const accountantDetails = this.state.details;
    accountantDetails[e.target.name] = e.target.value;
    this.setState({ details: accountantDetails });
    this.props.onChange(accountantDetails);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ details: this.props.data })
    }
  }

  render() {
    const data = this.state.details;
    return (<div className="form">
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Typography variant="h5"> Invoice Address</Typography>
        </Grid>
        <Grid item xs={9} container spacing={4}>
          <Grid item xs={4}>
            <TextField fullWidth
              name="streetAndNumber"
              label="Street and Number"
              InputProps={{ readOnly: this.state.isReadOnly }}
              onChange={e => this.handleChange(e)}
              value={data.streetAndNumber}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              InputProps={{ readOnly: this.state.isReadOnly }}
              name="postCode"
              label="PostCode"
              onChange={e => this.handleChange(e)}
              value={data.postCode}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              InputProps={{ readOnly: this.state.isReadOnly }}
              name="city"
              label="City"
              onChange={e => this.handleChange(e)}
              value={data.city}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5">Responsible Person</Typography>
        </Grid>
        <Grid item xs={9} container spacing={4}>
          <Grid item xs={6}>
            <TextField fullWidth required
              name="firstName"
              label="firstName"
              InputProps={{ readOnly: this.state.isReadOnly }}
              onChange={e => this.handleChange(e)}
              value={data.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth required
            InputProps={{ readOnly: this.state.isReadOnly }}
              name="lastName"
              label="Last Name"
              onChange={e => this.handleChange(e)}
              value={data.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth
              name="bankName"
              label="Bank Name"
              InputProps={{ readOnly: this.state.isReadOnly }}
              onChange={e => this.handleChange(e)}
              value={data.bankName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth
              InputProps={{ readOnly: this.state.isReadOnly }}
              name="bankPlace"
              label="Bank place"
              onChange={e => this.handleChange(e)}
              value={data.bankPlace}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth
              name="telephoneNumber"
              label="Telephone Number"
              InputProps={{ readOnly: this.state.isReadOnly }}
              onChange={e => this.handleChange(e)}
              value={data.telephoneNumber}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth
              InputProps={{ readOnly: this.state.isReadOnly }}
              name="emailId"
              label='CompanyEmailid'
              disabled={!this.props.newAccountant}
              onChange={e => this.handleChange(e)}
              value={data.emailId}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel id=""
              style={{ marginTop: '18px' }}
              control={
                <Checkbox
                  checked={this.state.allowedToGiveApprovalForExpense}
                  value={data.allowedToGiveApprovalForExpense}
                  name="allowedToGiveApprovalForExpense"
                  onChange={e => this.handleChange(e)} />
              }
              label="Allowed to give approval for Expense"
            />
          </Grid>
        </Grid>
      </Grid>
    </div >)
  }
}


export default AccountantForm



