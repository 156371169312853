import React from 'react';
import { connect } from 'react-redux';
import ScrollArea from 'react-scrollbars-custom';

import Page from '../page';
import { clearExpenses, getExpenses, updateExpense } from '../../store/actions';
import * as moment from 'moment';
import dbStatements from '../../firebasecomponents/statements';
import dbTask from '../../firebasecomponents/task';
import dbEmployee from '../../firebasecomponents/employee';

import Select from '../../newComponents/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Table from '../../newComponents/Table';
import QuickFilters from '../../newComponents/QuickFilters';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import Drawer from '../../newComponents/Drawer';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { FailButton, SimpleButton, SuccessButton } from '../../newComponents/Button';
import './reconciliation.scss';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Pwebapps } from '../../externalAPI';
import { NumberFormatCustom } from '../../newComponents/NumberFormatCustom';
import { getCollapsed, getShowExpenseItems } from '../../newHelpers/pageHelper';
import Log from '../../newHelpers/Log';
import { getReconciliationAccounts } from './reconciliationHelper';
const dbCard = dbEmployee.creditCards;
const currencies = require('../../currency.json');
const _ = require('lodash');
const Logger = new Log('Reconciliation');

class Reconciliation extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;

    this.stateFilter = this.stateFilter.bind(this);
    this.reimbursabilityFilter = this.reimbursabilityFilter.bind(this);
    this.expensesFilter = this.expensesFilter.bind(this);
    this.privateFilter = this.privateFilter.bind(this);
    this.transactionFilter = this.transactionFilter.bind(this);
    this.holderExpensesFilter = this.holderExpensesFilter.bind(this);
    this.sortExpensesAmountFilter = this.sortExpensesAmountFilter.bind(this);

    this.opening = this.opening.bind(this);
    this.setTransaction = this.setTransaction.bind(this);
    // this.handleChangeExpense = this.handleChangeExpense.bind(this);
    this.handleChangeExpenseCurrency = this.handleChangeExpenseCurrency.bind(this);
    this.handleChangeTransaction = this.handleChangeTransaction.bind(this);
    this.changeUser = this.changeUser.bind(this);
    this.match = this.match.bind(this);
    this.setPrivate = this.setPrivate.bind(this);
    this.keyListener = this.keyListener.bind(this);
    this.updateTransaction = this.updateTransaction.bind(this);
    this.updateExpense = this.updateExpense.bind(this);
    this.addTask = this.addTask.bind(this);

    // if (props.currentCompany && props.getStatement) {
    //get statements from redux after props have loaded
    // const statement = props.getStatement(this.props.match.params.id);
    // }
    if (this.props.currentCompany) {
      const id = this.props.currentCompany.companyId;
      // dbTask.getTaskBasedOnComapnyId({companyId:this.props.currentCompany.companyId}, response => {this.setState({tasks:response})})
      props.getExpenses(id);
    }
  }

  get initialState() {
    return {
      openedExpense: undefined, // used to open expense details
      openedTransaction: undefined, // used to open transaction details
      reimbursabilityFilter: ['Yes'], // as default for quickfilter
      holderExpensesFilter: ['Yes'], // as default for quickFilter
      updateDone: false,
      openAddTask: false, // to be able to show different detail view after clicking add task, this view is not used right now
      correctiontype: '',
      changedExpenseAmount: false, // if amount of an expense has been changed (i.e. a correction amount has been added)
    };
  }

  async componentDidMount() {
    window.addEventListener('keydown', this.keyListener); //for pressing esc to close detail view
    const id = this.props.match.params.id; //get id from url params from router
    await dbStatements.getStatement({ id }, (response) => {
      // get statements via firebasecomponents.
      this.setState({ statement: response });
      const id = response.card.id;
      dbCard.getCreditCardUsers(id, (res) => {
        const users = res.data().users;
        this.setState({ users: res.data().users });
        const employees = [];
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.keyListener); //remove event listener for esc key
  }

  keyListener(e) {
    //key listener for escape key to close detail view
    if (e.key === 'Escape') {
      this.setState({ openedTransaction: undefined, openedExpense: undefined });
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentCompany !== this.props.currentCompany) {
      // dbTask.getTaskBasedOnComapnyId({companyId:this.props.currentCompany.companyId}, response => {this.setState({tasks:response})})
      this.props.getExpenses(this.props.currentCompany.companyId);
    }

    //   if (prevProps.updateDone !== this.props.updateDone) {
    //   //idea would be here to cleanup tasks, more important if using tasks instead of expenses from redux
    //   if (this.props.updateDone) {
    //     this.props.clearExpenses();
    //   }
    //   this.setState(this.initialState);
    // }
    if (prevState.updateDone !== this.state.updateDone) {
      //get statements
      const id = this.props.match.params.id;
      await dbStatements.getStatement({ id }, (response) => {
        this.setState({ statement: response, updateDone: false });
      });
    }

    if (prevState.users !== this.state.users) {
      //get credit card users + holder information
      const users = this.state.users;
      const employees = [];
      users.push(this.state.statement.holder.id);
      for (let idx in users) {
        const employeeId = users[idx];
        dbEmployee.fetchEmployeeDetailsById({ employeeId }, (response) => {
          employees.push({
            id: employeeId,
            empl_emailId: response.userData.emailid,
            empl_name: response.userData.firstName + ' ' + response.userData.lastName,
          });
          //   })
          this.setState({ employees: employees });
        });
      }
    }
  }

  stateFilter(item) {
    //fiilter s.t. only expenses that are after extract-data get shown
    const filter = this.state.stateFilter;
    if (item.task_defination === 'extract-data') {
      return false;
    } else {
      return true;
    }
  }

  expensesFilter(item) {
    //filter for connected expenses
    const filter = this.state.expensesFilter;
    if ((!filter || filter.length === 0) && item.connected) {
      return false;
    } else {
      return true;
    }
  }

  privateFilter(item) {
    //filter for transactions marked as private
    const filter = this.state.privateFilter;
    if ((!filter || filter.length === 0) && item.private) {
      return false;
    } else {
      return true;
    }
  }

  sortExpensesAmountFilter(tasks) {
    const filter = this.state.sortExpensesAmountFilter;
    if (!filter || filter === 0) return tasks;
    const arr = tasks;

    arr.sort(function (a, b) {
      const a_amount_total = a.amounts
        ? a.amounts.reduce((prev, curr) => prev + curr.amount, 0)
        : 0;
      const b_amount_total = b.amounts
        ? b.amounts.reduce((prev, curr) => prev + curr.amount, 0)
        : 0;
      return Math.abs(a_amount_total - filter) - Math.abs(b_amount_total - filter);
    });
    return arr;
  }

  reimbursabilityFilter(item) {
    //filter for expenses that are non reimbursable only. per default on (see initial state)
    const filter = this.state.reimbursabilityFilter;
    if (filter === undefined || filter === '') return true;
    if (filter && !item.reimbursable) {
      return true;
    }
    if (filter.length === 0) {
      return true;
    }
  }

  transactionFilter(item) {
    //filter for transactions that were connected
    const filter = this.state.transactionFilter;
    if ((!filter || filter.length === 0) && item.expense) {
      return false;
    } else {
      return true;
    }
  }

  holderExpensesFilter(item) {
    //filter for expenses that are connected to thiscredit card
    const filter = this.state.holderExpensesFilter;
    if (!this.state.employees || filter.length === 0) return true;
    const empl = this.state.employees.find((x) => x.empl_emailId === item.empl_emailId);
    if (!empl) {
      return false;
    }
    return true;
  }

  setTransaction(item, index) {
    //open detail view from transaction, set index of the opened transaction
    return () => {
      Logger.log('item', item);
      this.setState({
        openedTransaction: {
          ...item,
        },
        sortExpensesAmountFilter: item.amount,
      });
    };
  }

  opening(item) {
    //open detail view from expense
    return () => {
      Logger.log('item', item);
      this.setState({
        openedExpense: {
          ...item,
        },
      });
      const amounts = item.amounts;
      const idx = amounts.findIndex((x) => x.correction === true);
      if (idx > -1) {
        Logger.log('found correction, setting type', amounts[idx].type);
        this.setState({ correctiontype: amounts[idx].type });
      }
    };
  }

  async addTask() {
    Logger.log('adding task');
    if (!this.state.mappedUser) {
      Logger.log('no mapped user');
      const err = this.state.errors || [];
      err.push('User missing');
      this.setState({ errors: err });
      return;
    }
    const transaction = this.state.openedTransaction;
    const statement = this.state.statement;

    Logger.log('transaction', transaction);
    Logger.log('statement', statement);
    const companyName = this.props.match.params.companyName;
    //get exmployee details from db, since we need email

    //todo as of now, send to extract data, in future send directly to manager-approval
    //but this would mean that a mechanism to change vat type and percentage here would be needed
    const empl = this.state.employees.find((x) => x.empl_emailId === this.state.mappedUser);
    const emplName = empl.empl_name;
    Logger.log('emplName', emplName);
    const task = {
      //todo check if other fields are required in amounts
      base64Value: '',
      // amount_total: transaction.amount,
      merchant: transaction.description,
      date: moment(transaction.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      task_defination: 'extract-data',
      reimbursable: false,
      currency: statement.currency,
      empl_emailId: this.state.mappedUser,
      empl_name: emplName,
      company_id: statement.companyId,
      company_name: companyName,
      text: transaction.comment || '',
      reconciliation: true,
    };
    if (transaction.amounts) {
      Logger.log('has transaction amounts=> merged');
      task.amounts = transaction.amounts;
      let amounts_total = 0;
      for (let idx in task.amounts) {
        amounts_total += parseFloat(task.amounts[idx].amount);
      }
      task.amounts.push({
        amount: parseFloat(transaction.amount) - amounts_total,
        vat_amount: 0,
        amountNoVat: parseFloat(transaction.amount),
        item_name: transaction.description,
        policyFields: {},
        vat_percentage: {
          name: '0.0%',
          value: 0,
        },
      });
    } else {
      Logger.log('does not have transaction amounts. no merge.');
      task.amounts = [];
      task.amounts.push({
        amount: parseFloat(transaction.amount),
        amountNoVat: parseFloat(transaction.amount),
        policyFields: {},
        vat_amount: 0,
        item_name: transaction.description,
        vat_percentage: {
          name: '0.0%',
          value: 0,
        },
      });
    }
    Logger.log('task', task);

    dbTask.addTask(task);
    this.setState({
      openedExpense: undefined,
      openedTransaction: undefined,
      mappedUser: '',
      openAddTask: false,
      errors: [],
    });
  }

  async match() {
    //match transaction with expense
    let transaction = this.state.openedTransaction;
    let statement = this.state.statement;

    //Match for expense
    const expense = {
      ...this.state.openedExpense,
      connected: true,
      creditcard: statement.card && statement.card.accounts ? statement.card.accounts : '',
      statement: {
        id: statement.id,
        transactionId: transaction.id,
        description: transaction.description,
      },
    };

    Logger.log('expense', expense);
    Logger.log('transaction', transaction);
    this.props.updateExpense(expense);
    //Match for transaction
    transaction = {
      ...transaction,
      expense: expense.taskId,
    };
    const transactionIdx = statement.amounts.findIndex((x) => x.id === transaction.id);
    statement.amounts[transactionIdx] = transaction;
    this.setState({ statement: statement });
    //update the full statement
    await dbStatements.updateStatement({ statement }, (response) => {
      this.setState({ statement: response, updateDone: true });
    });
    //reset state
    this.setState({
      openedExpense: undefined,
      openedTransaction: undefined,
      updateDone: true,
      correctiontype: '',
    });
  }

  async setPrivate() {
    //mark a transaction as private and update
    const transaction = { ...this.state.openedTransaction, private: true };
    let statement = this.state.statement;
    const transactionIdx = statement.amounts.findIndex((x) => x.id === transaction.id);
    statement.amounts[transactionIdx] = transaction;
    this.setState({ statement: statement });
    await dbStatements.updateStatement({ statement }, (response) => {
      this.setState({ statement: response, updateDone: true, openedTransaction: undefined });
    });
  }

  async updateTransaction() {
    //comment on a transaction/or update user
    const transaction = this.state.openedTransaction;
    let statement = this.state.statement;
    const transactionIdx = statement.amounts.findIndex((x) => x.id === transaction.id);
    statement.amounts[transactionIdx] = transaction;
    this.setState({ statement: statement });
    await dbStatements.updateStatement({ statement }, (response) => {
      this.setState({ statement: response, updateDone: true });
    });
  }

  updateExpense() {
    //update expense and reset
    Logger.log('updating expense');
    const amounts = _.cloneDeep(this.state.openedExpense.amounts);
    const idx = amounts.findIndex((x) => x.correction === true);
    if (idx > -1 && this.state.correctiontype === '') {
      const err = this.state.errors || [];
      err.push('type missing');
      this.setState({ errors: err });
      return;
    }
    if (idx > -1) {
      amounts[idx].type = this.state.correctiontype;
    }

    const expense = { ...this.state.openedExpense, amounts };
    Logger.log('expense', expense);
    this.props.updateExpense(expense);
    this.setState({
      openedExpense: undefined,
      openedTransaction: undefined,
      updateDone: true,
      correctiontype: '',
      changedExpenseAmount: false,
    });
  }

  handleChangeExpenseAmount = (e) => {
    //save amount in statement index
    Logger.log('opened Expense', this.state.openedExpense);
    Logger.log('tasks', this.props.expenses);
    Logger.log('e', e.target.value);
    this.setState({ changedExpenseAmount: true });
    //calculate total amount from the expense and the difference
    const total_amount_in_expense = this.state.openedExpense.amounts
      ? this.state.openedExpense.amounts.reduce((prev, curr) => prev + curr.amount, 0)
      : 0;
    Logger.log('total am', total_amount_in_expense);
    const newAmount = parseFloat(e.target.value);
    let difference = parseFloat(newAmount - total_amount_in_expense);
    difference = parseFloat(difference.toFixed(2));
    Logger.log('diff', difference);
    if (isNaN(difference)) return;

    const amounts = _.cloneDeep(this.state.openedExpense.amounts);
    //look if a correction position is already present
    const idx = amounts.findIndex((x) => x.correction === true);
    Logger.log('idx', idx);
    if (idx > -1) {
      Logger.log('found', idx, amounts[idx]);
      //is present, adjust difference here
      amounts[idx].amount += difference;
      amounts[idx].amountNoVat += difference;
      if (this.state.openedExpense.currency !== 'CHF') {
        amounts[idx].amountCHF += parseFloat(
          (difference * this.state.openedExpense.rate).toFixed(2)
        );
        amounts[idx].vat_amountCHF = 0;
      }
    } else {
      //create new position
      Logger.log('create new pos');
      Logger.log(difference, typeof difference);
      const correctionAmount = {
        correction: true,
        amount: difference,
        amountNoVat: difference,
        vat_amount: 0,
        item_name: 'Correction Amount',
        vat_percentage: {
          name: '0.0%',
          value: 0,
        },
        policyFields: {},
        type: this.state.correctiontype,
      };
      if (this.state.openedExpense.currency !== 'CHF') {
        correctionAmount.amountCHF = parseFloat(
          (difference * this.state.openedExpense.rate).toFixed(2)
        );
        correctionAmount.vat_amountCHF = 0;
      }
      amounts.push(correctionAmount);
    }
    const expense = {
      ...this.state.openedExpense,
      amounts,
    };
    this.setState({ openedExpense: expense });
  };

  handleChangeExpenseCurrency = async (e) => {
    Logger.log('e', e);
    const date = this.state.openedExpense.date;
    Logger.log('date', date);
    let amounts = _.cloneDeep(this.state.openedExpense.amounts);
    let rate = {};
    const currency = e;
    if (currency !== 'CHF') {
      const { value, multiple } = await Pwebapps.getRateDaily(date, currency);
      rate = { rate: value / multiple };
      Logger.log('rate', rate);
      amounts.forEach((taxAmount) => {
        taxAmount.amountCHF = (taxAmount.amount * value) / multiple;
        taxAmount.vat_amountCHF = (taxAmount.vat_amount * value) / multiple;
      });
    } else {
      rate = { rate: 1.0 };
      amounts.forEach((taxAmount) => {
        delete taxAmount.amountCHF;
        delete taxAmount.vat_amountCHF;
      });
    }
    const expense = {
      ...this.state.openedExpense,
      currency: e,
      amounts,
      ...rate,
    };
    this.setState({ openedExpense: expense });
  };

  handleChangeTransaction = (e) => {
    //save comment in statement index
    const transaction = {
      ...this.state.openedTransaction,
      [e.target.name]: e.target.value,
    };
    this.setState({ openedTransaction: transaction });
  };

  changeUser = (e) => {
    Logger.log('e', e);
    this.setState({ mappedUser: e });
  };

  /**
   * Disable matching functionality if a correction amount for an expense exists, but no type has been assigned.
   * This is required, since every amount must be assigned a type.
   * @returns {boolean}
   */
  checkMatch = () => {
    if (
      this.state.changedExpenseAmount &&
      (!this.state.correctiontype || !this.state.correctiontype.length)
    ) {
      return false;
    }
    return true;
  };

  /**
   * Sets the account for the correction item.
   * @param e {String}
   */
  setCorrectionType = (e) => {
    Logger.log('setting correctiontype', e);
    this.setState({ correctiontype: e });
    if (!this.state.openedExpense) {
      return;
    }
    const amounts = _.cloneDeep(this.state.openedExpense.amounts);
    /*
     check if a correction amount item exists. if it does, set its type.
     In the case that it does not exist yet, it will be set when changing the amount
     */
    const idx = amounts.findIndex((x) => x.correction === true);
    if (idx > -1) {
      amounts[idx].type = e;
      const expense = {
        ...this.state.openedExpense,
        amounts,
      };
      Logger.log('expense', expense);
      this.setState({ openedExpense: expense });
    }
  };

  render() {
    //list of expenses with filters
    let tasks = this.props.expenses
      ? this.props.expenses
          .filter(this.stateFilter)
          .filter(this.reimbursabilityFilter)
          .filter(this.expensesFilter)
          .filter(this.holderExpensesFilter)
          .filter((tasks) => tasks.task_defination !== 'non-readable')
          .filter((tasks) => tasks.task_defination !== 'clarification')
      : [];
    tasks = tasks && tasks.length > 0 ? this.sortExpensesAmountFilter(tasks) : [];

    //list of transactions with filters
    const transactions = this.state.statement
      ? this.state.statement.amounts.filter(this.privateFilter).filter(this.transactionFilter)
      : undefined;

    const employees = this.state.employees ? this.state.employees : [];
    const reconciliationTypes = getReconciliationAccounts(this.props);

    return (
      <Page
        role="Company"
        currentPage="statements"
        header="Reconciliation"
        collapsedNavBar={getCollapsed(this.props)}
        collapsedExpItems={getShowExpenseItems(this.props)}
      >
        {tasks && transactions ? (
          <>
            <div className="tableBar">
              <QuickFilters
                className="quickFilters"
                name="Non-Reimbursable Expenses only"
                values={['Yes']}
                selected={this.state.reimbursabilityFilter}
                onChange={(e) => this.setState({ reimbursabilityFilter: e })}
              />
              <QuickFilters
                className="quickFilters"
                name="Show Connected Expenses"
                values={['Yes']}
                selected={this.state.expensesFilter}
                onChange={(e) => this.setState({ expensesFilter: e })}
              />
              <QuickFilters
                className="quickFilters"
                name="Show Connected Transactions"
                values={['Yes']}
                selected={this.state.transactionFilter}
                onChange={(e) => this.setState({ transactionFilter: e })}
              />
            </div>
            <div className="tableBar">
              <QuickFilters
                className="quickFilters"
                name="Holder's Expenses only"
                values={['Yes']}
                selected={this.state.holderExpensesFilter}
                onChange={(e) => this.setState({ holderExpensesFilter: e })}
              />
              <QuickFilters
                className="quickFilters"
                name="Show Private Transactions"
                values={['Yes']}
                selected={this.state.privateFilter}
                onChange={(e) => this.setState({ privateFilter: e })}
                style={{ marginLeft: '-50px' }}
              />
            </div>
            <Grid container>
              <Grid item xs={4}>
                <h3>Transactions</h3>
                <div style={{ marginTop: '20px' }} />
                <ScrollArea style={{ height: '70vh' }} noScrollX>
                  <Table
                    data={{
                      head: {
                        amount: <span>Amount</span>,
                        description: <span>Description</span>,
                        date: <span>Date</span>,
                        action: '',
                      },
                      content: transactions.map((e, idx) => {
                        const amount = e.amount + ' ' + this.state.statement.currency;
                        return {
                          row: {
                            amount: <span>{amount}</span>,
                            description: <span>{e.description}</span>,
                            date: <span>{e.date}</span>,
                            action: (
                              <ChevronRightIcon
                                onClick={this.setTransaction(e, idx)}
                                style={{ cursor: 'pointer' }}
                              />
                            ),
                          },
                          sortData: {
                            amount: parseFloat(e.amount),
                            description: e.description,
                            date: e.date,
                          },
                        };
                      }),
                    }}
                    sortable={true}
                  />
                </ScrollArea>
              </Grid>
              <Grid item xs={5} style={{ marginLeft: '15px' }}>
                <h3>Expenses</h3>
                <div style={{ marginTop: '20px' }} />
                <ScrollArea style={{ height: '70vh' }} noScrollX>
                  <Table
                    data={{
                      head: {
                        amount: <span>Amount</span>,
                        merchant: <span>Merchant</span>,
                        date: <span>Date</span>,
                        action: '',
                      },
                      content: tasks.map((e, idx) => {
                        const amount_total = e.amounts
                          ? e.amounts.reduce((prev, curr) => prev + curr.amount, 0)
                          : 0;
                        const amount = amount_total + ' ' + e.currency;
                        return {
                          row: {
                            amount: <span>{amount}</span>,
                            merchant: <span>{e.merchant}</span>,
                            date: <span>{e.date}</span>,
                            action: (
                              <ChevronRightIcon
                                onClick={this.opening(e)}
                                style={{ cursor: 'pointer' }}
                              />
                            ),
                          },
                          sortData: {
                            amount: amount_total,
                            merchant: e.merchant,
                            date: e.date,
                          },
                        };
                      }),
                    }}
                    sortable={true}
                  />
                </ScrollArea>
              </Grid>
              <Drawer
                style={{ position: 'fixed', top: '81px' }}
                direction="left"
                opened={
                  this.state.openedExpense !== undefined ||
                  this.state.openedTransaction !== undefined
                }
              >
                <div className="drawerContainer" style={{ maxWidth: 650 }}>
                  <div className="drawerHeader">
                    <Typography> Connect </Typography>
                    <CloseIcon
                      onClick={() =>
                        this.setState({
                          openedExpense: undefined,
                          openedTransaction: undefined,
                          correctiontype: '',
                          changedExpenseAmount: false,
                        })
                      }
                    />
                  </div>
                  <div className="drawerContent" style={{ width: 325, maxWidth: 650 }}>
                    <Box display="flex" justifyContent="center" className="expenseDetails">
                      <div style={{ background: '#FFFFFF', height: '20em' }}>
                        {this.state.openedTransaction ? (
                          <>
                            <ExpansionPanel defaultExpanded>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                Selected Transaction
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <dl>
                                  <dt>Description</dt>
                                  <dd>{this.state.openedTransaction.description}</dd>
                                  <dt>Amount</dt>
                                  <dd>
                                    {this.state.openedTransaction.amount}{' '}
                                    {this.state.statement.currency}
                                  </dd>
                                  <dt>Private?</dt>
                                  <dd>{this.state.openedTransaction.private ? 'true' : 'false'}</dd>
                                </dl>
                                <br />
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        ) : null}
                        {this.state.openedExpense ? (
                          <>
                            <ExpansionPanel defaultExpanded>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                Selected Expense
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <dl>
                                  <dt>ReceiptNo</dt>
                                  <dd>{this.state.openedExpense.receiptNo}</dd>
                                  <dt>Merchant</dt>
                                  <dd>{this.state.openedExpense.merchant}</dd>
                                  {/*{this.state.openedExpense.currency !== 'CHF' ?*/}
                                  {/*  <>*/}
                                  {/*  <dt style={{ paddingTop: '6px' }}>Amount (CHF)</dt>*/}
                                  {/*  <dd>*/}
                                  {/*    <FormControl fullWidth>*/}
                                  {/*      <TextField*/}
                                  {/*        value={*/}
                                  {/*          this.state.openedExpense.amounts ? this.state.openedExpense.amounts.reduce((prev, curr) => prev + curr.amountCHF, 0).toFixed(2) : 0*/}
                                  {/*        }*/}
                                  {/*        disabled*/}
                                  {/*        name="amount_total"*/}
                                  {/*        onChange={this.handleChangeExpenseAmount}*/}
                                  {/*        InputProps={{*/}
                                  {/*          inputComponent:NumberFormatCustom*/}
                                  {/*        }}*/}
                                  {/*      />*/}
                                  {/*    </FormControl>*/}
                                  {/*  </dd>*/}
                                  {/*</>:null}*/}
                                  <dt style={{ paddingTop: '6px' }}>Amount</dt>

                                  <dd>
                                    <TextField
                                      value={
                                        this.state.openedExpense
                                          ? this.state.openedExpense.amounts
                                              .reduce((prev, curr) => prev + curr.amount, 0)
                                              .toFixed(2)
                                          : 0
                                      }
                                      onChange={this.handleChangeExpenseAmount}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      size="small"
                                    />
                                  </dd>
                                  <dt>Currency</dt>
                                  <dd>
                                    <FormControl fullWidth>
                                      <InputLabel htmlFor="currency">Currency</InputLabel>
                                      <Select
                                        value={this.state.openedExpense.currency}
                                        name="currency"
                                        // labelId='currency'
                                        onChange={this.handleChangeExpenseCurrency}
                                      >
                                        <MenuItem key="defaultCHF" value={'CHF'}>
                                          CHF
                                        </MenuItem>
                                        <MenuItem key="defaultEUR" value={'EUR'}>
                                          EUR
                                        </MenuItem>
                                        <MenuItem key="defaultUSD" value={'USD'}>
                                          USD
                                        </MenuItem>
                                        <MenuItem key="defaultJPY" value={'JPY'}>
                                          JPY
                                        </MenuItem>
                                        <MenuItem key="defaultGBP" value={'GBP'}>
                                          GBP
                                        </MenuItem>
                                        <span key="select">_________</span>
                                        {Object.keys(currencies).map((k) => {
                                          return (
                                            <MenuItem key={k} value={k}>
                                              {k} : {currencies[k].name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </dd>
                                  <dt>Correction Account Type</dt>
                                  <dd>
                                    <Select
                                      onChange={(e) => this.setCorrectionType(e)}
                                      value={this.state.correctiontype}
                                    >
                                      {reconciliationTypes.map((k) => {
                                        return (
                                          <MenuItem key={k} value={k}>
                                            {k}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </dd>

                                  {/*<dd>{this.state.openedExpense.currency}</dd>*/}
                                  <dt>Employee</dt>
                                  <dd>{this.state.openedExpense.empl_name}</dd>
                                  <dt>Approved</dt>
                                  <dd>{this.state.openedExpense.approved ? 'true' : 'false'}</dd>
                                  <dt>Connected</dt>
                                  <dd>{this.state.openedExpense.connected ? 'true' : 'false'}</dd>
                                  <dt>State</dt>
                                  <dd>{this.state.openedExpense.task_defination}</dd>
                                  <dt>Description</dt>
                                  <dd>{this.state.openedExpense.description}</dd>
                                </dl>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        ) : null}
                        {this.state.openedTransaction ? (
                          <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Actions
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <TextField
                                multiline
                                label="Comment Transaction"
                                name="comment"
                                value={this.state.openedTransaction.comment || ''}
                                onChange={this.handleChangeTransaction}
                              />
                              <br />
                              {!this.state.openedExpense ? (
                                <Select
                                  label="Map to user"
                                  value={this.state.mappedUser || ''}
                                  onChange={this.changeUser}
                                  required
                                >
                                  {employees.map((e) => (
                                    <MenuItem key={e.id} value={e.empl_emailId}>
                                      {e.empl_name === this.state.statement.holder.name ? (
                                        <strong>{e.empl_name}</strong>
                                      ) : (
                                        e.empl_name
                                      )}
                                    </MenuItem>
                                  ))}
                                </Select>
                              ) : null}
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ) : null}
                        {/*//TODO vat mapping here if needed*/}
                        {/*{this.state.openAddTask?(*/}
                        {/*  <ExpansionPanel defaultExpanded>*/}
                        {/*    <ExpansionPanelSummary*/}
                        {/*      expandIcon={<ExpandMoreIcon />}*/}
                        {/*      aria-controls="panel1a-content"*/}
                        {/*      id="panel1a-header"*/}
                        {/*    >*/}
                        {/*      VAT*/}
                        {/*    </ExpansionPanelSummary>*/}
                        {/*    <ExpansionPanelDetails>*/}
                        {/*      <TaxAmount*/}
                        {/*        key={''}*/}
                        {/*        onChange={this.updateVat}*/}
                        {/*        amount={element}*/}
                        {/*        expenseTypes = {expenseTypes}*/}
                        {/*      />*/}
                        {/*    </ExpansionPanelDetails>*/}
                        {/*  </ExpansionPanel>*/}
                        {/*):null}*/}
                        {this.state.errors
                          ? this.state.errors.map((e) => {
                              return <h3 style={{ color: 'red' }}>{e}</h3>;
                            })
                          : null}
                      </div>
                    </Box>
                  </div>
                  <div
                    className="drawerFooter"
                    style={{
                      position: 'fixed',
                      bottom: '10px',
                      width: '325px',
                    }}
                  >
                    {this.state.openedExpense && !this.state.openedTransaction ? (
                      <SimpleButton onClick={this.updateExpense} full>
                        Update
                      </SimpleButton>
                    ) : null}
                    {this.state.openedTransaction &&
                    !this.state.openedExpense &&
                    !this.state.openAddTask ? (
                      <>
                        <SuccessButton onClick={() => this.setState({ openAddTask: true })} full>
                          Add
                        </SuccessButton>
                        <FailButton onClick={this.setPrivate} full style={{ marginLeft: '5px' }}>
                          Private
                        </FailButton>
                        <SimpleButton
                          onClick={this.updateTransaction}
                          full
                          style={{ marginLeft: '5px' }}
                        >
                          Update
                        </SimpleButton>
                      </>
                    ) : null}
                    {this.state.openAddTask ? (
                      <>
                        <SuccessButton onClick={this.addTask} full>
                          Commit
                        </SuccessButton>
                        <FailButton onClick={() => this.setState({ openAddTask: false })} full>
                          Cancel
                        </FailButton>
                      </>
                    ) : null}
                    {this.state.openedExpense && this.state.openedTransaction ? (
                      <SuccessButton
                        full
                        style={{ marginLeft: '10px' }}
                        onClick={this.match}
                        disabled={!this.checkMatch()}
                      >
                        Match
                      </SuccessButton>
                    ) : null}
                  </div>
                </div>
              </Drawer>
            </Grid>
          </>
        ) : (
          <CircularProgress />
        )}
      </Page>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // statements: state.CompanyReducer.statements,
    expenses: state.ExpenseReducer.expenses,
    updateDone: state.TaskReducer.done,
    currentCompany: state.CompanyReducer.currentCompany,
  };
};

export default connect(mapStateToProps, {
  getExpenses,
  // clearExpenses,
  updateExpense,
})(Reconciliation);
