import React from 'react'

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";
import {ImageComponent} from "../../../newComponents/ImageComponent";

const stateMap ={
  'extract-data':'Processing',
  'compliance-check':'Processing',
  'manager-approval':'Approval Pending',
  'reimbursability-check':'Payment Pending',
  'arrange-payment': 'Payment Pending',
  'payment-confirmation': 'Payment Pending',
  'manager-declined': 'Declined',
  // 'non-reimbursable':'Done',
  'non-reimbursible task':'Done',
  'payment-done':'Done',
  'non-readable':'Not Readable',
};


function OverviewExpenseDetails(props){

  const {task} = props;
  // console.log('task', task)
  const deliveryMethod = task.id.charAt(0) ==='C'? 'Clexx' : task.id.charAt(0) ==='M'?'Mail':task.id.charAt(0) ==='T'?'Teams': '';

  const types = task.amounts ? task.amounts.map(taxAmount => taxAmount.type).join(', ') : task.type
  const total = task.amounts ? task.amounts.reduce((prev, curr) => prev + curr.amount, 0) : task.amount
  const total_chf = task.amounts ? task.amounts.reduce((prev, curr) => prev + curr.amountCHF, 0) : task.amountCHF
  const vat_total = task.amounts ? task.amounts.reduce((prev, curr) => prev + curr.vat_amount, 0) : task.vat_amount
  // console.log('vt', vat_total)
  const taskQuestions = props.questions!==undefined && props.questions.length>1 ? props.questions
    .filter(e => e.taskId === task.taskId)
    .sort((a,b)=>moment(a.createdAt) - moment(b.createdAt)):[]
  // console.log(taskQuestions)

  return task ? (<Box display="flex" justifyContent="center" className="expenseDetails">
    <Grid container spacing={3}>
      <Grid item xs={6} style={{display:'flex'}}>
        <ImageComponent imagePreviewUrl={task ? task.base64Value : null} />
      </Grid>
      <Grid item xs={6}>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Expense Summary
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <dl>
              <dt>Employee Name:</dt><dd>{task.empl_name}</dd>
              <dt>Employee Email:</dt><dd>{task.empl_emailId}</dd>
              <dt>Expense No.:</dt><dd>{task.receiptNo}</dd>
              <dt>State: </dt><dd>{stateMap[task.task_defination]}</dd>
              <dt>Delivery Method:</dt><dd>{deliveryMethod}</dd>
              <dt>Upload Date:</dt><dd>{moment(task.createdAt).format('DD-MM-YYYY')}</dd>
              {task.managerComment ? (<><dt>Comment:</dt><dd>{task.managerComment}</dd></>):null}
              {task.reconciliation?(<><dt>From Reconciliation: </dt><dd>True</dd></>):null}
              {task.approvedBy?(<><dt>Approval By: </dt><dd>{task.approvedBy}</dd></>):null}
              {task.approvedDatum?(<><dt>Approval On: </dt><dd>{moment(task.approvedDatum).format('DD-MM-YYYY')}</dd></>):null}
              {task.paidDatum?(<><dt>Paid On: </dt><dd>{moment(task.paidDatum).format('DD-MM-YYYY')}</dd></>):null}
            </dl>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <hr />
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1b-content"
            id="panel1b-header"
          >
            Expense details
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <dl>
              <dt>Date:</dt><dd>{task.date?moment(task.date).format('DD-MM-YYYY'):''}</dd>
              <dt>Merchant:</dt><dd>{task.merchant}</dd>
              {task.description?(<><dt>Description: </dt><dd>{task.description}</dd></>):null}
              <dt>Total amount:</dt><dd>{total ? total.toFixed(2):''} {task.currency}</dd>
              <dt>Total VAT:</dt><dd>{vat_total || vat_total===0 ? vat_total.toFixed(2): ' '} {task.currency}</dd>
              {total_chf ? <dt>Amount CHF(tax excluded):</dt> : null}
              {total_chf ? <dd>{total_chf.toFixed(2)}CHF FX({task.rate})</dd> : null}
              <dt>Types of expense:</dt><dd>{types}</dd>

              {task.compliant !== undefined && task.compliant !== '' ? <dt>Compliance:</dt> : null}
              {task.compliant !== undefined && task.compliant !== '' ?
                <dd>
                  {task.compliant === true ? <Tooltip title='The expense is ACCEPTED for the policy compliance'><CheckIcon style={{color:"#3BB866"}}/></Tooltip> : null}
                  {task.compliant === false ? <Tooltip title="The expense is DECLINED for the policy compliance"><ClearIcon color='secondary'/></Tooltip> : null}
                  {task.compliant === 'unknown' || task.compliant === undefined? <Tooltip title='The expense needs CONTEXT INFO to determine policy compliance'><HelpIcon/></Tooltip> : null}
                </dd> : null}

              {task.reasonForDecline !== undefined && !task.compliant ?<>
                  <dt>Reason for decline:</dt><dd>{task.reasonForDecline}</dd></>
                : null}

              <dt>Reimbursable:</dt>

              {task.reimbursable === true ? <Tooltip title='Expense is proposed to be reimbursable'><dd>Yes</dd></Tooltip>:null}
              {task.reimbursable === false ? <dd>No</dd>:null}


              {task.comment ? <><dt>Comment: </dt><dd>{task.comment}</dd></> : null}
            </dl>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <hr />
        {task.amounts?
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            Amount details
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {
              task.amounts.map((am, idx) => {
                return <dl key={idx} style={{ paddingBottom: '5px', marginBottom: '5px', borderBottom: '1px solid #eaeaea' }}>
                  <dt>Type:</dt>
                  <dd>{am.type}</dd>
                  <dt>Amount:</dt><dd>{am.amount.toFixed(2)} {task.currency}</dd>
                  <dt>VAT :</dt><dd>{am.vat_amount} ({am.vat_percentage.name})</dd>
                  {am.amountCHF ? <dt>Amount CHF(tax excluded):</dt> : null}
                  {am.amountCHF ? <dd>{am.amountCHF}CHF FX({task.rate})</dd> : null}
                </dl>
              })
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>:null}
        {taskQuestions.length>0?
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              Questions
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {
            taskQuestions.map((q,idx) => {
              return <dl key={idx} style={{ paddingBottom: '5px', marginBottom: '5px', borderBottom: '1px solid #eaeaea' }}>
                <dt>Question:</dt><dd>{moment(q.createdAt).format('DD-MM-YYYY')} - {q.question}</dd>
                <dt>Answer:</dt><dd>{q.answer}</dd>
              </dl>
            })
              }
              </ExpansionPanelDetails>
            </ExpansionPanel>
        :null}
      </Grid>
    </Grid>
  </Box >) : null;

}
export default OverviewExpenseDetails;
