import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-firestore';
import 'firebase/firebase-storage';
import Cookies from 'universal-cookie';
import { production } from '../config';
import Log from "../newHelpers/Log";

const cookies = new Cookies();

//---------------------------------------------
// Production / Dev switch
const config = production
  ? {
      apiKey: 'AIzaSyBmgwtNabAIi9Ne0CAGb5pucv8i8jhGDJU',
      authDomain: 'clexx-eaebf.firebaseapp.com',
      databaseURL: 'https://clexx-eaebf.firebaseio.com',
      projectId: 'clexx-eaebf',
      storageBucket: 'clexx-eaebf.appspot.com',
      messagingSenderId: '836761715391',
      appId: '1:836761715391:web:316d862080027ff6f5bc0a',
      measurementId: 'G-SGVCGWQTRV',
    }
  : {
      apiKey: 'AIzaSyBl0DU7SmOQnA2ojQZdnF-4AHTngLAekRc',
      authDomain: 'clexx-dev-4c089.firebaseapp.com',
      databaseURL: 'https://clexx-dev-4c089.firebaseio.com',
      projectId: 'clexx-dev-4c089',
      storageBucket: 'clexx-dev-4c089.appspot.com',
      messagingSenderId: '160399263571',
      appId: '1:160399263571:web:d25aba7eef94e23cdac3a6',
      measurementId: 'G-6DHB1Y9GZ1',
    };
const Logger = new Log('firebase.js');

/*
  This class initializes the firebase components and is used to get roles based on firebase's user ids.
  Also, registering/login on the firebase side is handled here (logout as well).
 */
class Firebase {
  constructor() {
    app.initializeApp(config);
    //
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  //--------------------------------------------------------------------------------------------------------------------
  //LOGIN/REGISTER/LOGOUT -> pages/AuthPages
  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    cookies.remove('clexxUserRole', { path: '/' });
    return this.auth.signOut();
  }

  async register(name, email, password) {
    await this.auth.createUserWithEmailAndPassword(email, password);
    return this.auth.currentUser.updateProfile({
      displayName: name,
    });
  }

  addRole(role) {
    Logger.log('role', role);
    if (!this.auth.currentUser) {
      return alert('Not authorized');
    }
    return this.db.doc(`roles/${this.auth.currentUser.uid}`).set({
      role,
    });
  }
  addCompanyId(companyID) {
    Logger.log('companyID', companyID);
    if (!this.auth.currentUser) {
      return alert('Not authorized');
    }
    return this.db.doc(`roles/${this.auth.currentUser.uid}`).update({
      companyID,
    });
  }

  getCurrentUsername() {
    return this.auth.currentUser && this.auth.currentUser.displayName;
  }

  /*
  getCurrentUserId() uses firebase internals to return the current UserId.
  https://firebase.google.com/docs/reference/js/firebase.User
   */
  getCurrentUserId() {
    return this.auth.currentUser.uid;
  }

  getCompanyBasedOnUserId() {
    return new Promise((resolve) => {
      if (this.auth.currentUser) {
        const userId = this.auth.currentUser.uid;
        this.db
          .doc(`roles/${userId}`)
          .get()
          .then((roleDoc) => {
            const companyId = roleDoc.get('companyID');
            Logger.log('companyId', companyId);
            resolve(companyId);
          });
      } else {
        resolve(null);
      }
    });
  }

  getCurrentUserRole() {
    return new Promise((resolve) => {
      if (this.auth.currentUser) {
        let role = cookies.get('clexxUserRole');
        if (role === undefined) {
          this.db
            .doc(`roles/${this.auth.currentUser.uid}`)
            .get()
            .then((roleDoc) => {
              role = roleDoc.get('role') || 'Employee';
              cookies.set('clexxUserRole', role, { path: '/' });
              resolve(role);
            });
        } else {
          resolve(role);
        }
      } else {
        resolve(null);
      }
    });
  }
}

export default new Firebase();
