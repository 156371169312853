import moment from 'moment';
import _ from 'lodash';
export const CREDITOR = 'Kreditor';
export const DEBTOR = 'Debitor';
export const IBAN = 'IBAN';
export const MANUAL = 'Manual';
export const ESR = 'ESR';

export const stateFilter = (item, filter) => {
  if (filter === undefined || filter === '-' || filter === null) return true;
  if (filter.includes('new') && (item.state === 'new' || item.state === 'extraction_done')) {
    return true;
  }
  return filter.includes(item.state);
};

export const searchFilter = (item, filter) => {
  //to search for keywords in db, very useful for parashift info that is not shown in table
  if (filter === undefined || filter === '') return true;
  let res = JSON.stringify(item).toLowerCase();
  return res.includes(filter.toLowerCase());
};

export const dateFilter = (item, filter) => {
  //filter for from and until in dates
  if (filter === undefined || filter === '-' || filter === '' || filter.length === 0) {
    return true;
  }
  let itemDate = moment(item.dueDate, 'YYYY-MM-DD');
  let fromDate = moment(filter.fromDates);
  let untilDate = moment(filter.untilDates);
  if (itemDate === undefined || !itemDate.isValid()) {
    return true;
  }
  if (itemDate.isBetween(fromDate, untilDate, 'days', '[]')) {
    return true;
  } else {
    return false;
  }
};
/**
 * filter invoice type for 'Kreditor' or 'Debitor'
 * Returns false if filter is empty
 * @param item : invoice
 * @param filter : array
 * @returns {boolean|*}
 */
export const typeFilter = (item, filter) => {
  if (filter === undefined) {
    return true;
  }
  if (filter.length === 0) {
    return false;
  }
  return filter.includes(item.type);
};

export const invoiceManagementInitialState = {
  openedInvoice: undefined, //opened in detail view on the right
  dataLoading: false, //when data is loading for rendering
  errors: [], //if errors are present when accepting/transferring, array contains those, shown in top of screen
  reset: false,
  invoicesCounter: 0, //initial value for how many invoices were filtered
  acceptLoading: false, //for ui button
  declineLoading: false, //for ui button
};

export const invoiceManagementDefaultFilters = {
  initialFromDate: '', //inital for date picker
  initialUntilDate: '', //initial for date picker
  stateFilter: ['new', 'open', 'approved', 'pending', 'rejected'],
  typeFilter: ['Kreditor', 'Debitor'],
};

export const states = ['new', 'open', 'approved', 'pending', 'paid', 'rejected', 'declined'];

export const clexx_logo =
  'https://firebasestorage.googleapis.com/v0/b/clexx-eaebf.appspot.com/o/clexx_logo.jpg?alt=media&token=33a5358f-e5e7-4472-823d-4e172737373c'; //large

export const getInitState = (props) => {
  const invoiceFilterss = _.cloneDeep(props.invoiceFilters);
  const initState = _.cloneDeep(invoiceManagementInitialState);

  const t = _.merge({}, { ...initState }, { invoiceFilters: { ...invoiceFilterss } });
  if (props && props.location && props.location.state && props.location.state.showOpenCreditor) {
    const invoiceFiltersUpdated = _.cloneDeep(t.invoiceFilters);
    invoiceFiltersUpdated.typeFilter = ['Kreditor'];
    invoiceFiltersUpdated.stateFilter = ['open'];
    t.invoiceFilters = invoiceFiltersUpdated;
  } else if (
    props &&
    props.location &&
    props.location.state &&
    props.location.state.showAllCreditor
  ) {
    const invoiceFiltersUpdated = _.cloneDeep(t.invoiceFilters);
    invoiceFiltersUpdated.typeFilter = ['Kreditor'];
    invoiceFiltersUpdated.stateFilter = ['new', 'open', 'approved', 'pending'];
    t.invoiceFilters = invoiceFiltersUpdated;
  } else if (
    props &&
    props.location &&
    props.location.state &&
    props.location.state.showOpenDebitor
  ) {
    const invoiceFiltersUpdated = _.cloneDeep(t.invoiceFilters);
    invoiceFiltersUpdated.typeFilter = ['Debitor'];
    invoiceFiltersUpdated.stateFilter = ['open'];
    t.invoiceFilters = invoiceFiltersUpdated;
  } else if (
    props &&
    props.location &&
    props.location.state &&
    props.location.state.showAllDebitor
  ) {
    const invoiceFiltersUpdated = _.cloneDeep(t.invoiceFilters);
    invoiceFiltersUpdated.typeFilter = ['Debitor'];
    invoiceFiltersUpdated.stateFilter = ['new', 'open', 'approved', 'pending'];
    t.invoiceFilters = invoiceFiltersUpdated;
  }
  return _.cloneDeep(t);
};

export const checkNewOpenRejectedState = (invoice) => {
  return (
    invoice.state === 'new' ||
    invoice.state === 'extraction_done' ||
    invoice.state === 'open' ||
    invoice.state === 'rejected'
  );
};

export const checkNewOpenState = (invoice) => {
  return invoice.state === 'new' || invoice.state === 'extraction_done' || invoice.state === 'open';
};

export const isCreditor = (invoice) => {
  return invoice.type === 'Kreditor';
};

export const isApproved = (invoice) => {
  return invoice.state === 'approved';
};

export const paymentComplete = (invoice) => {
  if (!invoice.toPay) {
    return true;
  }
  if (!invoice.payment) {
    return false;
  }
  const payment = invoice.payment;
  if (payment.account_number && payment.esr) {
    return true;
  }
  if (payment.qrIBAN && payment.qrESR && payment.bic) {
    return true;
  }
  if (payment.iban && payment.bic) {
    return true;
  }
  return false;
};

export const getInvoicePaymentType = (invoice) => {
  if (!invoice || !invoice.payment) {
    return false;
  }
  if (invoice.payment.esr) {
    return ESR;
  }
  if (invoice.payment.iban) {
    return IBAN;
  }
  return false;
};
