import React from 'react'
import PDFViewer from 'mgr-pdf-viewer-react'

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'

import { taskStepName } from '../taskRedirect';

function TaskOverview(props) {
  const { task } = props;

  let imagePreviewUrl = task ? task.base64Value : null;
  let imagePreview = null;
  if (imagePreviewUrl) {
    imagePreview =
    imagePreviewUrl.includes('.jpg') ||
    imagePreviewUrl.includes('.jpeg') ||
    imagePreviewUrl.includes('.PNG') ||
    imagePreviewUrl.includes('.png') ?
     (<img id='uploadedImage' src={imagePreviewUrl} border='1'
      align="middle" alt=''
      className='center'
    />) :
      <object data={imagePreviewUrl}
              type='application/pdf'
              width='100%'
              height='600px'/>
      // <PDFViewer document={{ url: imagePreviewUrl }} />
   // <embed src={imagePreviewUrl} width="100%" height="100%" type="application/pdf" />;
  } else {
    imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
  }

  return task ? (<Box display="flex" justifyContent="center">
    <Grid container spacing={3}>
      <Grid container item xs={6} spacing={3} justify="flex-start"
        alignItems="flex-start">
        <Grid item xs={12}>
          <h3 style={{ marginBottom: '15px' }}>Step {taskStepName(task.task_defination)}</h3>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField id="type" label="Cost type" value={task.type} />
          </FormControl>
        </Grid>
        {!!task.reimbursable ?
          <Grid item xs={12}>
            <Typography variant="subtitle1" color={task.reimbursable ? 'primary' : 'secondary'}>
              Expense is proposed to be <strong>{task.reimbursable ? '' : 'non-'}reimbursible</strong>
              </Typography>
          </Grid>
          : null}
        {task.compliant !== undefined && task.compliant !== '' ?
          <Grid item xs={12}>
            {task.compliant === true ? <Typography variant="subtitle1" color="primary">The expense is <strong>ACCEPTED</strong> for the policy compliance</Typography>: null}
        {task.compliant === false ? <Typography variant="subtitle1" color="secondary">The expense is <strong>DECLINED</strong> for the policy compliance</Typography>: null}
        {task.compliant === 'unknown' ? <Typography variant="subtitle1" color="secondary">The expense needs <strong>CONTEXT INFO</strong> for determine policy compliance.</Typography>: null}

          </Grid>
          : null}
        {task.reasonForDecline !== undefined && !task.compliant ?
          <Grid item xs={12}><TextField id="ReasonForDecline" label="Reason for decline" value={task.reasonForDecline} /></Grid>
          : null}

        {task.approved !== undefined ?
          <Grid item xs={12}><TextField id="ManagerApproved" label="Manager approved?" value={task.approved} /></Grid>
          : null}
        {task.approved !== undefined && task.approvalReason !== undefined ?
          <Grid item xs={12}><TextField id="ApprovalReason" label="Approval reason:" value={task.approvalReason} /></Grid>
          : null}
      </Grid>
      <Grid item xs={6}>
        {imagePreview}
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <TextField id="Merchant" label="Merchant" value={task.merchant} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField id="Date" label="Date" value={task.date} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField id="Amount" label="Amount" value={task.amount + ' ' + task.currency} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField id="VatAmount" label="VatAmount" value={task.vat_amount} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField id="vat_percentage" label="Vat Percentage" value={task.vat_percentage + '%'} />
        </FormControl>
      </Grid>
      {task.projekt ? <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField id="Project" label="Project" value={task.project} />
        </FormControl>
      </Grid> : null}
      {props.managerApproval ? props.managerApproval : null}
    </Grid>
  </Box >) : null;
}

export default TaskOverview;
