import React from 'react';
import { connect } from 'react-redux';
import PDFViewer from 'mgr-pdf-viewer-react';
import Form from '../../../components/Form';
import Grid from '@material-ui/core/Grid';
import {CREDITOR} from '../invoiceHelper';
import Page from '../../page';
import invoice from '../../../firebasecomponents/invoice';
import Compressor from 'compressorjs';
import { SaveButton, LinkButton } from '../../../components/Buttons';
import Storage from '../../../firebasecomponents/storage';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Parashift } from '../../../externalAPI';
import dbInvoice from '../../../firebasecomponents/invoice';
import Log from '../../../newHelpers/Log';
const Logger = new Log('AddInvoicePage');

class AddInvoicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: null,
      base64: '',
      base64Initial: 'data:image/jpg;base64,',
      file: '',
      imagePreviewUrl: '',
      success: null,
      error: null,
      loading: false,
      type: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.invoiceSubmitClick = this.invoiceSubmitClick.bind(this);
    this.sendPara = this.sendPara.bind(this);
  }

  sendPara = async (base64file, invoiceId, extraction) => {
    Logger.log('addInvoice sendDoc');
    Logger.log(base64file, invoiceId, extraction);
    await Parashift.sendDocv2(invoiceId, base64file, true)
      .then((result) => {
        Logger.log('result', result);
      })
      .catch((err) => Logger.log(err));
  };

  async invoiceSubmitClick() {
    Logger.log('invoiceSubmitClick');
    Logger.log('state', this.state);
    Logger.log('props', this.props);

    this.setState({ loading: true });


    const defaultDebitAccount =
      this.props.currentCompany &&
      this.props.currentCompany.topalBankAccounts &&
      this.props.currentCompany.topalBankAccounts.find(
        (x) => x.default === true,
      );
    Logger.log('defaultDebitAccount', defaultDebitAccount);

    Logger.log('companyId', this.props.currentCompany.companyId);
    let invoiceDetails = {
      amounts: [],
      company_name: this.props.currentCompany.companyName,
      company_id: this.props.currentCompany.companyId,
      base64Value: this.state.imagePreviewUrl,
      state: 'new',
      type: this.state.type,
      extracted: false,
      extraction: this.state.extraction, // full or manual
      toPay: this.state.type === CREDITOR,
      currency: 'CHF',
    };
    if (defaultDebitAccount && defaultDebitAccount.id) {
      invoiceDetails.debitAccounts = defaultDebitAccount.id;
    }

    Storage.upload(
      'invoices/' + invoiceDetails.company_id,
      { name: this.state.file, data: this.state.imagePreviewUrl },
      (progress) => {
        this.setState({ progress });
      },
    )
      .then((url) =>
        invoice.addInvoice({ ...invoiceDetails, base64Value: url }),
      )
      // .then((url)=>this.setState({fileUrl:url},function(){Logger.log('url', this.state.fileUrl)}))
      .then((invoice) => {
        this.setState(
          {
            loading: false,
            success: 'You have uploaded this invoice',
            invoiceId: invoice.invoiceId,
            fileUrl: invoice.base64Value,
          },
          async function () {
            if (this.state.extraction === 'full') {
              Logger.log('invoiceId', this.state.invoiceId);
              Logger.log('fileUrl', this.state.fileUrl);
              await this.sendPara(
                { name: this.state.file, data: this.state.imagePreviewUrl },
                this.state.invoiceId,
                'full-extraction',
              );
            }
          },
        );
        setTimeout(
          // () => this.props.history.replace('invoice/' + invoice.invoiceId),
          () =>
            this.props.history.replace(
              '/company/invoiceManagement/' +
                this.props.currentCompany.companyId +
                '/' +
                this.props.currentCompany.companyName +
                '/invoice/' +
                invoice.invoiceId,
            ),
          3000,
        );
      })
      .catch((err) => {
        this.setState({ loading: false, error: JSON.stringify(err) });
      });
  }

  handleChange(e) {
    if (e.success) {
      this.setState({ success: e.success });
    }
    if (e.error) {
      this.setState({ error: e.error });
    }
    this.setState({ invoiceDetails: e.invoice });
  }

  handleImageChange(e) {
    //from AddTask.js
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    const self = this;
    reader.onloadend = () => {
      self.setState({ file: file.name, imagePreviewUrl: reader.result });
    };
    // handle large images
    if (file['type'].split('/')[0] === 'image') {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 700,
        success(result) {
          if (result.size > 10485760) {
            self.setState({ error: 'File is too big' });
          } else {
            self.setState({ error: null });
            const a = new FileReader();
            a.onloadend = () => {
              self.setState({
                file: file.name,
                imagePreviewUrl: a.result,
              });
            };
            a.readAsDataURL(result);
          }
        },
        error(err) {
          this.setState({ error: err.message });
        },
      });
    } else {
      //handle pdf
      if (file['type'].split('/')[1] !== 'pdf') {
        self.setState({ error: 'We support only images and PDF files' });
      } else if (file.size > 10485760) {
        self.setState({ error: 'Pdf file is too big' });
      } else {
        this.setState({ error: null });
        reader.readAsDataURL(file);
      }
    }
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview =
        imagePreviewUrl.includes('.jpg') ||
        imagePreviewUrl.includes('.jpeg') ||
        imagePreviewUrl.includes('.PNG') ||
        imagePreviewUrl.includes('.png') ? (
          <img
            id="uploadedImage"
            src={imagePreviewUrl}
            border="1"
            align="middle"
            alt=""
            style={{ width: '341px', height: '550px' }}
            className="center"
          />
        ) : (
          <object
            data={imagePreviewUrl}
            type="application/pdf"
            width="100%"
            height="600px"
          />
          // <PDFViewer document={{ url: imagePreviewUrl }} />
        );
      //(<embed src={imagePreviewUrl} type="application/pdf" />);
    } else {
      $imagePreview = (
        <div className="previewText">
          Please select an Image for Preview [Max size 200KB]
        </div>
      );
    }

    return (
      <Page role="Company" header="Add Invoice" currentPage="invoiceManagement">
        <Form
          success={this.state.success}
          error={this.state.error}
          maxwidth={'100%'}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className="previewComponent">
                <form>
                  <input
                    className="fileInput"
                    type="file"
                    onChange={(e) => this.handleImageChange(e)}
                    id="upload"
                  />
                </form>
                <div className="">{$imagePreview}</div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth required style={{ marginBottom: '10px' }}>
                <InputLabel htmlFor="type">Type</InputLabel>
                <Select
                  value={this.state.type || ''}
                  onChange={(e) => this.setState({ type: e.target.value })}
                  name="type"
                  labelId="type"
                >
                  <MenuItem key="Kreditor" value={'Kreditor'}>
                    Creditor
                  </MenuItem>
                  <MenuItem key="Debitor" value={'Debitor'}>
                    Debtor
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth required style={{ marginBottom: '10px' }}>
                <InputLabel htmlFor="type">Extraction Method</InputLabel>
                <Select
                  value={this.state.extraction || ''}
                  onChange={(e) => {
                    this.setState({ extraction: e.target.value });
                  }}
                  name="extraction"
                  labelId="extraction"
                >
                  <MenuItem key="Extraction" value={'full'}>
                    Full Extraction
                  </MenuItem>
                  <MenuItem key="Manual" value={'manual'}>
                    Manual
                  </MenuItem>
                </Select>
              </FormControl>

              <SaveButton
                fullWidth
                loading={this.state.loading}
                // disabled={!imagePreviewUrl}
                onClick={this.invoiceSubmitClick}
              >
                Submit
              </SaveButton>
              <LinkButton
                fullWidth
                to="/company/invoiceManagement"
                color="muted"
              >
                Cancel
              </LinkButton>
            </Grid>
          </Grid>
        </Form>
      </Page>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
  };
};

export default connect(mapStateToProps, {})(AddInvoicePage);
