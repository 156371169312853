import React, { Component } from 'react';
import { connect } from "react-redux";

import ScrollArea from 'react-scrollbars-custom';

import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import { SimpleButton } from '../../newComponents/Button';
import Drawer from '../../newComponents/Drawer';
import Table from '../../newComponents/Table';
import CreditCard from '../../newComponents/CreditCard';

import { getEmployees, updateCreditCard, getCreditCards } from '../../store/actions';

import CreditCardForm from './components/CreditCardForm';

import './customization.scss'

class CreditCards extends Component {

  constructor(props) {
    super(props);

    this.state = this.initialState;

    this.opening = this.opening.bind(this);
    this.updateOpenedCard = this.updateOpenedCard.bind(this);
    this.accept = this.accept.bind(this);
    this.cancel = this.cancel.bind(this);
    this.addCard = this.addCard.bind(this);
  }

  get initialState() {
    return {
      cards: [],
      openedIdx: undefined,
      openedCard: undefined,
      dataLoading: false,
      acceptLoading: false
    }
  }

  componentDidMount() {
    if (this.props.currentCompany) {
      this.props.getEmployees();
      this.props.getCreditCards({ company: this.props.currentCompany.companyId });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.props.getEmployees();
      this.props.getCreditCards({ company: this.props.currentCompany.companyId });
    }
    if (prevProps.creditCards !== this.props.creditCards) {
      const cards = this.props.creditCards;
      this.setState({ cards })
    }
    if (prevProps.employees !== this.props.employees) {
      const employeeNames = {};
      this.props.employees.forEach(e => {
        employeeNames[e.employeeId] = e.firstName + ' ' + e.lastName
      });
      this.setState({ employeeNames })
    }
  }

  opening(openedIdx) {
    return () => this.setState({ openedIdx })
  }
  updateOpenedCard(newCard) {
    this.setState({ openedCard: newCard })
  }

  accept() {
    this.props.updateCreditCard(this.state.openedCard)
    this.cancel();
  }
  cancel() {
    this.setState({
      openedIdx: undefined,
      openedCard: undefined
    })
  }

  addCard() {
    this.setState({
      openedIdx: this.state.cards.length,
      openedCard: {},
    })
  }

  render() {
    const cards = this.state.cards ? this.state.cards : [];

    const cardsTable = <Table
      data={{
        head: {
          type: <strong>Type</strong>,
          holder: <span>Card Holder</span>,
          accounts: <span>Connected Accounts</span>,
          action: ''
        },
        content: cards.map((c, idx) => ({
          row: {
            type: <strong><CreditCard card={c} /></strong>,
            holder: <span>{this.state.employeeNames[c.holder]}</span>,
            accounts: <span>{c.users.map(u => this.state.employeeNames[u])}</span>,
            action: <ChevronRightIcon onClick={this.opening(idx)} />
          },
          sortData: c
        }))
      }
      }
      sortable={true}
    />;

    const openedCard = this.state.openedIdx !== undefined && this.state.cards ? this.state.cards[this.state.openedIdx] || this.state.openedCard : this.state.openedCard;

    const drawer = <Drawer direction="left" opened={openedCard !== undefined}>
      {openedCard ?
        <div className="drawerContainer">
          <div className="drawerHeader">
            <Typography>{openedCard !== {} ? 'Credit Card Details' : 'New Credit Card'}</Typography>
            <CloseIcon onClick={() => this.setState({ openedCard: undefined, openedIdx: undefined })} />
          </div>
          <div className="drawerContent" style={{ width: 775 }}>
            <ScrollArea style={{ height: '100%' }} noScrollX >
              <CreditCardForm
                card={openedCard}
                creditAccounts={this.props.currentCompany.mapping && this.props.currentCompany.mapping.credit ? this.props.currentCompany.mapping.credit : []}
                employees={this.props.employees}
                onChange={this.updateOpenedCard}
              />
            </ScrollArea>
          </div>
          <div className="drawerFooter">
            <SimpleButton
              bordered
              style={{ marginRight: '10px' }}
              onClick={this.cancel}
            >
              Cancel
                </SimpleButton>
            <SimpleButton
              loading={this.state.acceptLoading}
              full
              onClick={this.accept}
            >
              Save Card
                </SimpleButton>
          </div>
        </div>
        : null}
    </Drawer>


    return (<div className="actionPanel">
      <SimpleButton className="actionButton" full icon={AddIcon} onClick={this.addCard}>Add Credit Card</SimpleButton>
      {cardsTable}
      {drawer}
    </div>)
  }
}


const mapStateToProps = state => {
  return {
    employees: state.EmployeeReducer.employees,
    currentCompany: state.CompanyReducer.currentCompany,
    creditCards: state.CreditCardReducer.creditCards
  };
}

export default connect(mapStateToProps, { getEmployees, updateCreditCard, getCreditCards })(CreditCards);