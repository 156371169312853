import React from 'react';
import { connect } from "react-redux";

import { LinearProgress } from '@material-ui/core';

import Login from '../AuthPages/Login';

import ClexxEmployeeDashboard from './ClexxEmployee';
import CompanyDashboard from '../../newPages/ManagerApproval';
import AccountantDashboard from './Accountant';
import ApproverDashboard from './Approver';

import './dashboard.css';

function Dashboard(props) {

  const getChildFromRole = (r) => {
    switch (r) {
      case 'Employee':
        return (<ClexxEmployeeDashboard />)
      case 'Company':
        return (<CompanyDashboard />)
      case 'Accountant':
        return (<AccountantDashboard />)
      case 'Approver':
        return(<ApproverDashboard />)
      case '':
        return (<LinearProgress />)
      default:
        return <Login/>;
    }
  }

  return getChildFromRole(props.role);
}

const mapStateToProps = state => {
  return {
    role: state.UserReducer.currentRole
  };
}

export default connect(mapStateToProps, { })(Dashboard);
