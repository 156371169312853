import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import isEmpty from '../../../newHelpers/isEmpty';
import IconButton from '@material-ui/core/IconButton';
import { PlayArrow } from '@material-ui/icons';
import Select from 'react-select';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { isNil as _isNil, isEmpty as _isEmpty } from 'lodash';
import Log from '../../../newHelpers/Log';
const Logger = new Log('InvoiceItems');

function VatSelection(props) {
  const selectVat = () => {
    if (props.value && props.value.id === -1) {
      return {
        value: props.value,
        label: '0% - No Vat Topal',
      };
    } else if (props.value) {
      return {
        value: props.value,
        label: props.value.value + ' - ' + props.value.code + ' - ' + props.value.name,
      };
    } else {
      return '';
    }
  };
  const [selectedVat, setSelectedVat] = useState(selectVat);

  const [checked, setChecked] = useState({
    isInputTax: true,
  });

  useEffect(() => {
    setSelectedVat(selectVat());
  }, [props.value]);

  let options =
    props.vatAccounts && props.vatAccounts.length > 0
      ? props.vatAccounts
          .filter((e) => e.isInputTax === checked.isInputTax)
          .filter((e) => !e.hidden)
          .map((e) => {
            return {
              value: { code: e.code, id: e.id, name: e.name, value: e.value },
              label: e.value + '% - ' + e.code + ' - ' + e.name,
            };
          })
      : [];
  options.push({
    value: { code: -1, id: -1, name: -1, value: 0 },
    label: '0% - No Vat Topal',
  });

  options.sort((a, b) => {
    return a.value.value - b.value.value;
  });

  const handleVatChange = (e) => {
    setSelectedVat(e);
    props.onChange(e.value);
  };
  const handleCheckbox = (e) => {
    setChecked({ ...checked, [e.target.name]: e.target.checked });
  };
  return (
    <div
      style={{
        marginTop: '5px',
        marginBottom: '5px',
        backgroundColor: '#fafafa',
      }}
    >
      <Select
        isSearchable={true}
        options={options}
        noOptionsMessage={() => 'Sync Vats in Settings'}
        placeholder={'Vat Account'}
        onChange={handleVatChange}
        value={selectedVat}
      />
      <FormGroup row>
        <FormControlLabel
          value="top"
          control={<Checkbox checked={checked.isInputTax} onChange={handleCheckbox} />}
          label="Vorsteuer"
          labelPlacement="start"
          name="isInputTax"
        />
      </FormGroup>
    </div>
  );
}

function InvoiceItems(props) {
  const errors = props.errors || {};
  const bookingAccounts = props.bookingAccounts || {};

  const selectBookingAccount = () => {
    return props.amount && props.amount.bookingAccount
      ? {
          value: props.amount.bookingAccount,
          label: props.amount.bookingAccount.number + ' - ' + props.amount.bookingAccount.name,
        }
      : '';
  };

  const selectVatAmount = () => {
    const tempVatAmount = !_isEmpty(props.amount) ? props.amount : {
      type: '',
      amount: 0,
      amountNoVat: 0,
      vat_amount: 0,
      vat_percentage: 0,
      policyFields: {},
      bookingAccount: '',
    };
    /*
    amountNoVat is not stored in DB, has to be computed.
    this can happen if invoice is updated from extraction => called from useState
    or when component is loaded with invoice => useEffect
     */
    if (_isNil(tempVatAmount.amountNoVat)) {
      tempVatAmount.amountNoVat = +(tempVatAmount.amount - tempVatAmount.vat_amount).toFixed(2);
    }
    return tempVatAmount;
  };

  const setField = (name, value) => {
    Logger.log('name, ', name);
    Logger.log('value, ', value);
    let newValue = { ...vatAmount, [name]: value };
    const vat = newValue.vat_percentage.value;

    if (name === 'amount') {
      Logger.log('setField amount');
      if (!value || isNaN(value)) {
        newValue.amountNoVat = 0;
        newValue.vat_amount = 0;
      } else {
        newValue.vat_amount = ((parseFloat(newValue.amount) * vat) / (100 + vat)).toFixed(2);
        newValue.amountNoVat = (
          parseFloat(newValue.amount) - parseFloat(newValue.vat_amount)
        ).toFixed(2);
      }
    } else if (name === 'amountNoVat') {
      Logger.log('setField amountNoVat');
      if (!value || isNaN(value)) {
        newValue.vat_amount = 0;
        newValue.amount = 0;
      } else {
        newValue.vat_amount = (parseFloat(newValue.amountNoVat) * (vat / 100)).toFixed(2);
        newValue.amount = (
          parseFloat(newValue.amountNoVat) + parseFloat(newValue.vat_amount)
        ).toFixed(2);
      }
    } else if (name === 'vat_percentage') {
      Logger.log('setField vat_percentage');
      if (isNaN(newValue.amount)) {
        newValue.amount = 0;
      }
      newValue.vat_amount = ((parseFloat(newValue.amount) * vat) / (100 + vat)).toFixed(2);
      newValue.amountNoVat = (
        parseFloat(newValue.amount) - parseFloat(newValue.vat_amount)
      ).toFixed(2);
    }

    setVatAmount(newValue);
    props.onChange(newValue);
  };

  const [selectedBookingAccount, setSelectedBookingAccount] = useState(selectBookingAccount);
  const [checked, setChecked] = useState({
    Aufwand: true,
    Aktiven: true,
    Ertrag: false,
    Passiven: false,
  });

  const [vatAmount, setVatAmount] = useState(selectVatAmount);

  const handleChange = (e) => {
    Logger.log('handle Change e', e);
    setField(e.target.name, e.target.value);
  };

  const handleBookingAccountChange = (e) => {
    Logger.log('handle Change e', e);
    if (e) {
      setField('bookingAccount', e.value);
      setSelectedBookingAccount(e);
    } else {
      setSelectedBookingAccount('');
    }
  };

  const handleSwap = (e) => {
    setField('amountNoVat', vatAmount.amount);
  };

  useEffect(() => {
    setSelectedBookingAccount(selectBookingAccount());
    setVatAmount(selectVatAmount());
  }, [props.amount]);

  const handleCheckbox = (e) => {
    setChecked({ ...checked, [e.target.name]: e.target.checked });
  };

  const options =
    bookingAccounts && bookingAccounts.length > 0
      ? bookingAccounts
          .map((e) => {
            return {
              value: e,
              label: e.number + ' - ' + e.name,
            };
          })
          .filter((e) => checked[e.value.accountTypeName] === true)
      : [];

  return (
    <div
      style={{
        padding: '15px',
        border: '1px solid #eaeaea',
        borderRadius: '5px',
        marginTop: '10px',
        backgroundColor: '#fafafa',
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            value={vatAmount.item_name}
            name="item_name"
            label="Item Name"
            fullWidth
            multiline
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          {!isEmpty(bookingAccounts) ? (
            <div
              style={{
                marginTop: '5px',
                marginBottom: '5px',
                backgroundColor: '#fafafa',
              }}
            >
              {errors && errors.type ? <p style={{ color: 'red' }}>{errors.type}</p> : null}
              <Select
                isSearchable={true}
                options={options}
                noOptionsMessage={() => 'Sync Booking Accounts from Topal first'}
                placeholder={'Booking Account'}
                onChange={handleBookingAccountChange}
                value={selectedBookingAccount}
                style={{ backgroundColor: 'white' }}
              />
              <FormGroup row>
                <FormControlLabel
                  value="top"
                  control={<Checkbox checked={checked.Aufwand} onChange={handleCheckbox} />}
                  label="Aufwand"
                  labelPlacement="start"
                  name="Aufwand"
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox checked={checked.Aktiven} onChange={handleCheckbox} />}
                  label="Aktiven"
                  name="Aktiven"
                  labelPlacement="start"
                />

                <FormControlLabel
                  value="top"
                  control={<Checkbox checked={checked.Ertrag} onChange={handleCheckbox} />}
                  label="Ertrag"
                  labelPlacement="start"
                  name="Ertrag"
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox checked={checked.Passiven} onChange={handleCheckbox} />}
                  label="Passiven"
                  name="Passiven"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
          ) : (
            <div>Sync Booking Accounts from Topal first</div>
          )}
        </Grid>
        <Grid item xs={6}>
          <VatSelection
            value={vatAmount.vat_percentage}
            vatAccounts={props.vatAccounts}
            onChange={(data) => setField('vat_percentage', data)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              name="amount"
              label="Amount (with tax)"
              error={!!errors.amount}
              helperText={errors.amount}
              onChange={handleChange}
              value={vatAmount.amount}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="secondary" style={{ marginLeft: '-10px' }} onClick={handleSwap}>
            <PlayArrow />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              name="amountNoVat"
              label="Amount (without tax)"
              error={!!errors.amountNoVat}
              helperText={errors.amountNoVat}
              onChange={handleChange}
              value={vatAmount.amountNoVat}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              type="number"
              name="vat_amount"
              label="VAT Amount"
              error={!!errors.vat_amount}
              helperText={errors.vat_amount}
              onChange={handleChange}
              value={vatAmount.vat_amount || 0}
              disabled
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

export default InvoiceItems;
