
import moment from 'moment';
import xml2js from 'xml2js';


function processCamt053(data, employees, cards) {

  const errors = [];

  const name = data.Acct[0].Ownr[0].Nm[0];

  const thisEmp = employees.find(e => name.includes(e.firstName) && name.includes(e.lastName));
  if (!thisEmp) {
    errors.push('Employee not found: ' + name);
    return { entries: [], errors };
  }
  const holder = [thisEmp]
    .map(e => ({
      name: e.firstName + ' ' + e.lastName,
      id: e.employeeId,
    }));

  // const card = data.Acct[0].Id[0].IBAN[0];
  // const cardDetails = cards.find(c => c.holder === thisEmp.id && d.cardNumber.includes(c.number))

  // if (!cardDetails) {
  //   errors.push('Employee does not have this card ' + card);
  //   return
  // }


  const amounts = data.Ntry.map(n =>
    ({
      date: moment(n.BookgDt[0].Dt[0]),
      description: n.AddtlNtryInf[0],
      amount: n.Amt[0]._,
      currency: n.Amt[0].$.Ccy
    })
  )
  const currencies = amounts.map(a => a.currency)

  let currency = currencies.sort((a, b) => currencies.filter(v => v === a).length - currencies.filter(v => v === b).length).pop();
  return {
    minDate: moment(data.FrDtTm),
    maxDate: moment(data.ToDtTm),
    card: data.Acct[0].Id[0].IBAN[0].substr(data.Acct[0].Id[0].IBAN[0].length - 4, 4),
    holder,
    currency,
    amounts
  }
}



export default (data, employees, cards) => {
  var reader = new FileReader();
  reader.onload = function () {
    var text = reader.result;
    xml2js.parseString(text, (err, result) => {
      if (result && result.Document &&
        result.Document.BkToCstmrStmt && result.Document.BkToCstmrStmt.length > 0 &&
        result.Document.BkToCstmrStmt[0].Stmt && result.Document.BkToCstmrStmt[0].Stmt.length > 0) { //process camt.053
        const entry = processCamt053(result.Document.BkToCstmrStmt[0].Stmt[0], employees, cards );
        console.log(entry)
      }
    })
  };
  reader.readAsText(data);

}