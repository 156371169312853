import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

import { LinkButton } from '../../components/Buttons';
import SortTask from '../../components/SortTask';
import { TaskOverview } from '../../components/TaskDetails';

import task from '../../firebasecomponents/task';
import employee from '../../firebasecomponents/employee';
import Page from '../../pages/page.js';

import './EmployeeDetails.css'


class EmployeeDetail extends Component {

  constructor(props) {
    super(props);

    this.state = this.intialComponentState;

    this.back = this.back.bind(this);
    this.setTaskDetails = this.setTaskDetails.bind(this);
  }


  back = () => {
    this.props.history.goBack();
  }

  get intialComponentState() {
    return {
      task: null,
      previousId: '',
      emplyWiseTasks: null,
      emplName: null,
      monthtasks: {}
    }
  }


  componentDidMount() {
    let emailid = this.props.match.params.emplId;
    task.getTaskBasedOnEmailId({ emailid }, response => {
      const monthtasks = this.state.monthtasks;
      response.forEach(t => {
        const month = this.getMonth(t.createdAt)
        if (Object.keys(this.state.monthtasks).includes(month)) {
          monthtasks[month].push(t);
        } else {
          monthtasks[month] = [t];
        }
      });
      this.setState({ emplyWiseTasks: response, monthtasks });
    });

    employee.fetchEmployeeDetailsByEmailId({ empl_emailId: emailid }, response => {
      this.setState({ emplName: response.name })
    });
  };

  getMonth(date) {
    const d = new Date(Date.parse(date));
    return d.getMonth();
  }

  setTaskDetails(task) {
    this.setState({ task })
  }

  render() {

    const taskDetails = this.state.emplyWiseTasks ? this.state.emplyWiseTasks.map(taskDetail =>
      (<Button
        color='primary'
        fullWidth
        onClick={() => this.setTaskDetails(taskDetail)}
        key={taskDetail.id}
      >{taskDetail.createdAt + ': '+taskDetail.date + ' ' + taskDetail.currency + ' ' + taskDetail.amount}</Button>
      )
    ) : null;

    const task = this.state.task ? (<TaskOverview task={this.state.task} />) : null;


    return (
      <Page>
        <div className='left' style={{ textAlign: 'left', marginBottom: '20px' }}>
          <IconButton onClick={this.back} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
          <h2 style={{ color: '#c0548e' }}>
            {this.state.emplName}
            <LinkButton to={'/employee/profile/' + this.props.match.params.emplId} className="settingsButton"><SettingsIcon />View profile</LinkButton>
          </h2>
        </div>
        <Tabs>
          <TabList>
            <Tab>Task List</Tab>
            <Tab>Month Overview</Tab>
          </TabList>
          <TabPanel>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {taskDetails}
              </Grid>
              <Grid item xs={8}>
                {task}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SortTask tasksMap={this.state.monthtasks} click={this.setTaskDetails} />
              </Grid>
              <Grid item xs={8}>
                {task}
              </Grid>
            </Grid>
          </TabPanel>
        </Tabs>

      </Page>
    );
  }
}

export default EmployeeDetail;
