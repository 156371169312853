import React, { useState } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import HelpIcon from '@material-ui/icons/Help';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';

import EditIcon from '@material-ui/icons/Edit';

import Select from '../../../newComponents/Select';
import { SimpleButton } from '../../../newComponents/Button';

import {
  CostCenterSelect,
  CustomerSelect,
} from '../../../newComponents/SelectCostObject';

import './expenseDetails.scss';
import Dropzone from '../../../newComponents/Dropzone';
import moment from 'moment';
import { Pwebapps } from '../../../externalAPI';
import { FormControl } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { NumberFormatCustom } from '../../../newComponents/NumberFormatCustom';
import {
  changeAmountCHF,
  changeAmountFC, changeVatCHF,
  changeVatFC,
  vatValues,
} from '../../../newHelpers/amountsHelper';

const defaultExpenseTypes = require('../../../expenses.json').expenses;
const currencies = require('../../../currency.json');

function ExpenseDetails(props) {
  const { task, onChange, onFileChange } = props;
  const [edit, setEdit] = useState(task.amounts.map((am, idx) => false));
  const [editCustom, setEditCustom] = useState(
    task.amounts.map((am, idx) => (am.type === 'Other' ? true : false)),
  );


  const handleChange = (e) => {
    onChange({ ...task, managerComment: e.target.value });
  };
  const setExpenseType = (idx) => (value) => {
    const am = task.amounts;
    am[idx].type = value;
    onChange({ ...task, amounts: am });
    if (value === 'Other' && customExpenseTypes) {
      const editList = [...editCustom];
      editList[idx] = true;
      setEditCustom(editList);
    } else {
      const editList = [...edit];
      editList[idx] = false;
      setEdit(editList);
    }
  };

  const handleChangeExpenseCurrency = async (e) => {
    // console.log('e change expense currency', e);
    const date = task.date;
    // console.log('date', date)
    let amounts = task.amounts;
    let rate = {};
    const currency = e;
    if (currency !== 'CHF') {
      const { value, multiple } = await Pwebapps.getRateDaily(date, currency);
      rate = { rate: value / multiple };
      // console.log('rate', rate);
      amounts.forEach((taxAmount) => {
        taxAmount.amountCHF = Number(
          ((taxAmount.amount * value) / multiple).toFixed(2),
        );
        taxAmount.vat_amountCHF = Number(
          ((taxAmount.vat_amount * value) / multiple).toFixed(2),
        );
      });
    } else {
      //is CHF
      rate = { rate: 1.0 };
      amounts.forEach((taxAmount) => {
        delete taxAmount.amountCHF;
        delete taxAmount.vat_amountCHF;
      });
    }
    const expense = {
      ...task,
      currency: e,
      amounts,
      ...rate,
    };
    // this.setState({ openedExpense: expense });
    // console.log('esp', expense)
    await onChange({ ...task, currency: e, amounts, ...rate });
  };

  const handleChangeVat = (idx) => (value) => {
    // console.log('idx', idx, 'value', value)
    const am = task.amounts;
    if (task.currency !== 'CHF') {
      //is FC
      const tempItem = changeVatFC(value, am[idx], task.rate);
      am[idx] = tempItem;
    } else {
      //is CHF
      const tempItem = changeVatCHF(value, am[idx])
      am[idx] = tempItem;
    }
    onChange({ ...task, amounts: am });
  };

  const handleQuestionClick = (e) => {
    props.onAskQuestions();
    e.stopPropagation();
  };

  const setAmount = (idx) => (e) => {
    //set amount for CHF
    // console.log('idx', idx);
    //delete after 2 decimal places
    // let dec = e.target.value.indexOf(".")
    // console.log('dec', dec)
    // let tooLong = e.target.value.length > dec + 3
    // console.log('tooLong', tooLong)
    // let invalidNum = isNaN(parseFloat(e.target.value))
    // console.log('inv num', invalidNum)

    const val = Number(e.target.value); //convert to number
    // const val = e.target.value//convert to number
    // console.log('val', val, typeof val);

    const am = task.amounts;
    const vat = am[idx].vat_percentage.value;

    am[idx].amount = val;
    am[idx].vat_amount = +((am[idx].amount * vat) / (100 + vat)).toFixed(2);

    if (task.currency === 'CHF') {
      //is CHF
      const tempItem = changeAmountCHF(val, am[idx]);
      am[idx] = tempItem;
    } else {
      //is FC
      const tempItem = changeAmountFC(val, am[idx], task.rate);
      am[idx] = tempItem;
    }
    onChange({ ...task, amounts: am });
  };

  const setExpenseCustom = (idx) => (value) => {
    const am = task.amounts;
    am[idx].type = value;
    onChange({ ...task, amounts: am });
    const editcList = [...editCustom];
    editcList[idx] = false;
    setEditCustom(editcList);
    const editList = [...edit];
    editList[idx] = false;
    setEdit(editList);
  };

  const editType = (idx) => () => {
    const editList = [...edit];
    editList[idx] = true;
    setEdit(editList);
  };

  const setTask = (field) => (data) => {
    onChange({ ...task, [field]: data });
  };

  const setField = (field) => (data) => {
    // console.log('field', 'data', field, data.target.value)
    onChange({ ...task, [field]: data.target.value });
  };

  let imagePreviewUrl = task ? task.base64Value : null;
  let imagePreview = null;
  if (imagePreviewUrl) {
    imagePreview =
      imagePreviewUrl.includes('.jpg') ||
      imagePreviewUrl.includes('.jpeg') ||
      imagePreviewUrl.includes('.PNG') ||
      imagePreviewUrl.includes('.png') ? (
        <img
          id="uploadedImage"
          src={imagePreviewUrl}
          align="middle"
          alt=""
          className="center"
        />
      ) : (
        <object
          data={imagePreviewUrl}
          type="application/pdf"
          width="100%"
          height="600px"
        />
      );
  } else {
    imagePreview = (
      <Dropzone
        label={'Drag and Drop, or click to select image/pdf of expense'}
        bucket={'expenses/' + task.company_id}
        onChange={onFileChange}
      />
    );
  }

  const types = task.amounts
    ? task.amounts.map((taxAmount) => taxAmount.type).join(', ')
    : task.type;
  const total = task.amounts
    ? task.amounts.reduce((prev, curr) => prev + parseFloat(curr.amount), 0)
    : task.amount;
  const total_chf = task.amounts
    ? task.amounts.reduce((prev, curr) => prev + curr.amountCHF, 0)
    : task.amountCHF;
  const vat_total = task.amounts
    ? task.amounts.reduce((prev, curr) => prev + curr.vat_amount, 0)
    : task.vat_amount;

  // const expenseTypes = props.expenseTypes && props.expenseTypes.expense ? props.expenseTypes.expense.map(e => e.expenseType) : undefined || defaultExpenseTypes;
  const expenseTypes =
    props.expenseTypes &&
    props.expenseTypes.expense &&
    props.expenseTypes.expense.length > 0
      ? props.expenseTypes.expense.map((e) => e.expenseType)
      : defaultExpenseTypes;
  const customExpenseTypes =
    props.expenseTypes &&
    props.expenseTypes.custom &&
    props.expenseTypes.custom.length > 0
      ? props.expenseTypes.custom.map((e) => ({ id: e.id, name: e.name }))
      : undefined;

  // console.log('props', props);
  // console.log('details expense types', expenseTypes);
  // console.log('details cust expense types', customExpenseTypes);
  // console.log('task', task);
  const deliveryMethod =
    task.id.charAt(0) === 'C'
      ? 'Clexx'
      : task.id.charAt(0) === 'M'
      ? 'Mail'
      : task.id.charAt(0) === 'T'
      ? 'Teams'
      : '';

  const taskQuestions =
    props.questions !== undefined && props.questions.length > 1
      ? props.questions
          .filter((e) => e.taskId === task.taskId)
          .sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
      : [];

  const editFields = props.editFields;

  return task ? (
    <Box display="flex" justifyContent="center" className="expenseDetails">
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ display: 'flex' }}>
          {imagePreview}
        </Grid>
        <Grid item xs={6}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Expense Summary
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <dl>
                <dt>Employee Name:</dt>
                <dd>{task.empl_name}</dd>
                <dt>Employee Email:</dt>
                <dd>{task.empl_emailId}</dd>
                <dt>Expense No.:</dt>
                <dd>{task.receiptNo}</dd>
                {task.description ? (
                  <>
                    <dt>Description: </dt>
                    <dd>{task.description}</dd>
                  </>
                ) : null}
                <dt>Delivery Method:</dt>
                <dd>{deliveryMethod}</dd>
                {task.reconciliation ? (
                  <>
                    <dt>From Reconciliation: </dt>
                    <dd>True</dd>
                  </>
                ) : null}
              </dl>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr />
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1b-content"
              id="panel1b-header"
            >
              Expense details
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <dl>
                <dt>Date:</dt>
                <dd>{moment(task.date).format('DD-MM-YYYY')}</dd>
                <dt>Merchant:</dt>
                {editFields ? (
                  <TextField
                    value={task.merchant}
                    onChange={setField('merchant')}
                    style={{ width: '50%' }}
                  />
                ) : (
                  <dd>{task.merchant}</dd>
                )}
                <dt>Total amount:</dt>
                <dd>
                  {total.toFixed(2)} {task.currency}
                </dd>
                {editFields ? (
                  <>
                    <dt>Currency:</dt>
                    <FormControl style={{ width: '50%' }}>
                      <Select
                        name="currency"
                        value={task.currency}
                        onChange={handleChangeExpenseCurrency}
                        // style={{width:'100', maxWidth:'100%'}}
                      >
                        <MenuItem key="defaultCHF" value={'CHF'}>
                          CHF
                        </MenuItem>
                        <MenuItem key="defaultEUR" value={'EUR'}>
                          EUR
                        </MenuItem>
                        <MenuItem key="defaultUSD" value={'USD'}>
                          USD
                        </MenuItem>
                        <MenuItem key="defaultJPY" value={'JPY'}>
                          JPY
                        </MenuItem>
                        <MenuItem key="defaultGBP" value={'GBP'}>
                          GBP
                        </MenuItem>
                        <span key="select">_________</span>
                        {Object.keys(currencies).map((k) => {
                          return (
                            <MenuItem key={k} value={k} className="menuItem">
                              {k} : {currencies[k].name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </>
                ) : null}
                <dt>Total VAT:</dt>
                <dd>
                  {vat_total.toFixed(2)} {task.currency}
                </dd>
                {total_chf ? <dt>Amount CHF:</dt> : null}
                {total_chf ? (
                  <dd>
                    {total_chf.toFixed(2)} CHF FX({task.rate})
                  </dd>
                ) : null}
                <dt>Types of expense:</dt>
                <dd>{types}</dd>

                {task.compliant !== undefined && task.compliant !== '' ? (
                  <dt>Compliance:</dt>
                ) : null}
                {task.compliant !== undefined && task.compliant !== '' ? (
                  <dd>
                    {task.compliant === true ? (
                      <Tooltip title="The expense is ACCEPTED for the policy compliance">
                        <CheckIcon style={{ color: '#3BB866' }} />
                      </Tooltip>
                    ) : null}
                    {task.compliant === false ? (
                      <Tooltip title="The expense is DECLINED for the policy compliance">
                        <ClearIcon color="secondary" />
                      </Tooltip>
                    ) : null}
                    {task.compliant === 'unknown' ||
                    task.compliant === undefined ? (
                      <Tooltip title="The expense needs CONTEXT INFO to determine policy compliance">
                        <HelpIcon />
                      </Tooltip>
                    ) : null}
                  </dd>
                ) : null}
                {task.reasonForDecline ? <dt>Reason for decline:</dt> : null}
                {task.reasonForDecline ? (
                  <dd>{task.reasonForDecline}</dd>
                ) : null}

                <dt>Reimbursable:</dt>
                {/*{task.reimbursable === true && !editFields ? <Tooltip title='Expense is proposed to be reimbursable'><CheckIcon style={{color:"#3BB866"}}/></Tooltip>:null}*/}
                {task.reimbursable === true && !editFields ? (
                  <Tooltip title="Expense is proposed to be reimbursable">
                    <dd>Yes</dd>
                  </Tooltip>
                ) : null}
                {task.reimbursable === false && !editFields ? (
                  <dd>No</dd>
                ) : null}
                {/*{task.reimbursable === false && !editFields ? <Tooltip title='Expense is proposed to be non-reimbursable'><ClearIcon color='secondary'/></Tooltip>:null}*/}

                {editFields ? (
                  <FormControl style={{ width: '50%' }}>
                    <Select
                      name="reimbursable"
                      value={task.reimbursable}
                      onChange={setTask('reimbursable')}
                    >
                      <MenuItem key="true" value={true}>
                        Yes
                      </MenuItem>
                      <MenuItem key="false" value={false}>
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
              </dl>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr />
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              Amount details
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {task.amounts.map((am, idx) => {
                return (
                  <dl
                    key={idx}
                    style={{
                      paddingBottom: '5px',
                      marginBottom: '5px',
                      borderBottom: '1px solid #eaeaea',
                    }}
                  >
                    <dt>Type:</dt>
                    {edit[idx] ? (
                      <dd>
                        {editCustom[idx] ? (
                          <Select
                            onChange={setExpenseCustom(idx)}
                            value={am.type}
                          >
                            <MenuItem value="Other" disabled>
                              Select Other Type
                            </MenuItem>
                            {customExpenseTypes !== undefined
                              ? customExpenseTypes.map((e, idx) => {
                                  // return (<MenuItem key={idx} value={e.id} >{e.name}</MenuItem>)
                                  return (
                                    <MenuItem key={idx} value={e.name}>
                                      {e.name}
                                    </MenuItem>
                                  );
                                })
                              : null}
                          </Select>
                        ) : (
                          <Select
                            onChange={setExpenseType(idx)}
                            value={am.type}
                          >
                            {expenseTypes.map((e, idx) => {
                              return (
                                <MenuItem key={idx} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })}
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        )}
                      </dd>
                    ) : !editFields ? (
                      <dd>
                        {expenseTypes.includes(am.type)
                          ? am.type
                          : am.type === 'Other' || am.type === 'other'
                          ? 'Other'
                          : // customExpenseTypes.find(e=>am.type===e.id).name}
                            am.type}
                      </dd>
                    ) : (
                      <dd>
                        {expenseTypes.includes(am.type)
                          ? am.type
                          : am.type === 'Other' || am.type === 'other'
                          ? 'Other'
                          : // customExpenseTypes.find(e=>am.type===e.id).name}
                            am.type}
                        <SimpleButton
                          onClick={editType(idx)}
                          icon={EditIcon}
                          iconified
                        >
                          OK
                        </SimpleButton>
                      </dd>
                    )}
                    <dt>Amount:</dt>
                    {editFields ? (
                      // <TextField value={am.amount.toString()}
                      <TextField
                        value={am.amount.toFixed(2)}
                        onChange={setAmount(idx)}
                        // inputProps={{ min: "0", step: "0.01" }}
                        // type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        size="small"
                        style={{ width: '50%' }}
                      />
                    ) : (
                      <dd>
                        {am.amount} {task.currency}
                      </dd>
                    )}
                    <dt>Amount without VAT:</dt>
                    <dd>
                      {(am.amount - am.vat_amount).toFixed(2)} {task.currency}
                    </dd>

                    <dt>VAT :</dt>
                    {editFields ? (
                      <Select
                        name="vat_percentage"
                        value={am.vat_percentage.name}
                        onChange={handleChangeVat(idx)}
                      >
                        {Object.keys(vatValues).map((v) => {
                          return (
                            <MenuItem key={v} value={v}>
                              {v}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      <dd>
                        {am.vat_amount} ({am.vat_percentage.name})
                      </dd>
                    )}
                    {am.amountCHF ? <dt>Amount CHF:</dt> : null}
                    {am.amountCHF ? (
                      <dd>
                        {am.amountCHF.toFixed(2)}CHF FX({task.rate})
                      </dd>
                    ) : null}
                  </dl>
                );
              })}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr />
          <>
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                Questions
                {props.askQuestions ? (
                  <SimpleButton
                    full
                    style={{
                      marginLeft: '23%',
                      height: '25px',
                    }}
                    onClick={handleQuestionClick}
                  >
                    Ask Questions
                  </SimpleButton>
                ) : null}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {!props.askQuestions ? (
                  <span>Employee is not connected</span>
                ) : null}
                {taskQuestions.length > 0 ? (
                  taskQuestions.map((q, idx) => {
                    return (
                      <dl
                        key={idx}
                        style={{
                          paddingBottom: '5px',
                          marginBottom: '5px',
                          borderBottom: '1px solid #eaeaea',
                        }}
                      >
                        <dt>Question:</dt>
                        <dd>
                          {moment(q.createdAt).format('DD-MM-YYYY')} -{' '}
                          {q.question}
                        </dd>
                        <dt>Answer:</dt>
                        <dd>{q.answer}</dd>
                      </dl>
                    );
                  })
                ) : (
                  <div />
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <hr />
          </>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              Comment
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <TextField
                name=""
                label="Your comment here..."
                multiline
                rows="4"
                value={task.managerComment || ''}
                variant="outlined"
                onChange={handleChange}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr />
          {task.costCenter || task.customer ? (
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                Mapping
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {task.costCenter ? (
                  <div className="box">
                    <CostCenterSelect
                      label="Map to Cost Center"
                      value={task.costCenter}
                      onChange={setTask('costCenter')}
                    />
                  </div>
                ) : null}
                {task.customer ? (
                  <div className="box">
                    <CustomerSelect
                      label="Map to Customer"
                      value={task.customer}
                      onChange={setTask('customer')}
                    />
                  </div>
                ) : null}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : (
            <div />
          )}
        </Grid>
      </Grid>

      {/*
        {task.reasonForDecline !== undefined && !task.compliant ?
          <Grid item xs={12}><TextField id="ReasonForDecline" label="Reason for decline" value={task.reasonForDecline} /></Grid>
          : null}

        {task.approved !== undefined ?
          <Grid item xs={12}><TextField id="ManagerApproved" label="Manager approved?" value={task.approved} /></Grid>
          : null}
        {task.approved !== undefined && task.approvalReason !== undefined ?
          <Grid item xs={12}><TextField id="ApprovalReason" label="Approval reason:" value={task.approvalReason} /></Grid>
          : null}
      </Grid>
      <Grid item xs={6}>

      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField id="VatAmount" label="VatAmount" value={task.vat_amount} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField id="vat_percentage" label="Vat Percentage" value={task.vat_percentage + '%'} />
        </FormControl>
      </Grid>
      {task.projekt ? <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField id="Project" label="Project" value={task.project} />
        </FormControl>
      </Grid> : null}
      {props.managerApproval ? props.managerApproval : null}
    </Grid> */}
    </Box>
  ) : null;
}

export default ExpenseDetails;
