import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Parashift } from '../../../externalAPI';
import dbInvoice from '../../../firebasecomponents/invoice';
import { SimpleButton } from '../../../newComponents/Button';
import Log from '../../../newHelpers/Log';
const Logger = new Log('InvoiceParashiftDetails');

function InvoiceParashiftDetails(props) {
  const invoice = props.data;
  const parashift = invoice.parashift;

  const [loadingParashift, setLoadingParashift] = useState(false);
  Logger.log('invoice', invoice);

  const getDoc = (id) => {
    setLoadingParashift(true);
    Parashift.getDocv2(id)
      .then((result) => {
        return dbInvoice.updateInvoice({
          invoiceId: invoice.invoiceId,
          'parashift.data': result.data,
          'parashift.included': result.included,
          'parashift.current_status': result.data.attributes.current_status,
        });
      })
      .then((result) => {
        setLoadingParashift(false);
      })
      .catch((err) => {
        setLoadingParashift(false);
      });
  };

  return (
    <Grid item xs={12}>
      {parashift === undefined ? (
        <CircularProgress />
      ) : parashift.current_status !== 'done' ? (
        <div>
          <SimpleButton full onClick={() => getDoc(parashift.id)}>
            Get Extraction Result
          </SimpleButton>
        </div>
      ) : (
        <div />
      )}
      {loadingParashift ? <CircularProgress /> : null}
    </Grid>
  );
}
export default InvoiceParashiftDetails;
