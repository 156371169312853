import { createStore, applyMiddleware, compose, combineReducers  } from "redux";
import thunk from "redux-thunk";

import * as reducers from './reducers';

//combine all reducers into one
const rootReducer = combineReducers(reducers);

// to use with Extension https://github.com/zalmoxisus/redux-devtools-extension
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//using redux-thunk for async requests
const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(thunk))
);

export default store;
