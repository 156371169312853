import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  root: {
    textTransform: 'capitalize;',
    color: '#757575',
    fontSize: 13,
  },
  label: {
    fontWeight: 700,
    whiteSpace: "nowrap",
  },
  item: {
    whiteSpace: "nowrap",
    margin: '0 3px',
    padding: 10,
    cursor: 'pointer',
    paddingRight:'38px',
  },
  selected: {
    margin: '0 3px',
    whiteSpace: "nowrap",
    padding: 10,
    backgroundColor: '#BD2B8210',
    borderRadius: 20,
    color: '#BD2B82',
    '& svg': {
      verticalAlign: 'bottom',
    },
  },
});

function QuickFilters(props) {
  const { name, values, className, reset, onChange, ...rest } = props;
  const classes = useStyles();
  let [selected, setSelected] = useState(props.selected || []);

  const select = (val) => () => {

    const idx = selected.indexOf(val);
    if (idx === -1) {
      selected.push(val);
    } else {
      selected.splice(idx, 1);
    }
    setSelected([...selected]);
    onChange(selected);
  };


  //clear the selection filters, if selected elements props has been passed, then take those (for the case, that the
  //filter is on per default), or clear the filters, given by props.reset
  useEffect(()=>{
    if(props.reset){
      setSelected(props.selected || [])
    }
  },[props])

  return (
    <div {...rest} className={classes.root + ' ' + className}>
      <span className={classes.label}>{name}</span>
      {values.map((v) => (
        <span
          key={v}
          className={
            classes.item +
            ' ' +
            (selected.includes(v) ? classes.selected : '')
          }
          onClick={select(v)}
        >
          {v} {selected.includes(v) ? <CloseIcon /> : null}
        </span>
      ))}
    </div>
  );
}

export default QuickFilters;
