import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Page from '../page';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router';

import './navigationDashboard.scss';
import { getInvoices } from '../../store/ducks/invoice';

import { getCollapsed, getShowExpenseItems } from '../../newHelpers/pageHelper';
import BanksView from './Components/BanksView';
import CreditorView from './Components/CreditorView';
import ExpenseView from './Components/ExpenseView';
import DebitorView from './Components/DebitorView';
import { getData } from '../../store/ducks/task';

const NavigationDashboard = (props) => {
  const company = props.currentCompany ? props.currentCompany : null;
  const [invoices, setInvoices] = useState();
  const [tasks, setTasks] = useState();

  useEffect(() => {
    if (props.currentCompany) {
      props.getInvoices(props.currentCompany.companyId, {});
      props.getData(props.currentCompany.companyId, {
        task_defination: 'manager-approval',
      });
    }
  }, [props.currentCompany]);

  useEffect(() => {
    if (props.invoices) {
      setInvoices(props.invoices);
    }
    if (props.tasks) {
      setTasks(props.tasks);
    }
  }, [props.invoices, props.tasks]);
  const link = '/company/invoiceManagement(companyLink)';
  const { companyId, companyName } = useParams();
  const companyLink =
    companyId === undefined ? '' : `/${companyId}/${companyName}`;
  const url = link.replace('(companyLink)', companyLink);
  const expensesLink = '/company/managerApproval(companyLink)';
  const expensesUrl = expensesLink.replace('(companyLink)', companyLink);
  return (
    <Page
      className="navigationDashboard"
      role="employee"
      header="Dashboard"
      currentPage="dashboard"
      actions={''}
      collapsedNavBar={getCollapsed(props)}
      collapsedExpItems={getShowExpenseItems(props)}
    >
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <BanksView company={company} />
          <CreditorView url={url} invoices={invoices} />
          <DebitorView url={url} invoices={invoices} />
        </Grid>

        {/*Right Expense Side*/}
        <Grid item xs={3}>
          <ExpenseView company={company} tasks={tasks} url={expensesUrl} />
        </Grid>
      </Grid>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    tasks: state.TaskReducer.tasks,
    currentCompany: state.CompanyReducer.currentCompany,
    invoices: state.InvoiceReducer.invoices,
  };
};

export default connect(mapStateToProps, { getInvoices, getData })(
  NavigationDashboard,
);
