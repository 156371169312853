import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { calculateDebitorInvoicesTotal } from '../navigationHelper';

const DebitorView = ({ url, invoices }) => {
  const [totalDebitors, setTotalDebitors] = useState('0');
  const [totalDebitors10d, setTotalDebitors10d] = useState('0');
  const [totalDebitorsOverdue, setTotalDebitorsOverdue] = useState('0');
  const [totalDebitorsNotApproved, setTotalDebitorsNotApproved] = useState('0');
  const [notApprovedAmount, setNotApprovedAmount] = useState(0);
  const [
    totalDebitorsNotApproved10d,
    setTotalDebitorsNotApproved10d,
  ] = useState('0');
  const [
    totalDebitorsNotApprovedOverdue,
    setTotalDebitorsNotApprovedOverdue,
  ] = useState('0');

  useEffect(() => {
    if (invoices) {
      const calcVals = calculateDebitorInvoicesTotal(invoices);
      setTotalDebitors(calcVals.totalDebitorSum);
      setTotalDebitors10d(calcVals.totalDebitor10d);
      setTotalDebitorsOverdue(calcVals.totalDebitorOverdue);
      setTotalDebitorsNotApproved(calcVals.totalDebitorsNotApproved);
      setTotalDebitorsNotApproved10d(calcVals.totalDebitorsNotApproved10d);
      setTotalDebitorsNotApprovedOverdue(
        calcVals.totalDebitorsNotApprovedOverdue,
      );
      setNotApprovedAmount(calcVals.notApprovedAmount);
    }
  }, [invoices]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="box-style creditorBox"
    >
      <div className="title">
        <Typography variant="h6" className="grey2">
          Open Customer Invoices
        </Typography>
        <Link
          to={{
            pathname: url,
            state: { showAllDebitor: true },
          }}
          style={{ textDecorationLine: 'none', alignSelf: 'flex-end' }}
        >
          <Typography className="purple">Show All</Typography>
        </Link>
      </div>
      <div className="borderDiv" />
      <div style={{ marginTop: '18px' }} />
      <Grid container spacing={0} style={{}}>
        {/*Left Side*/}
        <Grid item xs={6} style={{ borderRight: '1px solid #E0E0E0' }}>
          <Typography>Total Open Invoices</Typography>
          <Typography variant="h3" className="black">
            {totalDebitors}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Due in the next 10 days:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Overdue:</Typography>
            </Grid>
            <Grid item xs={6} className="alignedGridItem">
              <Typography variant="h6" className="blackalt">
                {totalDebitors10d}
              </Typography>
            </Grid>
            <Grid item xs={6} className="alignedGridItem">
              <Typography variant="h6" className="blackalt">
                {totalDebitorsOverdue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*Right Side*/}
        {/*padding of 32 comes from 24/2px in the middle + 20px*/}
        <Grid item xs={6} style={{ paddingLeft: '32px' }}>
          <div style={{ position: 'relative' }}>
            <Typography>Not Yet Approved</Typography>
            <Link
              to={{
                pathname: url,
                state: { showOpenDebitor: true },
              }}
              style={{ textDecorationLine: 'none' }}
            >
              <div className="circle absolute">
                <p className="textInCircle">{notApprovedAmount}</p>
              </div>
            </Link>
          </div>
          <Typography variant="h3">{totalDebitorsNotApproved}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Due in the next 10 days:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Overdue:</Typography>
            </Grid>
            <Grid item xs={6} className="alignedGridItem">
              <Typography variant="h5">
                {totalDebitorsNotApproved10d}
              </Typography>
            </Grid>
            <Grid item xs={6} className="alignedGridItem">
              <Typography variant="h5">
                {totalDebitorsNotApprovedOverdue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DebitorView;
