import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {

  },
  label: {
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'block',
    marginBottom: '10px'
  },
  input: {
    fontSize: '14px',
    border: '1px solid #DFE3E9',
    fontFamily: 'Space Grotesk',
    borderRadius: '4px',
    height: '36px',
    padding: '0 10px',
    width: '100%',
    outline: 'none',
    '&:focus': {
      borderColor: '#434b54'
    }
  },
});

function camelize(str = '') {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

function TextField(props) {
  const { value, type, label, disabled, required, inputProps, onChange, placeholder, ...rest } = props;
  const classes = useStyles(props);

  const id = camelize(label) + Math.random().toString(36).substring(2, 8);

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return <div className={classes.root} {...rest}>
    <label className={classes.label} htmlFor={id}>{label}{required ? '*' : null}</label>
    <input
      className={classes.input}
      type={type || 'text'}
      id={id}
      required={required}
      disabled={disabled}
      value={value || ''}
      onChange={handleChange}
      placeholder={placeholder}
      {...inputProps}
    />
  </div>
}


export default TextField;