import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import MenuItem from '@material-ui/core/MenuItem';

import TextField from '../../../newComponents/TextField';
import Select from '../../../newComponents/Select';
import Radio from "@material-ui/core/Radio";

function ExpenseMap(props) {

  const { expenseTypes, accountTypes, onChange, checkBox, setDefault, checked } = props;

  const [data, setData] = useState(props.data)

  const handleChange = (field) => (value) => {
    const newData = {...data, [field]: value};

    setData(newData);
    onChange(newData);
  }

  return (<Grid container spacing={2}>
    {expenseTypes ?
      <Grid item xs={3}>
        <Select
          label="Expense Type"
          onChange={handleChange('expenseType')}
          value={data.expenseType || '-'}
        >
          <MenuItem value={data.expenseType || '-'} disabled={!data.expenseType}>{data.expenseType || 'Choose Expense Type'}</MenuItem>
          {expenseTypes.map((e, i) => <MenuItem key={i} value={e} >{e}</MenuItem>)}
        </Select>
      </Grid>
      : null}
    <Grid item xs={3}>
      <TextField value={data.name} label="Account Name" placeholder="Enter your Account Name" onChange={handleChange('name')} />
    </Grid>
    <Grid item xs={3}>
      <TextField value={data.number} label="Account Number" placeholder="Enter your Account Number"  onChange={handleChange('number')} />
    </Grid>
    {accountTypes ?
      <Grid item xs={3}>
        <Select
          label="Account Type"
          onChange={handleChange('accountType')}
          value={data.accountType || '-'}
        >
          <MenuItem value='-' disabled>Choose your Account Type</MenuItem>
          {accountTypes.map((e, i) => <MenuItem key={i} value={e} >{e}</MenuItem>)}
        </Select>
        </Grid>
      : null}
    {checkBox ?
      <Grid item xs={3}>
        <h5 style={{fontSize:'14px'}}>Default</h5>
        <Radio checked={checked} value={data} onChange={setDefault} style={{marginTop:'7px'}}/>
      </Grid>
      : null}
  </Grid>)
}

export default ExpenseMap;
