import React from 'react'

import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

function Search(props) {
  const { onChange, ...rest } = props;
  return     (
    <TextField variant="outlined"
      type='search'
      placeholder="Search for..."
      value={props.value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...rest}
    />)
}

export default Search
