import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import task from '../../firebasecomponents/task';

import { SortTaskMonth } from '../../components/SortTask';
import Loading from '../../components/Loading';
import { TaskOverview } from '../../components/TaskDetails';
import GenerateReport from '../../components/GenerateReport';

import firebase from '../../components/firebase';

import Page from '../../pages/page.js';

import './MonthOverview.css';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

class MonthOverview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      monthWiseTasks: null,
      year: this.props.match.params.year,
      companyId: this.props.match.params.companyId,
      task: null,
      openDownload: false,
    }

    this.back = this.back.bind(this);
  }

  back = () => {
    this.props.history.goBack();
  }

  componentDidMount() {
    let companyId = this.props.match.params.companyId;

    if (companyId === undefined) {
      companyId = firebase.getCurrentUserId();
    }


    this.setState({ companyId })

    task.getTasksByCompanyAndYear(companyId, this.state.year, response => {

      const months = {};
      response.forEach(t => {
        const date = t.date === '' ? t.createdAt : t.date;
        const m = monthNames[(new Date(Date.parse(date))).getMonth()] + ' ' + this.state.year;
        const detail = t.empl_name + ' [' + t.task_defination + ']: ' + t.amount + ' ' + t.currency
        if (months[m] !== undefined) {
          months[m].push({ ...t, detail });
        } else {
          months[m] = [{ ...t, detail }];
        }
      });

      this.setState({ monthWiseTasks: months });

    });

  }



  render() {
    const task = this.state.task ? (<TaskOverview task={this.state.task} />) : null;

    return (
      <Page>
        <div className='left' style={{ textAlign: 'left', marginBottom: '20px' }}>
          <IconButton onClick={this.back} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
          <h2 style={{ color: '#c0548e' }}>
            Year {this.state.year}
            <Button onClick={() => this.setState({ openDownload: true })} className="settingsButton"><GetAppIcon />Download</Button>
          </h2>
        </div>
        <Loading loading={!this.state.monthWiseTasks} />
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <SortTaskMonth tasksMap={this.state.monthWiseTasks} click={(task) => this.setState({ task })} />
          </Grid>
          <Grid item xs={8}>
            {task}
          </Grid>
        </Grid>
        {this.state.openDownload ?
          <GenerateReport companyId={this.state.companyId} year={this.state.year} closed={() => this.setState({ openDownload: false })} />
          : null}
      </Page>
    );
  }
}

export default MonthOverview;
