import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { LinkButton } from '../Buttons';

class ManagerApprovalForm extends Component {

  constructor(props) {
    super(props);

    this.state = this.state = {
      taskDetails: this.props.task
    };
  }

  render() {
    const task = this.state.taskDetails;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">This task is waiting for manager approval.</Typography>
        </Grid>
        <Grid item xs={12}>
          {task.compliant === true ? <Typography variant="subtitle1">The expense is <strong>ACCEPTED</strong> for the policy compliance</Typography> : null}
          {task.compliant === false ? <Typography variant="subtitle1">The expense is <strong>DECLINED</strong> for the policy compliance</Typography> : null}
          {task.compliant === 'unknown' ? <Typography variant="subtitle1">The expense needs <strong>CONTEXT INFO</strong> for determine policy compliance.</Typography> : null}
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField disabled
              id="type" label="Type" value={(task.type)}
            />
          </FormControl>
        </Grid> */}
        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="merchant" label="Merchant" value={(task.merchant)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="date" label="Date" value={(task.date)}
          />
        </Grid>

        {
          task.amounts.map((elem, idx) => {
            // const add = this.getAdditionalFields(elem.policyFields);
            return (
              <Grid item xs={12} key={idx}>
                <strong style={{ textTransform: 'uppercase', marginRight: '10px' }}>{idx + 1}. {(elem.type)}</strong><br/>

                {/* {add ? add.label + ': ' + add.value : null}<br /> */}
                <TextField name="amount" label="Amount" value={elem.amount} disabled />
                <span style={{ marginTop: '24px', display: 'inline-block' }}>VAT amount: {elem.vat_amount} {task.currency} ({elem.vat_percentage.name})</span>
              </Grid>);
          })
        }

        {/* <Grid item xs={6}>
          <TextField fullWidth disabled
            id="amount" label="Amount" value={(task.amount)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="vat_amount" label="VAT Amount" value={(task.vat_amount)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="vat_percentage" label="VAT Percentage" value={(task.vat_percentage)}
          />
        </Grid> 
        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="currency" label="Currency" value={(task.currency)}
          />
        </Grid>*/}

        <Grid item xs={4}>
          <LinkButton fullWidth to='/' color="muted">Cancel</LinkButton>
        </Grid>
      </Grid>

    );
  }
}
export default ManagerApprovalForm;

