export const vatValues = {
  '7.7%': 7.7,
  '3.7%': 3.7,
  '2.5%': 2.5,
  '0.0%': 0,
  Bezugsteuer: 0,
};

export const changeAmountFC = (newAmount, amountItem, rate) => {
  const t = changeAmountCHF(newAmount, amountItem);
  t.amountCHF = parseFloat((newAmount * rate).toFixed(2));
  t.vat_amountCHF = parseFloat((t.vat_amount * rate).toFixed(2));
  return t;
};

export const changeAmountCHF = (newAmount, amountItem) => {
  const t = { ...amountItem };
  const vat = amountItem.vat_percentage.value;
  t.amount = newAmount;
  t.vat_amount = +((newAmount * vat) / (100 + vat)).toFixed(2);
  return t;
};

export const changeVatFC = (newValue, amountItem, rate) => {
  const t = changeVatCHF(newValue, amountItem);
  t.vat_amountCHF = parseFloat((t.vat_amount * rate).toFixed(2));
  return t;
};

export const changeVatCHF = (newValue, amountItem) => {
  const t = { ...amountItem };
  const vat = vatValues[newValue]; //expects 0.0%, 7.7%, Bezugsteuer etc.
  t.vat_percentage.value = vat;
  t.vat_percentage.name = newValue.toString();
  t.vat_amount = +((t.amount * vat) / (100 + vat)).toFixed(2);
  return t;
};
