import React, { Component } from 'react'
import { connect } from "react-redux";


import { getData, updateTask, updateTasks, clearDone } from "../../store/actions";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import GeneralInformation from './GeneralInformation';
import AccountMapping from './AccountMapping';
import ApproverInformation from '../Customization/ApproverInformation';
import TopalSettings from "./TopalSettings";

import Page from '../page';

import TabPanel from '../../newComponents/TabPanel'

import './settings.scss'
import {getCollapsed, getShowExpenseItems} from "../../newHelpers/pageHelper";


class Settings extends Component {

  constructor(props) {
    super(props)
    this.state = { value: 0 };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <Page role='Company'
            currentPage="settings"
            header="Settings"
            actions={''}
            collapsedNavBar={getCollapsed(this.props)}
            collapsedExpItems={getShowExpenseItems(this.props)}
      >

        <Tabs value={this.state.value} onChange={this.handleChange} style={{ overflow: 'visible' }}>
          <Tab label="General Information" />
          <Tab label="Account Mapping" />
          <Tab label="Topal Invoices"/>
        </Tabs>

        <TabPanel value={this.state.value} index={0}>
          <GeneralInformation/>
          </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <AccountMapping/>
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          <TopalSettings/>
        </TabPanel>
      </Page>
    )
  }
}


const mapStateToProps = state => {
  return {
    tasks: state.TaskReducer.tasks,
    updateDone: state.TaskReducer.done,
    currentCompany: state.CompanyReducer.currentCompany,
  };
}

export default connect(mapStateToProps, { getData, updateTask, updateTasks, clearDone })(Settings);
