import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { SimpleButton } from '../../newComponents/Button';
import TextField from '../../newComponents/TextField';

import CostObject, {useStyles} from './components/CostObject';
import { updateCustomer, getCustomers } from '../../store/actions';

import './customization.scss'




function Customers(props) {

  const [customers, setCustomers] = useState({})
  const [newCustomer, setNewCustomer] = useState(false)
  const [newCustomerName, setNewCustomerName] = useState('')

  useEffect(() => {
    if (props.currentCompany !== undefined) {
      props.getCustomers(props.currentCompany.companyId);
    }
  }, [props.currentCompany])

  useEffect(() => {
    if(props.customers !== undefined){
      const newCustomers ={};
      props.customers.forEach(c => newCustomers[c.id] = c);
      setCustomers(newCustomers || {})
    }
  }, [setCustomers, props.customers])

  const classes = useStyles(props);
  const addCustomer = () => {
    const id = props.currentCompany.companyId+ '-' + Math.random().toString(36).substring(2, 16);
    const newCustomer = { id, name: newCustomerName, projects: [], uses: [], disabled:false }
    const newCustomers = { ...customers, [id]: newCustomer }
    setCustomers(newCustomers)
    setNewCustomer(false);
    props.updateCustomer(newCustomer)
  }
  const customerUpdated = (customer) => {
    const newCustomers = { ...customers, [customer.id]: customer }
    setCustomers(newCustomers);
    props.updateCustomer(customer)
  }
  const remove = (customer) => {
    const newCustomers = { ...customers };
    if (customer.uses.length === 0 && customer.projects.map(p => !p.disabled && p.uses.length === 0).reduce((p, c) => p && c, true)) {
      delete newCustomers[customer.id];
      props.updateCustomer(customer, true);
    } else {
      newCustomers[customer.id].disabled = true;
      props.updateCustomer(newCustomers[customer.id]);
    }
    setCustomers(newCustomers);
    
  }

  return (<div className="costObjectPanel">
    <SimpleButton className="actionButton" full icon={AddIcon} onClick={() => setNewCustomer(true)}>Add Customer</SimpleButton>
    {Object.values(customers).filter(c => !c.disabled).map(c => <CostObject key={c.name} costObject={c} onChange={customerUpdated} onDelete={() => remove(c)} />)}
    {newCustomer ? <div className={classes.row}>
      <TextField
        label="Name of the new Customer"
        onChange={(name) => setNewCustomerName(name)}
        value={newCustomerName}
      />
      <SimpleButton full onClick={addCustomer}>Add</SimpleButton>
      <CloseIcon onClick={() => setNewCustomer(false)} />
    </div> : null}
  </div >)

}


const mapStateToProps = state => {
  return {
    currentCompany: state.CompanyReducer.currentCompany,
    customers: state.CompanyReducer.customers
  };
}

export default connect(mapStateToProps, { updateCustomer, getCustomers })(Customers);