import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { LinkButton } from '../Buttons';

class FinishedTask extends Component {

  constructor(props) {
    super(props);

    this.state = {
      taskDetails: this.props.task,
    };
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
    <h1> The task is finished with the status '{this.state.taskDetails.task_defination}'</h1>
        </Grid>
        <Grid item xs={12}>
          <LinkButton to='/' color="muted">Go back to task list</LinkButton>
        </Grid>
      </Grid>
    );
  }
}
export default FinishedTask;


