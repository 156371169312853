import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SyncIcon from '@material-ui/icons/Sync';
import { formatDate } from '../../../components/dateHelpers';
import { SaveButton, LinkButton, ActionButton } from '../../../components/Buttons';
import dbInvoice from '../../../firebasecomponents/invoice';
import dbCompany from '../../../firebasecomponents/company';
import * as moment from 'moment';
import { matchSorter } from 'match-sorter';
import ScrollArea from 'react-scrollbars-custom';

import Parashift from '../../../externalAPI/Parashift';
import InvoiceParashiftDetails from './InvoiceParashiftDetails';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InvoiceItems from './InvoiceItems';
import topal from '../../../firebasecomponents/topal';
import Log from '../../../newHelpers/Log';

const formatNumber = (str) => str.replace(/ /g, '').replace(/,/g, '.');
const numberCheckFails = (str) => isNaN(parseFloat(formatNumber(str)));
const currencies = require('../../../currency.json');
const Logger = new Log('InvoiceExtractDataForm');

class InvoiceExtractDataForm extends React.Component {
  constructor(props) {
    super(props);
    let invoiceDetails = this.mapParashiftInvoice(props.invoice);
    if (invoiceDetails.extracted && invoiceDetails.amounts) {
      Logger.log('already extracted, calculate total amount');
      invoiceDetails['amount_total'] = invoiceDetails.amounts.reduce(
        (prev, curr) => prev + curr.amount,
        0,
      );
    }

    this.state = {
      invoiceDetails,
      loading: false,
      errors: {},
      dueDate: '',
      payTerm: '',
    };

    dbCompany.fetchCompanyDetails(this.props.invoice.company_id).then((details) => {
      Logger.log('details', details);
      details !== undefined
        ? this.setState({
            bookingAccounts: details.topalAccounts ? details.topalAccounts.map((e) => e) : [],
            company: details,
            vatAccounts: details.topalVats ? details.topalVats.map((e) => e) : [],
          })
        : this.setState({ bookingAccounts: {} });
    });
    topal.getClientParties(this.props.invoice.company_id).then((res) => {
      Logger.log('parties: ', res);
      this.setState({ parties: res });
    });

    this.updateInvoice = this.updateInvoice.bind(this);
    this.extractInvoice = this.extractInvoice.bind(this);
    this.updateAmount = this.updateAmount.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
  }

  //--------------------------------------------
  componentDidUpdate(prevProps) {
    Logger.log('inv extract didupdate');
    Logger.log('inv ex did update: ', this.props.invoice);
    if (prevProps.invoice !== this.props.invoice) {
      Logger.log('invoice mismatch');
      this.setState({
        invoiceDetails: this.mapParashiftInvoice(this.props.invoice),
      });
      this.setState({ dueDate: this.state.invoiceDetails.dueDate });
    }
  }

  mapParashiftInvoice(invoice) {
    Logger.log('map parashift invoice fun', invoice);
    return invoice.parashift && invoice.parashift.current_status === 'done' && !invoice.extracted
      ? Parashift.mapParashiftToInvoice(invoice)
      : invoice;
  }

  async extractInvoice(invoiceID) {
    Parashift.extractInvoice(invoiceID);
  }

  async updateInvoice() {
    this.setState({ loading: true });
    const invoiceDetails = this.state.invoiceDetails;
    Logger.log('inv details', invoiceDetails);
    const messages = [];
    const errors = {};
    let {
      invoiceId,
      merchant,
      currency,
      invoiceDate,
      dueDate,
      amount_total,
      type,
      payment,
      extracted,
      freeInvoiceNum,
      vatNumber,
      payTerm,
    } = invoiceDetails;
    invoiceDate = formatDate(invoiceDate);
    dueDate = formatDate(dueDate);
    let state = 'open';
    Logger.log('freeInvNum', freeInvoiceNum);

    if (!invoiceId) messages.push('Invoice id missing');
    if (!merchant.name) {
      messages.push('Merchant  missing');
      errors.merchant = 'Merchant is required';
    }
    if (!invoiceDate) {
      messages.push('Invoice date missing');
      errors.invoiceDate = 'Invoice Date is required';
    }
    if (!dueDate) {
      messages.push('Due date missing');
      errors.invoiceDate = 'Due Date is required';
    }
    if (!currency) {
      messages.push('Currency missing');
      errors.currency = 'Select a currency from the list';
    }

    if (!payTerm) {
      messages.push('Pay Term missing');
      errors.payTerm = 'Pay Term is required';
    }

    invoiceDetails.amounts.forEach((am, idx) => {
      let err = {};

      am.amount = parseFloat(am.amount);
      am.amountNoVat = parseFloat(am.amountNoVat);
      am.vat_amount = parseFloat(am.vat_amount);

      if (am.bookingAccount === undefined || am.bookingAccount === '') {
        messages.push('There is no Booking Account in ' + idx + '. subamount');
        err = { ...err, type: 'Select Booking Account from the list' };
      }

      if (am.amount === undefined || isNaN(am.amount)) {
        messages.push('There is no amount in ' + idx + '. subamount');
        err = { ...err, amount: 'Amount is required' };
      }
      if (am.vat_amount === undefined || isNaN(am.vat_amount)) {
        messages.push('There is no VAT amount in ' + idx + '. subamount');
        err = { ...err, vat_amount: 'VAT amount is required' };
      }
      if (Object.keys(err).length > 0) {
        (errors.amounts = errors.amounts || {})[idx] = err;
        return;
      }
      if (am.type === undefined) {
        Logger.log('am type undef');
        am.type = '';
      }
    });

    let amounts = invoiceDetails.amounts ? invoiceDetails.amounts : [];

    let total_sum = 0;
    for (let idx in amounts) {
      let item = amounts[idx].amount;
      Logger.log('item', item);
      total_sum += item;
      delete amounts[idx]['amountNoVat'];
    }
    Logger.log('total amount', parseFloat(amount_total));
    Logger.log('total sum', total_sum);
    if (Math.abs(parseFloat(amount_total) - parseFloat(total_sum)) > 0.1) {
      messages.push('Total amount error');
      errors.amount_total = 'Total amount is required';
    }

    vatNumber = invoiceDetails.vatNumber ? invoiceDetails.vatNumber : '';
    Logger.log('vat number', vatNumber + ':');
    if (!freeInvoiceNum) {
      freeInvoiceNum = '';
    }
    if (messages.length > 0) {
      Logger.log('errors: ', errors);
      this.setState({ loading: false, errors });
      this.props.onChange({
        invoice: invoiceDetails,
        error: messages.map((m) => <div key={m}>{m}</div>),
      });
      return;
    }
    Logger.log('no errors --------------');
    // Add currency extraction error if another currency was selected
    const extractionErrors = [];

    dbInvoice
      .updateInvoice({
        invoiceId,
        merchant,
        invoiceDate,
        dueDate,
        currency,
        amounts,
        type,
        payment,
        state,
        extracted,
        freeInvoiceNum,
        vatNumber,
        payTerm,
        extractionErrors,
        extractionWarnings: [],
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.onChange({
          task: {
            ...invoiceDetails,
          },
          success: 'Great! You have saved the data!',
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.onChange({
          invoice: invoiceDetails,
          error: JSON.stringify(err),
        });
        Logger.log('error=>', err);
      });
  }

  handleChange = (e) => {
    if (e.target.type === 'number' && numberCheckFails(e.target.value)) return;
    const invoice = {
      ...this.state.invoiceDetails,
      [e.target.name]: e.target.value,
    };
    this.setState({ invoiceDetails: invoice });
  };

  handleChangeMerchant = (e) => {
    const partyMatch = this.state.parties
      ? this.state.parties.find((x) => x.name === e)
      : undefined;
    const merchant = {
      ...this.state.invoiceDetails.merchant,
      name: e,
    };
    if (partyMatch) {
      merchant.topalPartyFID = partyMatch.id;
    } else {
      delete merchant.topalPartyFID;
    }
    const invoice = {
      ...this.state.invoiceDetails,
      merchant,
    };
    this.setState({ invoiceDetails: invoice });
  };

  handleChangeMerchantFields = (e) => {
    Logger.log('handle Change', e.target.type, e.target.name, e.target.value);
    if (e.target.type === 'number' && numberCheckFails(e.target.value)) return;
    const merchant = {
      ...this.state.invoiceDetails.merchant,
      [e.target.name]: e.target.value,
    };
    const invoice = {
      ...this.state.invoiceDetails,
      merchant,
    };
    this.setState({ invoiceDetails: invoice });
  };

  handleChangePayment = (e) => {
    if (e.target.type === 'number' && numberCheckFails(e.target.value)) return;
    const payment = {
      ...this.state.invoiceDetails.payment,
      [e.target.name]: e.target.value,
    };
    const invoice = {
      ...this.state.invoiceDetails,
      payment,
    };
    this.setState({ invoiceDetails: invoice });
  };

  updateAmount(idx) {
    return (data) => {
      const invoiceDetails = this.state.invoiceDetails;
      const amounts = invoiceDetails.amounts;
      amounts[idx] = data;
      this.setState({ invoiceDetails });
    };
  }

  handleDateChange1 = (date) => {
    const invoice = this.state.invoiceDetails;
    invoice.invoiceDate = date;
    this.setState({ invoiceDetails: invoice });
  };
  handleDateChange2 = (date) => {
    const invoice = this.state.invoiceDetails;
    invoice.dueDate = date;
    this.setState({ invoiceDetails: invoice });
    this.setState({ dueDate: date });
  };

  filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue);
  };

  render() {
    const invoice = this.state.invoiceDetails;
    const errors = this.state.errors;
    let maxDate = moment(invoice.invoiceDate).add(720, 'days').format('YYYY-MM-DD');
    let minDate = moment(invoice.invoiceDate).add(1, 'days').format('YYYY-MM-DD');
    const ddate =
      invoice.dueDate === undefined || invoice.dueDate === ''
        ? invoice.invoiceDate
        : invoice.dueDate;

    const bookingAccounts = this.state.bookingAccounts;
    const backLink =
      '/company/invoiceManagement/' + invoice.company_id + '/' + invoice.company_name;

    const vatAccounts = this.state.vatAccounts;
    const partiesOptions = this.state.parties ? this.state.parties : [];

    const amountItems = invoice.amounts && invoice.amounts.length > 0 ? invoice.amounts : [{}];
    return (
      <>
        <ScrollArea style={{ height: '83vh' }} native noScrollX>
          <Grid container spacing={3}>
            {invoice.extraction === 'full' ? <InvoiceParashiftDetails data={invoice} /> : <div />}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  name="freeInvoiceNum"
                  label="Invoice Number"
                  error={!!errors.freeInvoiceNum}
                  helperText={errors.freeInvoiceNum}
                  onChange={this.handleChange}
                  value={invoice.freeInvoiceNum || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12} />

            <Grid item xs={6}>
              <Autocomplete
                options={partiesOptions.map((o) => o.name)}
                filterOptions={this.filterOptions}
                value={invoice.merchant.name || ''}
                onChange={(e, val) => this.handleChangeMerchant(val)}
                freeSolo
                autoSelect
                renderInput={(params) => (
                  <TextField {...params} label="Merchant" InputProps={{ ...params.InputProps }} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  name="vatNumber"
                  label="Vat Number"
                  onChange={this.handleChange}
                  value={invoice.vatNumber || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} />

            <Grid item xs={1}>
              <FormControl fullWidth>
                <TextField
                  name="country"
                  label="Country"
                  onChange={this.handleChangeMerchantFields}
                  value={invoice.merchant.country || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  name="zip"
                  label="ZIP"
                  onChange={this.handleChangeMerchantFields}
                  value={invoice.merchant.zip || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  name="city"
                  label="City"
                  onChange={this.handleChangeMerchantFields}
                  value={invoice.merchant.city || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  name="address1"
                  label="Address"
                  onChange={this.handleChangeMerchantFields}
                  value={invoice.merchant.address1 || ''}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} />

            <Grid item xs={4}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    id="date-picker-invDate"
                    label="Invoice Date"
                    autoOk
                    maxDate={maxDate}
                    maxDateMessage={'Please Check the Date'}
                    value={invoice.invoiceDate}
                    onChange={this.handleDateChange1}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    id="date-picker-dueDate"
                    label="Due Date"
                    autoOk
                    maxDate={maxDate}
                    minDate={minDate}
                    maxDateMessage={'Please Check the Date'}
                    value={ddate}
                    onChange={this.handleDateChange2}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                {this.state.company && this.state.company.topalPayTerms ? (
                  <>
                    <InputLabel htmlFor="payTerm">PayTerm</InputLabel>
                    <Select
                      value={invoice.payTerm || ''}
                      onChange={this.handleChange}
                      name="payTerm"
                      labelId="payTerm"
                    >
                      {Object.values(this.state.company.topalPayTerms).map((k, idx) => {
                        return (
                          <MenuItem key={k.id} value={k.code}>
                            {k.dueDays} : {k.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                ) : null}
                {errors.payTerm ? <FormHelperText>{errors.payTerm}</FormHelperText> : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={2}>
              <FormControl fullWidth error={!!errors.currency}>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select
                  value={invoice.currency}
                  onChange={this.handleChange}
                  name="currency"
                  labelId="currency"
                >
                  <MenuItem key="defaultCHF" value={'CHF'}>
                    CHF
                  </MenuItem>
                  <MenuItem key="defaultEUR" value={'EUR'}>
                    EUR
                  </MenuItem>
                  <MenuItem key="defaultUSD" value={'USD'}>
                    USD
                  </MenuItem>
                  <MenuItem key="defaultJPY" value={'JPY'}>
                    JPY
                  </MenuItem>
                  <MenuItem key="defaultGBP" value={'GBP'}>
                    GBP
                  </MenuItem>
                  <span key="select">_________</span>
                  {Object.keys(currencies).map((k) => {
                    return (
                      <MenuItem key={k} value={k}>
                        {k} : {currencies[k].name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.currency ? <FormHelperText>{errors.currency}</FormHelperText> : null}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  name="amount_total"
                  label="Total Amount"
                  error={!!errors.amount_total}
                  helperText={errors.amount_total}
                  onChange={this.handleChange}
                  value={invoice.amount_total ? invoice.amount_total : ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} />

            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  name="account_number"
                  label="Account Number"
                  error={!!errors.account_number}
                  helperText={errors.account_number}
                  onChange={this.handleChangePayment}
                  value={
                    invoice.payment && invoice.payment.account_number
                      ? invoice.payment.account_number
                      : ''
                  }
                  variant={
                    !!(
                      (invoice.payment &&
                        invoice.payment.qrESR &&
                        invoice.payment.qrIBAN &&
                        invoice.payment.bic) ||
                      (invoice.payment && invoice.payment.bic && invoice.payment.iban)
                    )
                      ? 'filled'
                      : 'standard'
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  name="esr"
                  label="ESR"
                  error={!!errors.account_number}
                  helperText={errors.account_number}
                  onChange={this.handleChangePayment}
                  value={invoice.payment && invoice.payment.esr ? invoice.payment.esr : ''}
                  variant={
                    !!(
                      (invoice.payment &&
                        invoice.payment.qrESR &&
                        invoice.payment.qrIBAN &&
                        invoice.payment.bic) ||
                      (invoice.payment && invoice.payment.bic && invoice.payment.iban)
                    )
                      ? 'filled'
                      : 'standard'
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  name="bic"
                  label="BIC"
                  onChange={this.handleChangePayment}
                  value={invoice.payment && invoice.payment.bic ? invoice.payment.bic : ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  name="iban"
                  label="IBAN"
                  error={!!errors.account_number}
                  helperText={errors.account_number}
                  onChange={this.handleChangePayment}
                  value={invoice.payment && invoice.payment.iban ? invoice.payment.iban : ''}
                  variant={
                    !!(
                      (invoice.payment && invoice.payment.esr && invoice.payment.account_number) ||
                      (invoice.payment && invoice.payment.qrIBAN && invoice.payment.qrESR)
                    )
                      ? 'filled'
                      : 'standard'
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  name="qrIBAN"
                  label="QR-IBAN"
                  error={!!errors.qrIBAN}
                  helperText={errors.qrIBAN}
                  onChange={this.handleChangePayment}
                  value={invoice.payment && invoice.payment.qrIBAN ? invoice.payment.qrIBAN : ''}
                  variant={
                    !!(
                      (invoice.payment && invoice.payment.esr && invoice.payment.account_number) ||
                      (invoice.payment && invoice.payment.bic && invoice.payment.iban)
                    )
                      ? 'filled'
                      : 'standard'
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  name="qrESR"
                  label="QR-Reference"
                  error={!!errors.qrESR}
                  helperText={errors.qrESR}
                  onChange={this.handleChangePayment}
                  value={invoice.payment && invoice.payment.qrESR ? invoice.payment.qrESR : ''}
                  variant={
                    !!(
                      (invoice.payment && invoice.payment.esr && invoice.payment.account_number) ||
                      (invoice.payment && invoice.payment.bic && invoice.payment.iban)
                    )
                      ? 'filled'
                      : 'standard'
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="text"
                label="Payment Text"
                fullWidth
                onChange={this.handleChangePayment}
                value={invoice.payment && invoice.payment.text ? invoice.payment.text : ''}
                multiline
              />
            </Grid>

            {
              amountItems.map((element,idx) => (
                <InvoiceItems
                  key={idx}
                  errors={errors.amounts ? errors.amounts[idx] : {}}
                  onChange={this.updateAmount(idx)}
                  amount={element}
                  bookingAccounts={bookingAccounts}
                  vatAccounts={vatAccounts}
                  isManual={false}
                  vatIncluded={true}
                />
              ))

            }
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  const amts = this.state.invoiceDetails.amounts;
                  amts.push({});
                  this.setState({ invoiceDetails: this.state.invoiceDetails});
                }}
              >
                <AddIcon />
                Add Item
              </Button>
              {amountItems.length > 1 ? (
                <Button
                  onClick={() => {
                    const amts = this.state.invoiceDetails.amounts;
                    amts.pop();
                    this.setState({ invoiceDetails: this.state.invoiceDetails});
                  }}
                >
                  <RemoveIcon />
                  Remove last item
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </ScrollArea>
        <Grid
          container
          spacing={2}
          style={{
            position: 'absolute',
            bottom: '29px',
            right: '29px',
            width: '50%',
          }}
        >
          <Grid item xs={4}>
            {invoice.parashift ? (
              <ActionButton
                fullWidth
                onClick={() => this.extractInvoice(invoice.invoiceId)}
                color={'muted'}
              >
                <SyncIcon />
              </ActionButton>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <LinkButton fullWidth to={backLink} color="muted">
              Back
            </LinkButton>
          </Grid>
          <Grid item xs={4}>
            <SaveButton fullWidth onClick={() => this.updateInvoice()} loading={this.state.loading}>
              Save
            </SaveButton>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default InvoiceExtractDataForm;
