import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import './index.scss';

function NavItem(props) {
  const { icon, iconSelected, text, link, selected, collapsed, indent, isCollapseExpense, setCollapseExpense, showExpenseItems, showCollapsedExpense } = props;
  const { companyId, companyName } = useParams();
  const companyLink =
    companyId === undefined ? '' : `/${companyId}/${companyName}`;

  // if showCollapsedExpense = false it means that all expense nav items will be hidden,
  // except the currently selected item
  if(showExpenseItems && showCollapsedExpense === false && !selected) {
    return null;
  }

  // indent for expense navitems, only visible if the menu is not collapsed
  if (collapsed === false && indent) {
    return (
      <div style={{ paddingLeft: '20px' }}>
        <Link
          to={{pathname:link.replace('(companyLink)', companyLink), state: {collapsed, showExpenseItems}}}
          className={selected ? 'selected' : ''}
        >
          <img id="logo" src={selected ? iconSelected : icon} alt={text} />
          {text}
        </Link>
      </div>
    );
  }

  // clickable menu item to hide expense navitems, for collapsed and not collapsed
  else if(isCollapseExpense) {
    return (
      <a className={selected ? 'selected' : ''} onClick={setCollapseExpense} style={{cursor:'pointer'}}>
        <img id="logo" src={selected ? iconSelected : icon} alt={text} />
        {collapsed ? null : text}
      </a>
    );
  }
  // collapsed menu
  else {
    return (
      <Link
        to={{pathname:link.replace('(companyLink)', companyLink), state: {collapsed, showExpenseItems}}}
        className={selected ? 'selected' : ''}
      >
        <img id="logo" src={selected ? iconSelected : icon} alt={text} />
        {collapsed ? null : text}
      </Link>
    );
  }
}

const navigation = {
  Company: [
    // {
    //   icon: require('../../assets/icons/Dashboard-icon.svg'),
    //   iconSelected: require('../../assets/icons/Dashboard-selected-icon.svg'),
    //   text: 'Dashboard',
    //   link: '/company/dashboard(companyLink)',
    //   id: 'dashboard',
    // },
    {
      icon: require('../../assets/icons/InvoiceManagement-icon.svg'),
      iconSelected: require('../../assets/icons/InvoiceManagement-selected-icon.svg'),
      text: 'Invoice Management',
      link: '/company/invoiceManagement(companyLink)',
      id: 'invoiceManagement',
      indent: false,
      showCollapsedExpense:true,
    },
    {
      icon: require('../../assets/icons/ExpenseManagement-icon.svg'),
      text: 'Expense Management',
      link: '/company/managerApproval(companyLink)',
      id: 'expenseManagement',
      indent: false,
      setCollapseExpense:true,
      showCollapsedExpense:true,
    },
    {
      icon: require('../../assets/icons/Approval-icon.svg'),
      iconSelected: require('../../assets/icons/Approval-selected-icon.svg'),
      text: 'Approval',
      link: '/company/managerApproval',
      id: 'managerApproval',
      indent: true,
      showCollapsedExpense:false,
    },
    {
      icon: require('../../assets/icons/Overview-icon.svg'),
      iconSelected: require('../../assets/icons/Overview-icon-selected.svg'),
      text: 'All Expenses',
      link: '/company/overview(companyLink)',
      id: 'overview',
      indent: true,
      showCollapsedExpense:false,
    },
    {
      icon: require('../../assets/icons/Reports-icon.svg'),
      iconSelected: require('../../assets/icons/Reports-selected-icon.svg'),
      text: 'Reports',
      link: '/company/reports',
      id: 'reports',
      indent: true,
      showCollapsedExpense:false,
    },
    {
      icon: require('../../assets/icons/Employees-icon.svg'),
      iconSelected: require('../../assets/icons/Employees-selected-icon.svg'),
      text: 'Employees',
      link: '/company/employees',
      id: 'employees',
      indent: true,
      showCollapsedExpense:false,
    },
    {
      icon: require('../../assets/icons/Creditcard-icon.svg'),
      iconSelected: require('../../assets/icons/Creditcard-selected-icon.svg'),
      text: 'Credit Card Statement',
      link: '/company/statements(companyLink)',
      id: 'statements',
      indent: true,
      showCollapsedExpense:false,
    },
    {
      icon: require('../../assets/icons/Questions-icon.png'),
      iconSelected: require('../../assets/icons/Questions-selected-icon.png'),
      text: 'Questions',
      link: '/company/questions(companyLink)',
      id: 'questions',
      indent: true,
      showCollapsedExpense:false,
    },
    {
      icon: require('../../assets/icons/Customization-icon.svg'),
      iconSelected: require('../../assets/icons/Customization-selected-icon.svg'),
      text: 'Customization',
      link: '/company/customization(companyLink)',
      id: 'customization',
      indent: false,
      showCollapsedExpense:true,
    },
    // {
    //   icon: require('../../assets/icons/Settings-icon.svg'),
    //   iconSelected: require('../../assets/icons/Settings-selected-icon.svg'),
    //   text: 'Settings',
    //   link: '/company/settings(companyLink)',
    //   id: 'settings',
    // },
  ],
  //--------------------------------------------------------------------------------------
  Approver:[
    {
      icon: require('../../assets/icons/ExpenseManagement-icon.svg'),
      text: 'Expense Management',
      link: '/company/managerApproval(companyLink)',
      id: 'expenseManagement',
      indent: false,
      showCollapsedExpense:true,
    },
    {
      icon: require('../../assets/icons/Approval-icon.svg'),
      iconSelected: require('../../assets/icons/Approval-selected-icon.svg'),
      text: 'Approval',
      link: '/company/managerApproval(companyLink)',
      id: 'managerApproval',
      indent: true,
      showCollapsedExpense:true,
    },
    {
      icon: require('../../assets/icons/Overview-icon.svg'),
      iconSelected: require('../../assets/icons/Overview-icon-selected.svg'),
      text: 'All Expenses',
      link: '/company/overview(companyLink)',
      id: 'overview',
      indent: true,
      showCollapsedExpense:true,
    },
  ],
  //--------------------------------------------------------------------------------------
  Employee: [
    {
      icon: require('../../assets/icons/Dashboard-icon.svg'),
      iconSelected: require('../../assets/icons/Dashboard-selected-icon.svg'),
      text: 'Dashboard',
      link: '/company/dashboard(companyLink)',
      id: 'dashboard',
      showCollapsedExpense:true,
      indent: false,
    },
    {
      icon: require('../../assets/icons/InvoiceManagement-icon.svg'),
      iconSelected: require('../../assets/icons/InvoiceManagement-selected-icon.svg'),
      text: 'Invoice Management',
      link: '/company/invoiceManagement(companyLink)',
      id: 'invoiceManagement',
      showCollapsedExpense:true,
      indent: false,
    },
    {
      icon: require('../../assets/icons/ExpenseManagement-icon.svg'),
      text: 'Expense Management',
      link: '/company/managerApproval(companyLink)',
      id: 'expenseManagement',
      showCollapsedExpense:true,
      indent: false,
      setCollapseExpense:true
    },
    {
      icon: require('../../assets/icons/Approval-icon.svg'),
      iconSelected: require('../../assets/icons/Approval-selected-icon.svg'),
      text: 'Approval',
      link: '/company/managerApproval(companyLink)',
      id: 'managerApproval',
      showCollapsedExpense:false,
      indent: true,
    },
    {
      icon: require('../../assets/icons/Overview-icon.svg'),
      iconSelected: require('../../assets/icons/Overview-icon-selected.svg'),
      text: 'All Expenses',
      link: '/company/overview(companyLink)',
      id: 'overview',
      showCollapsedExpense:false,
      indent: true,
    },
    {
      icon: require('../../assets/icons/Creditcard-icon.svg'),
      iconSelected: require('../../assets/icons/Creditcard-selected-icon.svg'),
      text: 'Credit Card Statement',
      link: '/company/statements(companyLink)',
      id: 'statements',
      showCollapsedExpense:false,
      indent: true,
    },
    {
      icon: require('../../assets/icons/Reports-icon.svg'),
      iconSelected: require('../../assets/icons/Reports-selected-icon.svg'),
      text: 'Reports',
      link: '/company/reports(companyLink)',
      id: 'reports',
      showCollapsedExpense:false,
      indent: true,
    },
    {
      icon: require('../../assets/icons/Employees-icon.svg'),
      iconSelected: require('../../assets/icons/Employees-selected-icon.svg'),
      text: 'Employees',
      link: '/company/employees(companyLink)',
      id: 'employees',
      showCollapsedExpense:false,
      indent: true,
    },
    {
      icon: require('../../assets/icons/Questions-icon.png'),
      iconSelected: require('../../assets/icons/Questions-selected-icon.png'),
      text: 'Questions',
      link: '/company/questions(companyLink)',
      id: 'questions',
      showCollapsedExpense:false,
      indent: true,
    },
    {
      icon: require('../../assets/icons/Customization-icon.svg'),
      iconSelected: require('../../assets/icons/Customization-selected-icon.svg'),
      text: 'Customization',
      link: '/company/customization(companyLink)',
      id: 'customization',
      showCollapsedExpense:true,
      indent: false,
    },
    {
      icon: require('../../assets/icons/Settings-icon.svg'),
      iconSelected: require('../../assets/icons/Settings-selected-icon.svg'),
      text: 'Settings',
      link: '/company/settings(companyLink)',
      id: 'settings',
      showCollapsedExpense:true,
      indent: false,
    },
  ],
  //-------------------------------------------------------------------
  Accountant: {},
};

const bottomNavigation = [
  // {
  //   icon: require("../../assets/settings-ico.png"),
  //   text: "Settings",
  //   link: "/",
  //   id: "settings"
  // },
  // {
  //   icon: require("../../assets/info-ico.png"),
  //   text: "FAQ",
  //   link: "/",
  //   id: "faq"
  // }
];

function Navigation(props) {
  const role = props.role;
  const collapsed = props.collapsed;
  const showExpenseItems = props.showExpenseItems;
  const setShowExpenseItems = props.setShowExpenseItems;
  const items = navigation[role].map((item) => (
    <NavItem key={item.id} {...item}
             selected={props.current === item.id}
             collapsed={collapsed}
             isCollapseExpense={item.setCollapseExpense}
             setCollapseExpense={setShowExpenseItems}
             showExpenseItems={showExpenseItems}
             showCollapsedExpense={item.showCollapsedExpense}
    />
  ));

  const bottomItems = bottomNavigation.map((item) => (
    <NavItem key={item.id} {...item} selected={props.current === item.id}/>
  ));
  return (
    <div className="mainNavigation" style={{overflowX:'hidden'}}>
      {items}
      <div className="spacer" />
      {bottomItems}
    </div>
  );
}
export default Navigation;
