import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';


import task from '../../firebasecomponents/task';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import { SaveButton, LinkButton } from '../../components/Buttons';

import { Averix } from '../../externalAPI';
// import invoice from "../../firebasecomponents/invoice";


class ReimbursabilityCheckForm extends Component {

  constructor(props) {
    super(props);

    this.state = this.state = {
      taskDetails: this.props.task,
      loading: false
    };

    this.updatetask = this.updatetask.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.reimbursableChange = this.reimbursableChange.bind(this);
  }

  // handleChange = (e) => {
  //   const task = this.state.taskDetails;
  //   task[e.target.name] = e.target.value;
  //   this.setState({ taskDetails: task });
  // }

  updatetask() {
    this.setState({ loading: true });

    const taskDetails = this.state.taskDetails;
    const { taskId, reimbursable } = taskDetails;


    const task_defination = reimbursable ? 'arrange-payment' : 'non-reimbursible task';

    task.updateTask({
      reimbursable,
      taskId,
      task_defination
    }).then(res => {
      taskDetails.task_defination = task_defination;
      //TODO credit card non reimbursable
      // if(!reimbursable){
      //   console.log('not reimbursable');
      //   invoice.addInvoice(taskDetails)
      // }
      return Averix.reimbursabilityCheck({
        reimbursable,
        expenseId: taskId
      });
    }).then(res => {
      this.setState({ loading: false });
      this.props.onChange({ task: taskDetails, success: 'You have ' + (reimbursable ? 'accepted' : 'declined') + ' reimbursability!' });
    }).catch(err => {
      this.setState({ loading: false });
      this.props.onChange({ task: taskDetails, error: JSON.stringify(err) });
    });

  }

  reimbursableChange = () => {
    const task = this.state.taskDetails;
    task.reimbursable = !task.reimbursable;
    this.setState({ taskDetails: task });
  }

  getAdditionalFields(policyFields) {
    if (policyFields.train !== undefined) return { label: "Train class", value: policyFields.train };
    if (policyFields.plane !== undefined) return { label: "Plane class", value: policyFields.plane };
    if (policyFields.meal !== undefined) return { label: "Meal type", value: policyFields.meal };
    if (policyFields.level !== undefined) return { label: "Lodging type", value: policyFields.level };
    return false;
  }

  render() {
    const task = this.state.taskDetails;
    return (
      <Grid container spacing={3}>
        {
        task.reimbursable !== undefined?
        <Grid item xs={12}>
          <Typography variant="subtitle1">Expense is proposed to be <strong>{task.reimbursable?'':'non-'}reimbursible</strong></Typography>
        </Grid>
        : null
        }
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField disabled
              id="type" label="Type" value={(task.type)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="merchant" label="Merchant" value={(task.merchant)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth disabled
            id="date" label="Date" value={(task.date)}
          />
        </Grid>

        {
          task.amounts.map((elem, idx) => {
            const add = this.getAdditionalFields(elem.policyFields);
            return (
              <Grid item xs={12} key={idx}>
                <strong style={{ textTransform: 'uppercase', marginRight: '10px' }}>{idx + 1}. {(elem.type)}</strong>
                {add ? add.label + ': ' + add.value : null}<br />
                <TextField
                  name="amount" label="Amount" value={elem.amount}
                />
                <span style={{ marginTop: '24px', display: 'inline-block' }}>VAT amount: {elem.vat_amount} {task.currency} ({elem.vat_percentage.name})</span>
              </Grid>);
          })
        }
        <Grid item xs={7}>
          <FormControlLabel style={{ marginLeft: '10px!important' }}
            control={
              <Checkbox checked={task.reimbursable}
                onChange={() => this.reimbursableChange()}
              />
            }
            label=" Reimbursable ?"
          />
        </Grid>
        <Grid item xs={6}>
          <SaveButton fullWidth loading={this.state.loading} onClick={this.updatetask}>Save</SaveButton>
        </Grid>
        <Grid item xs={6}>
          <LinkButton fullWidth to='/' color="muted">Go back to task list</LinkButton>
        </Grid>
      </Grid>
    );
  }
}
export default ReimbursabilityCheckForm;
