import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from '../page';
import QuickFilters from '../../newComponents/QuickFilters';
import { Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Select from '../../newComponents/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Search from '../../newComponents/Search';
import makeUnique from '../../newHelpers/makeUnique';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollArea from 'react-scrollbars-custom';
import Table from '../../newComponents/Table';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getEmployees } from '../../store/ducks/employee';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '../../newComponents/Drawer';
import OverviewExpenseDetails from './components/OverviewExpenseDetails';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import task from '../../firebasecomponents/task';
import { getQuestions } from '../../store/actions';
import '../ManagerApproval/managerApproval.scss';
import {getCollapsed, getShowExpenseItems} from "../../newHelpers/pageHelper";

const defaultExpenseTypes = require('../../expenses.json').expenses;

const stateMap = {
  'extract-data': 'Processing',
  'compliance-check': 'Processing',
  'manager-approval': 'Approval Pending',
  'reimbursability-check': 'Payment Pending',
  'arrange-payment': 'Payment Pending',
  'payment-confirmation': 'Payment Pending',
  'manager-declined': 'Declined',
  // 'non-reimbursable':'Done',
  'non-reimbursible task': 'Done',
  'payment-done': 'Done',
  'non-readable': 'Not Readable',
  // non-reimbursible task
};

const states = [
  'Processing',
  'Approval Pending',
  'Payment Pending',
  'Declined',
  'Done',
  'Not Readable',
];

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;

    this.policyFilter = this.policyFilter.bind(this);
    this.reimbursabilityFilter = this.reimbursabilityFilter.bind(this);
    this.userFilter = this.userFilter.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.stateFilter = this.stateFilter.bind(this);
    this.dateFilter = this.dateFilter.bind(this);

    if (this.props.currentCompany) {
      task.getTaskBasedOnComapnyId(
        { companyId: this.props.currentCompany.companyId },
        (response) => {
          this.setState({ tasks: response });
        },
      );
      this.props.getEmployees();
      this.props.getQuestions(this.props.currentCompany.companyId);
      // console.log('constr get done')
    }
    this.state = {
      openedTask: undefined,
      dataLoading: false,
      userFilter: '-',
      searchFilter: '',
      questions: [],
      nameOfTheEmployees: null,
      stateFilter: ['Approval Pending', 'Payment Pending', 'Declined', 'Done'], //don't use states here, elements disappear
      dateFilter: {
        fromDates: moment().add(-30, 'd').format('YYYY-MM-DD'),
        untilDates: moment().add(30, 'd').format('YYYY-MM-DD'),
      },
      reset: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questions !== this.props.questions) {
      this.setState({ questions: this.props.questions });
    }
    if (prevProps.currentCompany !== this.props.currentCompany) {
      task.getTaskBasedOnComapnyId(
        { companyId: this.props.currentCompany.companyId },
        (response) => {
          this.setState({ tasks: response });
        },
      );
      this.props.getEmployees();
      this.props.getQuestions(this.props.currentCompany.companyId);
    }
  }

  opening(task) {
    if (
      task.task_defination !== 'extract-data' &&
      task.task_defination !== 'non-readable'
    ) {
      return () => {
        this.setState({
          openedTask: {
            ...task,
            amounts: [...task.amounts.map((a) => ({ ...a }))],
          },
        });
      };
    } else {
      return () => {
        this.setState({ openedTask: { ...task } });
      };
    }
  }

  policyFilter(item) {
    const filter = this.state.policyFilter;
    if (filter === undefined || filter.length === 0) return true;
    return (
      (item.compliant === true && filter.includes('According')) ||
      (item.compliant === false && filter.includes('Violating')) ||
      (item.compliant === 'unknown' && filter.includes('Unknown'))
    );
  }
  reimbursabilityFilter(item) {
    const filter = this.state.reimbursabilityFilter;
    if (filter === undefined || filter.length === 0) return true;

    return (
      (item.reimbursable && filter.includes('Reimbursable')) ||
      (!item.reimbursable && filter.includes('Non-reimbursable'))
    );
  }

  userFilter(item) {
    const filter = this.state.userFilter;
    if (filter === undefined || filter === '-') return true;

    return item.empl_name === filter;
  }
  searchFilter(item) {
    const filter = this.state.searchFilter;
    if (filter === undefined || filter === '') return true;
    let res = JSON.stringify(item).toLowerCase();
    return res.includes(filter.toLowerCase());
  }
  stateFilter(item) {
    const filter = this.state.stateFilter;
    if (filter === undefined || filter === '-') return true;
    return filter.includes(stateMap[item.task_defination]);
  }

  addDefaultSrc(ev) {
    //fallback if thumbnails are blocked (pdf's where no thumbnails exist)
    ev.target.src =
      'https://firebasestorage.googleapis.com/v0/b/clexx-eaebf.appspot.com/o/clexx_logo_small.jpg?alt=media&token=4fbd9576-5c0c-4a05-8e28-69c0cd7dc660';
  }

  dateFilter(item) {
    //filter for from and until in dates
    let filter = this.state.dateFilter;
    if (
      filter === undefined ||
      filter === '-' ||
      filter === '' ||
      filter.length === 0
    ) {
      return true;
    }
    let itemDate = item.date
      ? moment(item.date, 'YYYY-MM-DD')
      : moment(item.createdAt, 'YYYY-MM-DD');
    // console.log('itemDate', itemDate)
    let fromDate = moment(filter.fromDates);
    let untilDate = moment(filter.untilDates);
    if (itemDate === undefined || !itemDate.isValid()) {
      return true;
    }
    return itemDate.isBetween(fromDate, untilDate, 'days', '[]');
  }

  resetFilters = () => {
    this.setState({
      reset: true,
      searchFilter: '',
      stateFilter: ['Approval Pending', 'Payment Pending', 'Declined', 'Done'],
      userFilter: '-',
      dateFilter: {
        fromDates: moment().add(-30, 'd').format('YYYY-MM-DD'),
        untilDates: moment().add(30, 'd').format('YYYY-MM-DD'),
      },
    });
  };

  handleDate = (f) => (e) => {
    // console.log('field', f, 'e', e)
    const t = moment(e).format('YYYY-MM-DD');
    this.setState({
      dateFilter: { ...this.state.dateFilter, [f]: t },
    });
  };

  compare(a, b) {
    var splitA = a.split(' ');
    var splitB = b.split(' ');
    var lastA = splitA[splitA.length - 1].toUpperCase();
    var lastB = splitB[splitB.length - 1].toUpperCase();
    if (lastA < lastB) return -1;
    if (lastA > lastB) return 1;
    return 0;
  }

  render() {
    const employees = this.props.employees;
    const userFilter = '-';
    let users = makeUnique(
      employees ? employees.map((t) => t.firstName + ' ' + t.lastName) : [],
    );
    users = users.sort(this.compare);
    const tableData = this.state.tasks
      ? this.state.tasks
          .filter(this.policyFilter)
          .filter(this.reimbursabilityFilter)
          .filter(this.userFilter)
          .filter(this.searchFilter)
          .filter(this.dateFilter)
          .filter(this.stateFilter)
      : [];

    const mapping = this.props.currentCompany
      ? this.props.currentCompany.mapping
      : undefined;
    // const expenseTypes = mapping && mapping.expense ? mapping.expense.map(e => e.expenseType) : undefined || defaultExpenseTypes;
    const expenseTypes =
      mapping && mapping.expense && mapping.expense.length > 0
        ? mapping.expense.map((e) => e.expenseType)
        : defaultExpenseTypes;
    const customExpenseTypes =
      mapping && mapping.custom
        ? mapping.custom.map((e) => ({ id: e.id, name: e.name }))
        : undefined;

    return (
      <Page
        role="Company"
        header="All Expenses"
        currentPage="overview"
        // actions={}
        collapsedNavBar={getCollapsed(this.props)}
        collapsedExpItems={getShowExpenseItems(this.props)}
      >
        <div
          className="tableBar"
          style={{ justifyContent: 'flex-start', display: 'flex' }}
        >
          {/*<div*/}
          {/*  // className="filterTitle"*/}
          {/*  style={{ width: '7.5em', minWidth: '7.5em', maxWidth: '7.5em',*/}
          {/*  }}>*/}
          {/*  Quick Filters:*/}
          {/*</div>*/}
          <QuickFilters
            className="quickFilters"
            name="Policy Compliance:"
            values={['According', 'Violating', 'Unknown']}
            reset={this.state.reset}
            onChange={(e) => this.setState({ policyFilter: e, reset: false })}
          />
          <QuickFilters
            className="quickFilters"
            name="Reimbursability:"
            values={['Reimbursable', 'Non-reimbursable']}
            reset={this.state.reset}
            onChange={(e) =>
              this.setState({ reimbursabilityFilter: e, reset: false })
            }
          />
          <div style={{ display: 'flex' }}>
            <div className="selectFilter" style={{ width: '100%' }}>
              <Typography>Employees</Typography>
              <Select
                variant="outlined"
                value={this.state.userFilter}
                onChange={(e) => this.setState({ userFilter: e })}
                style={{ width: '100%', minWidth: '145px' }}
              >
                <MenuItem key="all" value="-">
                  All
                </MenuItem>
                {users.map((k) => {
                  return (
                    <MenuItem key={k} value={k}>
                      {k}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Search
              className="search"
              style={{ minWidth: '50px', width: '100%' }}
              onChange={(e) => this.setState({ searchFilter: e })}
              value={this.state.searchFilter}
            />
          </div>
        </div>
        <div
          className="tableBar"
          style={{
            justifyContent: 'flex-start',
            // flexDirection:'row', flexGrow:1, flexShrink:1
          }}
        >
          <QuickFilters
            className="quickFilters"
            name="State"
            values={states}
            reset={this.state.reset}
            selected={this.state.stateFilter}
            onChange={(e) => {
              this.setState({ stateFilter: e, reset: false });
            }}
          />
        </div>
        <div
          className="tableBar"
          style={{ justifyContent: 'flex-start', flexDirection: 'row' }}
        >
          <span style={{ color: '#757575', fontWeight: '700' }}>Period</span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              label="from"
              value={this.state.dateFilter.fromDates}
              onChange={this.handleDate('fromDates')}
              format="dd/MM/yyyy"
              style={{ marginLeft: '20px' }}
            />
            <DatePicker
              variant="inline"
              label="until"
              value={this.state.dateFilter.untilDates}
              onChange={this.handleDate('untilDates')}
              format="dd/MM/yyyy"
              style={{ marginLeft: '20px' }}
            />
          </MuiPickersUtilsProvider>
          <Button
            title="Reset Filters"
            onClick={this.resetFilters}
            style={{
              marginLeft: '20px',
              display: 'flex',
              flexGrow: 1,
              alignSelf: 'normal',
              maxWidth: '145px',
            }}
            color={'secondary'}
          >
            Clear All Filters
          </Button>
        </div>
        {this.state.tasks && !this.state.dataLoading && employees ? (
          <ScrollArea style={{ height: '75vh' }} native>
            <Table
              data={{
                head: {
                  thumbnail: <strong>Receipt</strong>,
                  empl_name: <strong>Name of the employee</strong>,
                  state: <strong>State</strong>,
                  type: <span>Expense type</span>,
                  merchant: <span> Merchant </span>,
                  amount: <span>Amount</span>,
                  date: <span>Date</span>,
                  receiptNo: <span>Expense No</span>,
                  action: '',
                },
                content: tableData.map((e) => {
                  const types = e.amounts
                    ? e.amounts
                        .map((taxAmount) => {
                          const type = taxAmount.type;
                          return expenseTypes.includes(type)
                            ? type
                            : type === 'Other'
                            ? 'Other'
                            : customExpenseTypes.find((e) => type === e.id)
                            ? customExpenseTypes.find((e) => type === e.id).name
                            : type;
                        })
                        .join(', ')
                    : e.type;
                  const total = e.amounts
                    ? e.amounts.reduce((prev, curr) => prev + curr.amount, 0)
                    : e.amount;
                  // console.log('total', total)
                  const thbn = (
                    <img
                      src={e.thumbnail || e.base64Value}
                      onClick={this.opening(e)}
                      onError={this.addDefaultSrc}
                      style={{
                        maxWidth: '30px',
                        maxHeight: '30px',
                        cursor: 'pointer',
                      }}
                    />
                  );
                  return {
                    row: {
                      thumbnail: thbn,
                      empl_name: <strong>{e.empl_name}</strong>,
                      state: <span>{stateMap[e.task_defination]}</span>,
                      type: <span>{types}</span>,
                      amount: (
                        <span>
                          {total ? total.toFixed(2) : ''} {e.currency}
                        </span>
                      ),
                      merchant: <span>{e.merchant}</span>,
                      //take upload date if invoice date is not known yet
                      date: (
                        <span>
                          {e.date
                            ? moment(e.date).format('DD-MM-YYYY')
                            : moment(e.createdAt).format('DD-MM-YYYY')}
                        </span>
                      ),
                      receiptNo: <span>{e.receiptNo}</span>,
                      action: <ChevronRightIcon onClick={this.opening(e)} />,
                    },
                    sortData: {
                      id: e.taskId || e.id,
                      empl_name: e.empl_name,
                      state: e.task_defination,
                      type: types ? types.toUpperCase() : '',
                      merchant: e.merchant ? e.merchant.toLowerCase() : '',
                      amount: total,
                      date: e.date,
                      receiptNo: e.receiptNo,
                    },
                  };
                }),
              }}
              sortable={true}
              // checkable="multi"
              onSelect={(selected) => this.setState({ selected })}
            />
          </ScrollArea>
        ) : (
          <CircularProgress />
        )}
        <Drawer direction="left" opened={this.state.openedTask !== undefined}>
          {this.state.openedTask ? (
            <div className="drawerContainer">
              <div className="drawerHeader">
                <Typography>Expense Details</Typography>
                <CloseIcon
                  onClick={() => this.setState({ openedTask: undefined })}
                />
              </div>
              <div className="drawerContent" style={{ width: 775 }}>
                <ScrollArea style={{ height: '100%' }} noScrollX>
                  <OverviewExpenseDetails
                    task={this.state.openedTask}
                    questions={this.state.questions}
                  />
                </ScrollArea>
              </div>
            </div>
          ) : null}
        </Drawer>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.EmployeeReducer.employees,
    currentCompany: state.CompanyReducer.currentCompany,
    currentUser: state.UserReducer.currentUser,
    questions: state.TaskReducer.questions,
  };
};

export default connect(mapStateToProps, { getEmployees, getQuestions })(
  Overview,
);
