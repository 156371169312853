import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TopalCostCenterSelect, DebitAccountSelect } from '../../../newComponents/SelectCostObject';
import { FormControl, Typography, InputAdornment } from '@material-ui/core';
import './invoiceDetails.scss';
import { ImageComponent } from '../../../newComponents/ImageComponent';
import DateFnsUtils from '@date-io/date-fns';
import TodayIcon from '@material-ui/icons/Today';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import Log from '../../../newHelpers/Log';
import { checkNewOpenRejectedState, checkNewOpenState, DEBTOR, isCreditor } from '../invoiceHelper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TopalFiscalYearSelect } from '../../../newComponents/SelectFiscalYear';
import CircularProgress from '@material-ui/core/CircularProgress';

const Logger = new Log('InvoiceDetails');

function InvoiceDetails(props) {
  Logger.log('props', props);
  const { invoice, onChange, onDueDateChange } = props;

  const setField = (field) => (data) => {
    Logger.log('setField', field, data);
    onChange({ ...invoice, [field]: data });
  };

  const setCheckboxField = (field) => (e) => {
    Logger.log('setCheckboxField', field, 'e:', e.target.checked);
    onChange({ ...invoice, [field]: e.target.checked });
  };

  const handleDate = (f) => (e) => {
    const t = moment(e).format('YYYY-MM-DD');
    onDueDateChange({ ...invoice, [f]: t });
  };

  let total = invoice.amounts
    ? invoice.amounts.reduce((prev, curr) => prev + curr.amount, 0)
    : invoice.amount;
  total = total ? total.toFixed(2) : total;

  return invoice ? (
    <Box display="flex" justifyContent="center" className="invoiceDetails">
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <ImageComponent imagePreviewUrl={invoice ? invoice.base64Value : null} />
        </Grid>
        <Grid item xs={6}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Summary
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <dl>
                <dt>ID</dt>
                <dd>{invoice.invoiceId}</dd>
                {invoice.fibuError && checkNewOpenRejectedState(invoice) ? (
                  <>
                    <dt style={{ color: 'red' }}> Error: </dt>
                    <dd>{invoice.fibuError}</dd>
                  </>
                ) : null}
                {invoice.extractionErrors &&
                invoice.extractionErrors.length > 0 &&
                checkNewOpenState(invoice) ? (
                  <>
                    <dt style={{ color: 'red' }}> Extraction Errors: </dt>
                    <dd>
                      {invoice.extractionErrors.map((item) => (
                        <p key={item}>{item}</p>
                      ))}
                    </dd>
                  </>
                ) : null}
                {invoice.extractionWarnings &&
                invoice.extractionWarnings.length > 0 &&
                checkNewOpenState(invoice) ? (
                  <>
                    <dt style={{ color: 'orange' }}> Extraction Warnings: </dt>
                    <dd>
                      {invoice.extractionWarnings.map((item) => (
                        <p key={item}>{item}</p>
                      ))}
                    </dd>
                  </>
                ) : null}
                {invoice.merchant && invoice.merchant.name ? (
                  <>
                    <dt> Merchant</dt>
                    <dd>{invoice.merchant.name}</dd>
                    <dt />
                    <dd>{invoice.vatNumber}</dd>
                    <dt> Merchant Address</dt>
                    <dd>{invoice.merchant.address1}</dd>
                    <dt />
                    <dd>
                      {invoice.merchant.zip} {invoice.merchant.city}
                    </dd>
                  </>
                ) : null}
                {invoice.freeInvoiceNum ? (
                  <>
                    <dt> Invoice Number</dt>
                    <dd>{invoice.freeInvoiceNum}</dd>
                  </>
                ) : null}
                {invoice.amounts ? (
                  <>
                    <dt> Amount</dt>
                    <dd>
                      {total} {invoice.currency}
                    </dd>
                  </>
                ) : null}
                {invoice.invoiceDate ? (
                  <>
                    <dt> Date of Invoice</dt>
                    <dd>{moment(invoice.invoiceDate).format('DD. MMM YYYY')}</dd>
                  </>
                ) : null}
                {invoice.dueDate ? (
                  <>
                    <dt> Invoice Due Date</dt>
                    <dd>{moment(invoice.dueDate).format('DD. MMM YYYY')}</dd>
                  </>
                ) : null}
                {invoice.state !== 'new' && invoice.state !== 'paid' ? (
                  <>
                    <dt> Scheduled Payment Date</dt>
                    {invoice.state === 'open' || invoice.state === 'approved' ? (
                      <dd>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            variant="inline"
                            value={invoice.dueDateScheduled}
                            onChange={handleDate('dueDateScheduled')}
                            format="dd. MMM yyyy"
                            fullWidth
                            inputProps={{
                              style: { fontSize: '14px' },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <TodayIcon style={{ color: '#BD2B82' }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </dd>
                    ) : (
                      <dd>{moment(invoice.dueDateScheduled).format('DD. MMM YYYY')}</dd>
                    )}
                  </>
                ) : null}
                {invoice.paidDatum ? (
                  <>
                    <dt> Paid Date</dt>
                    <dd> {moment(invoice.paidDatum).format('DD. MMM YYYY')} </dd>
                  </>
                ) : null}
              </dl>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr />
          {isCreditor(invoice) ? (
            <>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  Payment Instruction
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <dl>
                    {invoice.payment && invoice.payment.account_number ? (
                      <>
                        <dt> Account</dt>
                        <dd>{invoice.payment.account_number}</dd>
                      </>
                    ) : null}
                    {invoice.payment && invoice.payment.esr ? (
                      <>
                        <dt> Reference Number</dt>
                        <dd>{invoice.payment.esr}</dd>
                      </>
                    ) : null}
                    {invoice.payment && invoice.payment.iban ? (
                      <>
                        <dt> IBAN</dt>
                        <dd>{invoice.payment.iban}</dd>
                      </>
                    ) : null}
                    {invoice.payment && invoice.payment.bic ? (
                      <>
                        <dt> SWIFT</dt>
                        <dd>{invoice.payment.bic}</dd>
                      </>
                    ) : null}
                    {invoice.payment && invoice.payment.qrIBAN ? (
                      <>
                        <dt> QR-IBAN</dt>
                        <dd>{invoice.payment.qrIBAN}</dd>
                      </>
                    ) : null}
                    {invoice.payment && invoice.payment.qrESR ? (
                      <>
                        <dt> QR-ESR</dt>
                        <dd>{invoice.payment.qrESR}</dd>
                      </>
                    ) : null}
                    {invoice.payment && invoice.payment.text ? (
                      <>
                        <dt> Text </dt>
                        <dd>{invoice.payment.text}</dd>
                      </>
                    ) : null}
                  </dl>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <hr />
            </>
          ) : (
            <div />
          )}

          {isCreditor(invoice) ? (
            <div>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  Payment Account
                </ExpansionPanelSummary>
                <div className="box">
                  <FormControl
                    disabled={
                      !(
                        invoice.state === 'open' ||
                        invoice.state === 'rejected' ||
                        invoice.state === 'extraction_done'
                      )
                    }
                    style={{ display: 'block' }}
                  >
                    <DebitAccountSelect
                      label="Payment Account"
                      value={invoice.debitAccounts || ''}
                      onChange={setField('debitAccounts')}
                    />
                  </FormControl>
                </div>
              </ExpansionPanel>
              <hr />
            </div>
          ) : (
            <div />
          )}
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1d-header"
            >
              Booking Information
            </ExpansionPanelSummary>

            <div className="box">
              <FormControl
                disabled={!(invoice.state === 'open' || invoice.state === 'extraction_done')}
                style={{ display: 'block' }}
              >
                <TopalCostCenterSelect
                  label="Cost Center"
                  value={invoice.costCenter}
                  onChange={setField('costCenter')}
                />
              </FormControl>
              <FormControl
                disabled={!(invoice.state === 'open' || invoice.state === 'extraction_done')}
                style={{ display: 'block', marginTop: '10px' }}
              >
                <TopalFiscalYearSelect
                  style={{ marginTop: '10px' }}
                  label="Fiscal Year"
                  value={invoice.topalFiscalID}
                  onChange={setField('topalFiscalID')}
                />
              </FormControl>

              <FormControlLabel
                style={{ marginLeft: '10px!important', marginBottom: '30px', marginTop: '10px' }}
                control={
                  <Checkbox
                    checked={invoice.toPay === true}
                    onChange={setCheckboxField('toPay')}
                    disabled={!checkNewOpenRejectedState(invoice) || invoice.type === DEBTOR}
                  />
                }
                label="Pay Invoice"
              />
            </div>
          </ExpansionPanel>
          {props.syncDone === false ? (
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <CircularProgress />
              <span style={{ marginLeft: '20px' }}>Syncing Invoice</span>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  ) : null;
}

export default InvoiceDetails;
