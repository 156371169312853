
import dbCompany from '../../firebasecomponents/company';
import dbCustomer from '../../firebasecomponents/customer';

const COMPANY_LOADED = "COMAPNY_LOADED";
const COMPANIES_LOADED = "COMPANIES_LOADED";
const CUSTOMERS_LOADED = "CUSTOMERS_LOADED";
const REPORTS_LOADED = "REPORTS_LOADED";
const STATEMENTS_LOADED = "STATEMENTS_LOADED";
const COMPANY_UPDATE = "COMPANY_UPDATE";
const CLEAR_DONE = "COMPANY_CLEAR_DONE";
const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
const STATEMENTS_UPDATE = "STATEMENTS_UPDATE";

/** reducer */
export default function reducer(state = {}, action) {
  if (action.type === COMPANY_LOADED) {
    return { ...state, currentCompany: action.payload };
  }
  if (action.type === COMPANIES_LOADED) {
    return { ...state, companiesToSelect: action.payload };
  }
  if (action.type === CUSTOMERS_LOADED) {
    return { ...state, customers: action.payload };
  }
  if (action.type === REPORTS_LOADED) {
    return { ...state, reports: action.payload };
  }
  if (action.type === STATEMENTS_LOADED) {
    return { ...state, statements: action.payload };
  }
  if (action.type === COMPANY_UPDATE) {
    return { ...state, done: true };
  }
  if (action.type === CUSTOMER_UPDATE) {
    return { ...state, done: true };
  }
  if (action.type === CLEAR_DONE) {
    return { ...state, done: false };
  }
  return state;
};

/** actions */

export function setCompany(companyId) {
  return function (dispatch) {
    dbCompany.fetchCompanyDetails(companyId)
      .then(response => {
        dispatch({ type: COMPANY_LOADED, payload: { companyId, ...response } });
      });
  };
};

export function getCompanies(companyId) {
  return function (dispatch) {
    dbCompany.getAllCompanyDetails((response) => dispatch({ type: COMPANIES_LOADED, payload: response }))

  };
};

export function updateCompany(docId, companyData) {
  return function (dispatch) {
    dbCompany.updateCompanyDetails({ docId, companyData }).then((response) => dispatch({ type: COMPANY_UPDATE, payload: response }))
  };
};

export function clearDone() {
  return function (dispatch) {
    dispatch({ type: CLEAR_DONE, payload: {} });
  };
};


export function updateCustomer(customer, del = false) {
  return function (dispatch, getState) {
    let modifyFunction;
    if (del) {
      modifyFunction = dbCustomer.deleteCustomer(customer)
    }
    else if (customer.companyId !== undefined) {
      modifyFunction = dbCustomer.updateCustomerDetails(customer)
    }
    else {
      const companyId = getState().CompanyReducer.currentCompany.companyId;
      modifyFunction = dbCustomer.addCustomer(companyId, customer)
    }

    modifyFunction.then((response) => dispatch({ type: CUSTOMER_UPDATE, payload: response }))
  };
}

export function getCustomers(companyId) {
  return function (dispatch, getState) {
    let id = companyId;
    if (!id) {
      id = getState().CompanyReducer.currentCompany.companyId;
    }
    dbCustomer.getCustomers(id, (response) => dispatch({ type: CUSTOMERS_LOADED, payload: response }))
  };
}

export function modifyCostCenterUser(centerId, userId) {
  return function (dispatch, getState) {
    const [root, project] = centerId.split('@')
    const company = getState().CompanyReducer.currentCompany;
    const center = { ...company.costCenters[root] };
    if (project === undefined) {
      const data = center;
      if (data.uses.includes(userId)) {
        data.uses = data.uses.filter(u => u !== userId)
      } else {
        data.uses.push(userId)
      }
    } else {
      const data = center.projects.find(p => p.id === project);
      if (data.uses.includes(userId)) {
        data.uses = data.uses.filter(u => u !== userId)
      } else {
        data.uses.push(userId)
      }
    }
    dbCompany
      .updateCompanyDetails({ docId: company.companyId, companyData: { ['costCenters.' + root]: center } })
      .then((response) => dispatch({ type: COMPANY_UPDATE, payload: response }))
  };
}
export function modifyCustomerUser(customerId, userId) {
  return function (dispatch, getState) {
    const [root, project] = customerId.split('@')
    const customers = getState().CompanyReducer.customers;
    const customer = { ...customers.find(c => c.id === root) };

    if (project === undefined) {
      const data = customer;
      if (data.uses.includes(userId)) {
        data.uses = data.uses.filter(u => u !== userId)
      } else {
        data.uses.push(userId)
      }
    } else {
      const data = customer.projects.find(p => p.id === project);
      if (data.uses.includes(userId)) {
        data.uses = data.uses.filter(u => u !== userId)
      } else {
        data.uses.push(userId)
      }
    }
    dbCustomer.updateCustomerDetails(customer).then((response) => dispatch({ type: CUSTOMER_UPDATE, payload: response }))

  };
}

export function getReports(companyId) {
  return function (dispatch) {
    dbCompany.getReports(companyId, response => {
      dispatch({ type: REPORTS_LOADED, payload: response });
    });
  };
}


export function getStatements(companyId) {
  return function (dispatch) {
    dbCompany.getStatements(companyId, response => {
      dispatch({ type: STATEMENTS_LOADED, payload: response });
    });
  };
}

export function uploadStatements(companyId, statements) {
  return function (dispatch) {

    const promises = statements.map(s => {
      const { minDate, maxDate, amounts, ...rest } = s;
      const am = amounts.map(a => ({
        ...a,
        date: a.date.format('DD-MM-YYYY')
      }))

      dbCompany.addStatement({
        companyId: companyId,
        minDate: minDate.format('DD-MM-YYYY'),
        maxDate: maxDate.format('DD-MM-YYYY'),
        ...rest,
        amounts: am
      })
    })

    Promise.all(promises).then(() => {
      dispatch({ type: STATEMENTS_UPDATE, payload: {} });
    })

  };
}
