import React from 'react';

import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Badge from '@material-ui/core/Badge';

import { LinkButton } from '../Buttons';
// import { taskStepName } from '../taskRedirect';

import './SortTask.css';

export function SortTaskMonth(props){
  const click = props.click;
  return props.tasksMap ? Object.keys(props.tasksMap).map(key => (
    <ExpansionPanel key={key}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={key + "-content"}
        id={key + "-header"}
      >
        <Badge badgeContent={props.tasksMap[key].length} color="primary">
          <span style={{ paddingRight: 15 }}>{key}</span>
        </Badge>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {props.tasksMap[key].map(task => {
          const name = task.detail ;//|| (task.task_defination + ' - ' + (task.companyName || task.company_name) + ' - ' + (task.empl_name || task.emplId ) + ' [' + task.id + ']');
          return click ?
            (<Button color='primary' key={task.id}
              onClick={()=>click(task)}
            >{name}
            </Button>) :
            (<LinkButton color='primary' key={task.id}
              to={'task/' + task.id}
              value={task.task_defination}
            >{name}
            </LinkButton>)
        }
      )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )) : null;
}

function SortTask(props) {
  const click = props.click;
  return props.tasksMap ? Object.keys(props.tasksMap).map(key => (
    <ExpansionPanel key={key}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={key + "-content"}
        id={key + "-header"}
      >
        <Badge badgeContent={props.tasksMap[key].length} color="primary">
          <span style={{ paddingRight: 15 }}>{key}</span>
        </Badge>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {props.tasksMap[key].map(task => {
          const name = task.detail ;//|| (task.task_defination + ' - ' + (task.companyName || task.company_name) + ' - ' + (task.empl_name || task.emplId ) + ' [' + task.id + ']');
          return click ?
            (<Button color='primary' key={task.id}
              onClick={()=>click(task)}
            >{name}
            </Button>) :
            (<LinkButton color='primary' key={task.id}
              to={'task/' + task.id}
              value={task.task_defination}
            >{name}
            </LinkButton>)
        }
      )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )) : null;
}

export default SortTask;