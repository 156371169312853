import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, FormControl, Input, InputLabel } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { SmallPage } from '../page';

import { Message, validateEmail } from '../../components/Form';
import { SaveButton } from '../../components/Buttons';

import AccountantForm from '../../components/AccountantForm'
import CompanyForm from '../../components/CompanyForm'
import ApproverForm from '../../components/ApproverForm'

import firebase from '../../components/firebase';

import company from '../../firebasecomponents/company';
import accountant from '../../firebasecomponents/accountant';
import approver from '../../firebasecomponents/approver';
import Log from "../../newHelpers/Log";

const roles = [
  {
    value: 'Accountant',
    label: 'Accountant',
  },
  {
    value: 'Company',
    label: 'Company',
  },
  {
    value: 'Approver',
    label: 'Approver'
  }
];
const Logger = new Log('Register');


function Register(props) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');

  const [loading, setLoading] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [message, setMessage] = useState('');

  const [accountantDetails, setAccountantDetails] = useState({
    streetAndNumber: '',
    postCode: '',
    city: '',
    firstName: '',
    lastName: '',
    bankName: '',
    bankPlace: '',
    telephoneNumber: '',
    emailId: '',
    employeePosition: '',
    allowedToGiveApprovalForExpense: false,
    accountantId: ''
  });
  const [companyDetails, setCompanyDetails] = useState({
    streetAndNumber: '',
    postCode: '',
    city: '',
    firstName: '',
    lastName: '',
    telephoneNumber: '',
    emailId: '',
    companyName: ''
  });
  const [approverDetails, setApproverDetails] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
  });

  const setCompanyName = (e) => {
    setCompanyDetails({ ...companyDetails, companyName: e.target.value });
  }

  const onNext = (e) => {
    if (name === '' || email === '' || password === '') {
      setMessage('All fields are required');
      return;
    }
    if (!validateEmail(email)) {
      setMessage('Invalid email format');
      return;
    }

    if (password.length < 6) {
      setMessage('Password needs to be at least 6 characters in length');
      return;
    }

    const [fName, ...lName] = name.split(' ')

    setCompanyDetails({ ...companyDetails, emailId: email, firstName: fName, lastName: lName.join(' ') });
    setAccountantDetails({ ...accountantDetails, emailId: email, firstName: fName, lastName: lName.join(' ') });
    setApproverDetails({...approverDetails, emailId: email, firstName: fName, lastName: lName.join(' ') });

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    e.preventDefault();
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onRegister = async (e) => {
    let call;
    if (role === 'Accountant') {
      if (accountantDetails.firstName === '' || accountantDetails.lastName === '') {
        setMessage('First and last name cannot be empty');
        return;
      }

      call = (id) => accountant.addAccountantDetails(accountantDetails,id)
    }
    else if (role ==='Approver') {
      if ( approverDetails.firstName === '' || approverDetails.lastName === ''){
        setMessage('First and last name cannot be empty');
        return
      }
      const exists = await approver.approverMailExists(approverDetails.emailId);
      Logger.log('exists', exists);
      if(!exists){
        setMessage('approvermail does not exist!');
        Logger.log('approvermail does not exist!');
        await firebase.logout();
        return;
      }
      call = (id) => approver.addApproverDetails(approverDetails, id)
    }
    else if (role === 'Company') {
      if (companyDetails.companyName === '') {
        setMessage('Name of the company cannot be empty');
        return;
      }
      if (companyDetails.firstName === '' || companyDetails.lastName === '') {
        setMessage('First and last name cannot be empty');
        return;
      }

      call = (id) =>  company.addCompanyDetails(companyDetails,id).then(([guid, id]) => {
        Logger.log('comp id', guid, 'userId', id)
        firebase.addCompanyId(guid)
      })
    }

    setLoading(true);
    e.preventDefault();
    firebase.register(name, email, password)
      .then(() =>
        firebase.addRole(role)
      )
      .then(() => {
        const id = firebase.getCurrentUserId();
        Logger.log('user Id', id);
        return call(id)
      }
      )
      .then(() => {
        //
        firebase.logout();
        //
        props.history.replace('/')
      }
      )
      .catch(error => {
        setMessage(error.message);
        setLoading(false);
      });
  };

  return (
    <SmallPage title="Register Account" style={{ width: '800px' }}>

      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>Login credentials</StepLabel>
        </Step>
        <Step>
          <StepLabel>Profile data</StepLabel>
        </Step>
      </Stepper>
      <Message message={message} type="error" />
      {activeStep === 0 ? (
        <form>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input id="name" name="name" autoComplete="off" autoFocus value={name} onChange={e => setName(e.target.value)} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email" name="email" autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password" type="password" id="password" autoComplete="off" value={password} onChange={e => setPassword(e.target.value)} />
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onNext}>
            Next
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to="/login">
            Go back to Login
          </Button>

        </form>) : (
          <form>
            <FormControl fullWidth style={{ margin: '20px 0 30px' }}>
              <TextField
                id="standard-select-role"
                select
                label="Select type of profile"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                fullWidth
              >
                {roles.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl>
              {role === 'Company' ?
                <FormControl>
                  <TextField required
                    fullWidth
                    name="companyName"
                    label="Company Name"
                    value={companyDetails.companyName}
                    onChange={setCompanyName}
                  />
                  <CompanyForm
                    newCompany={false}
                    isReadOnly={false}
                    onChange={setCompanyDetails}
                    data={companyDetails} />
                </FormControl> :
                role === 'Accountant' ?
                  <AccountantForm
                    newAccountant={false}
                    isReadOnly={false}
                    onChange={setAccountantDetails}
                    data={accountantDetails} /> :
                role === 'Approver'?
                <ApproverForm
                newApprover={false}
                isReadOnly={false}
                onChange={setApproverDetails}
                data={approverDetails} />
                  :null
              }
            </FormControl>
            <SaveButton disabled={role === ''}
              loading={loading}
              fullWidth
              variant="contained"
              color="primary"
              onClick={onRegister}>
              Register
            </SaveButton>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleBack}>
              Go to prev step
          </Button>
          </form>

        )}


    </SmallPage>
  );


}

export default withRouter(Register);
