import React from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const InvoiceBox = (props) => {
  //7 states: new -> open -> approved -> pending -> paid/(rejected) & (declined from ui)
  const { text } = props;

  const colors = {
    approved: `rgba(144,238,144, 0.2)`,
    paid: `rgba(60,179,113, 0.25)`,
    open: 'aliceblue',
    new: 'ghostwhite',
    extraction_done: 'ghostwhite',
    pending: `rgba(211,211,211, 0.5)`,
    rejected: `rgba(205,92,92, 0.5)`,
    declined: `rgba(205,92,92, 0.5)`,
  };

  const iconprops = {
    position: 'absolute',
    height: '16px',
    left: '8px',
    color: 'black',
  };

  const statesMap = {
    new: 'new',
    open: 'open',
    approved: 'approved',
    pending: 'pending',
    paid: 'paid',
    rejected: 'rejected',
    declined: 'declined',
    extraction_done: 'new',
  };

  const Icon = ({ choice }) => {
    switch (choice) {
      case 'new':
        return <ImportExportIcon style={iconprops} />;
      case 'extraction_done':
        return <ImportExportIcon style={iconprops} />;
      case 'open':
        return <NewReleasesIcon style={iconprops} />;
      case 'approved':
        return <DoneIcon style={iconprops} />;
      case 'paid':
        return <AccountBalanceIcon style={iconprops} />;
      case 'pending':
        return <SyncAltIcon style={iconprops} />;
      case 'declined':
        return <NotInterestedIcon style={iconprops} />;
      case 'rejected':
        return <ErrorIcon style={iconprops} />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        border: '1px solid lightslategrey',
        backgroundColor: colors[text],
        borderRadius: '16px',
        padding: '7px',
        width: '100%',
        textAlign: 'center',
        textTransform: 'uppercase',
        position: 'relative',
        minWidth: '120px',
      }}
    >
      <Icon choice={text} />
      <span>{statesMap[props.text]}</span>
    </div>
  );
};
export default InvoiceBox;
